import * as React from 'react';

function SvgStop(props) {
	return (
		<svg enableBackground='new 0 0 24 24' viewBox='0 0 24 24' {...props}>
			<path
				d='m19.2 3h-14.203c-1.099 0-1.997.898-1.997 1.997v14.203c0 .99.81 1.8 1.8 1.8h14.4c.99 0 1.8-.81 1.8-1.8v-14.4c0-.99-.81-1.8-1.8-1.8z'
				style={{
					fill: 'none',
					stroke: '#8b90a7',
					strokeWidth: 1,
					strokeLinecap: 'round',
					strokeLinejoin: 'round',
					strokeMiterlimit: 10,
				}}
			/>
		</svg>
	);
}

export default SvgStop;
