import * as React from 'react';

function SvgInboxFull(props) {
	return (
		<svg enableBackground='new 0 0 24 24' viewBox='0 0 24 24' {...props}>
			<g
				style={{
					fill: 'none',
					stroke: '#000',
					strokeWidth: 2,
					strokeLinecap: 'round',
					strokeLinejoin: 'round',
					strokeMiterlimit: 10,
				}}
			>
				<path d='m14.816 14c-.413 1.163-1.512 2-2.816 2s-2.403-.837-2.816-2h-8.184v6c0 1.104.895 2 2 2h18c1.105 0 2-.896 2-2v-6z' />
				<path d='m2 10h20' />
				<path d='m2 6h20' />
				<path d='m2 2h20' />
			</g>
		</svg>
	);
}

export default SvgInboxFull;
