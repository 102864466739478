import React from 'react';
import css from './BadgeIcon.module.scss';
import classes from 'classnames';
import { Badge, IBadgeProps } from 'components/Badge';
import { Icon, IIconProps } from 'components/Icon';

interface IBadgeIconProps {
	className?: string;
	icon: IIconProps;
	badge?: IBadgeProps;
	position?: 'bottom' | 'default';
}

export const BadgeIcon: React.FC<IBadgeIconProps> = props => {
	const { className, children, icon, badge, position } = props;
	return (
		<span
			className={classes(
				className,
				css.badgeIcon,
				icon.size ? css[icon.size] : css.medium,
				css[position]
			)}
		>
			<Icon {...icon} className={classes(icon?.className, css.icon)}></Icon>
			<Badge {...badge} className={classes(badge?.className, css.badge, { [css.noText]: !children })}>
				{children}
			</Badge>
		</span>
	);
};
