import React, { useRef, useEffect } from 'react';
import css from './Modal.module.scss';
import classes from 'classnames';
import { Container } from 'components/Container';
import ReactDOM from 'react-dom';
import { Button } from 'components/Button';
import { useToggle } from 'common/hooks/useToggle';
import 'wicg-inert';
import { IModalProps } from 'common/interfaces/IModalProps';
import { disableBodyScroll, enableBodyScroll } from 'common/utils/bodyScrollLock';

export const Modal: React.FC<IModalProps> = props => {
	const toggle = useToggle(true);

	const {
		Content,
		className,
		heading,
		removeModal,
		sourceEvent,
		primaryButtonType = 'primary',
		primaryButtonAction = () => toggle.toggle(),
		primaryButtonLabel = 'Ok',
		hasSecondaryButton = true,
		secondaryButtonAction = () => toggle.toggle(),
		secondaryButtonLabel = 'Close',
		secondaryButtonType = 'secondary',
		isOpen = false,
		backdropClassName,
		hasPrimaryButton,
		headerClassName,
		closeIconColor,
		primaryButtonLoading = false,
		innerClassName
	} = props;

	const modalRef = useRef(null);
	const modalDescriptionRef = useRef(null);
	const backdropRef = useRef(null);

	useEffect(() => {
		toggle.setRef(modalRef);
		if (sourceEvent) {
			toggle.setSourceEvent(sourceEvent);
		}

		if (toggle.isShown) {
			if (modalDescriptionRef && modalDescriptionRef.current) {
				modalDescriptionRef.current.focus();
			}
		} else {
			removeModal();
		}
		// eslint-disable-next-line
	}, [toggle, modalDescriptionRef, isOpen]);

	useEffect(() => {
		const rootElement = document.querySelector('#root');
		const backdropRefCurrent = backdropRef.current;
		disableBodyScroll(backdropRefCurrent);
		// @ts-ignore
		rootElement.inert = true;
		return () => {
			const rootElement = document.querySelector('#root');
			// @ts-ignore
			rootElement.inert = false;
			enableBodyScroll(backdropRefCurrent);
		};
	}, []);

	return ReactDOM.createPortal(
		<div className={classes(css.backdrop, backdropClassName)} ref={backdropRef}>
			<Container className={css.container}>
				<div
					className={classes(css.modal, className)}
					onClick={event => event.stopPropagation()}
					ref={modalRef}
					role='dialog'
					aria-labelledby='modalHeading'
					aria-describedby='modalDescription'
				>
					<div id='modalDescription' className='screenReaderText'>
						Beginning of dialog window. Escape will cancel and close the window.
					</div>
					<div className={classes(css.header, headerClassName)}>
						{heading && (
							<h2
								id='modalHeading'
								className={classes('h4', css.heading)}
								tabIndex={0}
								ref={modalDescriptionRef}
							>
								{heading}
							</h2>
						)}
						<Button
							tooltipMessage='Close Modal'
							onClick={(event: any) => {
								event.persist();
								toggle.setIsShown(false);
							}}
							iconLeft={{
								type: 'Cancel',
								size: 'large',
								color: closeIconColor || 'gray-1'
							}}
							type='link'
						></Button>
					</div>
					<div className={classes(innerClassName, css.inner)}>
						<Content />
					</div>
					{(hasPrimaryButton || hasSecondaryButton) && (
						<div className={css.footer}>
							{hasPrimaryButton && (
								<Button
									type={primaryButtonType}
									onClick={(event: any) => {
										event.persist();
										primaryButtonAction();
									}}
									isLoading={primaryButtonLoading}
									className={css.primary}
								>
									{primaryButtonLabel}
								</Button>
							)}
							{hasSecondaryButton && (
								<Button
									type={secondaryButtonType}
									onClick={(event: any) => {
										event.persist();
										secondaryButtonAction();
									}}
									className={css.secondary}
								>
									{secondaryButtonLabel}
								</Button>
							)}
						</div>
					)}
				</div>
			</Container>
		</div>,
		document.body
	);
};
