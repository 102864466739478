import React from 'react';

export { HomeView } from 'views/HomeView';
export { LoginView } from 'views/LoginView';

export const FeedbackView = React.lazy(() => import('./FeedbackView'));
export const FeedbackEditView = React.lazy(() => import('./FeedbackView/FeedbackEditView'));
export const FeedbackCompleteView = React.lazy(() => import('./FeedbackCompleteView'));
export const FeedbackSessionHistory = React.lazy(() => import('./FeedbackSessionHistory'));
export const InfractionView = React.lazy(() => import('./InfractionView'));
export const InfractionReviewView = React.lazy(() => import('./InfractionReviewView'));
export const InfractionCompleteView = React.lazy(() => import('./InfractionCompleteView'));
export const InfractionHistoryView = React.lazy(() => import('./InfractionHistoryView'));
export const ReviewView = React.lazy(() => import('./ReviewView'));
export const WelcomeView = React.lazy(() => import('./WelcomeView'));
export const TeamMemberView = React.lazy(() => import('./TeamMemberView'));
export const TeamView = React.lazy(() => import('./TeamView'));
export const AccountChangeDetails = React.lazy(() => import('./AccountChangeDetails'));
export const ForgotPasswordView = React.lazy(() => import('./ForgotPasswordView'));
export const AccountSetupView = React.lazy(() => import('./AccountSetupView'));
export const ResetPasswordView = React.lazy(() => import('./ResetPasswordView'));
export const AdminFrameworksView = React.lazy(() => import('./AdminFrameworksView'));
export const AdminCategoriesView = React.lazy(() => import('./AdminCategoriesView'));
export const AdminAddCategoryView = React.lazy(() => import('./AdminAddCategoryView'));
export const AdminEditCategoryView = React.lazy(() => import('./AdminEditCategoryView'));
export const AdminAddUserView = React.lazy(() => import('./AdminAddUserView'));
export const AdminUsersView = React.lazy(() => import('./AdminUsersView'));
export const AdminGeneralSettingsView = React.lazy(() => import('./AdminGeneralSettings'));
export const AdminArchivedUsersView = React.lazy(() => import('./AdminArchivedUsersView'));
export const AdminEditUserView = React.lazy(() => import('./AdminEditUserView'));
export const MyAccountView = React.lazy(() => import('./MyAccountView'));
export const PlanPurchaseView = React.lazy(() => import('./PlanPurchaseView'));
export const ConfirmEmailView = React.lazy(() => import('./ConfirmEmailView'));
export const SignUpView = React.lazy(() => import('./SignUpView'));
export const CompleteYourAccountView = React.lazy(() => import('./CompleteYourAccountView'));
export const PlanHistoryView = React.lazy(() => import('./PlanHistoryView'));
export const PlanOverviewView = React.lazy(() => import('./PlanOverviewView'));
export const CompanyDetailsView = React.lazy(() => import('./CompanyDetailsView'));
export const PlanBillingView = React.lazy(() => import('./PlanBillingView'));
export const TrialEndedView = React.lazy(() => import('./TrialEndedView'));
export const GettingStartedView = React.lazy(() => import('./GettingStartedView'));
