import * as React from 'react';

function SvgPageCheck(props) {
	return (
		<svg enableBackground='new 0 0 24 24' viewBox='0 0 24 24' {...props}>
			<g
				style={{
					fill: 'none',
					stroke: '#000',
					strokeWidth: 2,
					strokeLinecap: 'round',
					strokeLinejoin: 'round',
					strokeMiterlimit: 10,
				}}
			>
				<path d='m21 9h-8v-8' />
				<path d='m9.667 16.667 2 1.333 2.666-4' />
				<path d='m19 23h-14c-1.105 0-2-.895-2-2v-18c0-1.105.895-2 2-2h8l8 8v12c0 1.105-.896 2-2 2z' />
			</g>
		</svg>
	);
}

export default SvgPageCheck;
