import * as React from 'react';

function SvgUserCircle(props) {
	return (
		<svg enableBackground='new 0 0 24 24' viewBox='0 0 24 24' {...props}>
			<g
				style={{
					fill: 'none',
					stroke: '#000',
					strokeWidth: 1,
					strokeLinecap: 'round',
					strokeLinejoin: 'round',
					strokeMiterlimit: 10,
				}}
			>
				<circle cx={12} cy={10.223} r={4} />
				<path d='m18.631 21c-.939-1.611-3.563-2.777-6.631-2.777s-5.692 1.166-6.631 2.777' />
				<path d='m21.368 17.967c1.028-1.673 1.632-3.636 1.632-5.744 0-6.075-4.925-11-11-11s-11 4.925-11 11c0 2.108.604 4.071 1.632 5.745' />
			</g>
		</svg>
	);
}

export default SvgUserCircle;
