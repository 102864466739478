import React from 'react';
import css from './Alert.module.scss';
import classes from 'classnames';
import { Button } from 'components/Button/Button';
import { TAlertTypes } from 'common/types/TAlertTypes';
import { Panel } from 'components/Panel/Panel';
import { Icon } from 'components/Icon/Icon';
import { TIconTypes } from 'common/types/TIconTypes';
import { TColors } from 'common/types/TColors';

interface IAlertProps {
	className?: string;
	type?: TAlertTypes;
	isDismissable?: boolean;
	setIsShown?: React.Dispatch<React.SetStateAction<boolean>>;
	accessibilityRole?: 'alert' | 'status';
}

export const Alert: React.FC<IAlertProps> = props => {
	const {
		type = 'info',
		className,
		children,
		isDismissable = false,
		setIsShown,
		accessibilityRole = 'alert'
	} = props;
	let iconType: TIconTypes = 'Info';
	let iconColor: TColors = 'white';
	switch (type) {
		case 'success':
			iconType = 'Check';
			break;
		case 'warning':
			iconType = 'Warning';
			break;
		case 'danger':
			iconType = 'Info';
			iconColor = 'white';
			break;
		case 'danger-light':
			iconType = 'Info';
			iconColor = 'red';
			break;
		case 'success-light':
			iconType = 'Check';
			iconColor = 'green';
			break;
	}
	const alertContent = (
		<div
			className={classes(css.alert, css[type], className)}
			role={accessibilityRole}
			onClick={() => {
				isDismissable === true && setIsShown(false);
			}}
		>
			<div className={css.iconWrap}>
				<Icon className={css.icon} type={iconType} color={iconColor}></Icon>
			</div>
			<div className={css.text}>{children}</div>
			{isDismissable && (
				<Button
					type='link'
					onClick={() => setIsShown(false)}
					iconLeft={{
						type: 'Cancel',
						color: 'black'
					}}
					tooltipMessage='Dismiss Message'
					className={css.dismissMessage}
				></Button>
			)}
		</div>
	);
	return (
		<Panel hasPadding={false} className={css.alertPanel}>
			{alertContent}
		</Panel>
	);
};
