import { IExampleStoreState } from './exampleStoreStateInterface';
import { Reducer } from 'redux';
import { ActionType } from 'typesafe-actions';
import * as exampleStoreActions from './exampleStoreActions';
import { clone } from 'ramda';

type exampleStoreActionsInterfaces = ActionType<typeof exampleStoreActions>;

// Example Store State
const initialState: IExampleStoreState = {
	integer: 1,
	post: null,
	postLoadingState: 'Empty'
};

// Reducer updates the state.
// Takes action and copy of current state
// Returns new copy of state
export const ExampleStoreReducer: Reducer<IExampleStoreState> = (
	state = initialState,
	action: exampleStoreActionsInterfaces
) => {
	const clonedState = clone(state);
	// Switch through each action
	switch (action.type) {
		case 'INCREMENT_INTEGER':
			return {
				...clonedState,
				integer: clonedState.integer + 1
			};

		case 'DECREMENT_INTEGER':
			return {
				...clonedState,
				integer: clonedState.integer - 1
			};

		case 'INCREMENT_BY_AMOUNT':
			return {
				...clonedState,
				integer: clonedState.integer + action.payload
			};

		case 'GET_POST_REQUEST':
			return {
				...clonedState,
				postLoadingState: 'Loading'
			};

		case 'GET_POST_SUCCESS':
			return {
				...clonedState,
				post: action.payload,
				postLoadingState: 'Loaded'
			};

		case 'GET_POST_ERROR':
			return {
				...clonedState,
				postLoadingState: 'Error'
			};

		//Ensures that you define all of the reducer scenarios
		default:
			const exhaustiveCheck: never = action; // eslint-disable-line
	}
	return state || initialState;
};
