import React, { useEffect } from 'react';
import './App.scss';
import { Routes } from 'routes';
import { Provider } from 'react-redux';
import { store, history } from './stores';
import { ConnectedRouter } from 'connected-react-router';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { MessageProvider } from 'components/Message/MessageProvider';
import { ModalProvider } from 'components/Modal/ModalProvider';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import { useLocation } from 'react-router-dom';
// @ts-ignore
function ScrollToTop() {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	return null;
}

// export default withRouter(ScrollToTop);

const App: React.FC = () => {
	useEffect(() => {
		setTimeout(() => {
			console.log('backstopjs_ready');
		}, 1000);
	}, []);

	return (
		<ErrorBoundary>
			{/* Provider is giving access to the redux store */}
			<Provider store={store}>
				{/* Connected Router is connecting routes to the redux store */}
				<ConnectedRouter history={history}>
					<ScrollToTop></ScrollToTop>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<MessageProvider>
							<ModalProvider>{Routes}</ModalProvider>
						</MessageProvider>
					</MuiPickersUtilsProvider>
				</ConnectedRouter>
			</Provider>
		</ErrorBoundary>
	);
};

export const handleFirstTab = (event: KeyboardEvent) => {
	if (event.keyCode === 9) {
		// the "I am a keyboard user" key
		document.body.classList.add('user-is-tabbing');
		window.removeEventListener('keydown', handleFirstTab);
	}
};

window.addEventListener('keydown', handleFirstTab);

export default App;
