import React, { useEffect, useState } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { LoadingDelay } from 'components/LoadingDelay/LoadingDelay';
import { Spinner } from 'components/Spinner';
import { useAuth } from 'components/Auth';

// A route component that extends Route
// Uses the auth custom hook to check if current user is authenticated
// IF not authenticated then redirects to the /login page.
// Passes the last visited location to the Login state so upon successful login
// The user can be Redirected back to desired route
export const PublicRoute: React.FC<RouteProps> = props => {
	const { getIsAuthenticated } = useAuth();
	const { children, ...remainingProps } = props;
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [isAuthenticatedLoaded, setIsAuthenticatedLoaded] = useState(false);

	useEffect(() => {
		(async () => {
			const isAuthenticated = await getIsAuthenticated();
			setIsAuthenticated(isAuthenticated);
			setIsAuthenticatedLoaded(true);
		})();
	}, [getIsAuthenticated]);

	if (!isAuthenticatedLoaded) {
		return (
			<LoadingDelay>
				<Spinner type='darker' size='large' isCenteredHorizontally isCenteredVertically></Spinner>
			</LoadingDelay>
		);
	}

	return (
		<Route
			{...remainingProps}
			// The location (below) exists on the <Route /> props.
			render={({ location }) =>
				!isAuthenticated ? (
					children
				) : (
					<Redirect
						to={{
							pathname: '/',
							// pass location state (contains url to redirect back to after auth success)
							state: { from: location }
						}}
					/>
				)
			}
		/>
	);
};
