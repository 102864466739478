import { useAuth } from 'components/Auth/AuthProvider';
import { useGetCurrentAuthenticatedTeamMemberQuery } from 'generated/graphql';

export const useCurrentUser = () => {
	const { currentUserId } = useAuth();
	const { data: getUserData, loading, error } = useGetCurrentAuthenticatedTeamMemberQuery({
		variables: {
			id: currentUserId
		}
	});

	const currentUser = getUserData?.TeamMember[0];

	return { currentUser, loading, error };
};
