import React from 'react';
import { DropdownList } from 'components/DropdownList';
import { useAuth } from 'components/Auth';
import { useCurrentUser } from 'common/hooks/useCurrentUser';
import css from './DropdownAccount.module.scss';

interface IDropdownAccountProps {
	isOpen?: boolean;
}

export const DropdownAccount: React.FC<IDropdownAccountProps> = props => {
	const { isOpen } = props;
	// eslint-disable-next-line
	const { userLogout } = useAuth();
	const { currentUser, loading, error } = useCurrentUser();

	return (
		<>
			{!loading && !error && currentUser && (
				<DropdownList
					type='control'
					items={[
						{ title: 'Getting started', action: '/gettingStarted' },
						{ title: 'My account', action: '/myAccount' },
						{ title: 'Log out', action: userLogout }
					]}
					size='small'
					iconRight={{ type: 'CaretDown', color: 'blue' }}
					className={css.dropdownAccount}
					dropdownPosition='right'
					isOpen={isOpen}
					enableHover={true}
				>
					<div className={css.initials}>
						<div className={css.intialsInner}>
							{currentUser.firstName &&
								currentUser.firstName.substr(0, 1) + currentUser.lastName.substr(0, 1)}
						</div>
					</div>
					<div className={css.text}>
						<div className={css.name}>
							{currentUser.firstName && currentUser.firstName + ' ' + currentUser.lastName}
						</div>
						<div className={css.title}>{currentUser.title}</div>
					</div>
				</DropdownList>
			)}
		</>
	);
};
