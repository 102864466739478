import React from 'react';
import css from './Badge.module.scss';
import classes from 'classnames';

export interface IBadgeProps {
	className?: string;
	color?: 'blue' | 'white' | 'orange';
}

export const Badge: React.FC<IBadgeProps> = props => {
	const { className, children, color = 'blue' } = props;
	return (
		<span className={classes(className, css.badge, css[color], { [css.noText]: !children })}>
			{children}
		</span>
	);
};
