import * as React from 'react';

function SvgNavCurveUp(props) {
	return (
		<svg width={17} height={18} viewBox='0 0 17 18' fill='none' {...props}>
			<path d='M17 18L17 0C17 13.6 5.50476 17.6667 -7.86805e-07 18L17 18Z' fill='white' />
		</svg>
	);
}

export default SvgNavCurveUp;
