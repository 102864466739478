import { authConfig } from './authConfig';
import { authApiService } from 'components/Auth/authApiService';
import { GraphQLError } from 'graphql';
import { isAccessTokenExpired } from 'common/utils/isAccessTokenExpired';

import {
	ApolloClient,
	createHttpLink,
	ApolloLink,
	Observable,
	split,
	InMemoryCache
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { WebSocketLink } from '@apollo/client/link/ws';
import { onError } from '@apollo/client/link/error';
import { getMainDefinition } from '@apollo/client/utilities';

const isInvalidJWTGraphQLError = (errors: readonly GraphQLError[]): boolean => {
	if (errors) {
		const graphQLJWTError = errors.filter(error => error.extensions.code === 'invalid-jwt');
		if (graphQLJWTError.length > 0) {
			return true;
		}
	}
	return false;
};

/**
 * URI to connect to must be able to handle Web Sockets or there will be an error
 */
const uri = process.env.REACT_APP_GRAPH_QL_URL;

export const authorizeRequestHeaders: ApolloLink = setContext((_, { headers }) => {
	if (authConfig.accessToken) {
		return {
			headers: {
				...headers,
				Authorization: `Bearer ${authConfig.accessToken}`
			}
		};
	} else {
		return headers;
	}
});

export const enhancedErrorHandling: ApolloLink = onError(
	({ graphQLErrors, networkError, operation, forward }) => {
		console.log('GRAPHQL ERROR: ', graphQLErrors);
		console.log('GRAPHQL NETWORK ERROR: ', networkError);
		// Refresh JWT Catch
		if (isInvalidJWTGraphQLError(graphQLErrors)) {
			return new Observable(observer => {
				const oldHeaders = operation.getContext().headers;
				authApiService
					.refreshAccessToken()
					.then(accessToken => {
						authConfig.accessToken = accessToken;
						operation.setContext({
							headers: {
								...oldHeaders,
								Authorization: `Bearer ${authConfig.accessToken}`
							}
						});
					})
					.then(() => {
						const subscriber = {
							next: observer.next.bind(observer),
							error: observer.error.bind(observer),
							complete: observer.complete.bind(observer)
						};
						forward(operation).subscribe(subscriber);
					})
					.catch(error => {
						observer.error(error);
					});
			});
		}
	}
);

const wsLink = new WebSocketLink({
	uri: `wss${uri}`,
	options: {
		lazy: true, //wait for accessToken
		reconnect: true,
		connectionParams: async () => {
			// If no access token or token is invalid lets attempt a refresh
			if (!authConfig.accessToken || isAccessTokenExpired(authConfig.accessToken)) {
				const accessToken = await authApiService.refreshAccessToken();
				authConfig.accessToken = accessToken;
			}

			return {
				headers: {
					Authorization: `Bearer ${authConfig.accessToken}`
				}
			};
		}
	}
});

const httpLink = ApolloLink.from([
	authorizeRequestHeaders,
	createHttpLink({
		uri: `https${uri}`
	})
]);

/**
 * Route requests for Websockets and HTTP to correct link
 */

const connectionLink: ApolloLink = split(
	({ query }) => {
		const definition = getMainDefinition(query);
		return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
	},
	wsLink,
	httpLink
);

/**
 * Create new ApolloClient with link and use enhanced error handling
 */
// export const initApolloClient = () => {
// 	return new ApolloClient({
// 		link: ApolloLink.from([enhancedErrorHandling, connectionLink]),
// 		cache: new InMemoryCache(),
// 		connectToDevTools: true
// 	});
// };

export const client = new ApolloClient({
	link: ApolloLink.from([enhancedErrorHandling, connectionLink]),
	cache: new InMemoryCache(),
	connectToDevTools: true
});
