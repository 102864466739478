import * as React from 'react';

function SvgArrowRight(props) {
	return (
		<svg enableBackground='new 0 0 24 24' viewBox='0 0 24 24' {...props}>
			<g
				style={{
					fill: 'none',
					stroke: '#000',
					strokeWidth: 2,
					strokeLinecap: 'round',
					strokeLinejoin: 'round',
					strokeMiterlimit: 10,
				}}
			>
				<path d='m13 19 8-7-8-7' />
				<path d='m21 12h-18' />
			</g>
		</svg>
	);
}

export default SvgArrowRight;
