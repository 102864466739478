import React, { useState, useContext, useCallback } from 'react';
import { Modal } from './Modal';

const ModalContext = React.createContext(null);

export const ModalProvider: React.FC = props => {
	const { children } = props;
	const [modalProps, setModalProps] = useState(null);

	const removeModal = useCallback(() => {
		setModalProps(null);
	}, [setModalProps]);

	const createModal = useCallback(
		(props: any) => {
			props.removeModal = removeModal;
			setModalProps(props);
		},
		[setModalProps, removeModal]
	);

	return (
		<ModalContext.Provider
			value={{
				createModal,
				removeModal
			}}
		>
			{modalProps && <Modal {...modalProps}></Modal>}
			{children}
		</ModalContext.Provider>
	);
};

const useModal = () => {
	const modalHelpers: {
		createModal: (modal: any) => void;
		removeModal: () => {};
	} = useContext(ModalContext);

	return modalHelpers;
};

export { ModalContext, useModal };
