import * as React from 'react';

function SvgInformation(props) {
	return (
		<svg width={24} height={24} viewBox='0 0 24 24' fill='none' {...props}>
			<path
				d='M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z'
				stroke='#0079A8'
				strokeWidth={1.5}
				strokeMiterlimit={10}
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M12 13L14.342 11.829C15.358 11.321 16 10.282 16 9.146V9C16 7.343 14.657 6 13 6H12C10.343 6 9 7.343 9 9'
				stroke='#0079A8'
				strokeWidth={1.5}
				strokeMiterlimit={10}
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M12 17.01V17'
				stroke='#0079A8'
				strokeWidth={1.5}
				strokeMiterlimit={10}
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
}

export default SvgInformation;
