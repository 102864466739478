import { decodeJWT } from 'common/utils/decodeJWT';
import { IAccessTokenData } from 'common/interfaces/IAccessTokenData';

export const isAccessTokenExpired = (accessToken: string) => {
	//check if token is within expiration time
	const decodedToken = decodeJWT<IAccessTokenData>(accessToken);
	const fiftyNineMinutesInSeconds = Math.floor(Date.now() / 1000);
	//Check if token will expire in the next minute
	const tokenExpirationInSeconds = decodedToken.exp - fiftyNineMinutesInSeconds;

	const isAccessTokenExpired = tokenExpirationInSeconds <= 0;
	return isAccessTokenExpired;
};
