import * as React from 'react';

function SvgSpinnerThin(props) {
	return (
		<svg enableBackground='new 0 0 24 24' viewBox='0 0 24 24' {...props}>
			<g
				style={{
					fill: 'none',
					stroke: '#000',
					strokeWidth: 2,
					strokeLinecap: 'round',
					strokeLinejoin: 'round',
					strokeMiterlimit: 10,
				}}
			>
				<path d='m15.5 5.938 2-3.464' />
				<path d='m18.062 8.5 3.464-2' />
				<path d='m19 12h4' />
				<path d='m21.526 17.5-3.464-2' />
				<path d='m17.5 21.526-2-3.464' />
				<path d='m12 23v-4' />
				<path d='m6.5 21.526 2-3.464' />
				<path d='m2.474 17.5 3.464-2' />
				<path d='m1 12h4' />
				<path d='m5.938 8.5-3.464-2' />
				<path d='m8.5 5.938-2-3.464' />
				<path d='m12 5v-4' />
			</g>
		</svg>
	);
}

export default SvgSpinnerThin;
