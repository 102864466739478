import React from 'react';
import css from './DropdownTeam.module.scss';
import { Dropdown } from 'components/Dropdown/Dropdown';
import classes from 'classnames';
import { InputSearch } from 'components/Form/InputSearch/InputSearch';
import { Link } from 'components/Link/Link';
import { TeamRecentlyViewed } from 'components/TeamRecentlyViewed/TeamRecentlyViewed';
import { useHistory } from 'react-router';

interface IDropdownTeamProps {
	isOpen?: boolean;
	className?: string;
}

export const DropdownTeam: React.FC<IDropdownTeamProps> = props => {
	const { isOpen, className, children } = props;
	const history = useHistory();

	const searchFormHandler: React.ReactEventHandler<HTMLFormElement> = event => {
		event.preventDefault();
		// @ts-ignore
		const searchInputValue = event.target.search.value || '';
		console.log('searchInputValue', searchInputValue);
		history.push('/team', { dropdownSearchValue: searchInputValue });
	};

	const DropdownContent = () => (
		<div className={css.dropdownContent}>
			<form onSubmit={searchFormHandler}>
				<button type='submit' style={{ display: 'none' }}></button>
				<InputSearch label={''} id={'search'} name={'search'} className={css.search}></InputSearch>
			</form>
			<TeamRecentlyViewed></TeamRecentlyViewed>
			<Link type='primary' to='/team' className={css.viewAll}>
				View All Team Members
			</Link>
		</div>
	);

	return (
		<Dropdown
			enableHover={true}
			type='control'
			DropdownContent={DropdownContent}
			dropdownContentClassName={css.dropdownContentArea}
			isOpen={isOpen}
			className={classes(className, css.dropdownTeam)}
		>
			{children}
		</Dropdown>
	);
};
