import React from 'react';
import { ErrorView } from 'views/ErrorView';

interface IErrorBoundaryState {
	hasError: boolean;
}

export class ErrorBoundary extends React.Component<{}, IErrorBoundaryState> {
	readonly state: IErrorBoundaryState = {
		hasError: false
	};

	static getDerivedStateFromError() {
		return { hasError: true };
	}

	componentDidCatch(error: Error, errorInfo: object) {
		//@ts-ignore
		if (!!window.rg4js) {
			//@ts-ignore
			rg4js('send', error);
			//@ts-ignore
			rg4js('customTags', errorInfo);
		}
	}

	render() {
		if (this.state.hasError) {
			return <ErrorView />;
		}

		return this.props.children;
	}
}
