import React from 'react';
import css from './AuthFrame.module.scss';
import classes from 'classnames';

interface IAuthFrameProps {
	className?: string;
}

export const AuthFrame: React.FC<IAuthFrameProps> = props => {
	const { children, className } = props;
	return <div className={classes(css.authframe, className)}>{children}</div>;
};
