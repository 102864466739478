import * as React from 'react';

function SvgLayoutGrid(props) {
	return (
		<svg enableBackground='new 0 0 24 24' viewBox='0 0 24 24' {...props}>
			<g
				style={{
					fill: 'none',
					stroke: '#000',
					strokeWidth: 1,
					strokeLinecap: 'round',
					strokeLinejoin: 'round',
					strokeMiterlimit: 10,
				}}
			>
				<path d='m8 10h-4c-1.105 0-2-.895-2-2v-4c0-1.105.895-2 2-2h4c1.105 0 2 .895 2 2v4c0 1.105-.895 2-2 2z' />
				<path d='m20 10h-4c-1.105 0-2-.895-2-2v-4c0-1.105.895-2 2-2h4c1.105 0 2 .895 2 2v4c0 1.105-.895 2-2 2z' />
				<path d='m8 22h-4c-1.105 0-2-.895-2-2v-4c0-1.105.895-2 2-2h4c1.105 0 2 .895 2 2v4c0 1.105-.895 2-2 2z' />
				<path d='m20 22h-4c-1.105 0-2-.895-2-2v-4c0-1.105.895-2 2-2h4c1.105 0 2 .895 2 2v4c0 1.105-.895 2-2 2z' />
			</g>
		</svg>
	);
}

export default SvgLayoutGrid;
