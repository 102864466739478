import * as React from 'react';

function SvgHome(props) {
	return (
		<svg enableBackground='new 0 0 24 24' viewBox='0 0 24 24' {...props}>
			<g
				style={{
					fill: 'none',
					stroke: '#000',
					strokeWidth: 1,
					strokeLinecap: 'round',
					strokeLinejoin: 'round',
					strokeMiterlimit: 10,
				}}
			>
				<path d='m8 23v-6c0-2.209 1.791-4 4-4 2.209 0 4 1.791 4 4v6' />
				<path d='m1 21v-11l11-9 11 9v11c0 1.105-.895 2-2 2h-18c-1.105 0-2-.895-2-2z' />
			</g>
		</svg>
	);
}

export default SvgHome;
