import * as React from 'react';

function SvgCaretUpFilled(props) {
	return (
		<svg width={24} height={24} viewBox='0 0 24 24' fill='none' {...props}>
			<path
				d='M11.164 8.30481C11.3546 8.13189 11.6454 8.13189 11.836 8.30481L19.634 15.3797C19.9727 15.6869 19.7553 16.25 19.298 16.25L3.70196 16.25C3.2447 16.25 3.02734 15.6869 3.366 15.3797L11.164 8.30481Z'
				fill='#27363E'
			/>
		</svg>
	);
}

export default SvgCaretUpFilled;
