import React, { useState, useEffect } from 'react';
import { Select } from 'components/Form/Select/Select';
import { PageFrame } from 'components/PageFrame/PageFrame';
import { DashboardTabs } from 'components/DashboardTabs/DashboardTabs';
import css from './HomeView.module.scss';
import classes from 'classnames';
import { ArrowLink } from 'components/ArrowLink/ArrowLink';
import { GridContainer } from 'components/Grid/GridContainer/GridContainer';
import { GridItem } from 'components/Grid/GridItem/GridItem';
import { PanelTable } from 'components/Table/PanelTable';
import { Link } from 'components/Link';
import { Section } from 'components/Section/Section';
import { InputSearch } from 'components/Form/InputSearch/InputSearch';
import { Button } from 'components/Button/Button';
import { DataPanels } from 'components/DataPanels/DataPanels';
import { SectionHeading } from 'components/SectionHeading/SectionHeading';
import {
	useGetRecentActivityQuery,
	useGetFeedbackItemCountQuery,
	useGetActionItemsQuery,
	useUpdateTeamMemberIsFirstLoginMutation
} from 'generated/graphql';
import { sortArrayByProperty } from 'common/utils/sortArrayByProperty';
import { useCurrentUser } from 'common/hooks/useCurrentUser';
import { useModal } from 'components/Modal/ModalProvider';
import { Container } from 'components/Container/Container';
import { Icon } from 'components/Icon/Icon';

export const HomeView = () => {
	const { currentUser } = useCurrentUser();

	const today = new Date(),
		y = today.getFullYear(),
		m = today.getMonth(),
		d = today.getDate();

	const getWeek = () => {
		const tempDate = new Date();
		const start = 0;
		var today = new Date(tempDate.setHours(0, 0, 0, 0));
		var day = today.getDay() - start;
		var date = today.getDate() - day;
		var StartDate = new Date(today.setDate(date));
		var EndDate = new Date(today.setDate(date + 7));
		return [StartDate, EndDate];
	};

	const [dateRange, setDateRange] = useState('30-days');
	const [startDate, setStartDate] = useState(new Date(y, m, d - 30));
	const [endDate, setEndDate] = useState(today);
	const requiredInteractions = currentUser.Company.requiredInteractions;
	const requiredInteractionTimePeriod = currentUser.Company.requiredInteractionTimePeriod;
	const week = getWeek();
	const defaultActionItemsStartDate =
		requiredInteractionTimePeriod === 'Annually'
			? new Date(y, 1, 1)
			: requiredInteractionTimePeriod === 'Weekly'
			? week[0]
			: new Date(y, m, 1);
	const defaultActionItemsEndDate =
		requiredInteractionTimePeriod === 'Annually'
			? new Date(y, 12, 31, 23, 59, 59)
			: requiredInteractionTimePeriod === 'Weekly'
			? week[1]
			: new Date(y, m + 1, 0, 23, 59, 59);

	// eslint-disable-next-line
	const [actionItemsStartDate, setActionItemsStartDate] = useState(defaultActionItemsStartDate);
	// eslint-disable-next-line
	const [actionItemsEndDate, setActionItemsEndDate] = useState(defaultActionItemsEndDate);

	useEffect(() => {
		if (dateRange === '90-days') {
			setStartDate(new Date(y, m, d - 90));
			setEndDate(today);
		} else if (dateRange === 'this-year') {
			setStartDate(new Date(y - 1, m, d));
			setEndDate(today);
		} else if (dateRange === 'previous-year') {
			setStartDate(new Date(y - 1, 1, 1));
			setEndDate(new Date(y - 1, 12, 31));
		} else {
			setStartDate(new Date(y, m, d - 30));
			setEndDate(today);
		}

		// eslint-disable-next-line
	}, [dateRange]);

	const {
		loading: recentActivityLoading,
		data: recentActivityData,
		error: recentActivityError
	} = useGetRecentActivityQuery({ fetchPolicy: 'network-only' });

	const {
		loading: feedbackCountLoading,
		data: feedbackCount,
		error: feedbackCountError
	} = useGetFeedbackItemCountQuery({
		variables: {
			startDate: startDate.toISOString(),
			endDate: endDate.toISOString(),
			managerId: currentUser.id
		}
	});

	const [searchQuery, setSearchQuery] = useState('');
	const [searchInputValue, setSearchInputValue] = useState('');
	const formattedSearchQuery = '%' + searchQuery.replace(/%/g, '\\%') + '%';
	const searchInputHandler: React.ReactEventHandler<HTMLInputElement> = event => {
		const searchInputValue = (event.target as HTMLInputElement).value;
		setSearchInputValue(searchInputValue);
		if (searchInputValue === '') {
			setSearchQuery(searchInputValue);
		}
	};

	const searchFormHandler: React.ReactEventHandler<HTMLFormElement> = event => {
		setSearchQuery(searchInputValue);
		event.preventDefault();
	};

	const {
		loading: actionItemsLoading,
		data: actionItems,
		error: actionItemsError
	} = useGetActionItemsQuery({
		variables: {
			currentTeamMemberId: currentUser.id,
			startDate: actionItemsStartDate.toISOString(),
			endDate: actionItemsEndDate.toISOString(),
			searchQuery: formattedSearchQuery
		},
		fetchPolicy: 'network-only'
	});

	const [actionItemsFiltered, setActionItemsFiltered] = useState(null);

	const actionItemsPerPage = 10;
	const [actionItemsLimit, setActionItemsLimit] = useState(actionItemsPerPage);
	const [actionItemsTotal, setActionItemsTotal] = useState(null);

	useEffect(() => {
		if (!actionItemsLoading && !actionItemsError && actionItems) {
			// eslint-disable-next-line
			let actionItemsTemp: any[] = [];
			actionItems.TeamMember.forEach(teamMember => {
				if (!teamMember.Feedback_aggregate) {
					actionItemsTemp.push({
						id: teamMember.id,
						teamMember: teamMember.firstName + ' ' + teamMember.lastName,
						description:
							(currentUser.role === 'CompanyManager' ? 'You’ve had ' : 'has had ') +
							0 +
							' of at least ' +
							requiredInteractions +
							' required interactions needed before ' +
							actionItemsEndDate.toLocaleDateString() +
							'.'
					});
				} else if (teamMember.Feedback_aggregate.aggregate.count < requiredInteractions) {
					actionItemsTemp.push({
						id: teamMember.id,
						teamMember: teamMember.firstName + ' ' + teamMember.lastName,
						description:
							(currentUser.role === 'CompanyManager' ? 'You’ve had ' : 'has had ') +
							teamMember.Feedback_aggregate.aggregate.count +
							' of at least ' +
							requiredInteractions +
							' required interactions needed before ' +
							actionItemsEndDate.toLocaleDateString() +
							'.'
					});
				}
			});
			setActionItemsTotal(actionItemsTemp.length);
			setActionItemsFiltered(actionItemsTemp.slice(0, actionItemsLimit));
		}
		// eslint-disable-next-line
	}, [actionItems, actionItemsLoading, actionItemsError, actionItemsLimit]);

	const [recentActivitySorted, setRecentActivitySorted] = useState(null);

	useEffect(() => {
		if (!recentActivityLoading && !recentActivityError && recentActivityData) {
			// eslint-disable-next-line
			const recentActivityTemp = recentActivityData.FeedbackSession.concat(
				//@ts-ignore
				recentActivityData.Infraction,
				//@ts-ignore
				recentActivityData.TitleHistory
			);
			const sortedRecentActivityTemp = sortArrayByProperty(recentActivityTemp, 'createdAt', 'desc');
			setRecentActivitySorted(sortedRecentActivityTemp.slice(0, 6));
		}
		// eslint-disable-next-line
	}, [recentActivityData, recentActivityLoading, recentActivityError]);

	const { createModal, removeModal } = useModal();

	const getModalProps = () => ({
		className: css.welcomeModal,
		headerClassName: css.welcomeModalHeader,
		innerClassName: css.welcomeModalInner,
		closeIconColor: 'white',
		heading: false,
		Content: () => (
			<div className={css.welcomeModalContent}>
				<div className={css.inner}>
					<h2 className='h1'>Hi, {currentUser.firstName}</h2>
					<p className={css.description}>
						Welcome to Culch, where team development is achieved through behavioral feedback, data
						collection, and direct coaching.
					</p>
					{currentUser.role === 'CompanyAdmin' ? (
						<p>
							There are a few things to do before you’re up and running. Click the button below and we’ll
							show you what to do next.
						</p>
					) : (
						<p>Click the button below and we’ll make getting started easy.</p>
					)}

					<Link className={css.button} onClick={removeModal} type='primary' to='/gettingStarted'>
						Walk me through getting started{' '}
					</Link>
				</div>
				<div className={css.image}></div>
			</div>
		),
		hasPrimaryButton: false,
		hasSecondaryButton: false
	});
	const [updateIsFirstLogin] = useUpdateTeamMemberIsFirstLoginMutation();
	const isFirstLogin = currentUser.isFirstLogin;
	useEffect(() => {
		if (isFirstLogin) {
			createModal(getModalProps());
			try {
				updateIsFirstLogin({
					variables: { id: currentUser.id, isFirstLogin: false },
					refetchQueries: ['GetCurrentAuthenticatedTeamMember']
				});
			} catch (error) {
				console.log('error', error);
			}
		}
		// updateIsFirstLogin({ variables: { id: currentUser.id, isFirstLogin: true } });

		// eslint-disable-next-line
	}, []);

	console.log('currentUser', currentUser);

	return (
		<PageFrame title='Dashboard'>
			{currentUser.role === 'CompanyAdmin' && currentUser.DirectReports.length <= 0 ? (
				<>
					<div className={css.hero}>
						<Container className={css.split}>
							<div className={css.image}></div>

							<div className={css.text}>
								<h1>Welcome, {currentUser.firstName}</h1>
								<p>
									Welcome back to Culch, where team development is achieved through behavioral feedback
									data collection and direct coaching.
								</p>
							</div>
						</Container>
					</div>
					<Container>
						<Section className={css.quickLinksWrap}>
							<h2>Quick Links</h2>
							<GridContainer columnAmount='3' className={css.quickLinks}>
								<GridItem className={css.gridItem}>
									<Link type='panel' to='/admin/frameworks'>
										<div>
											<Icon className={css.icon} size={'largest'} type={'Comment'} color={'red'}></Icon>
											<p>
												<strong>Feedback Frameworks</strong>
											</p>
											<p>
												Your feedback Framework is a detailed collection of behavioral goals you set for
												team members to strvie for.{' '}
											</p>
										</div>
									</Link>
								</GridItem>
								<GridItem className={css.gridItem}>
									<Link type='panel' to='/admin/users'>
										<div>
											<Icon className={css.icon} size={'largest'} type={'Users'} color={'yellow'}></Icon>
											<p>
												<strong>User Management</strong>
											</p>
											<p>
												Add team members to get set up and tracking in Culch. You can add, edit, and
												manage team members here at any time.
											</p>
										</div>
									</Link>
								</GridItem>
								<GridItem className={css.gridItem}>
									<Link type='panel' to='/admin/settings'>
										<div>
											<Icon
												className={css.icon}
												size={'largest'}
												type={'Settings'}
												color={'green'}
											></Icon>
											<p>
												<strong>General Settings</strong>
											</p>
											<p>Manage and update your company settings within Culch here.</p>
										</div>
									</Link>
								</GridItem>
							</GridContainer>
							<p className={css.gettingStarted}>
								Just getting started or need a refresh? Visit{' '}
								<Link to='/gettingStarted'>Getting Started</Link>.
							</p>
						</Section>
					</Container>
				</>
			) : (
				<DashboardTabs
					className={css.dashboard}
					items={[
						{
							title: 'Quick Actions',
							content: (
								<Section className={css.dashboardSection}>
									<h2 className={css.desktopTitle}>Quick Actions</h2>
									<p className={css.mobileOnly}>
										Choose from the interaction type below to get started.
									</p>
									<GridContainer columnAmount='3'>
										<GridItem>
											<ArrowLink
												location={{ pathname: '/feedback', state: { feedbackType: 'Positive' } }}
											>
												Provide Positive Feedback
											</ArrowLink>
										</GridItem>
										<GridItem>
											<ArrowLink
												color='yellow'
												location={{ pathname: '/feedback', state: { feedbackType: 'Constructive' } }}
											>
												Provide Constructive Feedback
											</ArrowLink>
										</GridItem>
										<GridItem>
											<ArrowLink color='red' location='/infraction'>
												Record an Infraction
											</ArrowLink>
										</GridItem>
									</GridContainer>
								</Section>
							)
						},
						{
							title: 'Action Items',
							content: (
								<Section className={css.dashboardSection}>
									{!actionItemsLoading && !actionItemsError && actionItems && actionItemsFiltered && (
										<>
											<>
												<PanelTable
													className={css.actionItemsPanel}
													title={'Action Items'}
													description='Action items help you stay on top of your tasks and goals. Once you have completed an action item, it will disappear from the list.'
													numberOfActions={actionItemsFiltered.length}
													headers={[
														{
															label: 'Description',
															property: 'description',
															RenderFunction: (data: any) => (
																<div className={css.actionItemDescription}>
																	<span>
																		<Link to={'/team/' + data.id}>{data.teamMember}</Link>{' '}
																		{data.description}
																	</span>
																</div>
															)
														},
														{
															label: 'Type',
															property: 'typeStatusLabel'
														}
													]}
													showHeaders={false}
													data={actionItemsFiltered}
													contentTop={
														<div className={css.tableFilters}>
															<form onSubmit={searchFormHandler}>
																<button type='submit' style={{ display: 'none' }}></button>
																<InputSearch
																	className={css.searchInput}
																	label={'Search'}
																	name={'number'}
																	isLabelVisible={false}
																	value={searchInputValue}
																	onChange={searchInputHandler}
																	onBlur={() => {
																		setSearchQuery(searchInputValue);
																	}}
																></InputSearch>
															</form>
														</div>
													}
												></PanelTable>

												{actionItemsLimit < actionItemsTotal && (
													<Button
														className={css.viewMore}
														onClick={() => setActionItemsLimit(actionItemsLimit + actionItemsPerPage)}
													>
														View More
													</Button>
												)}
											</>
										</>
									)}
								</Section>
							)
						},
						{
							title: 'Overview',
							isSidebarItem: true,
							content: (
								<Section className={classes(css.dashboardSection, css.overviewSection)}>
									<h2 className={css.desktopTitle}>Overview</h2>
									<Select
										inputFrameClassName={classes(css.dateRangeFrame, css.dateRangeSidebar)}
										label={'Viewing:'}
										name={'dateRange'}
										className={css.dateRange}
										onChange={event => {
											setDateRange(event.target.value);
										}}
									>
										<option value='30-days'>Last 30 Days</option>
										<option value='90-days'>Last 90 Days</option>
										<option value='this-year'>This Year</option>
										<option value='previous-year'>Previous Year</option>
									</Select>
									<SectionHeading
										tooltipMessage='This displays your total interactions across all team members. It then breaks them down so you can understand your interaction trends by type: positive, constructive, and infractions.'
										className={css.sidebarSectionTitle}
									>
										Interaction Summary
									</SectionHeading>

									{!feedbackCountLoading && !feedbackCountError && feedbackCount && (
										<>
											<DataPanels
												panel={[
													{
														description: 'postive feedback interaction',
														interactions: feedbackCount.positive.aggregate.count
													},
													{
														description: 'constructive feedback interaction',
														interactions: feedbackCount.constructive.aggregate.count
													},
													{
														description: 'infractions',
														interactions: feedbackCount.infraction.aggregate.count
													}
												]}
												totalInteractions={
													feedbackCount.positive.aggregate.count +
													feedbackCount.constructive.aggregate.count +
													feedbackCount.infraction.aggregate.count
												}
											></DataPanels>
										</>
									)}
								</Section>
							)
						},
						{
							title: 'Recent Activity',
							isSidebarItem: true,
							content: (
								<>
									{!recentActivityLoading &&
										!recentActivityError &&
										recentActivityData &&
										recentActivitySorted && (
											<>
												{recentActivitySorted.length ? (
													<PanelTable
														className={css.recentActivityPanel}
														title={'Recent Activity'}
														headers={[
															{
																label: 'Description',
																property: 'description',
																RenderFunction: (data: any) => (
																	<div className={css.recentActivityDescription}>
																		<Link
																			to={
																				data.borderColor === 'red'
																					? '/infractionHistory/' + data.id
																					: data.borderColor === 'blue'
																					? '/accountChangeDetails/' + data.id
																					: '/feedbackHistory/' + data.id
																			}
																		>
																			{data.teamMember}
																		</Link>
																	</div>
																)
															},
															{
																label: 'Last Edited',
																property: 'lastEdited'
															}
														]}
														showHeaders={false}
														//@ts-ignore
														data={recentActivitySorted.map(activity => {
															let borderColor = 'red';
															if (activity.oldTitle) {
																borderColor = 'blue';
															}
															if (activity.FeedbackItem) {
																borderColor = '';
																activity.FeedbackItem.forEach((item: any) => {
																	let tempBorderColor =
																		item.type === 'Constructive' ? 'yellow' : 'green';
																	if (borderColor !== '' && tempBorderColor !== borderColor) {
																		borderColor = 'yellowGreen';
																	} else {
																		borderColor = tempBorderColor;
																	}
																});
															}
															const date = new Date(activity.createdAt);

															return {
																id: activity.id,
																teamMember:
																	activity.TeamMember &&
																	activity.TeamMember.firstName + ' ' + activity.TeamMember.lastName,
																lastEdited: date.toLocaleDateString(),
																borderColor: borderColor
															};
														})}
													></PanelTable>
												) : (
													<></>
												)}
											</>
										)}
								</>
							)
						}
					]}
				/>
			)}
		</PageFrame>
	);
};

export default HomeView;
