import React from 'react';
import css from './ErrorView.module.scss';

export const ErrorView: React.FC<{}> = () => {
	return (
		<div className={css.errorView}>
			<h1>Whoops! Something happened.</h1>
			<p>
				We seemed to have run into an issue. Please try reloading the page or return back to the
				homepage.
			</p>
			<a href='/'>Back to Home</a>
		</div>
	);
};
