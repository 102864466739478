import { IUser } from 'common/interfaces/IUser';
import { config } from 'config';

export interface IAuthLoginResponse {
	user: IUser;
	accessToken: string;
}

export const authApiService = {
	userLogin: async (email: string, password: string): Promise<IAuthLoginResponse> => {
		const loginRequest = await fetch(`${config.authApiEndpoint}/login`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: config.apiLoginKey
			},
			credentials: 'include',
			body: JSON.stringify({
				loginId: email,
				password,
				applicationId: config.applicationId
			})
		});

		switch (loginRequest.status) {
			case 200:
				const result = await loginRequest.json();
				return {
					user: result.user,
					accessToken: result.token
				};
			default:
			// throw new Error('Invalid sign in, please try again.');
		}
	},
	refreshAccessToken: async (): Promise<string> => {
		// Generates an access token from the httpOnly cookie with the refresh token
		const refreshRequest = await fetch(`${config.authApiEndpoint}/jwt/refresh`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			//Tell fetch to pass the cookies that include the refresh token cookie
			credentials: 'include'
		});

		// Fusionauth sends responses that contain the error. So catch and respond accordingly with our errors
		switch (refreshRequest.status) {
			case 400:
				throw new Error('There is no refresh_token set.');
			case 401:
				throw new Error('An invalid refresh token has been provided.');
			default:
				const result = await refreshRequest.json();
				return result.token;
		}
	},
	userLogout: async (): Promise<void> => {
		// Logs out an authenticated user
		// An access_token and refresh_token httpOnly cookie must be set for this to work.
		// Those cookies are automatically set with userLogin
		await fetch(`${config.authApiEndpoint}/logout?global=true`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			credentials: 'include'
		});
	},
	getUser: async (token: string): Promise<IUser> => {
		const getUserRequest = await fetch(`${config.authApiEndpoint}/user`, {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${token}`
			}
		});

		switch (getUserRequest.status) {
			case 400:
				throw new Error('Invalid get user request');
			default:
				const result = await getUserRequest.json();
				return {
					...result.user
				};
		}
	}
};
