import React from 'react';
import css from './SectionHeading.module.scss';
import classes from 'classnames';
import { Button } from 'components/Button/Button';

interface ISectionHeadingProps {
	className?: string;
	buttonClassName?: string;
	tooltipMessage?: string;
}

export const SectionHeading: React.FC<ISectionHeadingProps> = props => {
	const { children, className, buttonClassName, tooltipMessage } = props;

	return (
		<h4 className={classes(css.sectionHeading, className)}>
			{children}
			{tooltipMessage && (
				<Button
					type='control'
					size='small'
					iconLeft={{ color: 'gray-3', size: 'small', type: 'Information' }}
					tooltipMessage={tooltipMessage}
					className={buttonClassName}
				></Button>
			)}
		</h4>
	);
};
