import * as React from 'react';

function SvgCaretRight(props) {
	return (
		<svg width={24} height={24} viewBox='0 0 24 24' fill='none' {...props}>
			<path
				d='M8 3L16 12L8 21'
				stroke='#27363E'
				strokeWidth={1.5}
				strokeMiterlimit={10}
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
}

export default SvgCaretRight;
