import React from 'react';
import css from './TrayNavigation.module.scss';
import classes from 'classnames';
import { Icon } from 'components/Icon/Icon';
import { TIconTypes } from 'common/types/TIconTypes';
import { NavLink } from 'react-router-dom';
import { BadgeIcon } from 'components/BadgeIcon/BadgeIcon';
import { useModal } from 'components/Modal/ModalProvider';
import { ArrowLink } from 'components/ArrowLink/ArrowLink';

interface ITrayNavigationProps {
	className?: string;
	zIndex?: number;
}

export interface IMenuItem {
	icon?: TIconTypes;
	label: string;
	location: string;
	type?: 'quickActions' | 'notifications';
}

export const TrayNavigation: React.FC<ITrayNavigationProps> = props => {
	const { className, zIndex } = props;

	const { createModal, removeModal } = useModal();
	const getModalProps = (event: any) => ({
		sourceEvent: event,
		heading: 'Quick Actions',
		className: css.quickActionsModal,
		backdropClassName: css.quickActionsBackdrop,
		hasSecondaryButton: false,
		hasPrimaryButton: false,
		Content: () => (
			<div className={css.quickActionsList}>
				<p>Choose from the interaction type below to get started.</p>
				<ul>
					<li>
						<ArrowLink
							onClick={removeModal}
							location={{ pathname: '/feedback', state: { feedbackType: 'Positive' } }}
						>
							Provide Positive Feedback
						</ArrowLink>
					</li>
					<li>
						<ArrowLink
							onClick={removeModal}
							color='yellow'
							location={{ pathname: '/feedback', state: { feedbackType: 'Constructive' } }}
						>
							Provide Constructive Feedback
						</ArrowLink>
					</li>
					<li>
						<ArrowLink onClick={removeModal} color='red' location='/infraction'>
							Record an Infraction
						</ArrowLink>
					</li>
				</ul>
			</div>
		)
	});

	const items: IMenuItem[] = [
		{
			icon: 'Home',
			label: 'Dashboard',
			location: '/dashboard'
		},
		{
			icon: 'Add',
			label: 'Quick actions',
			location: '/quick-actions',
			type: 'quickActions'
		},
		{
			icon: 'Users',
			label: 'Team',
			location: '/team'
		}
		// {
		// 	icon: 'PageCheck',
		// 	label: 'Reports',
		// 	location: '/reports'
		// },
		// {
		// 	label: 'Notifications',
		// 	location: '/notifications',
		// 	type: 'notifications'
		// }
	];
	return (
		<nav
			className={classes(className, css.trayContainer)}
			style={{ zIndex: zIndex }}
			aria-label='Secondary'
		>
			<div className={css.trayInner}>
				{items.map((item, index) => (
					<React.Fragment key={index}>
						{item.type === 'quickActions' ? (
							<button
								className={classes(css[item.type], css.linkItem)}
								onClick={event => {
									createModal(getModalProps(event));
								}}
							>
								<Icon
									className={css.icon}
									type={item.icon}
									color='white'
									size='medium'
									aria-hidden='true'
								></Icon>
								<div className={css.label}>{item.label}</div>
							</button>
						) : (
							<NavLink
								key={index}
								to={item.location}
								className={classes(css[item.type], css.linkItem)}
								activeClassName={css.active}
								aria-current='page'
							>
								{item.type === 'notifications' ? (
									<BadgeIcon
										icon={{ type: 'Bell', size: 'medium' }}
										className={css.badgeIcon}
									></BadgeIcon>
								) : (
									<Icon
										className={css.icon}
										type={item.icon}
										color={item.type === 'quickActions' ? 'white' : 'gray-3'}
										size='medium'
										aria-hidden='true'
									></Icon>
								)}
								<div className={css.label}>{item.label}</div>
							</NavLink>
						)}
					</React.Fragment>
				))}
			</div>
		</nav>
	);
};
