import React, { Suspense } from 'react';
import css from './Icon.module.scss';
import classes from 'classnames';
import { TIconTypes } from 'common/types/TIconTypes';
import { TColors } from 'common/types/TColors';
import { LoadingDelay } from 'components/LoadingDelay/LoadingDelay';

const Add = React.lazy(() => import('assets/svg-components/Add'));
const AddCircle = React.lazy(() => import('assets/svg-components/AddCircle'));
const Alert = React.lazy(() => import('assets/svg-components/Alert'));
const ArrowLeft = React.lazy(() => import('assets/svg-components/ArrowLeft'));
const ArrowRight = React.lazy(() => import('assets/svg-components/ArrowRight'));
const Bell = React.lazy(() => import('assets/svg-components/Bell'));
const Check = React.lazy(() => import('assets/svg-components/Check'));
const Cancel = React.lazy(() => import('assets/svg-components/Cancel'));
const CancelCircle = React.lazy(() => import('assets/svg-components/CancelCircle'));
const CaretDownFilled = React.lazy(() => import('assets/svg-components/CaretDownFilled'));
const CaretDown = React.lazy(() => import('assets/svg-components/CaretDown'));
const CaretLeft = React.lazy(() => import('assets/svg-components/CaretLeft'));
const CaretRight = React.lazy(() => import('assets/svg-components/CaretRight'));
const CaretUp = React.lazy(() => import('assets/svg-components/CaretUp'));
const CaretUpFilled = React.lazy(() => import('assets/svg-components/CaretUpFilled'));
const ChartBar = React.lazy(() => import('assets/svg-components/ChartBar'));
const CheckCircle = React.lazy(() => import('assets/svg-components/CheckCircle'));
const Edit = React.lazy(() => import('assets/svg-components/Edit'));
const FolderUser = React.lazy(() => import('assets/svg-components/FolderUser'));
const Frown = React.lazy(() => import('assets/svg-components/Frown'));
const Home = React.lazy(() => import('assets/svg-components/Home'));
const Info = React.lazy(() => import('assets/svg-components/Info'));
const Information = React.lazy(() => import('assets/svg-components/Information'));
const InboxFull = React.lazy(() => import('assets/svg-components/InboxFull'));
const Laptop = React.lazy(() => import('assets/svg-components/Laptop'));
const LayoutGrid = React.lazy(() => import('assets/svg-components/LayoutGrid'));
const Location = React.lazy(() => import('assets/svg-components/Location'));
const MinusSquare = React.lazy(() => import('assets/svg-components/MinusSquare'));
const MoneyCircle = React.lazy(() => import('assets/svg-components/MoneyCircle'));
const PageCheck = React.lazy(() => import('assets/svg-components/PageCheck'));
const Search = React.lazy(() => import('assets/svg-components/Search'));
const Settings = React.lazy(() => import('assets/svg-components/Settings'));
const Smile = React.lazy(() => import('assets/svg-components/Smile'));
const SpinnerThin = React.lazy(() => import('assets/svg-components/SpinnerThin'));
const Stop = React.lazy(() => import('assets/svg-components/Stop'));
const Time = React.lazy(() => import('assets/svg-components/Time'));
const TriangleGreen = React.lazy(() => import('assets/svg-components/TriangleGreen'));
const TriangleRed = React.lazy(() => import('assets/svg-components/TriangleRed'));
const UserCircle = React.lazy(() => import('assets/svg-components/UserCircle'));
const UserList = React.lazy(() => import('assets/svg-components/UserList'));
const Users = React.lazy(() => import('assets/svg-components/Users'));
const Warning = React.lazy(() => import('assets/svg-components/Warning'));
const Headset = React.lazy(() => import('assets/svg-components/Headset'));
const Comment = React.lazy(() => import('assets/svg-components/Comment'));

export type TIconSize = 'smallest' | 'small' | 'medium' | 'large' | 'largest';

export interface IIconProps {
	className?: string;
	type: TIconTypes;
	size?: TIconSize;
	color?: TColors;
	style?: React.CSSProperties;
}
export const Icon: React.FC<IIconProps> = props => {
	const { className, type, size = 'medium', color = 'gray', style } = props;

	const iconClasses = classes(css.icon, className, css[size], css[color], css[type]);

	const iconProperties = {
		className: iconClasses,
		style
	};

	return (
		<Suspense
			fallback={
				<LoadingDelay
					WaitComponent={() => (
						<div className={classes(css[size], css.waitingComponent, className)}></div>
					)}
				>
					<div className={classes(css[size], css.skeleton)}></div>
				</LoadingDelay>
			}
		>
			{(() => {
				switch (type) {
					case 'Add':
						return <Add {...iconProperties} />;
					case 'AddCircle':
						return <AddCircle {...iconProperties} />;
					case 'Alert':
						return <Alert {...iconProperties} />;
					case 'ArrowLeft':
						return <ArrowLeft {...iconProperties} />;
					case 'ArrowRight':
						return <ArrowRight {...iconProperties} />;
					case 'Bell':
						return <Bell {...iconProperties} />;
					case 'Check':
						return <Check {...iconProperties} />;
					case 'Cancel':
						return <Cancel {...iconProperties} />;
					case 'CancelCircle':
						return <CancelCircle {...iconProperties} />;
					case 'CaretDownFilled':
						return <CaretDownFilled {...iconProperties} />;
					case 'CaretDown':
						return <CaretDown {...iconProperties} />;
					case 'CaretLeft':
						return <CaretLeft {...iconProperties} />;
					case 'CaretRight':
						return <CaretRight {...iconProperties} />;
					case 'CaretUpFilled':
						return <CaretUpFilled {...iconProperties} />;
					case 'CaretUp':
						return <CaretUp {...iconProperties} />;
					case 'ChartBar':
						return <ChartBar {...iconProperties} />;
					case 'CheckCircle':
						return <CheckCircle {...iconProperties} />;
					case 'Edit':
						return <Edit {...iconProperties} />;
					case 'FolderUser':
						return <FolderUser {...iconProperties} />;
					case 'Frown':
						return <Frown {...iconProperties} />;
					case 'Home':
						return <Home {...iconProperties} />;
					case 'Info':
						return <Info {...iconProperties} />;
					case 'Information':
						return <Information {...iconProperties} />;
					case 'InboxFull':
						return <InboxFull {...iconProperties} />;
					case 'Laptop':
						return <Laptop {...iconProperties} />;
					case 'LayoutGrid':
						return <LayoutGrid {...iconProperties} />;
					case 'Location':
						return <Location {...iconProperties} />;
					case 'MinusSquare':
						return <MinusSquare {...iconProperties} />;
					case 'MoneyCircle':
						return <MoneyCircle {...iconProperties} />;
					case 'PageCheck':
						return <PageCheck {...iconProperties} />;
					case 'Search':
						return <Search {...iconProperties} />;
					case 'Settings':
						return <Settings {...iconProperties} />;
					case 'Smile':
						return <Smile {...iconProperties} />;
					case 'SpinnerThin':
						return <SpinnerThin {...iconProperties} />;
					case 'Stop':
						return <Stop {...iconProperties} />;
					case 'Time':
						return <Time {...iconProperties} />;
					case 'TriangleGreen':
						return <TriangleGreen {...iconProperties} />;
					case 'TriangleRed':
						return <TriangleRed {...iconProperties} />;
					case 'UserCircle':
						return <UserCircle {...iconProperties} />;
					case 'UserList':
						return <UserList {...iconProperties} />;
					case 'Users':
						return <Users {...iconProperties} />;
					case 'Warning':
						return <Warning {...iconProperties} />;
					case 'Headset':
						return <Headset {...iconProperties} />;
					case 'Comment':
						return <Comment {...iconProperties} />;
				}
			})()}
		</Suspense>
	);
};
