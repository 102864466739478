import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useCurrentUser } from 'common/hooks/useCurrentUser';

export const SubscribedRoute: React.FC<RouteProps> = props => {
	const { children, ...remainingProps } = props;

	const { currentUser } = useCurrentUser();
	return (
		<Route
			{...remainingProps}
			// The location (below) exists on the <Route /> props.
			render={({ location }) =>
				currentUser.Company.isActive ? (
					<>{children}</>
				) : (
					<Redirect
						to={{
							pathname: '/trialEnded',
							// pass location state (contains url to redirect back to after auth success)
							state: { from: location }
						}}
					/>
				)
			}
		/>
	);
};
