import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  date: any;
  numeric: any;
  timestamp: any;
  timestamptz: any;
  uuid: any;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
};

export type ChangePasswordOutput = {
  __typename?: 'ChangePasswordOutput';
  isSuccess: Scalars['Boolean'];
};

/** columns and relationships of "Company" */
export type Company = {
  __typename?: 'Company';
  /** An array relationship */
  TeamMembers: Array<TeamMember>;
  /** An aggregate relationship */
  TeamMembers_aggregate: TeamMember_Aggregate;
  billingEmail?: Maybe<Scalars['String']>;
  billingId?: Maybe<Scalars['String']>;
  businessType?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  isActive: Scalars['Boolean'];
  isFreeAccount: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  requiredInteractionTimePeriod: Scalars['String'];
  requiredInteractions: Scalars['numeric'];
  size?: Maybe<Scalars['String']>;
  timezone: Scalars['String'];
  trialEndDate?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "Company" */
export type CompanyTeamMembersArgs = {
  distinct_on?: Maybe<Array<TeamMember_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TeamMember_Order_By>>;
  where?: Maybe<TeamMember_Bool_Exp>;
};


/** columns and relationships of "Company" */
export type CompanyTeamMembers_AggregateArgs = {
  distinct_on?: Maybe<Array<TeamMember_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TeamMember_Order_By>>;
  where?: Maybe<TeamMember_Bool_Exp>;
};

export type CompanyBillingOutput = {
  __typename?: 'CompanyBillingOutput';
  isSuccess: Scalars['Boolean'];
};

export type CompanyCancelSubscriptionOutput = {
  __typename?: 'CompanyCancelSubscriptionOutput';
  isSuccess?: Maybe<Scalars['Boolean']>;
};

export type CompanyInitialSignUpOutput = {
  __typename?: 'CompanyInitialSignUpOutput';
  companyId: Scalars['uuid'];
  teamMemberId: Scalars['uuid'];
};

export type CompanyInitialSubscriptionOutput = {
  __typename?: 'CompanyInitialSubscriptionOutput';
  isSuccess: Scalars['Boolean'];
};

export type CompanyPaymentMethodOutput = {
  __typename?: 'CompanyPaymentMethodOutput';
  addressLine1: Scalars['String'];
  addressLine2: Scalars['String'];
  billingEmail: Scalars['String'];
  brand: Scalars['String'];
  city: Scalars['String'];
  expMonth: Scalars['Int'];
  expYear: Scalars['Int'];
  last4: Scalars['String'];
  name: Scalars['String'];
  postalCode: Scalars['String'];
  state: Scalars['String'];
};

export type CompanyReactivateSubscriptionOutput = {
  __typename?: 'CompanyReactivateSubscriptionOutput';
  isSuccess?: Maybe<Scalars['Boolean']>;
};

export type CompanySubscriptionCheckoutPriceOutput = {
  __typename?: 'CompanySubscriptionCheckoutPriceOutput';
  monthlyFullCost: Scalars['Int'];
  monthlyProratedCost: Scalars['Int'];
  yearlyFullCost: Scalars['Int'];
  yearlyProratedCost: Scalars['Int'];
};

export type CompanySubscriptionOutput = {
  __typename?: 'CompanySubscriptionOutput';
  cost?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  interval: Scalars['String'];
  isCancelled: Scalars['Boolean'];
  name: Scalars['String'];
  renewalDate: Scalars['String'];
  units: Scalars['Int'];
};

export type CompanyUpgradeSubscriptionOutput = {
  __typename?: 'CompanyUpgradeSubscriptionOutput';
  isSuccess: Scalars['Boolean'];
};

/** aggregated selection of "Company" */
export type Company_Aggregate = {
  __typename?: 'Company_aggregate';
  aggregate?: Maybe<Company_Aggregate_Fields>;
  nodes: Array<Company>;
};

/** aggregate fields of "Company" */
export type Company_Aggregate_Fields = {
  __typename?: 'Company_aggregate_fields';
  avg?: Maybe<Company_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Company_Max_Fields>;
  min?: Maybe<Company_Min_Fields>;
  stddev?: Maybe<Company_Stddev_Fields>;
  stddev_pop?: Maybe<Company_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Company_Stddev_Samp_Fields>;
  sum?: Maybe<Company_Sum_Fields>;
  var_pop?: Maybe<Company_Var_Pop_Fields>;
  var_samp?: Maybe<Company_Var_Samp_Fields>;
  variance?: Maybe<Company_Variance_Fields>;
};


/** aggregate fields of "Company" */
export type Company_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Company_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Company_Avg_Fields = {
  __typename?: 'Company_avg_fields';
  requiredInteractions?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Company". All fields are combined with a logical 'AND'. */
export type Company_Bool_Exp = {
  TeamMembers?: Maybe<TeamMember_Bool_Exp>;
  _and?: Maybe<Array<Company_Bool_Exp>>;
  _not?: Maybe<Company_Bool_Exp>;
  _or?: Maybe<Array<Company_Bool_Exp>>;
  billingEmail?: Maybe<String_Comparison_Exp>;
  billingId?: Maybe<String_Comparison_Exp>;
  businessType?: Maybe<String_Comparison_Exp>;
  deletedAt?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  isActive?: Maybe<Boolean_Comparison_Exp>;
  isFreeAccount?: Maybe<Boolean_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  requiredInteractionTimePeriod?: Maybe<String_Comparison_Exp>;
  requiredInteractions?: Maybe<Numeric_Comparison_Exp>;
  size?: Maybe<String_Comparison_Exp>;
  timezone?: Maybe<String_Comparison_Exp>;
  trialEndDate?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Company" */
export enum Company_Constraint {
  /** unique or primary key constraint */
  CompanyPkey = 'Company_pkey'
}

/** input type for incrementing numeric columns in table "Company" */
export type Company_Inc_Input = {
  requiredInteractions?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "Company" */
export type Company_Insert_Input = {
  TeamMembers?: Maybe<TeamMember_Arr_Rel_Insert_Input>;
  billingEmail?: Maybe<Scalars['String']>;
  billingId?: Maybe<Scalars['String']>;
  businessType?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isFreeAccount?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  requiredInteractionTimePeriod?: Maybe<Scalars['String']>;
  requiredInteractions?: Maybe<Scalars['numeric']>;
  size?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  trialEndDate?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Company_Max_Fields = {
  __typename?: 'Company_max_fields';
  billingEmail?: Maybe<Scalars['String']>;
  billingId?: Maybe<Scalars['String']>;
  businessType?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  requiredInteractionTimePeriod?: Maybe<Scalars['String']>;
  requiredInteractions?: Maybe<Scalars['numeric']>;
  size?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  trialEndDate?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Company_Min_Fields = {
  __typename?: 'Company_min_fields';
  billingEmail?: Maybe<Scalars['String']>;
  billingId?: Maybe<Scalars['String']>;
  businessType?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  requiredInteractionTimePeriod?: Maybe<Scalars['String']>;
  requiredInteractions?: Maybe<Scalars['numeric']>;
  size?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  trialEndDate?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "Company" */
export type Company_Mutation_Response = {
  __typename?: 'Company_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Company>;
};

/** input type for inserting object relation for remote table "Company" */
export type Company_Obj_Rel_Insert_Input = {
  data: Company_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Company_On_Conflict>;
};

/** on conflict condition type for table "Company" */
export type Company_On_Conflict = {
  constraint: Company_Constraint;
  update_columns: Array<Company_Update_Column>;
  where?: Maybe<Company_Bool_Exp>;
};

/** Ordering options when selecting data from "Company". */
export type Company_Order_By = {
  TeamMembers_aggregate?: Maybe<TeamMember_Aggregate_Order_By>;
  billingEmail?: Maybe<Order_By>;
  billingId?: Maybe<Order_By>;
  businessType?: Maybe<Order_By>;
  deletedAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  isActive?: Maybe<Order_By>;
  isFreeAccount?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  requiredInteractionTimePeriod?: Maybe<Order_By>;
  requiredInteractions?: Maybe<Order_By>;
  size?: Maybe<Order_By>;
  timezone?: Maybe<Order_By>;
  trialEndDate?: Maybe<Order_By>;
};

/** primary key columns input for table: Company */
export type Company_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "Company" */
export enum Company_Select_Column {
  /** column name */
  BillingEmail = 'billingEmail',
  /** column name */
  BillingId = 'billingId',
  /** column name */
  BusinessType = 'businessType',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  IsFreeAccount = 'isFreeAccount',
  /** column name */
  Name = 'name',
  /** column name */
  RequiredInteractionTimePeriod = 'requiredInteractionTimePeriod',
  /** column name */
  RequiredInteractions = 'requiredInteractions',
  /** column name */
  Size = 'size',
  /** column name */
  Timezone = 'timezone',
  /** column name */
  TrialEndDate = 'trialEndDate'
}

/** input type for updating data in table "Company" */
export type Company_Set_Input = {
  billingEmail?: Maybe<Scalars['String']>;
  billingId?: Maybe<Scalars['String']>;
  businessType?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isFreeAccount?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  requiredInteractionTimePeriod?: Maybe<Scalars['String']>;
  requiredInteractions?: Maybe<Scalars['numeric']>;
  size?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  trialEndDate?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Company_Stddev_Fields = {
  __typename?: 'Company_stddev_fields';
  requiredInteractions?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Company_Stddev_Pop_Fields = {
  __typename?: 'Company_stddev_pop_fields';
  requiredInteractions?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Company_Stddev_Samp_Fields = {
  __typename?: 'Company_stddev_samp_fields';
  requiredInteractions?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Company_Sum_Fields = {
  __typename?: 'Company_sum_fields';
  requiredInteractions?: Maybe<Scalars['numeric']>;
};

/** update columns of table "Company" */
export enum Company_Update_Column {
  /** column name */
  BillingEmail = 'billingEmail',
  /** column name */
  BillingId = 'billingId',
  /** column name */
  BusinessType = 'businessType',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  IsFreeAccount = 'isFreeAccount',
  /** column name */
  Name = 'name',
  /** column name */
  RequiredInteractionTimePeriod = 'requiredInteractionTimePeriod',
  /** column name */
  RequiredInteractions = 'requiredInteractions',
  /** column name */
  Size = 'size',
  /** column name */
  Timezone = 'timezone',
  /** column name */
  TrialEndDate = 'trialEndDate'
}

/** aggregate var_pop on columns */
export type Company_Var_Pop_Fields = {
  __typename?: 'Company_var_pop_fields';
  requiredInteractions?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Company_Var_Samp_Fields = {
  __typename?: 'Company_var_samp_fields';
  requiredInteractions?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Company_Variance_Fields = {
  __typename?: 'Company_variance_fields';
  requiredInteractions?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Feedback" */
export type Feedback = {
  __typename?: 'Feedback';
  /** An object relationship */
  Company: Company;
  /** An array relationship */
  FeedbackEditHistory: Array<FeedbackEditHistory>;
  /** An aggregate relationship */
  FeedbackEditHistory_aggregate: FeedbackEditHistory_Aggregate;
  /** An object relationship */
  FeedbackSession: FeedbackSession;
  /** An object relationship */
  Manager: TeamMember;
  /** An object relationship */
  TeamMember: TeamMember;
  category: Scalars['String'];
  categoryDescription?: Maybe<Scalars['String']>;
  companyId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  impact: Scalars['numeric'];
  isAgreed?: Maybe<Scalars['Boolean']>;
  managerId: Scalars['uuid'];
  notes: Scalars['String'];
  sessionId: Scalars['Int'];
  teamMemberId: Scalars['uuid'];
  type: Scalars['String'];
};


/** columns and relationships of "Feedback" */
export type FeedbackFeedbackEditHistoryArgs = {
  distinct_on?: Maybe<Array<FeedbackEditHistory_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FeedbackEditHistory_Order_By>>;
  where?: Maybe<FeedbackEditHistory_Bool_Exp>;
};


/** columns and relationships of "Feedback" */
export type FeedbackFeedbackEditHistory_AggregateArgs = {
  distinct_on?: Maybe<Array<FeedbackEditHistory_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FeedbackEditHistory_Order_By>>;
  where?: Maybe<FeedbackEditHistory_Bool_Exp>;
};

/** columns and relationships of "FeedbackEditHistory" */
export type FeedbackEditHistory = {
  __typename?: 'FeedbackEditHistory';
  /** An object relationship */
  Company?: Maybe<Company>;
  /** An object relationship */
  Feedback: Feedback;
  /** An object relationship */
  FeedbackSession: FeedbackSession;
  /** An object relationship */
  Manager: TeamMember;
  companyId?: Maybe<Scalars['uuid']>;
  createdAt: Scalars['timestamptz'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  feedbackId: Scalars['Int'];
  id: Scalars['Int'];
  impact: Scalars['numeric'];
  isAgreed?: Maybe<Scalars['Boolean']>;
  managerId: Scalars['uuid'];
  notes: Scalars['String'];
  sessionId: Scalars['Int'];
};

/** aggregated selection of "FeedbackEditHistory" */
export type FeedbackEditHistory_Aggregate = {
  __typename?: 'FeedbackEditHistory_aggregate';
  aggregate?: Maybe<FeedbackEditHistory_Aggregate_Fields>;
  nodes: Array<FeedbackEditHistory>;
};

/** aggregate fields of "FeedbackEditHistory" */
export type FeedbackEditHistory_Aggregate_Fields = {
  __typename?: 'FeedbackEditHistory_aggregate_fields';
  avg?: Maybe<FeedbackEditHistory_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<FeedbackEditHistory_Max_Fields>;
  min?: Maybe<FeedbackEditHistory_Min_Fields>;
  stddev?: Maybe<FeedbackEditHistory_Stddev_Fields>;
  stddev_pop?: Maybe<FeedbackEditHistory_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<FeedbackEditHistory_Stddev_Samp_Fields>;
  sum?: Maybe<FeedbackEditHistory_Sum_Fields>;
  var_pop?: Maybe<FeedbackEditHistory_Var_Pop_Fields>;
  var_samp?: Maybe<FeedbackEditHistory_Var_Samp_Fields>;
  variance?: Maybe<FeedbackEditHistory_Variance_Fields>;
};


/** aggregate fields of "FeedbackEditHistory" */
export type FeedbackEditHistory_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<FeedbackEditHistory_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "FeedbackEditHistory" */
export type FeedbackEditHistory_Aggregate_Order_By = {
  avg?: Maybe<FeedbackEditHistory_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<FeedbackEditHistory_Max_Order_By>;
  min?: Maybe<FeedbackEditHistory_Min_Order_By>;
  stddev?: Maybe<FeedbackEditHistory_Stddev_Order_By>;
  stddev_pop?: Maybe<FeedbackEditHistory_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<FeedbackEditHistory_Stddev_Samp_Order_By>;
  sum?: Maybe<FeedbackEditHistory_Sum_Order_By>;
  var_pop?: Maybe<FeedbackEditHistory_Var_Pop_Order_By>;
  var_samp?: Maybe<FeedbackEditHistory_Var_Samp_Order_By>;
  variance?: Maybe<FeedbackEditHistory_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "FeedbackEditHistory" */
export type FeedbackEditHistory_Arr_Rel_Insert_Input = {
  data: Array<FeedbackEditHistory_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<FeedbackEditHistory_On_Conflict>;
};

/** aggregate avg on columns */
export type FeedbackEditHistory_Avg_Fields = {
  __typename?: 'FeedbackEditHistory_avg_fields';
  feedbackId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  impact?: Maybe<Scalars['Float']>;
  sessionId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "FeedbackEditHistory" */
export type FeedbackEditHistory_Avg_Order_By = {
  feedbackId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  impact?: Maybe<Order_By>;
  sessionId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "FeedbackEditHistory". All fields are combined with a logical 'AND'. */
export type FeedbackEditHistory_Bool_Exp = {
  Company?: Maybe<Company_Bool_Exp>;
  Feedback?: Maybe<Feedback_Bool_Exp>;
  FeedbackSession?: Maybe<FeedbackSession_Bool_Exp>;
  Manager?: Maybe<TeamMember_Bool_Exp>;
  _and?: Maybe<Array<FeedbackEditHistory_Bool_Exp>>;
  _not?: Maybe<FeedbackEditHistory_Bool_Exp>;
  _or?: Maybe<Array<FeedbackEditHistory_Bool_Exp>>;
  companyId?: Maybe<Uuid_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  deletedAt?: Maybe<Timestamptz_Comparison_Exp>;
  feedbackId?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  impact?: Maybe<Numeric_Comparison_Exp>;
  isAgreed?: Maybe<Boolean_Comparison_Exp>;
  managerId?: Maybe<Uuid_Comparison_Exp>;
  notes?: Maybe<String_Comparison_Exp>;
  sessionId?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "FeedbackEditHistory" */
export enum FeedbackEditHistory_Constraint {
  /** unique or primary key constraint */
  FeedbackEditHistoryPkey = 'FeedbackEditHistory_pkey'
}

/** input type for incrementing numeric columns in table "FeedbackEditHistory" */
export type FeedbackEditHistory_Inc_Input = {
  feedbackId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  impact?: Maybe<Scalars['numeric']>;
  sessionId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "FeedbackEditHistory" */
export type FeedbackEditHistory_Insert_Input = {
  Company?: Maybe<Company_Obj_Rel_Insert_Input>;
  Feedback?: Maybe<Feedback_Obj_Rel_Insert_Input>;
  FeedbackSession?: Maybe<FeedbackSession_Obj_Rel_Insert_Input>;
  Manager?: Maybe<TeamMember_Obj_Rel_Insert_Input>;
  companyId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  feedbackId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  impact?: Maybe<Scalars['numeric']>;
  isAgreed?: Maybe<Scalars['Boolean']>;
  managerId?: Maybe<Scalars['uuid']>;
  notes?: Maybe<Scalars['String']>;
  sessionId?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type FeedbackEditHistory_Max_Fields = {
  __typename?: 'FeedbackEditHistory_max_fields';
  companyId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  feedbackId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  impact?: Maybe<Scalars['numeric']>;
  managerId?: Maybe<Scalars['uuid']>;
  notes?: Maybe<Scalars['String']>;
  sessionId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "FeedbackEditHistory" */
export type FeedbackEditHistory_Max_Order_By = {
  companyId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  deletedAt?: Maybe<Order_By>;
  feedbackId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  impact?: Maybe<Order_By>;
  managerId?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  sessionId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type FeedbackEditHistory_Min_Fields = {
  __typename?: 'FeedbackEditHistory_min_fields';
  companyId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  feedbackId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  impact?: Maybe<Scalars['numeric']>;
  managerId?: Maybe<Scalars['uuid']>;
  notes?: Maybe<Scalars['String']>;
  sessionId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "FeedbackEditHistory" */
export type FeedbackEditHistory_Min_Order_By = {
  companyId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  deletedAt?: Maybe<Order_By>;
  feedbackId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  impact?: Maybe<Order_By>;
  managerId?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  sessionId?: Maybe<Order_By>;
};

/** response of any mutation on the table "FeedbackEditHistory" */
export type FeedbackEditHistory_Mutation_Response = {
  __typename?: 'FeedbackEditHistory_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<FeedbackEditHistory>;
};

/** on conflict condition type for table "FeedbackEditHistory" */
export type FeedbackEditHistory_On_Conflict = {
  constraint: FeedbackEditHistory_Constraint;
  update_columns: Array<FeedbackEditHistory_Update_Column>;
  where?: Maybe<FeedbackEditHistory_Bool_Exp>;
};

/** Ordering options when selecting data from "FeedbackEditHistory". */
export type FeedbackEditHistory_Order_By = {
  Company?: Maybe<Company_Order_By>;
  Feedback?: Maybe<Feedback_Order_By>;
  FeedbackSession?: Maybe<FeedbackSession_Order_By>;
  Manager?: Maybe<TeamMember_Order_By>;
  companyId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  deletedAt?: Maybe<Order_By>;
  feedbackId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  impact?: Maybe<Order_By>;
  isAgreed?: Maybe<Order_By>;
  managerId?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  sessionId?: Maybe<Order_By>;
};

/** primary key columns input for table: FeedbackEditHistory */
export type FeedbackEditHistory_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "FeedbackEditHistory" */
export enum FeedbackEditHistory_Select_Column {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  FeedbackId = 'feedbackId',
  /** column name */
  Id = 'id',
  /** column name */
  Impact = 'impact',
  /** column name */
  IsAgreed = 'isAgreed',
  /** column name */
  ManagerId = 'managerId',
  /** column name */
  Notes = 'notes',
  /** column name */
  SessionId = 'sessionId'
}

/** input type for updating data in table "FeedbackEditHistory" */
export type FeedbackEditHistory_Set_Input = {
  companyId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  feedbackId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  impact?: Maybe<Scalars['numeric']>;
  isAgreed?: Maybe<Scalars['Boolean']>;
  managerId?: Maybe<Scalars['uuid']>;
  notes?: Maybe<Scalars['String']>;
  sessionId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type FeedbackEditHistory_Stddev_Fields = {
  __typename?: 'FeedbackEditHistory_stddev_fields';
  feedbackId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  impact?: Maybe<Scalars['Float']>;
  sessionId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "FeedbackEditHistory" */
export type FeedbackEditHistory_Stddev_Order_By = {
  feedbackId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  impact?: Maybe<Order_By>;
  sessionId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type FeedbackEditHistory_Stddev_Pop_Fields = {
  __typename?: 'FeedbackEditHistory_stddev_pop_fields';
  feedbackId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  impact?: Maybe<Scalars['Float']>;
  sessionId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "FeedbackEditHistory" */
export type FeedbackEditHistory_Stddev_Pop_Order_By = {
  feedbackId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  impact?: Maybe<Order_By>;
  sessionId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type FeedbackEditHistory_Stddev_Samp_Fields = {
  __typename?: 'FeedbackEditHistory_stddev_samp_fields';
  feedbackId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  impact?: Maybe<Scalars['Float']>;
  sessionId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "FeedbackEditHistory" */
export type FeedbackEditHistory_Stddev_Samp_Order_By = {
  feedbackId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  impact?: Maybe<Order_By>;
  sessionId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type FeedbackEditHistory_Sum_Fields = {
  __typename?: 'FeedbackEditHistory_sum_fields';
  feedbackId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  impact?: Maybe<Scalars['numeric']>;
  sessionId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "FeedbackEditHistory" */
export type FeedbackEditHistory_Sum_Order_By = {
  feedbackId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  impact?: Maybe<Order_By>;
  sessionId?: Maybe<Order_By>;
};

/** update columns of table "FeedbackEditHistory" */
export enum FeedbackEditHistory_Update_Column {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  FeedbackId = 'feedbackId',
  /** column name */
  Id = 'id',
  /** column name */
  Impact = 'impact',
  /** column name */
  IsAgreed = 'isAgreed',
  /** column name */
  ManagerId = 'managerId',
  /** column name */
  Notes = 'notes',
  /** column name */
  SessionId = 'sessionId'
}

/** aggregate var_pop on columns */
export type FeedbackEditHistory_Var_Pop_Fields = {
  __typename?: 'FeedbackEditHistory_var_pop_fields';
  feedbackId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  impact?: Maybe<Scalars['Float']>;
  sessionId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "FeedbackEditHistory" */
export type FeedbackEditHistory_Var_Pop_Order_By = {
  feedbackId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  impact?: Maybe<Order_By>;
  sessionId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type FeedbackEditHistory_Var_Samp_Fields = {
  __typename?: 'FeedbackEditHistory_var_samp_fields';
  feedbackId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  impact?: Maybe<Scalars['Float']>;
  sessionId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "FeedbackEditHistory" */
export type FeedbackEditHistory_Var_Samp_Order_By = {
  feedbackId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  impact?: Maybe<Order_By>;
  sessionId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type FeedbackEditHistory_Variance_Fields = {
  __typename?: 'FeedbackEditHistory_variance_fields';
  feedbackId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  impact?: Maybe<Scalars['Float']>;
  sessionId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "FeedbackEditHistory" */
export type FeedbackEditHistory_Variance_Order_By = {
  feedbackId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  impact?: Maybe<Order_By>;
  sessionId?: Maybe<Order_By>;
};

/** columns and relationships of "FeedbackFramework" */
export type FeedbackFramework = {
  __typename?: 'FeedbackFramework';
  /** An array relationship */
  Children: Array<FeedbackFramework>;
  /** An aggregate relationship */
  Children_aggregate: FeedbackFramework_Aggregate;
  /** An object relationship */
  Company?: Maybe<Company>;
  /** An object relationship */
  CreatedBy?: Maybe<TeamMember>;
  /** An object relationship */
  Parent?: Maybe<FeedbackFramework>;
  /** An object relationship */
  TeamMember?: Maybe<TeamMember>;
  /** An array relationship */
  TeamMemberFrameworks: Array<TeamMemberFramework>;
  /** An aggregate relationship */
  TeamMemberFrameworks_aggregate: TeamMemberFramework_Aggregate;
  companyId?: Maybe<Scalars['uuid']>;
  createdBy?: Maybe<Scalars['uuid']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isAdmin: Scalars['Boolean'];
  name: Scalars['String'];
  parentId?: Maybe<Scalars['Int']>;
  selectableNotes?: Maybe<Scalars['String']>;
  teamMemberId?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "FeedbackFramework" */
export type FeedbackFrameworkChildrenArgs = {
  distinct_on?: Maybe<Array<FeedbackFramework_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FeedbackFramework_Order_By>>;
  where?: Maybe<FeedbackFramework_Bool_Exp>;
};


/** columns and relationships of "FeedbackFramework" */
export type FeedbackFrameworkChildren_AggregateArgs = {
  distinct_on?: Maybe<Array<FeedbackFramework_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FeedbackFramework_Order_By>>;
  where?: Maybe<FeedbackFramework_Bool_Exp>;
};


/** columns and relationships of "FeedbackFramework" */
export type FeedbackFrameworkTeamMemberFrameworksArgs = {
  distinct_on?: Maybe<Array<TeamMemberFramework_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TeamMemberFramework_Order_By>>;
  where?: Maybe<TeamMemberFramework_Bool_Exp>;
};


/** columns and relationships of "FeedbackFramework" */
export type FeedbackFrameworkTeamMemberFrameworks_AggregateArgs = {
  distinct_on?: Maybe<Array<TeamMemberFramework_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TeamMemberFramework_Order_By>>;
  where?: Maybe<TeamMemberFramework_Bool_Exp>;
};

/** aggregated selection of "FeedbackFramework" */
export type FeedbackFramework_Aggregate = {
  __typename?: 'FeedbackFramework_aggregate';
  aggregate?: Maybe<FeedbackFramework_Aggregate_Fields>;
  nodes: Array<FeedbackFramework>;
};

/** aggregate fields of "FeedbackFramework" */
export type FeedbackFramework_Aggregate_Fields = {
  __typename?: 'FeedbackFramework_aggregate_fields';
  avg?: Maybe<FeedbackFramework_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<FeedbackFramework_Max_Fields>;
  min?: Maybe<FeedbackFramework_Min_Fields>;
  stddev?: Maybe<FeedbackFramework_Stddev_Fields>;
  stddev_pop?: Maybe<FeedbackFramework_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<FeedbackFramework_Stddev_Samp_Fields>;
  sum?: Maybe<FeedbackFramework_Sum_Fields>;
  var_pop?: Maybe<FeedbackFramework_Var_Pop_Fields>;
  var_samp?: Maybe<FeedbackFramework_Var_Samp_Fields>;
  variance?: Maybe<FeedbackFramework_Variance_Fields>;
};


/** aggregate fields of "FeedbackFramework" */
export type FeedbackFramework_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<FeedbackFramework_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "FeedbackFramework" */
export type FeedbackFramework_Aggregate_Order_By = {
  avg?: Maybe<FeedbackFramework_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<FeedbackFramework_Max_Order_By>;
  min?: Maybe<FeedbackFramework_Min_Order_By>;
  stddev?: Maybe<FeedbackFramework_Stddev_Order_By>;
  stddev_pop?: Maybe<FeedbackFramework_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<FeedbackFramework_Stddev_Samp_Order_By>;
  sum?: Maybe<FeedbackFramework_Sum_Order_By>;
  var_pop?: Maybe<FeedbackFramework_Var_Pop_Order_By>;
  var_samp?: Maybe<FeedbackFramework_Var_Samp_Order_By>;
  variance?: Maybe<FeedbackFramework_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "FeedbackFramework" */
export type FeedbackFramework_Arr_Rel_Insert_Input = {
  data: Array<FeedbackFramework_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<FeedbackFramework_On_Conflict>;
};

/** aggregate avg on columns */
export type FeedbackFramework_Avg_Fields = {
  __typename?: 'FeedbackFramework_avg_fields';
  id?: Maybe<Scalars['Float']>;
  parentId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "FeedbackFramework" */
export type FeedbackFramework_Avg_Order_By = {
  id?: Maybe<Order_By>;
  parentId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "FeedbackFramework". All fields are combined with a logical 'AND'. */
export type FeedbackFramework_Bool_Exp = {
  Children?: Maybe<FeedbackFramework_Bool_Exp>;
  Company?: Maybe<Company_Bool_Exp>;
  CreatedBy?: Maybe<TeamMember_Bool_Exp>;
  Parent?: Maybe<FeedbackFramework_Bool_Exp>;
  TeamMember?: Maybe<TeamMember_Bool_Exp>;
  TeamMemberFrameworks?: Maybe<TeamMemberFramework_Bool_Exp>;
  _and?: Maybe<Array<FeedbackFramework_Bool_Exp>>;
  _not?: Maybe<FeedbackFramework_Bool_Exp>;
  _or?: Maybe<Array<FeedbackFramework_Bool_Exp>>;
  companyId?: Maybe<Uuid_Comparison_Exp>;
  createdBy?: Maybe<Uuid_Comparison_Exp>;
  deletedAt?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  isAdmin?: Maybe<Boolean_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  parentId?: Maybe<Int_Comparison_Exp>;
  selectableNotes?: Maybe<String_Comparison_Exp>;
  teamMemberId?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "FeedbackFramework" */
export enum FeedbackFramework_Constraint {
  /** unique or primary key constraint */
  FeedbackFrameworkPkey = 'FeedbackFramework_pkey'
}

/** input type for incrementing numeric columns in table "FeedbackFramework" */
export type FeedbackFramework_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  parentId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "FeedbackFramework" */
export type FeedbackFramework_Insert_Input = {
  Children?: Maybe<FeedbackFramework_Arr_Rel_Insert_Input>;
  Company?: Maybe<Company_Obj_Rel_Insert_Input>;
  CreatedBy?: Maybe<TeamMember_Obj_Rel_Insert_Input>;
  Parent?: Maybe<FeedbackFramework_Obj_Rel_Insert_Input>;
  TeamMember?: Maybe<TeamMember_Obj_Rel_Insert_Input>;
  TeamMemberFrameworks?: Maybe<TeamMemberFramework_Arr_Rel_Insert_Input>;
  companyId?: Maybe<Scalars['uuid']>;
  createdBy?: Maybe<Scalars['uuid']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['Int']>;
  selectableNotes?: Maybe<Scalars['String']>;
  teamMemberId?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type FeedbackFramework_Max_Fields = {
  __typename?: 'FeedbackFramework_max_fields';
  companyId?: Maybe<Scalars['uuid']>;
  createdBy?: Maybe<Scalars['uuid']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['Int']>;
  selectableNotes?: Maybe<Scalars['String']>;
  teamMemberId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "FeedbackFramework" */
export type FeedbackFramework_Max_Order_By = {
  companyId?: Maybe<Order_By>;
  createdBy?: Maybe<Order_By>;
  deletedAt?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  parentId?: Maybe<Order_By>;
  selectableNotes?: Maybe<Order_By>;
  teamMemberId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type FeedbackFramework_Min_Fields = {
  __typename?: 'FeedbackFramework_min_fields';
  companyId?: Maybe<Scalars['uuid']>;
  createdBy?: Maybe<Scalars['uuid']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['Int']>;
  selectableNotes?: Maybe<Scalars['String']>;
  teamMemberId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "FeedbackFramework" */
export type FeedbackFramework_Min_Order_By = {
  companyId?: Maybe<Order_By>;
  createdBy?: Maybe<Order_By>;
  deletedAt?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  parentId?: Maybe<Order_By>;
  selectableNotes?: Maybe<Order_By>;
  teamMemberId?: Maybe<Order_By>;
};

/** response of any mutation on the table "FeedbackFramework" */
export type FeedbackFramework_Mutation_Response = {
  __typename?: 'FeedbackFramework_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<FeedbackFramework>;
};

/** input type for inserting object relation for remote table "FeedbackFramework" */
export type FeedbackFramework_Obj_Rel_Insert_Input = {
  data: FeedbackFramework_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<FeedbackFramework_On_Conflict>;
};

/** on conflict condition type for table "FeedbackFramework" */
export type FeedbackFramework_On_Conflict = {
  constraint: FeedbackFramework_Constraint;
  update_columns: Array<FeedbackFramework_Update_Column>;
  where?: Maybe<FeedbackFramework_Bool_Exp>;
};

/** Ordering options when selecting data from "FeedbackFramework". */
export type FeedbackFramework_Order_By = {
  Children_aggregate?: Maybe<FeedbackFramework_Aggregate_Order_By>;
  Company?: Maybe<Company_Order_By>;
  CreatedBy?: Maybe<TeamMember_Order_By>;
  Parent?: Maybe<FeedbackFramework_Order_By>;
  TeamMember?: Maybe<TeamMember_Order_By>;
  TeamMemberFrameworks_aggregate?: Maybe<TeamMemberFramework_Aggregate_Order_By>;
  companyId?: Maybe<Order_By>;
  createdBy?: Maybe<Order_By>;
  deletedAt?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  isAdmin?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  parentId?: Maybe<Order_By>;
  selectableNotes?: Maybe<Order_By>;
  teamMemberId?: Maybe<Order_By>;
};

/** primary key columns input for table: FeedbackFramework */
export type FeedbackFramework_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "FeedbackFramework" */
export enum FeedbackFramework_Select_Column {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  CreatedBy = 'createdBy',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsAdmin = 'isAdmin',
  /** column name */
  Name = 'name',
  /** column name */
  ParentId = 'parentId',
  /** column name */
  SelectableNotes = 'selectableNotes',
  /** column name */
  TeamMemberId = 'teamMemberId'
}

/** input type for updating data in table "FeedbackFramework" */
export type FeedbackFramework_Set_Input = {
  companyId?: Maybe<Scalars['uuid']>;
  createdBy?: Maybe<Scalars['uuid']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['Int']>;
  selectableNotes?: Maybe<Scalars['String']>;
  teamMemberId?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type FeedbackFramework_Stddev_Fields = {
  __typename?: 'FeedbackFramework_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  parentId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "FeedbackFramework" */
export type FeedbackFramework_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  parentId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type FeedbackFramework_Stddev_Pop_Fields = {
  __typename?: 'FeedbackFramework_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  parentId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "FeedbackFramework" */
export type FeedbackFramework_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  parentId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type FeedbackFramework_Stddev_Samp_Fields = {
  __typename?: 'FeedbackFramework_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  parentId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "FeedbackFramework" */
export type FeedbackFramework_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  parentId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type FeedbackFramework_Sum_Fields = {
  __typename?: 'FeedbackFramework_sum_fields';
  id?: Maybe<Scalars['Int']>;
  parentId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "FeedbackFramework" */
export type FeedbackFramework_Sum_Order_By = {
  id?: Maybe<Order_By>;
  parentId?: Maybe<Order_By>;
};

/** update columns of table "FeedbackFramework" */
export enum FeedbackFramework_Update_Column {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  CreatedBy = 'createdBy',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsAdmin = 'isAdmin',
  /** column name */
  Name = 'name',
  /** column name */
  ParentId = 'parentId',
  /** column name */
  SelectableNotes = 'selectableNotes',
  /** column name */
  TeamMemberId = 'teamMemberId'
}

/** aggregate var_pop on columns */
export type FeedbackFramework_Var_Pop_Fields = {
  __typename?: 'FeedbackFramework_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  parentId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "FeedbackFramework" */
export type FeedbackFramework_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  parentId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type FeedbackFramework_Var_Samp_Fields = {
  __typename?: 'FeedbackFramework_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  parentId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "FeedbackFramework" */
export type FeedbackFramework_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  parentId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type FeedbackFramework_Variance_Fields = {
  __typename?: 'FeedbackFramework_variance_fields';
  id?: Maybe<Scalars['Float']>;
  parentId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "FeedbackFramework" */
export type FeedbackFramework_Variance_Order_By = {
  id?: Maybe<Order_By>;
  parentId?: Maybe<Order_By>;
};

/** columns and relationships of "FeedbackSession" */
export type FeedbackSession = {
  __typename?: 'FeedbackSession';
  /** An object relationship */
  Company: Company;
  /** An array relationship */
  FeedbackEditHistory: Array<FeedbackEditHistory>;
  /** An aggregate relationship */
  FeedbackEditHistory_aggregate: FeedbackEditHistory_Aggregate;
  /** An array relationship */
  FeedbackItem: Array<Feedback>;
  /** An aggregate relationship */
  FeedbackItem_aggregate: Feedback_Aggregate;
  /** An object relationship */
  Manager: TeamMember;
  /** An object relationship */
  TeamMember: TeamMember;
  companyId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  managerId: Scalars['uuid'];
  summarySentAt?: Maybe<Scalars['timestamptz']>;
  teamMemberId: Scalars['uuid'];
};


/** columns and relationships of "FeedbackSession" */
export type FeedbackSessionFeedbackEditHistoryArgs = {
  distinct_on?: Maybe<Array<FeedbackEditHistory_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FeedbackEditHistory_Order_By>>;
  where?: Maybe<FeedbackEditHistory_Bool_Exp>;
};


/** columns and relationships of "FeedbackSession" */
export type FeedbackSessionFeedbackEditHistory_AggregateArgs = {
  distinct_on?: Maybe<Array<FeedbackEditHistory_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FeedbackEditHistory_Order_By>>;
  where?: Maybe<FeedbackEditHistory_Bool_Exp>;
};


/** columns and relationships of "FeedbackSession" */
export type FeedbackSessionFeedbackItemArgs = {
  distinct_on?: Maybe<Array<Feedback_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Feedback_Order_By>>;
  where?: Maybe<Feedback_Bool_Exp>;
};


/** columns and relationships of "FeedbackSession" */
export type FeedbackSessionFeedbackItem_AggregateArgs = {
  distinct_on?: Maybe<Array<Feedback_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Feedback_Order_By>>;
  where?: Maybe<Feedback_Bool_Exp>;
};

/** aggregated selection of "FeedbackSession" */
export type FeedbackSession_Aggregate = {
  __typename?: 'FeedbackSession_aggregate';
  aggregate?: Maybe<FeedbackSession_Aggregate_Fields>;
  nodes: Array<FeedbackSession>;
};

/** aggregate fields of "FeedbackSession" */
export type FeedbackSession_Aggregate_Fields = {
  __typename?: 'FeedbackSession_aggregate_fields';
  avg?: Maybe<FeedbackSession_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<FeedbackSession_Max_Fields>;
  min?: Maybe<FeedbackSession_Min_Fields>;
  stddev?: Maybe<FeedbackSession_Stddev_Fields>;
  stddev_pop?: Maybe<FeedbackSession_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<FeedbackSession_Stddev_Samp_Fields>;
  sum?: Maybe<FeedbackSession_Sum_Fields>;
  var_pop?: Maybe<FeedbackSession_Var_Pop_Fields>;
  var_samp?: Maybe<FeedbackSession_Var_Samp_Fields>;
  variance?: Maybe<FeedbackSession_Variance_Fields>;
};


/** aggregate fields of "FeedbackSession" */
export type FeedbackSession_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<FeedbackSession_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type FeedbackSession_Avg_Fields = {
  __typename?: 'FeedbackSession_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "FeedbackSession". All fields are combined with a logical 'AND'. */
export type FeedbackSession_Bool_Exp = {
  Company?: Maybe<Company_Bool_Exp>;
  FeedbackEditHistory?: Maybe<FeedbackEditHistory_Bool_Exp>;
  FeedbackItem?: Maybe<Feedback_Bool_Exp>;
  Manager?: Maybe<TeamMember_Bool_Exp>;
  TeamMember?: Maybe<TeamMember_Bool_Exp>;
  _and?: Maybe<Array<FeedbackSession_Bool_Exp>>;
  _not?: Maybe<FeedbackSession_Bool_Exp>;
  _or?: Maybe<Array<FeedbackSession_Bool_Exp>>;
  companyId?: Maybe<Uuid_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  deletedAt?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  managerId?: Maybe<Uuid_Comparison_Exp>;
  summarySentAt?: Maybe<Timestamptz_Comparison_Exp>;
  teamMemberId?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "FeedbackSession" */
export enum FeedbackSession_Constraint {
  /** unique or primary key constraint */
  FeedbackSessionPkey = 'FeedbackSession_pkey'
}

/** input type for incrementing numeric columns in table "FeedbackSession" */
export type FeedbackSession_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "FeedbackSession" */
export type FeedbackSession_Insert_Input = {
  Company?: Maybe<Company_Obj_Rel_Insert_Input>;
  FeedbackEditHistory?: Maybe<FeedbackEditHistory_Arr_Rel_Insert_Input>;
  FeedbackItem?: Maybe<Feedback_Arr_Rel_Insert_Input>;
  Manager?: Maybe<TeamMember_Obj_Rel_Insert_Input>;
  TeamMember?: Maybe<TeamMember_Obj_Rel_Insert_Input>;
  companyId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  managerId?: Maybe<Scalars['uuid']>;
  summarySentAt?: Maybe<Scalars['timestamptz']>;
  teamMemberId?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type FeedbackSession_Max_Fields = {
  __typename?: 'FeedbackSession_max_fields';
  companyId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  managerId?: Maybe<Scalars['uuid']>;
  summarySentAt?: Maybe<Scalars['timestamptz']>;
  teamMemberId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type FeedbackSession_Min_Fields = {
  __typename?: 'FeedbackSession_min_fields';
  companyId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  managerId?: Maybe<Scalars['uuid']>;
  summarySentAt?: Maybe<Scalars['timestamptz']>;
  teamMemberId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "FeedbackSession" */
export type FeedbackSession_Mutation_Response = {
  __typename?: 'FeedbackSession_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<FeedbackSession>;
};

/** input type for inserting object relation for remote table "FeedbackSession" */
export type FeedbackSession_Obj_Rel_Insert_Input = {
  data: FeedbackSession_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<FeedbackSession_On_Conflict>;
};

/** on conflict condition type for table "FeedbackSession" */
export type FeedbackSession_On_Conflict = {
  constraint: FeedbackSession_Constraint;
  update_columns: Array<FeedbackSession_Update_Column>;
  where?: Maybe<FeedbackSession_Bool_Exp>;
};

/** Ordering options when selecting data from "FeedbackSession". */
export type FeedbackSession_Order_By = {
  Company?: Maybe<Company_Order_By>;
  FeedbackEditHistory_aggregate?: Maybe<FeedbackEditHistory_Aggregate_Order_By>;
  FeedbackItem_aggregate?: Maybe<Feedback_Aggregate_Order_By>;
  Manager?: Maybe<TeamMember_Order_By>;
  TeamMember?: Maybe<TeamMember_Order_By>;
  companyId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  deletedAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  managerId?: Maybe<Order_By>;
  summarySentAt?: Maybe<Order_By>;
  teamMemberId?: Maybe<Order_By>;
};

/** primary key columns input for table: FeedbackSession */
export type FeedbackSession_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "FeedbackSession" */
export enum FeedbackSession_Select_Column {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Id = 'id',
  /** column name */
  ManagerId = 'managerId',
  /** column name */
  SummarySentAt = 'summarySentAt',
  /** column name */
  TeamMemberId = 'teamMemberId'
}

/** input type for updating data in table "FeedbackSession" */
export type FeedbackSession_Set_Input = {
  companyId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  managerId?: Maybe<Scalars['uuid']>;
  summarySentAt?: Maybe<Scalars['timestamptz']>;
  teamMemberId?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type FeedbackSession_Stddev_Fields = {
  __typename?: 'FeedbackSession_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type FeedbackSession_Stddev_Pop_Fields = {
  __typename?: 'FeedbackSession_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type FeedbackSession_Stddev_Samp_Fields = {
  __typename?: 'FeedbackSession_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type FeedbackSession_Sum_Fields = {
  __typename?: 'FeedbackSession_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "FeedbackSession" */
export enum FeedbackSession_Update_Column {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Id = 'id',
  /** column name */
  ManagerId = 'managerId',
  /** column name */
  SummarySentAt = 'summarySentAt',
  /** column name */
  TeamMemberId = 'teamMemberId'
}

/** aggregate var_pop on columns */
export type FeedbackSession_Var_Pop_Fields = {
  __typename?: 'FeedbackSession_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type FeedbackSession_Var_Samp_Fields = {
  __typename?: 'FeedbackSession_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type FeedbackSession_Variance_Fields = {
  __typename?: 'FeedbackSession_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "Feedback" */
export type Feedback_Aggregate = {
  __typename?: 'Feedback_aggregate';
  aggregate?: Maybe<Feedback_Aggregate_Fields>;
  nodes: Array<Feedback>;
};

/** aggregate fields of "Feedback" */
export type Feedback_Aggregate_Fields = {
  __typename?: 'Feedback_aggregate_fields';
  avg?: Maybe<Feedback_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Feedback_Max_Fields>;
  min?: Maybe<Feedback_Min_Fields>;
  stddev?: Maybe<Feedback_Stddev_Fields>;
  stddev_pop?: Maybe<Feedback_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Feedback_Stddev_Samp_Fields>;
  sum?: Maybe<Feedback_Sum_Fields>;
  var_pop?: Maybe<Feedback_Var_Pop_Fields>;
  var_samp?: Maybe<Feedback_Var_Samp_Fields>;
  variance?: Maybe<Feedback_Variance_Fields>;
};


/** aggregate fields of "Feedback" */
export type Feedback_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Feedback_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Feedback" */
export type Feedback_Aggregate_Order_By = {
  avg?: Maybe<Feedback_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Feedback_Max_Order_By>;
  min?: Maybe<Feedback_Min_Order_By>;
  stddev?: Maybe<Feedback_Stddev_Order_By>;
  stddev_pop?: Maybe<Feedback_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Feedback_Stddev_Samp_Order_By>;
  sum?: Maybe<Feedback_Sum_Order_By>;
  var_pop?: Maybe<Feedback_Var_Pop_Order_By>;
  var_samp?: Maybe<Feedback_Var_Samp_Order_By>;
  variance?: Maybe<Feedback_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Feedback" */
export type Feedback_Arr_Rel_Insert_Input = {
  data: Array<Feedback_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Feedback_On_Conflict>;
};

/** aggregate avg on columns */
export type Feedback_Avg_Fields = {
  __typename?: 'Feedback_avg_fields';
  id?: Maybe<Scalars['Float']>;
  impact?: Maybe<Scalars['Float']>;
  sessionId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Feedback" */
export type Feedback_Avg_Order_By = {
  id?: Maybe<Order_By>;
  impact?: Maybe<Order_By>;
  sessionId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Feedback". All fields are combined with a logical 'AND'. */
export type Feedback_Bool_Exp = {
  Company?: Maybe<Company_Bool_Exp>;
  FeedbackEditHistory?: Maybe<FeedbackEditHistory_Bool_Exp>;
  FeedbackSession?: Maybe<FeedbackSession_Bool_Exp>;
  Manager?: Maybe<TeamMember_Bool_Exp>;
  TeamMember?: Maybe<TeamMember_Bool_Exp>;
  _and?: Maybe<Array<Feedback_Bool_Exp>>;
  _not?: Maybe<Feedback_Bool_Exp>;
  _or?: Maybe<Array<Feedback_Bool_Exp>>;
  category?: Maybe<String_Comparison_Exp>;
  categoryDescription?: Maybe<String_Comparison_Exp>;
  companyId?: Maybe<Uuid_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  deletedAt?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  impact?: Maybe<Numeric_Comparison_Exp>;
  isAgreed?: Maybe<Boolean_Comparison_Exp>;
  managerId?: Maybe<Uuid_Comparison_Exp>;
  notes?: Maybe<String_Comparison_Exp>;
  sessionId?: Maybe<Int_Comparison_Exp>;
  teamMemberId?: Maybe<Uuid_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Feedback" */
export enum Feedback_Constraint {
  /** unique or primary key constraint */
  FeedbackPkey = 'Feedback_pkey'
}

/** input type for incrementing numeric columns in table "Feedback" */
export type Feedback_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  impact?: Maybe<Scalars['numeric']>;
  sessionId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Feedback" */
export type Feedback_Insert_Input = {
  Company?: Maybe<Company_Obj_Rel_Insert_Input>;
  FeedbackEditHistory?: Maybe<FeedbackEditHistory_Arr_Rel_Insert_Input>;
  FeedbackSession?: Maybe<FeedbackSession_Obj_Rel_Insert_Input>;
  Manager?: Maybe<TeamMember_Obj_Rel_Insert_Input>;
  TeamMember?: Maybe<TeamMember_Obj_Rel_Insert_Input>;
  category?: Maybe<Scalars['String']>;
  categoryDescription?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  impact?: Maybe<Scalars['numeric']>;
  isAgreed?: Maybe<Scalars['Boolean']>;
  managerId?: Maybe<Scalars['uuid']>;
  notes?: Maybe<Scalars['String']>;
  sessionId?: Maybe<Scalars['Int']>;
  teamMemberId?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Feedback_Max_Fields = {
  __typename?: 'Feedback_max_fields';
  category?: Maybe<Scalars['String']>;
  categoryDescription?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  impact?: Maybe<Scalars['numeric']>;
  managerId?: Maybe<Scalars['uuid']>;
  notes?: Maybe<Scalars['String']>;
  sessionId?: Maybe<Scalars['Int']>;
  teamMemberId?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "Feedback" */
export type Feedback_Max_Order_By = {
  category?: Maybe<Order_By>;
  categoryDescription?: Maybe<Order_By>;
  companyId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  deletedAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  impact?: Maybe<Order_By>;
  managerId?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  sessionId?: Maybe<Order_By>;
  teamMemberId?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Feedback_Min_Fields = {
  __typename?: 'Feedback_min_fields';
  category?: Maybe<Scalars['String']>;
  categoryDescription?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  impact?: Maybe<Scalars['numeric']>;
  managerId?: Maybe<Scalars['uuid']>;
  notes?: Maybe<Scalars['String']>;
  sessionId?: Maybe<Scalars['Int']>;
  teamMemberId?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "Feedback" */
export type Feedback_Min_Order_By = {
  category?: Maybe<Order_By>;
  categoryDescription?: Maybe<Order_By>;
  companyId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  deletedAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  impact?: Maybe<Order_By>;
  managerId?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  sessionId?: Maybe<Order_By>;
  teamMemberId?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** response of any mutation on the table "Feedback" */
export type Feedback_Mutation_Response = {
  __typename?: 'Feedback_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Feedback>;
};

/** input type for inserting object relation for remote table "Feedback" */
export type Feedback_Obj_Rel_Insert_Input = {
  data: Feedback_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Feedback_On_Conflict>;
};

/** on conflict condition type for table "Feedback" */
export type Feedback_On_Conflict = {
  constraint: Feedback_Constraint;
  update_columns: Array<Feedback_Update_Column>;
  where?: Maybe<Feedback_Bool_Exp>;
};

/** Ordering options when selecting data from "Feedback". */
export type Feedback_Order_By = {
  Company?: Maybe<Company_Order_By>;
  FeedbackEditHistory_aggregate?: Maybe<FeedbackEditHistory_Aggregate_Order_By>;
  FeedbackSession?: Maybe<FeedbackSession_Order_By>;
  Manager?: Maybe<TeamMember_Order_By>;
  TeamMember?: Maybe<TeamMember_Order_By>;
  category?: Maybe<Order_By>;
  categoryDescription?: Maybe<Order_By>;
  companyId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  deletedAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  impact?: Maybe<Order_By>;
  isAgreed?: Maybe<Order_By>;
  managerId?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  sessionId?: Maybe<Order_By>;
  teamMemberId?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** primary key columns input for table: Feedback */
export type Feedback_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "Feedback" */
export enum Feedback_Select_Column {
  /** column name */
  Category = 'category',
  /** column name */
  CategoryDescription = 'categoryDescription',
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Id = 'id',
  /** column name */
  Impact = 'impact',
  /** column name */
  IsAgreed = 'isAgreed',
  /** column name */
  ManagerId = 'managerId',
  /** column name */
  Notes = 'notes',
  /** column name */
  SessionId = 'sessionId',
  /** column name */
  TeamMemberId = 'teamMemberId',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "Feedback" */
export type Feedback_Set_Input = {
  category?: Maybe<Scalars['String']>;
  categoryDescription?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  impact?: Maybe<Scalars['numeric']>;
  isAgreed?: Maybe<Scalars['Boolean']>;
  managerId?: Maybe<Scalars['uuid']>;
  notes?: Maybe<Scalars['String']>;
  sessionId?: Maybe<Scalars['Int']>;
  teamMemberId?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Feedback_Stddev_Fields = {
  __typename?: 'Feedback_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  impact?: Maybe<Scalars['Float']>;
  sessionId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Feedback" */
export type Feedback_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  impact?: Maybe<Order_By>;
  sessionId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Feedback_Stddev_Pop_Fields = {
  __typename?: 'Feedback_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  impact?: Maybe<Scalars['Float']>;
  sessionId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Feedback" */
export type Feedback_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  impact?: Maybe<Order_By>;
  sessionId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Feedback_Stddev_Samp_Fields = {
  __typename?: 'Feedback_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  impact?: Maybe<Scalars['Float']>;
  sessionId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Feedback" */
export type Feedback_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  impact?: Maybe<Order_By>;
  sessionId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Feedback_Sum_Fields = {
  __typename?: 'Feedback_sum_fields';
  id?: Maybe<Scalars['Int']>;
  impact?: Maybe<Scalars['numeric']>;
  sessionId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Feedback" */
export type Feedback_Sum_Order_By = {
  id?: Maybe<Order_By>;
  impact?: Maybe<Order_By>;
  sessionId?: Maybe<Order_By>;
};

/** update columns of table "Feedback" */
export enum Feedback_Update_Column {
  /** column name */
  Category = 'category',
  /** column name */
  CategoryDescription = 'categoryDescription',
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Id = 'id',
  /** column name */
  Impact = 'impact',
  /** column name */
  IsAgreed = 'isAgreed',
  /** column name */
  ManagerId = 'managerId',
  /** column name */
  Notes = 'notes',
  /** column name */
  SessionId = 'sessionId',
  /** column name */
  TeamMemberId = 'teamMemberId',
  /** column name */
  Type = 'type'
}

/** aggregate var_pop on columns */
export type Feedback_Var_Pop_Fields = {
  __typename?: 'Feedback_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  impact?: Maybe<Scalars['Float']>;
  sessionId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Feedback" */
export type Feedback_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  impact?: Maybe<Order_By>;
  sessionId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Feedback_Var_Samp_Fields = {
  __typename?: 'Feedback_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  impact?: Maybe<Scalars['Float']>;
  sessionId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Feedback" */
export type Feedback_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  impact?: Maybe<Order_By>;
  sessionId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Feedback_Variance_Fields = {
  __typename?: 'Feedback_variance_fields';
  id?: Maybe<Scalars['Float']>;
  impact?: Maybe<Scalars['Float']>;
  sessionId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Feedback" */
export type Feedback_Variance_Order_By = {
  id?: Maybe<Order_By>;
  impact?: Maybe<Order_By>;
  sessionId?: Maybe<Order_By>;
};

/** columns and relationships of "GlobalSetting" */
export type GlobalSetting = {
  __typename?: 'GlobalSetting';
  defaultTrialPeriod: Scalars['Int'];
  id: Scalars['uuid'];
};

/** aggregated selection of "GlobalSetting" */
export type GlobalSetting_Aggregate = {
  __typename?: 'GlobalSetting_aggregate';
  aggregate?: Maybe<GlobalSetting_Aggregate_Fields>;
  nodes: Array<GlobalSetting>;
};

/** aggregate fields of "GlobalSetting" */
export type GlobalSetting_Aggregate_Fields = {
  __typename?: 'GlobalSetting_aggregate_fields';
  avg?: Maybe<GlobalSetting_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<GlobalSetting_Max_Fields>;
  min?: Maybe<GlobalSetting_Min_Fields>;
  stddev?: Maybe<GlobalSetting_Stddev_Fields>;
  stddev_pop?: Maybe<GlobalSetting_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<GlobalSetting_Stddev_Samp_Fields>;
  sum?: Maybe<GlobalSetting_Sum_Fields>;
  var_pop?: Maybe<GlobalSetting_Var_Pop_Fields>;
  var_samp?: Maybe<GlobalSetting_Var_Samp_Fields>;
  variance?: Maybe<GlobalSetting_Variance_Fields>;
};


/** aggregate fields of "GlobalSetting" */
export type GlobalSetting_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<GlobalSetting_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type GlobalSetting_Avg_Fields = {
  __typename?: 'GlobalSetting_avg_fields';
  defaultTrialPeriod?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "GlobalSetting". All fields are combined with a logical 'AND'. */
export type GlobalSetting_Bool_Exp = {
  _and?: Maybe<Array<GlobalSetting_Bool_Exp>>;
  _not?: Maybe<GlobalSetting_Bool_Exp>;
  _or?: Maybe<Array<GlobalSetting_Bool_Exp>>;
  defaultTrialPeriod?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "GlobalSetting" */
export enum GlobalSetting_Constraint {
  /** unique or primary key constraint */
  GlobalSettingPkey = 'GlobalSetting_pkey'
}

/** input type for incrementing numeric columns in table "GlobalSetting" */
export type GlobalSetting_Inc_Input = {
  defaultTrialPeriod?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "GlobalSetting" */
export type GlobalSetting_Insert_Input = {
  defaultTrialPeriod?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type GlobalSetting_Max_Fields = {
  __typename?: 'GlobalSetting_max_fields';
  defaultTrialPeriod?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type GlobalSetting_Min_Fields = {
  __typename?: 'GlobalSetting_min_fields';
  defaultTrialPeriod?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "GlobalSetting" */
export type GlobalSetting_Mutation_Response = {
  __typename?: 'GlobalSetting_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<GlobalSetting>;
};

/** on conflict condition type for table "GlobalSetting" */
export type GlobalSetting_On_Conflict = {
  constraint: GlobalSetting_Constraint;
  update_columns: Array<GlobalSetting_Update_Column>;
  where?: Maybe<GlobalSetting_Bool_Exp>;
};

/** Ordering options when selecting data from "GlobalSetting". */
export type GlobalSetting_Order_By = {
  defaultTrialPeriod?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** primary key columns input for table: GlobalSetting */
export type GlobalSetting_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "GlobalSetting" */
export enum GlobalSetting_Select_Column {
  /** column name */
  DefaultTrialPeriod = 'defaultTrialPeriod',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "GlobalSetting" */
export type GlobalSetting_Set_Input = {
  defaultTrialPeriod?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type GlobalSetting_Stddev_Fields = {
  __typename?: 'GlobalSetting_stddev_fields';
  defaultTrialPeriod?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type GlobalSetting_Stddev_Pop_Fields = {
  __typename?: 'GlobalSetting_stddev_pop_fields';
  defaultTrialPeriod?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type GlobalSetting_Stddev_Samp_Fields = {
  __typename?: 'GlobalSetting_stddev_samp_fields';
  defaultTrialPeriod?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type GlobalSetting_Sum_Fields = {
  __typename?: 'GlobalSetting_sum_fields';
  defaultTrialPeriod?: Maybe<Scalars['Int']>;
};

/** update columns of table "GlobalSetting" */
export enum GlobalSetting_Update_Column {
  /** column name */
  DefaultTrialPeriod = 'defaultTrialPeriod',
  /** column name */
  Id = 'id'
}

/** aggregate var_pop on columns */
export type GlobalSetting_Var_Pop_Fields = {
  __typename?: 'GlobalSetting_var_pop_fields';
  defaultTrialPeriod?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type GlobalSetting_Var_Samp_Fields = {
  __typename?: 'GlobalSetting_var_samp_fields';
  defaultTrialPeriod?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type GlobalSetting_Variance_Fields = {
  __typename?: 'GlobalSetting_variance_fields';
  defaultTrialPeriod?: Maybe<Scalars['Float']>;
};

export type HealthCheckOutput = {
  __typename?: 'HealthCheckOutput';
  authProviderConnected: Scalars['Boolean'];
  authProviderError?: Maybe<Scalars['String']>;
  culchApiConnected: Scalars['Boolean'];
  culchApiError?: Maybe<Scalars['String']>;
  emailProviderConnected: Scalars['Boolean'];
  emailProviderError?: Maybe<Scalars['String']>;
};

/** columns and relationships of "Infraction" */
export type Infraction = {
  __typename?: 'Infraction';
  /** An object relationship */
  Company: Company;
  /** An object relationship */
  Manager: TeamMember;
  /** An object relationship */
  TeamMember: TeamMember;
  companyId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  isAgreed?: Maybe<Scalars['Boolean']>;
  managerId: Scalars['uuid'];
  notes?: Maybe<Scalars['String']>;
  teamMemberId: Scalars['uuid'];
  type: Scalars['String'];
};

/** aggregated selection of "Infraction" */
export type Infraction_Aggregate = {
  __typename?: 'Infraction_aggregate';
  aggregate?: Maybe<Infraction_Aggregate_Fields>;
  nodes: Array<Infraction>;
};

/** aggregate fields of "Infraction" */
export type Infraction_Aggregate_Fields = {
  __typename?: 'Infraction_aggregate_fields';
  avg?: Maybe<Infraction_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Infraction_Max_Fields>;
  min?: Maybe<Infraction_Min_Fields>;
  stddev?: Maybe<Infraction_Stddev_Fields>;
  stddev_pop?: Maybe<Infraction_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Infraction_Stddev_Samp_Fields>;
  sum?: Maybe<Infraction_Sum_Fields>;
  var_pop?: Maybe<Infraction_Var_Pop_Fields>;
  var_samp?: Maybe<Infraction_Var_Samp_Fields>;
  variance?: Maybe<Infraction_Variance_Fields>;
};


/** aggregate fields of "Infraction" */
export type Infraction_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Infraction_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Infraction_Avg_Fields = {
  __typename?: 'Infraction_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Infraction". All fields are combined with a logical 'AND'. */
export type Infraction_Bool_Exp = {
  Company?: Maybe<Company_Bool_Exp>;
  Manager?: Maybe<TeamMember_Bool_Exp>;
  TeamMember?: Maybe<TeamMember_Bool_Exp>;
  _and?: Maybe<Array<Infraction_Bool_Exp>>;
  _not?: Maybe<Infraction_Bool_Exp>;
  _or?: Maybe<Array<Infraction_Bool_Exp>>;
  companyId?: Maybe<Uuid_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  deletedAt?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  isAgreed?: Maybe<Boolean_Comparison_Exp>;
  managerId?: Maybe<Uuid_Comparison_Exp>;
  notes?: Maybe<String_Comparison_Exp>;
  teamMemberId?: Maybe<Uuid_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Infraction" */
export enum Infraction_Constraint {
  /** unique or primary key constraint */
  InfractionPkey = 'Infraction_pkey'
}

/** input type for incrementing numeric columns in table "Infraction" */
export type Infraction_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Infraction" */
export type Infraction_Insert_Input = {
  Company?: Maybe<Company_Obj_Rel_Insert_Input>;
  Manager?: Maybe<TeamMember_Obj_Rel_Insert_Input>;
  TeamMember?: Maybe<TeamMember_Obj_Rel_Insert_Input>;
  companyId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  isAgreed?: Maybe<Scalars['Boolean']>;
  managerId?: Maybe<Scalars['uuid']>;
  notes?: Maybe<Scalars['String']>;
  teamMemberId?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Infraction_Max_Fields = {
  __typename?: 'Infraction_max_fields';
  companyId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  managerId?: Maybe<Scalars['uuid']>;
  notes?: Maybe<Scalars['String']>;
  teamMemberId?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Infraction_Min_Fields = {
  __typename?: 'Infraction_min_fields';
  companyId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  managerId?: Maybe<Scalars['uuid']>;
  notes?: Maybe<Scalars['String']>;
  teamMemberId?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Infraction" */
export type Infraction_Mutation_Response = {
  __typename?: 'Infraction_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Infraction>;
};

/** input type for inserting object relation for remote table "Infraction" */
export type Infraction_Obj_Rel_Insert_Input = {
  data: Infraction_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Infraction_On_Conflict>;
};

/** on conflict condition type for table "Infraction" */
export type Infraction_On_Conflict = {
  constraint: Infraction_Constraint;
  update_columns: Array<Infraction_Update_Column>;
  where?: Maybe<Infraction_Bool_Exp>;
};

/** Ordering options when selecting data from "Infraction". */
export type Infraction_Order_By = {
  Company?: Maybe<Company_Order_By>;
  Manager?: Maybe<TeamMember_Order_By>;
  TeamMember?: Maybe<TeamMember_Order_By>;
  companyId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  deletedAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  isAgreed?: Maybe<Order_By>;
  managerId?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  teamMemberId?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** primary key columns input for table: Infraction */
export type Infraction_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "Infraction" */
export enum Infraction_Select_Column {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsAgreed = 'isAgreed',
  /** column name */
  ManagerId = 'managerId',
  /** column name */
  Notes = 'notes',
  /** column name */
  TeamMemberId = 'teamMemberId',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "Infraction" */
export type Infraction_Set_Input = {
  companyId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  isAgreed?: Maybe<Scalars['Boolean']>;
  managerId?: Maybe<Scalars['uuid']>;
  notes?: Maybe<Scalars['String']>;
  teamMemberId?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Infraction_Stddev_Fields = {
  __typename?: 'Infraction_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Infraction_Stddev_Pop_Fields = {
  __typename?: 'Infraction_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Infraction_Stddev_Samp_Fields = {
  __typename?: 'Infraction_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Infraction_Sum_Fields = {
  __typename?: 'Infraction_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "Infraction" */
export enum Infraction_Update_Column {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsAgreed = 'isAgreed',
  /** column name */
  ManagerId = 'managerId',
  /** column name */
  Notes = 'notes',
  /** column name */
  TeamMemberId = 'teamMemberId',
  /** column name */
  Type = 'type'
}

/** aggregate var_pop on columns */
export type Infraction_Var_Pop_Fields = {
  __typename?: 'Infraction_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Infraction_Var_Samp_Fields = {
  __typename?: 'Infraction_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Infraction_Variance_Fields = {
  __typename?: 'Infraction_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
};

export type Invoice = {
  __typename?: 'Invoice';
  datePaid: Scalars['String'];
  id: Scalars['String'];
  invoicePdf: Scalars['String'];
  totalUsers: Scalars['Int'];
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: Maybe<Scalars['String']>;
  _is_null?: Maybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: Maybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: Maybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: Maybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: Maybe<Scalars['String']>;
};

/** columns and relationships of "TeamMember" */
export type TeamMember = {
  __typename?: 'TeamMember';
  /** An object relationship */
  Company: Company;
  /** An array relationship */
  DirectReports: Array<TeamMember>;
  /** An aggregate relationship */
  DirectReports_aggregate: TeamMember_Aggregate;
  /** An array relationship */
  Feedback: Array<Feedback>;
  /** An aggregate relationship */
  Feedback_aggregate: Feedback_Aggregate;
  /** An object relationship */
  Manager?: Maybe<TeamMember>;
  /** An array relationship */
  ManagerFeedback: Array<Feedback>;
  /** An aggregate relationship */
  ManagerFeedback_aggregate: Feedback_Aggregate;
  authenticationId?: Maybe<Scalars['uuid']>;
  companyId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  dateStarted?: Maybe<Scalars['date']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  department?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  isActive: Scalars['Boolean'];
  isFirstLogin: Scalars['Boolean'];
  isNotifiedByEmail: Scalars['Boolean'];
  isNotifiedBySMS: Scalars['Boolean'];
  lastName?: Maybe<Scalars['String']>;
  managerId?: Maybe<Scalars['uuid']>;
  phone?: Maybe<Scalars['String']>;
  recentlyViewedTeam?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  timezone: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};


/** columns and relationships of "TeamMember" */
export type TeamMemberDirectReportsArgs = {
  distinct_on?: Maybe<Array<TeamMember_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TeamMember_Order_By>>;
  where?: Maybe<TeamMember_Bool_Exp>;
};


/** columns and relationships of "TeamMember" */
export type TeamMemberDirectReports_AggregateArgs = {
  distinct_on?: Maybe<Array<TeamMember_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TeamMember_Order_By>>;
  where?: Maybe<TeamMember_Bool_Exp>;
};


/** columns and relationships of "TeamMember" */
export type TeamMemberFeedbackArgs = {
  distinct_on?: Maybe<Array<Feedback_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Feedback_Order_By>>;
  where?: Maybe<Feedback_Bool_Exp>;
};


/** columns and relationships of "TeamMember" */
export type TeamMemberFeedback_AggregateArgs = {
  distinct_on?: Maybe<Array<Feedback_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Feedback_Order_By>>;
  where?: Maybe<Feedback_Bool_Exp>;
};


/** columns and relationships of "TeamMember" */
export type TeamMemberManagerFeedbackArgs = {
  distinct_on?: Maybe<Array<Feedback_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Feedback_Order_By>>;
  where?: Maybe<Feedback_Bool_Exp>;
};


/** columns and relationships of "TeamMember" */
export type TeamMemberManagerFeedback_AggregateArgs = {
  distinct_on?: Maybe<Array<Feedback_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Feedback_Order_By>>;
  where?: Maybe<Feedback_Bool_Exp>;
};

/** columns and relationships of "TeamMemberFramework" */
export type TeamMemberFramework = {
  __typename?: 'TeamMemberFramework';
  /** An object relationship */
  Company: Company;
  /** An object relationship */
  FeedbackFramework: FeedbackFramework;
  /** An object relationship */
  TeamMember: TeamMember;
  companyId: Scalars['uuid'];
  frameworkId: Scalars['Int'];
  id: Scalars['Int'];
  teamMemberId: Scalars['uuid'];
};

/** aggregated selection of "TeamMemberFramework" */
export type TeamMemberFramework_Aggregate = {
  __typename?: 'TeamMemberFramework_aggregate';
  aggregate?: Maybe<TeamMemberFramework_Aggregate_Fields>;
  nodes: Array<TeamMemberFramework>;
};

/** aggregate fields of "TeamMemberFramework" */
export type TeamMemberFramework_Aggregate_Fields = {
  __typename?: 'TeamMemberFramework_aggregate_fields';
  avg?: Maybe<TeamMemberFramework_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<TeamMemberFramework_Max_Fields>;
  min?: Maybe<TeamMemberFramework_Min_Fields>;
  stddev?: Maybe<TeamMemberFramework_Stddev_Fields>;
  stddev_pop?: Maybe<TeamMemberFramework_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<TeamMemberFramework_Stddev_Samp_Fields>;
  sum?: Maybe<TeamMemberFramework_Sum_Fields>;
  var_pop?: Maybe<TeamMemberFramework_Var_Pop_Fields>;
  var_samp?: Maybe<TeamMemberFramework_Var_Samp_Fields>;
  variance?: Maybe<TeamMemberFramework_Variance_Fields>;
};


/** aggregate fields of "TeamMemberFramework" */
export type TeamMemberFramework_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<TeamMemberFramework_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "TeamMemberFramework" */
export type TeamMemberFramework_Aggregate_Order_By = {
  avg?: Maybe<TeamMemberFramework_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<TeamMemberFramework_Max_Order_By>;
  min?: Maybe<TeamMemberFramework_Min_Order_By>;
  stddev?: Maybe<TeamMemberFramework_Stddev_Order_By>;
  stddev_pop?: Maybe<TeamMemberFramework_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<TeamMemberFramework_Stddev_Samp_Order_By>;
  sum?: Maybe<TeamMemberFramework_Sum_Order_By>;
  var_pop?: Maybe<TeamMemberFramework_Var_Pop_Order_By>;
  var_samp?: Maybe<TeamMemberFramework_Var_Samp_Order_By>;
  variance?: Maybe<TeamMemberFramework_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "TeamMemberFramework" */
export type TeamMemberFramework_Arr_Rel_Insert_Input = {
  data: Array<TeamMemberFramework_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<TeamMemberFramework_On_Conflict>;
};

/** aggregate avg on columns */
export type TeamMemberFramework_Avg_Fields = {
  __typename?: 'TeamMemberFramework_avg_fields';
  frameworkId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "TeamMemberFramework" */
export type TeamMemberFramework_Avg_Order_By = {
  frameworkId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "TeamMemberFramework". All fields are combined with a logical 'AND'. */
export type TeamMemberFramework_Bool_Exp = {
  Company?: Maybe<Company_Bool_Exp>;
  FeedbackFramework?: Maybe<FeedbackFramework_Bool_Exp>;
  TeamMember?: Maybe<TeamMember_Bool_Exp>;
  _and?: Maybe<Array<TeamMemberFramework_Bool_Exp>>;
  _not?: Maybe<TeamMemberFramework_Bool_Exp>;
  _or?: Maybe<Array<TeamMemberFramework_Bool_Exp>>;
  companyId?: Maybe<Uuid_Comparison_Exp>;
  frameworkId?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  teamMemberId?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "TeamMemberFramework" */
export enum TeamMemberFramework_Constraint {
  /** unique or primary key constraint */
  TeamMemberFrameworkPkey = 'TeamMemberFramework_pkey'
}

/** input type for incrementing numeric columns in table "TeamMemberFramework" */
export type TeamMemberFramework_Inc_Input = {
  frameworkId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "TeamMemberFramework" */
export type TeamMemberFramework_Insert_Input = {
  Company?: Maybe<Company_Obj_Rel_Insert_Input>;
  FeedbackFramework?: Maybe<FeedbackFramework_Obj_Rel_Insert_Input>;
  TeamMember?: Maybe<TeamMember_Obj_Rel_Insert_Input>;
  companyId?: Maybe<Scalars['uuid']>;
  frameworkId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  teamMemberId?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type TeamMemberFramework_Max_Fields = {
  __typename?: 'TeamMemberFramework_max_fields';
  companyId?: Maybe<Scalars['uuid']>;
  frameworkId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  teamMemberId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "TeamMemberFramework" */
export type TeamMemberFramework_Max_Order_By = {
  companyId?: Maybe<Order_By>;
  frameworkId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  teamMemberId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type TeamMemberFramework_Min_Fields = {
  __typename?: 'TeamMemberFramework_min_fields';
  companyId?: Maybe<Scalars['uuid']>;
  frameworkId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  teamMemberId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "TeamMemberFramework" */
export type TeamMemberFramework_Min_Order_By = {
  companyId?: Maybe<Order_By>;
  frameworkId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  teamMemberId?: Maybe<Order_By>;
};

/** response of any mutation on the table "TeamMemberFramework" */
export type TeamMemberFramework_Mutation_Response = {
  __typename?: 'TeamMemberFramework_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<TeamMemberFramework>;
};

/** on conflict condition type for table "TeamMemberFramework" */
export type TeamMemberFramework_On_Conflict = {
  constraint: TeamMemberFramework_Constraint;
  update_columns: Array<TeamMemberFramework_Update_Column>;
  where?: Maybe<TeamMemberFramework_Bool_Exp>;
};

/** Ordering options when selecting data from "TeamMemberFramework". */
export type TeamMemberFramework_Order_By = {
  Company?: Maybe<Company_Order_By>;
  FeedbackFramework?: Maybe<FeedbackFramework_Order_By>;
  TeamMember?: Maybe<TeamMember_Order_By>;
  companyId?: Maybe<Order_By>;
  frameworkId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  teamMemberId?: Maybe<Order_By>;
};

/** primary key columns input for table: TeamMemberFramework */
export type TeamMemberFramework_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "TeamMemberFramework" */
export enum TeamMemberFramework_Select_Column {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  FrameworkId = 'frameworkId',
  /** column name */
  Id = 'id',
  /** column name */
  TeamMemberId = 'teamMemberId'
}

/** input type for updating data in table "TeamMemberFramework" */
export type TeamMemberFramework_Set_Input = {
  companyId?: Maybe<Scalars['uuid']>;
  frameworkId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  teamMemberId?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type TeamMemberFramework_Stddev_Fields = {
  __typename?: 'TeamMemberFramework_stddev_fields';
  frameworkId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "TeamMemberFramework" */
export type TeamMemberFramework_Stddev_Order_By = {
  frameworkId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type TeamMemberFramework_Stddev_Pop_Fields = {
  __typename?: 'TeamMemberFramework_stddev_pop_fields';
  frameworkId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "TeamMemberFramework" */
export type TeamMemberFramework_Stddev_Pop_Order_By = {
  frameworkId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type TeamMemberFramework_Stddev_Samp_Fields = {
  __typename?: 'TeamMemberFramework_stddev_samp_fields';
  frameworkId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "TeamMemberFramework" */
export type TeamMemberFramework_Stddev_Samp_Order_By = {
  frameworkId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type TeamMemberFramework_Sum_Fields = {
  __typename?: 'TeamMemberFramework_sum_fields';
  frameworkId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "TeamMemberFramework" */
export type TeamMemberFramework_Sum_Order_By = {
  frameworkId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** update columns of table "TeamMemberFramework" */
export enum TeamMemberFramework_Update_Column {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  FrameworkId = 'frameworkId',
  /** column name */
  Id = 'id',
  /** column name */
  TeamMemberId = 'teamMemberId'
}

/** aggregate var_pop on columns */
export type TeamMemberFramework_Var_Pop_Fields = {
  __typename?: 'TeamMemberFramework_var_pop_fields';
  frameworkId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "TeamMemberFramework" */
export type TeamMemberFramework_Var_Pop_Order_By = {
  frameworkId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type TeamMemberFramework_Var_Samp_Fields = {
  __typename?: 'TeamMemberFramework_var_samp_fields';
  frameworkId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "TeamMemberFramework" */
export type TeamMemberFramework_Var_Samp_Order_By = {
  frameworkId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type TeamMemberFramework_Variance_Fields = {
  __typename?: 'TeamMemberFramework_variance_fields';
  frameworkId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "TeamMemberFramework" */
export type TeamMemberFramework_Variance_Order_By = {
  frameworkId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** columns and relationships of "TeamMemberNotification" */
export type TeamMemberNotification = {
  __typename?: 'TeamMemberNotification';
  /** An object relationship */
  FeedbackSession?: Maybe<FeedbackSession>;
  /** An object relationship */
  Infraction?: Maybe<Infraction>;
  createdAt: Scalars['timestamp'];
  feedbackSessionId?: Maybe<Scalars['Int']>;
  id: Scalars['uuid'];
  infractionId?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
};

/** aggregated selection of "TeamMemberNotification" */
export type TeamMemberNotification_Aggregate = {
  __typename?: 'TeamMemberNotification_aggregate';
  aggregate?: Maybe<TeamMemberNotification_Aggregate_Fields>;
  nodes: Array<TeamMemberNotification>;
};

/** aggregate fields of "TeamMemberNotification" */
export type TeamMemberNotification_Aggregate_Fields = {
  __typename?: 'TeamMemberNotification_aggregate_fields';
  avg?: Maybe<TeamMemberNotification_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<TeamMemberNotification_Max_Fields>;
  min?: Maybe<TeamMemberNotification_Min_Fields>;
  stddev?: Maybe<TeamMemberNotification_Stddev_Fields>;
  stddev_pop?: Maybe<TeamMemberNotification_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<TeamMemberNotification_Stddev_Samp_Fields>;
  sum?: Maybe<TeamMemberNotification_Sum_Fields>;
  var_pop?: Maybe<TeamMemberNotification_Var_Pop_Fields>;
  var_samp?: Maybe<TeamMemberNotification_Var_Samp_Fields>;
  variance?: Maybe<TeamMemberNotification_Variance_Fields>;
};


/** aggregate fields of "TeamMemberNotification" */
export type TeamMemberNotification_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<TeamMemberNotification_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type TeamMemberNotification_Avg_Fields = {
  __typename?: 'TeamMemberNotification_avg_fields';
  feedbackSessionId?: Maybe<Scalars['Float']>;
  infractionId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "TeamMemberNotification". All fields are combined with a logical 'AND'. */
export type TeamMemberNotification_Bool_Exp = {
  FeedbackSession?: Maybe<FeedbackSession_Bool_Exp>;
  Infraction?: Maybe<Infraction_Bool_Exp>;
  _and?: Maybe<Array<TeamMemberNotification_Bool_Exp>>;
  _not?: Maybe<TeamMemberNotification_Bool_Exp>;
  _or?: Maybe<Array<TeamMemberNotification_Bool_Exp>>;
  createdAt?: Maybe<Timestamp_Comparison_Exp>;
  feedbackSessionId?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  infractionId?: Maybe<Int_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "TeamMemberNotification" */
export enum TeamMemberNotification_Constraint {
  /** unique or primary key constraint */
  TeamMemberNotificationPkey = 'TeamMemberNotification_pkey'
}

/** input type for incrementing numeric columns in table "TeamMemberNotification" */
export type TeamMemberNotification_Inc_Input = {
  feedbackSessionId?: Maybe<Scalars['Int']>;
  infractionId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "TeamMemberNotification" */
export type TeamMemberNotification_Insert_Input = {
  FeedbackSession?: Maybe<FeedbackSession_Obj_Rel_Insert_Input>;
  Infraction?: Maybe<Infraction_Obj_Rel_Insert_Input>;
  createdAt?: Maybe<Scalars['timestamp']>;
  feedbackSessionId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  infractionId?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type TeamMemberNotification_Max_Fields = {
  __typename?: 'TeamMemberNotification_max_fields';
  createdAt?: Maybe<Scalars['timestamp']>;
  feedbackSessionId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  infractionId?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type TeamMemberNotification_Min_Fields = {
  __typename?: 'TeamMemberNotification_min_fields';
  createdAt?: Maybe<Scalars['timestamp']>;
  feedbackSessionId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  infractionId?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "TeamMemberNotification" */
export type TeamMemberNotification_Mutation_Response = {
  __typename?: 'TeamMemberNotification_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<TeamMemberNotification>;
};

/** on conflict condition type for table "TeamMemberNotification" */
export type TeamMemberNotification_On_Conflict = {
  constraint: TeamMemberNotification_Constraint;
  update_columns: Array<TeamMemberNotification_Update_Column>;
  where?: Maybe<TeamMemberNotification_Bool_Exp>;
};

/** Ordering options when selecting data from "TeamMemberNotification". */
export type TeamMemberNotification_Order_By = {
  FeedbackSession?: Maybe<FeedbackSession_Order_By>;
  Infraction?: Maybe<Infraction_Order_By>;
  createdAt?: Maybe<Order_By>;
  feedbackSessionId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  infractionId?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** primary key columns input for table: TeamMemberNotification */
export type TeamMemberNotification_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "TeamMemberNotification" */
export enum TeamMemberNotification_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FeedbackSessionId = 'feedbackSessionId',
  /** column name */
  Id = 'id',
  /** column name */
  InfractionId = 'infractionId',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "TeamMemberNotification" */
export type TeamMemberNotification_Set_Input = {
  createdAt?: Maybe<Scalars['timestamp']>;
  feedbackSessionId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  infractionId?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type TeamMemberNotification_Stddev_Fields = {
  __typename?: 'TeamMemberNotification_stddev_fields';
  feedbackSessionId?: Maybe<Scalars['Float']>;
  infractionId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type TeamMemberNotification_Stddev_Pop_Fields = {
  __typename?: 'TeamMemberNotification_stddev_pop_fields';
  feedbackSessionId?: Maybe<Scalars['Float']>;
  infractionId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type TeamMemberNotification_Stddev_Samp_Fields = {
  __typename?: 'TeamMemberNotification_stddev_samp_fields';
  feedbackSessionId?: Maybe<Scalars['Float']>;
  infractionId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type TeamMemberNotification_Sum_Fields = {
  __typename?: 'TeamMemberNotification_sum_fields';
  feedbackSessionId?: Maybe<Scalars['Int']>;
  infractionId?: Maybe<Scalars['Int']>;
};

/** update columns of table "TeamMemberNotification" */
export enum TeamMemberNotification_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FeedbackSessionId = 'feedbackSessionId',
  /** column name */
  Id = 'id',
  /** column name */
  InfractionId = 'infractionId',
  /** column name */
  Type = 'type'
}

/** aggregate var_pop on columns */
export type TeamMemberNotification_Var_Pop_Fields = {
  __typename?: 'TeamMemberNotification_var_pop_fields';
  feedbackSessionId?: Maybe<Scalars['Float']>;
  infractionId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type TeamMemberNotification_Var_Samp_Fields = {
  __typename?: 'TeamMemberNotification_var_samp_fields';
  feedbackSessionId?: Maybe<Scalars['Float']>;
  infractionId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type TeamMemberNotification_Variance_Fields = {
  __typename?: 'TeamMemberNotification_variance_fields';
  feedbackSessionId?: Maybe<Scalars['Float']>;
  infractionId?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "TeamMember" */
export type TeamMember_Aggregate = {
  __typename?: 'TeamMember_aggregate';
  aggregate?: Maybe<TeamMember_Aggregate_Fields>;
  nodes: Array<TeamMember>;
};

/** aggregate fields of "TeamMember" */
export type TeamMember_Aggregate_Fields = {
  __typename?: 'TeamMember_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<TeamMember_Max_Fields>;
  min?: Maybe<TeamMember_Min_Fields>;
};


/** aggregate fields of "TeamMember" */
export type TeamMember_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<TeamMember_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "TeamMember" */
export type TeamMember_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<TeamMember_Max_Order_By>;
  min?: Maybe<TeamMember_Min_Order_By>;
};

/** input type for inserting array relation for remote table "TeamMember" */
export type TeamMember_Arr_Rel_Insert_Input = {
  data: Array<TeamMember_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<TeamMember_On_Conflict>;
};

/** Boolean expression to filter rows from the table "TeamMember". All fields are combined with a logical 'AND'. */
export type TeamMember_Bool_Exp = {
  Company?: Maybe<Company_Bool_Exp>;
  DirectReports?: Maybe<TeamMember_Bool_Exp>;
  Feedback?: Maybe<Feedback_Bool_Exp>;
  Manager?: Maybe<TeamMember_Bool_Exp>;
  ManagerFeedback?: Maybe<Feedback_Bool_Exp>;
  _and?: Maybe<Array<TeamMember_Bool_Exp>>;
  _not?: Maybe<TeamMember_Bool_Exp>;
  _or?: Maybe<Array<TeamMember_Bool_Exp>>;
  authenticationId?: Maybe<Uuid_Comparison_Exp>;
  companyId?: Maybe<Uuid_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  dateStarted?: Maybe<Date_Comparison_Exp>;
  deletedAt?: Maybe<Timestamptz_Comparison_Exp>;
  department?: Maybe<String_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  firstName?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  isActive?: Maybe<Boolean_Comparison_Exp>;
  isFirstLogin?: Maybe<Boolean_Comparison_Exp>;
  isNotifiedByEmail?: Maybe<Boolean_Comparison_Exp>;
  isNotifiedBySMS?: Maybe<Boolean_Comparison_Exp>;
  lastName?: Maybe<String_Comparison_Exp>;
  managerId?: Maybe<Uuid_Comparison_Exp>;
  phone?: Maybe<String_Comparison_Exp>;
  recentlyViewedTeam?: Maybe<String_Comparison_Exp>;
  role?: Maybe<String_Comparison_Exp>;
  timezone?: Maybe<String_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "TeamMember" */
export enum TeamMember_Constraint {
  /** unique or primary key constraint */
  TeamMemberEmailKey = 'TeamMember_email_key',
  /** unique or primary key constraint */
  TeamMemberPkey = 'TeamMember_pkey'
}

/** input type for inserting data into table "TeamMember" */
export type TeamMember_Insert_Input = {
  Company?: Maybe<Company_Obj_Rel_Insert_Input>;
  DirectReports?: Maybe<TeamMember_Arr_Rel_Insert_Input>;
  Feedback?: Maybe<Feedback_Arr_Rel_Insert_Input>;
  Manager?: Maybe<TeamMember_Obj_Rel_Insert_Input>;
  ManagerFeedback?: Maybe<Feedback_Arr_Rel_Insert_Input>;
  authenticationId?: Maybe<Scalars['uuid']>;
  companyId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  dateStarted?: Maybe<Scalars['date']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  department?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isFirstLogin?: Maybe<Scalars['Boolean']>;
  isNotifiedByEmail?: Maybe<Scalars['Boolean']>;
  isNotifiedBySMS?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  managerId?: Maybe<Scalars['uuid']>;
  phone?: Maybe<Scalars['String']>;
  recentlyViewedTeam?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type TeamMember_Max_Fields = {
  __typename?: 'TeamMember_max_fields';
  authenticationId?: Maybe<Scalars['uuid']>;
  companyId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  dateStarted?: Maybe<Scalars['date']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  department?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastName?: Maybe<Scalars['String']>;
  managerId?: Maybe<Scalars['uuid']>;
  phone?: Maybe<Scalars['String']>;
  recentlyViewedTeam?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "TeamMember" */
export type TeamMember_Max_Order_By = {
  authenticationId?: Maybe<Order_By>;
  companyId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  dateStarted?: Maybe<Order_By>;
  deletedAt?: Maybe<Order_By>;
  department?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  firstName?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lastName?: Maybe<Order_By>;
  managerId?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  recentlyViewedTeam?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
  timezone?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type TeamMember_Min_Fields = {
  __typename?: 'TeamMember_min_fields';
  authenticationId?: Maybe<Scalars['uuid']>;
  companyId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  dateStarted?: Maybe<Scalars['date']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  department?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastName?: Maybe<Scalars['String']>;
  managerId?: Maybe<Scalars['uuid']>;
  phone?: Maybe<Scalars['String']>;
  recentlyViewedTeam?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "TeamMember" */
export type TeamMember_Min_Order_By = {
  authenticationId?: Maybe<Order_By>;
  companyId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  dateStarted?: Maybe<Order_By>;
  deletedAt?: Maybe<Order_By>;
  department?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  firstName?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lastName?: Maybe<Order_By>;
  managerId?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  recentlyViewedTeam?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
  timezone?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** response of any mutation on the table "TeamMember" */
export type TeamMember_Mutation_Response = {
  __typename?: 'TeamMember_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<TeamMember>;
};

/** input type for inserting object relation for remote table "TeamMember" */
export type TeamMember_Obj_Rel_Insert_Input = {
  data: TeamMember_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<TeamMember_On_Conflict>;
};

/** on conflict condition type for table "TeamMember" */
export type TeamMember_On_Conflict = {
  constraint: TeamMember_Constraint;
  update_columns: Array<TeamMember_Update_Column>;
  where?: Maybe<TeamMember_Bool_Exp>;
};

/** Ordering options when selecting data from "TeamMember". */
export type TeamMember_Order_By = {
  Company?: Maybe<Company_Order_By>;
  DirectReports_aggregate?: Maybe<TeamMember_Aggregate_Order_By>;
  Feedback_aggregate?: Maybe<Feedback_Aggregate_Order_By>;
  Manager?: Maybe<TeamMember_Order_By>;
  ManagerFeedback_aggregate?: Maybe<Feedback_Aggregate_Order_By>;
  authenticationId?: Maybe<Order_By>;
  companyId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  dateStarted?: Maybe<Order_By>;
  deletedAt?: Maybe<Order_By>;
  department?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  firstName?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  isActive?: Maybe<Order_By>;
  isFirstLogin?: Maybe<Order_By>;
  isNotifiedByEmail?: Maybe<Order_By>;
  isNotifiedBySMS?: Maybe<Order_By>;
  lastName?: Maybe<Order_By>;
  managerId?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  recentlyViewedTeam?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
  timezone?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** primary key columns input for table: TeamMember */
export type TeamMember_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "TeamMember" */
export enum TeamMember_Select_Column {
  /** column name */
  AuthenticationId = 'authenticationId',
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DateStarted = 'dateStarted',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Department = 'department',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  IsFirstLogin = 'isFirstLogin',
  /** column name */
  IsNotifiedByEmail = 'isNotifiedByEmail',
  /** column name */
  IsNotifiedBySms = 'isNotifiedBySMS',
  /** column name */
  LastName = 'lastName',
  /** column name */
  ManagerId = 'managerId',
  /** column name */
  Phone = 'phone',
  /** column name */
  RecentlyViewedTeam = 'recentlyViewedTeam',
  /** column name */
  Role = 'role',
  /** column name */
  Timezone = 'timezone',
  /** column name */
  Title = 'title'
}

/** input type for updating data in table "TeamMember" */
export type TeamMember_Set_Input = {
  authenticationId?: Maybe<Scalars['uuid']>;
  companyId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  dateStarted?: Maybe<Scalars['date']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  department?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isFirstLogin?: Maybe<Scalars['Boolean']>;
  isNotifiedByEmail?: Maybe<Scalars['Boolean']>;
  isNotifiedBySMS?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  managerId?: Maybe<Scalars['uuid']>;
  phone?: Maybe<Scalars['String']>;
  recentlyViewedTeam?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** update columns of table "TeamMember" */
export enum TeamMember_Update_Column {
  /** column name */
  AuthenticationId = 'authenticationId',
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DateStarted = 'dateStarted',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Department = 'department',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  IsFirstLogin = 'isFirstLogin',
  /** column name */
  IsNotifiedByEmail = 'isNotifiedByEmail',
  /** column name */
  IsNotifiedBySms = 'isNotifiedBySMS',
  /** column name */
  LastName = 'lastName',
  /** column name */
  ManagerId = 'managerId',
  /** column name */
  Phone = 'phone',
  /** column name */
  RecentlyViewedTeam = 'recentlyViewedTeam',
  /** column name */
  Role = 'role',
  /** column name */
  Timezone = 'timezone',
  /** column name */
  Title = 'title'
}

/** columns and relationships of "TitleHistory" */
export type TitleHistory = {
  __typename?: 'TitleHistory';
  /** An object relationship */
  Manager: TeamMember;
  /** An object relationship */
  TeamMember: TeamMember;
  companyId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  managerId: Scalars['uuid'];
  oldTitle: Scalars['String'];
  teamMemberId: Scalars['uuid'];
  title: Scalars['String'];
};

/** aggregated selection of "TitleHistory" */
export type TitleHistory_Aggregate = {
  __typename?: 'TitleHistory_aggregate';
  aggregate?: Maybe<TitleHistory_Aggregate_Fields>;
  nodes: Array<TitleHistory>;
};

/** aggregate fields of "TitleHistory" */
export type TitleHistory_Aggregate_Fields = {
  __typename?: 'TitleHistory_aggregate_fields';
  avg?: Maybe<TitleHistory_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<TitleHistory_Max_Fields>;
  min?: Maybe<TitleHistory_Min_Fields>;
  stddev?: Maybe<TitleHistory_Stddev_Fields>;
  stddev_pop?: Maybe<TitleHistory_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<TitleHistory_Stddev_Samp_Fields>;
  sum?: Maybe<TitleHistory_Sum_Fields>;
  var_pop?: Maybe<TitleHistory_Var_Pop_Fields>;
  var_samp?: Maybe<TitleHistory_Var_Samp_Fields>;
  variance?: Maybe<TitleHistory_Variance_Fields>;
};


/** aggregate fields of "TitleHistory" */
export type TitleHistory_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<TitleHistory_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type TitleHistory_Avg_Fields = {
  __typename?: 'TitleHistory_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "TitleHistory". All fields are combined with a logical 'AND'. */
export type TitleHistory_Bool_Exp = {
  Manager?: Maybe<TeamMember_Bool_Exp>;
  TeamMember?: Maybe<TeamMember_Bool_Exp>;
  _and?: Maybe<Array<TitleHistory_Bool_Exp>>;
  _not?: Maybe<TitleHistory_Bool_Exp>;
  _or?: Maybe<Array<TitleHistory_Bool_Exp>>;
  companyId?: Maybe<Uuid_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  deletedAt?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  managerId?: Maybe<Uuid_Comparison_Exp>;
  oldTitle?: Maybe<String_Comparison_Exp>;
  teamMemberId?: Maybe<Uuid_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "TitleHistory" */
export enum TitleHistory_Constraint {
  /** unique or primary key constraint */
  TitleHistoryIdKey = 'TitleHistory_id_key',
  /** unique or primary key constraint */
  TitleHistoryPkey = 'TitleHistory_pkey'
}

/** input type for incrementing numeric columns in table "TitleHistory" */
export type TitleHistory_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "TitleHistory" */
export type TitleHistory_Insert_Input = {
  Manager?: Maybe<TeamMember_Obj_Rel_Insert_Input>;
  TeamMember?: Maybe<TeamMember_Obj_Rel_Insert_Input>;
  companyId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  managerId?: Maybe<Scalars['uuid']>;
  oldTitle?: Maybe<Scalars['String']>;
  teamMemberId?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type TitleHistory_Max_Fields = {
  __typename?: 'TitleHistory_max_fields';
  companyId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  managerId?: Maybe<Scalars['uuid']>;
  oldTitle?: Maybe<Scalars['String']>;
  teamMemberId?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type TitleHistory_Min_Fields = {
  __typename?: 'TitleHistory_min_fields';
  companyId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  managerId?: Maybe<Scalars['uuid']>;
  oldTitle?: Maybe<Scalars['String']>;
  teamMemberId?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "TitleHistory" */
export type TitleHistory_Mutation_Response = {
  __typename?: 'TitleHistory_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<TitleHistory>;
};

/** on conflict condition type for table "TitleHistory" */
export type TitleHistory_On_Conflict = {
  constraint: TitleHistory_Constraint;
  update_columns: Array<TitleHistory_Update_Column>;
  where?: Maybe<TitleHistory_Bool_Exp>;
};

/** Ordering options when selecting data from "TitleHistory". */
export type TitleHistory_Order_By = {
  Manager?: Maybe<TeamMember_Order_By>;
  TeamMember?: Maybe<TeamMember_Order_By>;
  companyId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  deletedAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  managerId?: Maybe<Order_By>;
  oldTitle?: Maybe<Order_By>;
  teamMemberId?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** primary key columns input for table: TitleHistory */
export type TitleHistory_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "TitleHistory" */
export enum TitleHistory_Select_Column {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Id = 'id',
  /** column name */
  ManagerId = 'managerId',
  /** column name */
  OldTitle = 'oldTitle',
  /** column name */
  TeamMemberId = 'teamMemberId',
  /** column name */
  Title = 'title'
}

/** input type for updating data in table "TitleHistory" */
export type TitleHistory_Set_Input = {
  companyId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  managerId?: Maybe<Scalars['uuid']>;
  oldTitle?: Maybe<Scalars['String']>;
  teamMemberId?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type TitleHistory_Stddev_Fields = {
  __typename?: 'TitleHistory_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type TitleHistory_Stddev_Pop_Fields = {
  __typename?: 'TitleHistory_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type TitleHistory_Stddev_Samp_Fields = {
  __typename?: 'TitleHistory_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type TitleHistory_Sum_Fields = {
  __typename?: 'TitleHistory_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "TitleHistory" */
export enum TitleHistory_Update_Column {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Id = 'id',
  /** column name */
  ManagerId = 'managerId',
  /** column name */
  OldTitle = 'oldTitle',
  /** column name */
  TeamMemberId = 'teamMemberId',
  /** column name */
  Title = 'title'
}

/** aggregate var_pop on columns */
export type TitleHistory_Var_Pop_Fields = {
  __typename?: 'TitleHistory_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type TitleHistory_Var_Samp_Fields = {
  __typename?: 'TitleHistory_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type TitleHistory_Variance_Fields = {
  __typename?: 'TitleHistory_variance_fields';
  id?: Maybe<Scalars['Float']>;
};


/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: Maybe<Scalars['date']>;
  _gt?: Maybe<Scalars['date']>;
  _gte?: Maybe<Scalars['date']>;
  _in?: Maybe<Array<Scalars['date']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['date']>;
  _lte?: Maybe<Scalars['date']>;
  _neq?: Maybe<Scalars['date']>;
  _nin?: Maybe<Array<Scalars['date']>>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  change_UserPassword?: Maybe<ChangePasswordOutput>;
  create_CompanyInitialSignUp?: Maybe<CompanyInitialSignUpOutput>;
  create_CompanyInitialSubscription?: Maybe<CompanyInitialSubscriptionOutput>;
  /** delete data from the table: "Company" */
  delete_Company?: Maybe<Company_Mutation_Response>;
  delete_CompanyCancelSubscription?: Maybe<CompanyCancelSubscriptionOutput>;
  /** delete single row from the table: "Company" */
  delete_Company_by_pk?: Maybe<Company>;
  /** delete data from the table: "Feedback" */
  delete_Feedback?: Maybe<Feedback_Mutation_Response>;
  /** delete data from the table: "FeedbackEditHistory" */
  delete_FeedbackEditHistory?: Maybe<FeedbackEditHistory_Mutation_Response>;
  /** delete single row from the table: "FeedbackEditHistory" */
  delete_FeedbackEditHistory_by_pk?: Maybe<FeedbackEditHistory>;
  /** delete data from the table: "FeedbackFramework" */
  delete_FeedbackFramework?: Maybe<FeedbackFramework_Mutation_Response>;
  /** delete single row from the table: "FeedbackFramework" */
  delete_FeedbackFramework_by_pk?: Maybe<FeedbackFramework>;
  /** delete data from the table: "FeedbackSession" */
  delete_FeedbackSession?: Maybe<FeedbackSession_Mutation_Response>;
  /** delete single row from the table: "FeedbackSession" */
  delete_FeedbackSession_by_pk?: Maybe<FeedbackSession>;
  /** delete single row from the table: "Feedback" */
  delete_Feedback_by_pk?: Maybe<Feedback>;
  /** delete data from the table: "GlobalSetting" */
  delete_GlobalSetting?: Maybe<GlobalSetting_Mutation_Response>;
  /** delete single row from the table: "GlobalSetting" */
  delete_GlobalSetting_by_pk?: Maybe<GlobalSetting>;
  /** delete data from the table: "Infraction" */
  delete_Infraction?: Maybe<Infraction_Mutation_Response>;
  /** delete single row from the table: "Infraction" */
  delete_Infraction_by_pk?: Maybe<Infraction>;
  /** delete data from the table: "TeamMember" */
  delete_TeamMember?: Maybe<TeamMember_Mutation_Response>;
  /** delete data from the table: "TeamMemberFramework" */
  delete_TeamMemberFramework?: Maybe<TeamMemberFramework_Mutation_Response>;
  /** delete single row from the table: "TeamMemberFramework" */
  delete_TeamMemberFramework_by_pk?: Maybe<TeamMemberFramework>;
  /** delete data from the table: "TeamMemberNotification" */
  delete_TeamMemberNotification?: Maybe<TeamMemberNotification_Mutation_Response>;
  /** delete single row from the table: "TeamMemberNotification" */
  delete_TeamMemberNotification_by_pk?: Maybe<TeamMemberNotification>;
  /** delete single row from the table: "TeamMember" */
  delete_TeamMember_by_pk?: Maybe<TeamMember>;
  /** delete data from the table: "TitleHistory" */
  delete_TitleHistory?: Maybe<TitleHistory_Mutation_Response>;
  /** delete single row from the table: "TitleHistory" */
  delete_TitleHistory_by_pk?: Maybe<TitleHistory>;
  /** insert data into the table: "Company" */
  insert_Company?: Maybe<Company_Mutation_Response>;
  /** insert a single row into the table: "Company" */
  insert_Company_one?: Maybe<Company>;
  /** insert data into the table: "Feedback" */
  insert_Feedback?: Maybe<Feedback_Mutation_Response>;
  /** insert data into the table: "FeedbackEditHistory" */
  insert_FeedbackEditHistory?: Maybe<FeedbackEditHistory_Mutation_Response>;
  /** insert a single row into the table: "FeedbackEditHistory" */
  insert_FeedbackEditHistory_one?: Maybe<FeedbackEditHistory>;
  /** insert data into the table: "FeedbackFramework" */
  insert_FeedbackFramework?: Maybe<FeedbackFramework_Mutation_Response>;
  /** insert a single row into the table: "FeedbackFramework" */
  insert_FeedbackFramework_one?: Maybe<FeedbackFramework>;
  /** insert data into the table: "FeedbackSession" */
  insert_FeedbackSession?: Maybe<FeedbackSession_Mutation_Response>;
  /** insert a single row into the table: "FeedbackSession" */
  insert_FeedbackSession_one?: Maybe<FeedbackSession>;
  /** insert a single row into the table: "Feedback" */
  insert_Feedback_one?: Maybe<Feedback>;
  /** insert data into the table: "GlobalSetting" */
  insert_GlobalSetting?: Maybe<GlobalSetting_Mutation_Response>;
  /** insert a single row into the table: "GlobalSetting" */
  insert_GlobalSetting_one?: Maybe<GlobalSetting>;
  /** insert data into the table: "Infraction" */
  insert_Infraction?: Maybe<Infraction_Mutation_Response>;
  /** insert a single row into the table: "Infraction" */
  insert_Infraction_one?: Maybe<Infraction>;
  /** insert data into the table: "TeamMember" */
  insert_TeamMember?: Maybe<TeamMember_Mutation_Response>;
  /** insert data into the table: "TeamMemberFramework" */
  insert_TeamMemberFramework?: Maybe<TeamMemberFramework_Mutation_Response>;
  /** insert a single row into the table: "TeamMemberFramework" */
  insert_TeamMemberFramework_one?: Maybe<TeamMemberFramework>;
  /** insert data into the table: "TeamMemberNotification" */
  insert_TeamMemberNotification?: Maybe<TeamMemberNotification_Mutation_Response>;
  /** insert a single row into the table: "TeamMemberNotification" */
  insert_TeamMemberNotification_one?: Maybe<TeamMemberNotification>;
  /** insert a single row into the table: "TeamMember" */
  insert_TeamMember_one?: Maybe<TeamMember>;
  /** insert data into the table: "TitleHistory" */
  insert_TitleHistory?: Maybe<TitleHistory_Mutation_Response>;
  /** insert a single row into the table: "TitleHistory" */
  insert_TitleHistory_one?: Maybe<TitleHistory>;
  /** update data of the table: "Company" */
  update_Company?: Maybe<Company_Mutation_Response>;
  update_CompanyBilling?: Maybe<CompanyBillingOutput>;
  update_CompanyReactivateSubscription?: Maybe<CompanyReactivateSubscriptionOutput>;
  update_CompanyUpgradeSubscription?: Maybe<CompanyUpgradeSubscriptionOutput>;
  /** update single row of the table: "Company" */
  update_Company_by_pk?: Maybe<Company>;
  /** update data of the table: "Feedback" */
  update_Feedback?: Maybe<Feedback_Mutation_Response>;
  /** update data of the table: "FeedbackEditHistory" */
  update_FeedbackEditHistory?: Maybe<FeedbackEditHistory_Mutation_Response>;
  /** update single row of the table: "FeedbackEditHistory" */
  update_FeedbackEditHistory_by_pk?: Maybe<FeedbackEditHistory>;
  /** update data of the table: "FeedbackFramework" */
  update_FeedbackFramework?: Maybe<FeedbackFramework_Mutation_Response>;
  /** update single row of the table: "FeedbackFramework" */
  update_FeedbackFramework_by_pk?: Maybe<FeedbackFramework>;
  /** update data of the table: "FeedbackSession" */
  update_FeedbackSession?: Maybe<FeedbackSession_Mutation_Response>;
  /** update single row of the table: "FeedbackSession" */
  update_FeedbackSession_by_pk?: Maybe<FeedbackSession>;
  /** update single row of the table: "Feedback" */
  update_Feedback_by_pk?: Maybe<Feedback>;
  /** update data of the table: "GlobalSetting" */
  update_GlobalSetting?: Maybe<GlobalSetting_Mutation_Response>;
  /** update single row of the table: "GlobalSetting" */
  update_GlobalSetting_by_pk?: Maybe<GlobalSetting>;
  /** update data of the table: "Infraction" */
  update_Infraction?: Maybe<Infraction_Mutation_Response>;
  /** update single row of the table: "Infraction" */
  update_Infraction_by_pk?: Maybe<Infraction>;
  /** update data of the table: "TeamMember" */
  update_TeamMember?: Maybe<TeamMember_Mutation_Response>;
  /** update data of the table: "TeamMemberFramework" */
  update_TeamMemberFramework?: Maybe<TeamMemberFramework_Mutation_Response>;
  /** update single row of the table: "TeamMemberFramework" */
  update_TeamMemberFramework_by_pk?: Maybe<TeamMemberFramework>;
  /** update data of the table: "TeamMemberNotification" */
  update_TeamMemberNotification?: Maybe<TeamMemberNotification_Mutation_Response>;
  /** update single row of the table: "TeamMemberNotification" */
  update_TeamMemberNotification_by_pk?: Maybe<TeamMemberNotification>;
  /** update single row of the table: "TeamMember" */
  update_TeamMember_by_pk?: Maybe<TeamMember>;
  /** update data of the table: "TitleHistory" */
  update_TitleHistory?: Maybe<TitleHistory_Mutation_Response>;
  /** update single row of the table: "TitleHistory" */
  update_TitleHistory_by_pk?: Maybe<TitleHistory>;
};


/** mutation root */
export type Mutation_RootChange_UserPasswordArgs = {
  currentPassword: Scalars['String'];
  email: Scalars['String'];
  newPassword: Scalars['String'];
};


/** mutation root */
export type Mutation_RootCreate_CompanyInitialSignUpArgs = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};


/** mutation root */
export type Mutation_RootCreate_CompanyInitialSubscriptionArgs = {
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  billingEmail: Scalars['String'];
  billingInterval: Scalars['String'];
  billingToken: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  priceId: Scalars['String'];
  state?: Maybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootDelete_CompanyArgs = {
  where: Company_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Company_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_FeedbackArgs = {
  where: Feedback_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_FeedbackEditHistoryArgs = {
  where: FeedbackEditHistory_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_FeedbackEditHistory_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_FeedbackFrameworkArgs = {
  where: FeedbackFramework_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_FeedbackFramework_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_FeedbackSessionArgs = {
  where: FeedbackSession_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_FeedbackSession_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Feedback_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_GlobalSettingArgs = {
  where: GlobalSetting_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_GlobalSetting_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_InfractionArgs = {
  where: Infraction_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Infraction_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_TeamMemberArgs = {
  where: TeamMember_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_TeamMemberFrameworkArgs = {
  where: TeamMemberFramework_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_TeamMemberFramework_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_TeamMemberNotificationArgs = {
  where: TeamMemberNotification_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_TeamMemberNotification_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_TeamMember_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_TitleHistoryArgs = {
  where: TitleHistory_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_TitleHistory_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootInsert_CompanyArgs = {
  objects: Array<Company_Insert_Input>;
  on_conflict?: Maybe<Company_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Company_OneArgs = {
  object: Company_Insert_Input;
  on_conflict?: Maybe<Company_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FeedbackArgs = {
  objects: Array<Feedback_Insert_Input>;
  on_conflict?: Maybe<Feedback_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FeedbackEditHistoryArgs = {
  objects: Array<FeedbackEditHistory_Insert_Input>;
  on_conflict?: Maybe<FeedbackEditHistory_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FeedbackEditHistory_OneArgs = {
  object: FeedbackEditHistory_Insert_Input;
  on_conflict?: Maybe<FeedbackEditHistory_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FeedbackFrameworkArgs = {
  objects: Array<FeedbackFramework_Insert_Input>;
  on_conflict?: Maybe<FeedbackFramework_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FeedbackFramework_OneArgs = {
  object: FeedbackFramework_Insert_Input;
  on_conflict?: Maybe<FeedbackFramework_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FeedbackSessionArgs = {
  objects: Array<FeedbackSession_Insert_Input>;
  on_conflict?: Maybe<FeedbackSession_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FeedbackSession_OneArgs = {
  object: FeedbackSession_Insert_Input;
  on_conflict?: Maybe<FeedbackSession_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Feedback_OneArgs = {
  object: Feedback_Insert_Input;
  on_conflict?: Maybe<Feedback_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_GlobalSettingArgs = {
  objects: Array<GlobalSetting_Insert_Input>;
  on_conflict?: Maybe<GlobalSetting_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_GlobalSetting_OneArgs = {
  object: GlobalSetting_Insert_Input;
  on_conflict?: Maybe<GlobalSetting_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_InfractionArgs = {
  objects: Array<Infraction_Insert_Input>;
  on_conflict?: Maybe<Infraction_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Infraction_OneArgs = {
  object: Infraction_Insert_Input;
  on_conflict?: Maybe<Infraction_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TeamMemberArgs = {
  objects: Array<TeamMember_Insert_Input>;
  on_conflict?: Maybe<TeamMember_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TeamMemberFrameworkArgs = {
  objects: Array<TeamMemberFramework_Insert_Input>;
  on_conflict?: Maybe<TeamMemberFramework_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TeamMemberFramework_OneArgs = {
  object: TeamMemberFramework_Insert_Input;
  on_conflict?: Maybe<TeamMemberFramework_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TeamMemberNotificationArgs = {
  objects: Array<TeamMemberNotification_Insert_Input>;
  on_conflict?: Maybe<TeamMemberNotification_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TeamMemberNotification_OneArgs = {
  object: TeamMemberNotification_Insert_Input;
  on_conflict?: Maybe<TeamMemberNotification_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TeamMember_OneArgs = {
  object: TeamMember_Insert_Input;
  on_conflict?: Maybe<TeamMember_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TitleHistoryArgs = {
  objects: Array<TitleHistory_Insert_Input>;
  on_conflict?: Maybe<TitleHistory_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TitleHistory_OneArgs = {
  object: TitleHistory_Insert_Input;
  on_conflict?: Maybe<TitleHistory_On_Conflict>;
};


/** mutation root */
export type Mutation_RootUpdate_CompanyArgs = {
  _inc?: Maybe<Company_Inc_Input>;
  _set?: Maybe<Company_Set_Input>;
  where: Company_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CompanyBillingArgs = {
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  billingEmail?: Maybe<Scalars['String']>;
  billingToken?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootUpdate_CompanyUpgradeSubscriptionArgs = {
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  billingEmail?: Maybe<Scalars['String']>;
  billingToken: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  priceId: Scalars['String'];
  state?: Maybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootUpdate_Company_By_PkArgs = {
  _inc?: Maybe<Company_Inc_Input>;
  _set?: Maybe<Company_Set_Input>;
  pk_columns: Company_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_FeedbackArgs = {
  _inc?: Maybe<Feedback_Inc_Input>;
  _set?: Maybe<Feedback_Set_Input>;
  where: Feedback_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_FeedbackEditHistoryArgs = {
  _inc?: Maybe<FeedbackEditHistory_Inc_Input>;
  _set?: Maybe<FeedbackEditHistory_Set_Input>;
  where: FeedbackEditHistory_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_FeedbackEditHistory_By_PkArgs = {
  _inc?: Maybe<FeedbackEditHistory_Inc_Input>;
  _set?: Maybe<FeedbackEditHistory_Set_Input>;
  pk_columns: FeedbackEditHistory_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_FeedbackFrameworkArgs = {
  _inc?: Maybe<FeedbackFramework_Inc_Input>;
  _set?: Maybe<FeedbackFramework_Set_Input>;
  where: FeedbackFramework_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_FeedbackFramework_By_PkArgs = {
  _inc?: Maybe<FeedbackFramework_Inc_Input>;
  _set?: Maybe<FeedbackFramework_Set_Input>;
  pk_columns: FeedbackFramework_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_FeedbackSessionArgs = {
  _inc?: Maybe<FeedbackSession_Inc_Input>;
  _set?: Maybe<FeedbackSession_Set_Input>;
  where: FeedbackSession_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_FeedbackSession_By_PkArgs = {
  _inc?: Maybe<FeedbackSession_Inc_Input>;
  _set?: Maybe<FeedbackSession_Set_Input>;
  pk_columns: FeedbackSession_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Feedback_By_PkArgs = {
  _inc?: Maybe<Feedback_Inc_Input>;
  _set?: Maybe<Feedback_Set_Input>;
  pk_columns: Feedback_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_GlobalSettingArgs = {
  _inc?: Maybe<GlobalSetting_Inc_Input>;
  _set?: Maybe<GlobalSetting_Set_Input>;
  where: GlobalSetting_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_GlobalSetting_By_PkArgs = {
  _inc?: Maybe<GlobalSetting_Inc_Input>;
  _set?: Maybe<GlobalSetting_Set_Input>;
  pk_columns: GlobalSetting_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_InfractionArgs = {
  _inc?: Maybe<Infraction_Inc_Input>;
  _set?: Maybe<Infraction_Set_Input>;
  where: Infraction_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Infraction_By_PkArgs = {
  _inc?: Maybe<Infraction_Inc_Input>;
  _set?: Maybe<Infraction_Set_Input>;
  pk_columns: Infraction_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_TeamMemberArgs = {
  _set?: Maybe<TeamMember_Set_Input>;
  where: TeamMember_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_TeamMemberFrameworkArgs = {
  _inc?: Maybe<TeamMemberFramework_Inc_Input>;
  _set?: Maybe<TeamMemberFramework_Set_Input>;
  where: TeamMemberFramework_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_TeamMemberFramework_By_PkArgs = {
  _inc?: Maybe<TeamMemberFramework_Inc_Input>;
  _set?: Maybe<TeamMemberFramework_Set_Input>;
  pk_columns: TeamMemberFramework_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_TeamMemberNotificationArgs = {
  _inc?: Maybe<TeamMemberNotification_Inc_Input>;
  _set?: Maybe<TeamMemberNotification_Set_Input>;
  where: TeamMemberNotification_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_TeamMemberNotification_By_PkArgs = {
  _inc?: Maybe<TeamMemberNotification_Inc_Input>;
  _set?: Maybe<TeamMemberNotification_Set_Input>;
  pk_columns: TeamMemberNotification_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_TeamMember_By_PkArgs = {
  _set?: Maybe<TeamMember_Set_Input>;
  pk_columns: TeamMember_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_TitleHistoryArgs = {
  _inc?: Maybe<TitleHistory_Inc_Input>;
  _set?: Maybe<TitleHistory_Set_Input>;
  where: TitleHistory_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_TitleHistory_By_PkArgs = {
  _inc?: Maybe<TitleHistory_Inc_Input>;
  _set?: Maybe<TitleHistory_Set_Input>;
  pk_columns: TitleHistory_Pk_Columns_Input;
};


/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: Maybe<Scalars['numeric']>;
  _gt?: Maybe<Scalars['numeric']>;
  _gte?: Maybe<Scalars['numeric']>;
  _in?: Maybe<Array<Scalars['numeric']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['numeric']>;
  _lte?: Maybe<Scalars['numeric']>;
  _neq?: Maybe<Scalars['numeric']>;
  _nin?: Maybe<Array<Scalars['numeric']>>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "Company" */
  Company: Array<Company>;
  CompanyInvoice?: Maybe<Array<Maybe<Invoice>>>;
  CompanyPaymentMethod?: Maybe<CompanyPaymentMethodOutput>;
  CompanySubscription?: Maybe<CompanySubscriptionOutput>;
  CompanySubscriptionCheckoutPrice?: Maybe<CompanySubscriptionCheckoutPriceOutput>;
  /** fetch aggregated fields from the table: "Company" */
  Company_aggregate: Company_Aggregate;
  /** fetch data from the table: "Company" using primary key columns */
  Company_by_pk?: Maybe<Company>;
  /** An array relationship */
  Feedback: Array<Feedback>;
  /** An array relationship */
  FeedbackEditHistory: Array<FeedbackEditHistory>;
  /** An aggregate relationship */
  FeedbackEditHistory_aggregate: FeedbackEditHistory_Aggregate;
  /** fetch data from the table: "FeedbackEditHistory" using primary key columns */
  FeedbackEditHistory_by_pk?: Maybe<FeedbackEditHistory>;
  /** fetch data from the table: "FeedbackFramework" */
  FeedbackFramework: Array<FeedbackFramework>;
  /** fetch aggregated fields from the table: "FeedbackFramework" */
  FeedbackFramework_aggregate: FeedbackFramework_Aggregate;
  /** fetch data from the table: "FeedbackFramework" using primary key columns */
  FeedbackFramework_by_pk?: Maybe<FeedbackFramework>;
  /** fetch data from the table: "FeedbackSession" */
  FeedbackSession: Array<FeedbackSession>;
  /** fetch aggregated fields from the table: "FeedbackSession" */
  FeedbackSession_aggregate: FeedbackSession_Aggregate;
  /** fetch data from the table: "FeedbackSession" using primary key columns */
  FeedbackSession_by_pk?: Maybe<FeedbackSession>;
  /** An aggregate relationship */
  Feedback_aggregate: Feedback_Aggregate;
  /** fetch data from the table: "Feedback" using primary key columns */
  Feedback_by_pk?: Maybe<Feedback>;
  /** fetch data from the table: "GlobalSetting" */
  GlobalSetting: Array<GlobalSetting>;
  /** fetch aggregated fields from the table: "GlobalSetting" */
  GlobalSetting_aggregate: GlobalSetting_Aggregate;
  /** fetch data from the table: "GlobalSetting" using primary key columns */
  GlobalSetting_by_pk?: Maybe<GlobalSetting>;
  HealthCheck?: Maybe<HealthCheckOutput>;
  /** fetch data from the table: "Infraction" */
  Infraction: Array<Infraction>;
  /** fetch aggregated fields from the table: "Infraction" */
  Infraction_aggregate: Infraction_Aggregate;
  /** fetch data from the table: "Infraction" using primary key columns */
  Infraction_by_pk?: Maybe<Infraction>;
  /** fetch data from the table: "TeamMember" */
  TeamMember: Array<TeamMember>;
  /** fetch data from the table: "TeamMemberFramework" */
  TeamMemberFramework: Array<TeamMemberFramework>;
  /** fetch aggregated fields from the table: "TeamMemberFramework" */
  TeamMemberFramework_aggregate: TeamMemberFramework_Aggregate;
  /** fetch data from the table: "TeamMemberFramework" using primary key columns */
  TeamMemberFramework_by_pk?: Maybe<TeamMemberFramework>;
  /** fetch data from the table: "TeamMemberNotification" */
  TeamMemberNotification: Array<TeamMemberNotification>;
  /** fetch aggregated fields from the table: "TeamMemberNotification" */
  TeamMemberNotification_aggregate: TeamMemberNotification_Aggregate;
  /** fetch data from the table: "TeamMemberNotification" using primary key columns */
  TeamMemberNotification_by_pk?: Maybe<TeamMemberNotification>;
  /** fetch aggregated fields from the table: "TeamMember" */
  TeamMember_aggregate: TeamMember_Aggregate;
  /** fetch data from the table: "TeamMember" using primary key columns */
  TeamMember_by_pk?: Maybe<TeamMember>;
  /** fetch data from the table: "TitleHistory" */
  TitleHistory: Array<TitleHistory>;
  /** fetch aggregated fields from the table: "TitleHistory" */
  TitleHistory_aggregate: TitleHistory_Aggregate;
  /** fetch data from the table: "TitleHistory" using primary key columns */
  TitleHistory_by_pk?: Maybe<TitleHistory>;
};


export type Query_RootCompanyArgs = {
  distinct_on?: Maybe<Array<Company_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Company_Order_By>>;
  where?: Maybe<Company_Bool_Exp>;
};


export type Query_RootCompanySubscriptionCheckoutPriceArgs = {
  monthlyPriceId: Scalars['String'];
  yearlyPriceId: Scalars['String'];
};


export type Query_RootCompany_AggregateArgs = {
  distinct_on?: Maybe<Array<Company_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Company_Order_By>>;
  where?: Maybe<Company_Bool_Exp>;
};


export type Query_RootCompany_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootFeedbackArgs = {
  distinct_on?: Maybe<Array<Feedback_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Feedback_Order_By>>;
  where?: Maybe<Feedback_Bool_Exp>;
};


export type Query_RootFeedbackEditHistoryArgs = {
  distinct_on?: Maybe<Array<FeedbackEditHistory_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FeedbackEditHistory_Order_By>>;
  where?: Maybe<FeedbackEditHistory_Bool_Exp>;
};


export type Query_RootFeedbackEditHistory_AggregateArgs = {
  distinct_on?: Maybe<Array<FeedbackEditHistory_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FeedbackEditHistory_Order_By>>;
  where?: Maybe<FeedbackEditHistory_Bool_Exp>;
};


export type Query_RootFeedbackEditHistory_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootFeedbackFrameworkArgs = {
  distinct_on?: Maybe<Array<FeedbackFramework_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FeedbackFramework_Order_By>>;
  where?: Maybe<FeedbackFramework_Bool_Exp>;
};


export type Query_RootFeedbackFramework_AggregateArgs = {
  distinct_on?: Maybe<Array<FeedbackFramework_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FeedbackFramework_Order_By>>;
  where?: Maybe<FeedbackFramework_Bool_Exp>;
};


export type Query_RootFeedbackFramework_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootFeedbackSessionArgs = {
  distinct_on?: Maybe<Array<FeedbackSession_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FeedbackSession_Order_By>>;
  where?: Maybe<FeedbackSession_Bool_Exp>;
};


export type Query_RootFeedbackSession_AggregateArgs = {
  distinct_on?: Maybe<Array<FeedbackSession_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FeedbackSession_Order_By>>;
  where?: Maybe<FeedbackSession_Bool_Exp>;
};


export type Query_RootFeedbackSession_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootFeedback_AggregateArgs = {
  distinct_on?: Maybe<Array<Feedback_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Feedback_Order_By>>;
  where?: Maybe<Feedback_Bool_Exp>;
};


export type Query_RootFeedback_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootGlobalSettingArgs = {
  distinct_on?: Maybe<Array<GlobalSetting_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<GlobalSetting_Order_By>>;
  where?: Maybe<GlobalSetting_Bool_Exp>;
};


export type Query_RootGlobalSetting_AggregateArgs = {
  distinct_on?: Maybe<Array<GlobalSetting_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<GlobalSetting_Order_By>>;
  where?: Maybe<GlobalSetting_Bool_Exp>;
};


export type Query_RootGlobalSetting_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootInfractionArgs = {
  distinct_on?: Maybe<Array<Infraction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Infraction_Order_By>>;
  where?: Maybe<Infraction_Bool_Exp>;
};


export type Query_RootInfraction_AggregateArgs = {
  distinct_on?: Maybe<Array<Infraction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Infraction_Order_By>>;
  where?: Maybe<Infraction_Bool_Exp>;
};


export type Query_RootInfraction_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootTeamMemberArgs = {
  distinct_on?: Maybe<Array<TeamMember_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TeamMember_Order_By>>;
  where?: Maybe<TeamMember_Bool_Exp>;
};


export type Query_RootTeamMemberFrameworkArgs = {
  distinct_on?: Maybe<Array<TeamMemberFramework_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TeamMemberFramework_Order_By>>;
  where?: Maybe<TeamMemberFramework_Bool_Exp>;
};


export type Query_RootTeamMemberFramework_AggregateArgs = {
  distinct_on?: Maybe<Array<TeamMemberFramework_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TeamMemberFramework_Order_By>>;
  where?: Maybe<TeamMemberFramework_Bool_Exp>;
};


export type Query_RootTeamMemberFramework_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootTeamMemberNotificationArgs = {
  distinct_on?: Maybe<Array<TeamMemberNotification_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TeamMemberNotification_Order_By>>;
  where?: Maybe<TeamMemberNotification_Bool_Exp>;
};


export type Query_RootTeamMemberNotification_AggregateArgs = {
  distinct_on?: Maybe<Array<TeamMemberNotification_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TeamMemberNotification_Order_By>>;
  where?: Maybe<TeamMemberNotification_Bool_Exp>;
};


export type Query_RootTeamMemberNotification_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootTeamMember_AggregateArgs = {
  distinct_on?: Maybe<Array<TeamMember_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TeamMember_Order_By>>;
  where?: Maybe<TeamMember_Bool_Exp>;
};


export type Query_RootTeamMember_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootTitleHistoryArgs = {
  distinct_on?: Maybe<Array<TitleHistory_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TitleHistory_Order_By>>;
  where?: Maybe<TitleHistory_Bool_Exp>;
};


export type Query_RootTitleHistory_AggregateArgs = {
  distinct_on?: Maybe<Array<TitleHistory_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TitleHistory_Order_By>>;
  where?: Maybe<TitleHistory_Bool_Exp>;
};


export type Query_RootTitleHistory_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "Company" */
  Company: Array<Company>;
  /** fetch aggregated fields from the table: "Company" */
  Company_aggregate: Company_Aggregate;
  /** fetch data from the table: "Company" using primary key columns */
  Company_by_pk?: Maybe<Company>;
  /** An array relationship */
  Feedback: Array<Feedback>;
  /** An array relationship */
  FeedbackEditHistory: Array<FeedbackEditHistory>;
  /** An aggregate relationship */
  FeedbackEditHistory_aggregate: FeedbackEditHistory_Aggregate;
  /** fetch data from the table: "FeedbackEditHistory" using primary key columns */
  FeedbackEditHistory_by_pk?: Maybe<FeedbackEditHistory>;
  /** fetch data from the table: "FeedbackFramework" */
  FeedbackFramework: Array<FeedbackFramework>;
  /** fetch aggregated fields from the table: "FeedbackFramework" */
  FeedbackFramework_aggregate: FeedbackFramework_Aggregate;
  /** fetch data from the table: "FeedbackFramework" using primary key columns */
  FeedbackFramework_by_pk?: Maybe<FeedbackFramework>;
  /** fetch data from the table: "FeedbackSession" */
  FeedbackSession: Array<FeedbackSession>;
  /** fetch aggregated fields from the table: "FeedbackSession" */
  FeedbackSession_aggregate: FeedbackSession_Aggregate;
  /** fetch data from the table: "FeedbackSession" using primary key columns */
  FeedbackSession_by_pk?: Maybe<FeedbackSession>;
  /** An aggregate relationship */
  Feedback_aggregate: Feedback_Aggregate;
  /** fetch data from the table: "Feedback" using primary key columns */
  Feedback_by_pk?: Maybe<Feedback>;
  /** fetch data from the table: "GlobalSetting" */
  GlobalSetting: Array<GlobalSetting>;
  /** fetch aggregated fields from the table: "GlobalSetting" */
  GlobalSetting_aggregate: GlobalSetting_Aggregate;
  /** fetch data from the table: "GlobalSetting" using primary key columns */
  GlobalSetting_by_pk?: Maybe<GlobalSetting>;
  /** fetch data from the table: "Infraction" */
  Infraction: Array<Infraction>;
  /** fetch aggregated fields from the table: "Infraction" */
  Infraction_aggregate: Infraction_Aggregate;
  /** fetch data from the table: "Infraction" using primary key columns */
  Infraction_by_pk?: Maybe<Infraction>;
  /** fetch data from the table: "TeamMember" */
  TeamMember: Array<TeamMember>;
  /** fetch data from the table: "TeamMemberFramework" */
  TeamMemberFramework: Array<TeamMemberFramework>;
  /** fetch aggregated fields from the table: "TeamMemberFramework" */
  TeamMemberFramework_aggregate: TeamMemberFramework_Aggregate;
  /** fetch data from the table: "TeamMemberFramework" using primary key columns */
  TeamMemberFramework_by_pk?: Maybe<TeamMemberFramework>;
  /** fetch data from the table: "TeamMemberNotification" */
  TeamMemberNotification: Array<TeamMemberNotification>;
  /** fetch aggregated fields from the table: "TeamMemberNotification" */
  TeamMemberNotification_aggregate: TeamMemberNotification_Aggregate;
  /** fetch data from the table: "TeamMemberNotification" using primary key columns */
  TeamMemberNotification_by_pk?: Maybe<TeamMemberNotification>;
  /** fetch aggregated fields from the table: "TeamMember" */
  TeamMember_aggregate: TeamMember_Aggregate;
  /** fetch data from the table: "TeamMember" using primary key columns */
  TeamMember_by_pk?: Maybe<TeamMember>;
  /** fetch data from the table: "TitleHistory" */
  TitleHistory: Array<TitleHistory>;
  /** fetch aggregated fields from the table: "TitleHistory" */
  TitleHistory_aggregate: TitleHistory_Aggregate;
  /** fetch data from the table: "TitleHistory" using primary key columns */
  TitleHistory_by_pk?: Maybe<TitleHistory>;
};


export type Subscription_RootCompanyArgs = {
  distinct_on?: Maybe<Array<Company_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Company_Order_By>>;
  where?: Maybe<Company_Bool_Exp>;
};


export type Subscription_RootCompany_AggregateArgs = {
  distinct_on?: Maybe<Array<Company_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Company_Order_By>>;
  where?: Maybe<Company_Bool_Exp>;
};


export type Subscription_RootCompany_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootFeedbackArgs = {
  distinct_on?: Maybe<Array<Feedback_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Feedback_Order_By>>;
  where?: Maybe<Feedback_Bool_Exp>;
};


export type Subscription_RootFeedbackEditHistoryArgs = {
  distinct_on?: Maybe<Array<FeedbackEditHistory_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FeedbackEditHistory_Order_By>>;
  where?: Maybe<FeedbackEditHistory_Bool_Exp>;
};


export type Subscription_RootFeedbackEditHistory_AggregateArgs = {
  distinct_on?: Maybe<Array<FeedbackEditHistory_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FeedbackEditHistory_Order_By>>;
  where?: Maybe<FeedbackEditHistory_Bool_Exp>;
};


export type Subscription_RootFeedbackEditHistory_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootFeedbackFrameworkArgs = {
  distinct_on?: Maybe<Array<FeedbackFramework_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FeedbackFramework_Order_By>>;
  where?: Maybe<FeedbackFramework_Bool_Exp>;
};


export type Subscription_RootFeedbackFramework_AggregateArgs = {
  distinct_on?: Maybe<Array<FeedbackFramework_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FeedbackFramework_Order_By>>;
  where?: Maybe<FeedbackFramework_Bool_Exp>;
};


export type Subscription_RootFeedbackFramework_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootFeedbackSessionArgs = {
  distinct_on?: Maybe<Array<FeedbackSession_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FeedbackSession_Order_By>>;
  where?: Maybe<FeedbackSession_Bool_Exp>;
};


export type Subscription_RootFeedbackSession_AggregateArgs = {
  distinct_on?: Maybe<Array<FeedbackSession_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FeedbackSession_Order_By>>;
  where?: Maybe<FeedbackSession_Bool_Exp>;
};


export type Subscription_RootFeedbackSession_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootFeedback_AggregateArgs = {
  distinct_on?: Maybe<Array<Feedback_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Feedback_Order_By>>;
  where?: Maybe<Feedback_Bool_Exp>;
};


export type Subscription_RootFeedback_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootGlobalSettingArgs = {
  distinct_on?: Maybe<Array<GlobalSetting_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<GlobalSetting_Order_By>>;
  where?: Maybe<GlobalSetting_Bool_Exp>;
};


export type Subscription_RootGlobalSetting_AggregateArgs = {
  distinct_on?: Maybe<Array<GlobalSetting_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<GlobalSetting_Order_By>>;
  where?: Maybe<GlobalSetting_Bool_Exp>;
};


export type Subscription_RootGlobalSetting_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootInfractionArgs = {
  distinct_on?: Maybe<Array<Infraction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Infraction_Order_By>>;
  where?: Maybe<Infraction_Bool_Exp>;
};


export type Subscription_RootInfraction_AggregateArgs = {
  distinct_on?: Maybe<Array<Infraction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Infraction_Order_By>>;
  where?: Maybe<Infraction_Bool_Exp>;
};


export type Subscription_RootInfraction_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootTeamMemberArgs = {
  distinct_on?: Maybe<Array<TeamMember_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TeamMember_Order_By>>;
  where?: Maybe<TeamMember_Bool_Exp>;
};


export type Subscription_RootTeamMemberFrameworkArgs = {
  distinct_on?: Maybe<Array<TeamMemberFramework_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TeamMemberFramework_Order_By>>;
  where?: Maybe<TeamMemberFramework_Bool_Exp>;
};


export type Subscription_RootTeamMemberFramework_AggregateArgs = {
  distinct_on?: Maybe<Array<TeamMemberFramework_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TeamMemberFramework_Order_By>>;
  where?: Maybe<TeamMemberFramework_Bool_Exp>;
};


export type Subscription_RootTeamMemberFramework_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootTeamMemberNotificationArgs = {
  distinct_on?: Maybe<Array<TeamMemberNotification_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TeamMemberNotification_Order_By>>;
  where?: Maybe<TeamMemberNotification_Bool_Exp>;
};


export type Subscription_RootTeamMemberNotification_AggregateArgs = {
  distinct_on?: Maybe<Array<TeamMemberNotification_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TeamMemberNotification_Order_By>>;
  where?: Maybe<TeamMemberNotification_Bool_Exp>;
};


export type Subscription_RootTeamMemberNotification_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootTeamMember_AggregateArgs = {
  distinct_on?: Maybe<Array<TeamMember_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TeamMember_Order_By>>;
  where?: Maybe<TeamMember_Bool_Exp>;
};


export type Subscription_RootTeamMember_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootTitleHistoryArgs = {
  distinct_on?: Maybe<Array<TitleHistory_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TitleHistory_Order_By>>;
  where?: Maybe<TitleHistory_Bool_Exp>;
};


export type Subscription_RootTitleHistory_AggregateArgs = {
  distinct_on?: Maybe<Array<TitleHistory_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TitleHistory_Order_By>>;
  where?: Maybe<TitleHistory_Bool_Exp>;
};


export type Subscription_RootTitleHistory_By_PkArgs = {
  id: Scalars['Int'];
};


/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamp']>;
  _gt?: Maybe<Scalars['timestamp']>;
  _gte?: Maybe<Scalars['timestamp']>;
  _in?: Maybe<Array<Scalars['timestamp']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamp']>;
  _lte?: Maybe<Scalars['timestamp']>;
  _neq?: Maybe<Scalars['timestamp']>;
  _nin?: Maybe<Array<Scalars['timestamp']>>;
};


/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
};


/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: Maybe<Scalars['uuid']>;
  _gt?: Maybe<Scalars['uuid']>;
  _gte?: Maybe<Scalars['uuid']>;
  _in?: Maybe<Array<Scalars['uuid']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['uuid']>;
  _lte?: Maybe<Scalars['uuid']>;
  _neq?: Maybe<Scalars['uuid']>;
  _nin?: Maybe<Array<Scalars['uuid']>>;
};

export type ArchiveFeedbackFrameworkMutationVariables = Exact<{
  id: Scalars['Int'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
}>;


export type ArchiveFeedbackFrameworkMutation = (
  { __typename?: 'mutation_root' }
  & { update_FeedbackFramework?: Maybe<(
    { __typename?: 'FeedbackFramework_mutation_response' }
    & { returning: Array<(
      { __typename?: 'FeedbackFramework' }
      & Pick<FeedbackFramework, 'id'>
    )> }
  )> }
);

export type ArchiveInfractionMutationVariables = Exact<{
  id: Scalars['Int'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
}>;


export type ArchiveInfractionMutation = (
  { __typename?: 'mutation_root' }
  & { update_Infraction?: Maybe<(
    { __typename?: 'Infraction_mutation_response' }
    & { returning: Array<(
      { __typename?: 'Infraction' }
      & Pick<Infraction, 'id'>
    )> }
  )> }
);

export type CloseAccountMutationVariables = Exact<{ [key: string]: never; }>;


export type CloseAccountMutation = (
  { __typename?: 'mutation_root' }
  & { delete_CompanyCancelSubscription?: Maybe<(
    { __typename?: 'CompanyCancelSubscriptionOutput' }
    & Pick<CompanyCancelSubscriptionOutput, 'isSuccess'>
  )> }
);

export type CreateCompanyMutationVariables = Exact<{
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
}>;


export type CreateCompanyMutation = (
  { __typename?: 'mutation_root' }
  & { create_CompanyInitialSignUp?: Maybe<(
    { __typename?: 'CompanyInitialSignUpOutput' }
    & Pick<CompanyInitialSignUpOutput, 'companyId' | 'teamMemberId'>
  )> }
);

export type CreateCompanyInitialSubscriptionMutationVariables = Exact<{
  billingEmail: Scalars['String'];
  billingToken: Scalars['String'];
  priceId: Scalars['String'];
  billingInterval: Scalars['String'];
  addressLine1: Scalars['String'];
  addressLine2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  companyName: Scalars['String'];
  postalCode: Scalars['String'];
  state: Scalars['String'];
}>;


export type CreateCompanyInitialSubscriptionMutation = (
  { __typename?: 'mutation_root' }
  & { create_CompanyInitialSubscription?: Maybe<(
    { __typename?: 'CompanyInitialSubscriptionOutput' }
    & Pick<CompanyInitialSubscriptionOutput, 'isSuccess'>
  )> }
);

export type DeleteTeamMemberFrameworksMutationVariables = Exact<{
  teamMemberId: Scalars['uuid'];
}>;


export type DeleteTeamMemberFrameworksMutation = (
  { __typename?: 'mutation_root' }
  & { delete_TeamMemberFramework?: Maybe<(
    { __typename?: 'TeamMemberFramework_mutation_response' }
    & Pick<TeamMemberFramework_Mutation_Response, 'affected_rows'>
  )> }
);

export type GetActionItemsQueryVariables = Exact<{
  currentTeamMemberId: Scalars['uuid'];
  startDate: Scalars['timestamptz'];
  endDate: Scalars['timestamptz'];
  searchQuery: Scalars['String'];
}>;


export type GetActionItemsQuery = (
  { __typename?: 'query_root' }
  & { TeamMember: Array<(
    { __typename?: 'TeamMember' }
    & Pick<TeamMember, 'firstName' | 'id' | 'lastName'>
    & { Feedback_aggregate: (
      { __typename?: 'Feedback_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'Feedback_aggregate_fields' }
        & Pick<Feedback_Aggregate_Fields, 'count'>
      )> }
    ) }
  )> }
);

export type GetActionItemsByTeamMemberQueryVariables = Exact<{
  startDate: Scalars['timestamptz'];
  endDate: Scalars['timestamptz'];
  teamMemberId: Scalars['uuid'];
}>;


export type GetActionItemsByTeamMemberQuery = (
  { __typename?: 'query_root' }
  & { TeamMember: Array<(
    { __typename?: 'TeamMember' }
    & Pick<TeamMember, 'firstName' | 'id' | 'lastName'>
    & { Feedback_aggregate: (
      { __typename?: 'Feedback_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'Feedback_aggregate_fields' }
        & Pick<Feedback_Aggregate_Fields, 'count'>
      )> }
    ) }
  )> }
);

export type GetAdminArchivedTeamMembersQueryVariables = Exact<{
  currentTeamMemberId: Scalars['uuid'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  searchQuery: Scalars['String'];
  managerId?: Maybe<Scalars['uuid']>;
  role?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<TeamMember_Order_By>>;
}>;


export type GetAdminArchivedTeamMembersQuery = (
  { __typename?: 'query_root' }
  & { TeamMember: Array<(
    { __typename?: 'TeamMember' }
    & Pick<TeamMember, 'id' | 'lastName' | 'isActive' | 'firstName' | 'email' | 'department' | 'dateStarted' | 'phone' | 'title' | 'managerId' | 'role' | 'authenticationId'>
    & { Manager?: Maybe<(
      { __typename?: 'TeamMember' }
      & Pick<TeamMember, 'firstName' | 'lastName' | 'id'>
    )> }
  )>, TeamMember_aggregate: (
    { __typename?: 'TeamMember_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'TeamMember_aggregate_fields' }
      & Pick<TeamMember_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type GetAdminFeedbackFrameworksQueryVariables = Exact<{
  manager?: Maybe<Scalars['uuid']>;
}>;


export type GetAdminFeedbackFrameworksQuery = (
  { __typename?: 'query_root' }
  & { FeedbackFramework: Array<(
    { __typename?: 'FeedbackFramework' }
    & Pick<FeedbackFramework, 'id' | 'name'>
    & { CreatedBy?: Maybe<(
      { __typename?: 'TeamMember' }
      & Pick<TeamMember, 'firstName' | 'lastName'>
    )>, Parent?: Maybe<(
      { __typename?: 'FeedbackFramework' }
      & Pick<FeedbackFramework, 'name' | 'id'>
    )>, Children: Array<(
      { __typename?: 'FeedbackFramework' }
      & Pick<FeedbackFramework, 'id' | 'name'>
      & { CreatedBy?: Maybe<(
        { __typename?: 'TeamMember' }
        & Pick<TeamMember, 'firstName' | 'lastName'>
      )> }
    )> }
  )> }
);

export type GetAdminTeamMembersQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  searchQuery: Scalars['String'];
  managerId?: Maybe<Scalars['uuid']>;
  role?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<TeamMember_Order_By>>;
}>;


export type GetAdminTeamMembersQuery = (
  { __typename?: 'query_root' }
  & { TeamMember: Array<(
    { __typename?: 'TeamMember' }
    & Pick<TeamMember, 'id' | 'lastName' | 'isActive' | 'firstName' | 'email' | 'department' | 'dateStarted' | 'phone' | 'title' | 'managerId' | 'role' | 'authenticationId'>
    & { Manager?: Maybe<(
      { __typename?: 'TeamMember' }
      & Pick<TeamMember, 'firstName' | 'lastName' | 'id'>
    )> }
  )>, TeamMember_aggregate: (
    { __typename?: 'TeamMember_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'TeamMember_aggregate_fields' }
      & Pick<TeamMember_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type GetArchivedFeedbackCategoryChildrenQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetArchivedFeedbackCategoryChildrenQuery = (
  { __typename?: 'query_root' }
  & { FeedbackFramework: Array<(
    { __typename?: 'FeedbackFramework' }
    & Pick<FeedbackFramework, 'id' | 'name' | 'description'>
  )> }
);

export type GetArchivedFeedbackFrameworksQueryVariables = Exact<{
  manager?: Maybe<Scalars['uuid']>;
}>;


export type GetArchivedFeedbackFrameworksQuery = (
  { __typename?: 'query_root' }
  & { FeedbackFramework: Array<(
    { __typename?: 'FeedbackFramework' }
    & Pick<FeedbackFramework, 'id' | 'name' | 'description'>
    & { CreatedBy?: Maybe<(
      { __typename?: 'TeamMember' }
      & Pick<TeamMember, 'firstName' | 'lastName'>
    )> }
  )> }
);

export type GetCompanyQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetCompanyQuery = (
  { __typename?: 'query_root' }
  & { Company: Array<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name' | 'requiredInteractions' | 'requiredInteractionTimePeriod' | 'isActive'>
  )> }
);

export type GetCompanyInvoiceQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCompanyInvoiceQuery = (
  { __typename?: 'query_root' }
  & { CompanyInvoice?: Maybe<Array<Maybe<(
    { __typename?: 'Invoice' }
    & Pick<Invoice, 'datePaid' | 'invoicePdf' | 'totalUsers'>
  )>>> }
);

export type GetCompanyPaymentMethodQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCompanyPaymentMethodQuery = (
  { __typename?: 'query_root' }
  & { CompanyPaymentMethod?: Maybe<(
    { __typename?: 'CompanyPaymentMethodOutput' }
    & Pick<CompanyPaymentMethodOutput, 'name' | 'billingEmail' | 'addressLine1' | 'addressLine2' | 'city' | 'state' | 'postalCode' | 'brand' | 'expMonth' | 'expYear' | 'last4'>
  )> }
);

export type GetCompanySubscriptionQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCompanySubscriptionQuery = (
  { __typename?: 'query_root' }
  & { CompanySubscription?: Maybe<(
    { __typename?: 'CompanySubscriptionOutput' }
    & Pick<CompanySubscriptionOutput, 'cost' | 'interval' | 'name' | 'renewalDate' | 'units' | 'isCancelled'>
  )> }
);

export type GetCompanySubscriptionPriceQueryVariables = Exact<{
  monthlyPriceId: Scalars['String'];
  yearlyPriceId: Scalars['String'];
}>;


export type GetCompanySubscriptionPriceQuery = (
  { __typename?: 'query_root' }
  & { CompanySubscriptionCheckoutPrice?: Maybe<(
    { __typename?: 'CompanySubscriptionCheckoutPriceOutput' }
    & Pick<CompanySubscriptionCheckoutPriceOutput, 'monthlyFullCost' | 'monthlyProratedCost' | 'yearlyFullCost' | 'yearlyProratedCost'>
  )> }
);

export type GetCurrentAuthenticatedTeamMemberQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetCurrentAuthenticatedTeamMemberQuery = (
  { __typename?: 'query_root' }
  & { TeamMember: Array<(
    { __typename?: 'TeamMember' }
    & Pick<TeamMember, 'companyId' | 'id' | 'firstName' | 'lastName' | 'email' | 'title' | 'recentlyViewedTeam' | 'role' | 'phone' | 'isFirstLogin' | 'authenticationId'>
    & { DirectReports: Array<(
      { __typename?: 'TeamMember' }
      & Pick<TeamMember, 'id'>
    )>, Company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'requiredInteractions' | 'requiredInteractionTimePeriod' | 'name' | 'size' | 'businessType' | 'trialEndDate' | 'isActive' | 'billingId' | 'isFreeAccount' | 'billingEmail'>
    ) }
  )> }
);

export type GetFeedbackByDateRangeQueryVariables = Exact<{
  teamMemberId: Scalars['uuid'];
  startDate: Scalars['timestamptz'];
  endDate: Scalars['timestamptz'];
  startDate30Days?: Maybe<Scalars['timestamptz']>;
  endDate30Days: Scalars['timestamptz'];
  startDatePrevious30Days: Scalars['timestamptz'];
  endDatePrevious30Days: Scalars['timestamptz'];
}>;


export type GetFeedbackByDateRangeQuery = (
  { __typename?: 'query_root' }
  & { Feedback: Array<(
    { __typename?: 'Feedback' }
    & Pick<Feedback, 'id' | 'createdAt' | 'type' | 'teamMemberId' | 'sessionId' | 'impact'>
  )>, feedback30Days: Array<(
    { __typename?: 'Feedback' }
    & Pick<Feedback, 'id' | 'createdAt' | 'type' | 'teamMemberId' | 'sessionId' | 'impact'>
  )>, feedbackPrevious30Days: Array<(
    { __typename?: 'Feedback' }
    & Pick<Feedback, 'id' | 'createdAt' | 'type' | 'teamMemberId' | 'sessionId' | 'impact'>
  )> }
);

export type GetFeedbackByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetFeedbackByIdQuery = (
  { __typename?: 'query_root' }
  & { Feedback: Array<(
    { __typename?: 'Feedback' }
    & Pick<Feedback, 'type' | 'id' | 'teamMemberId' | 'sessionId' | 'notes' | 'managerId' | 'isAgreed' | 'impact' | 'deletedAt' | 'createdAt' | 'companyId' | 'category' | 'categoryDescription'>
    & { TeamMember: (
      { __typename?: 'TeamMember' }
      & Pick<TeamMember, 'lastName' | 'firstName' | 'id' | 'managerId' | 'title' | 'email'>
    ), Manager: (
      { __typename?: 'TeamMember' }
      & Pick<TeamMember, 'firstName' | 'lastName' | 'id'>
    ) }
  )> }
);

export type GetFeedbackBySessionIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetFeedbackBySessionIdQuery = (
  { __typename?: 'query_root' }
  & { Feedback: Array<(
    { __typename?: 'Feedback' }
    & Pick<Feedback, 'category' | 'companyId' | 'id' | 'impact' | 'isAgreed' | 'managerId' | 'notes' | 'sessionId' | 'teamMemberId' | 'type' | 'createdAt'>
  )> }
);

export type GetFeedbackCategoriesQueryVariables = Exact<{
  id?: Maybe<Scalars['Int']>;
}>;


export type GetFeedbackCategoriesQuery = (
  { __typename?: 'query_root' }
  & { FeedbackFramework: Array<(
    { __typename?: 'FeedbackFramework' }
    & Pick<FeedbackFramework, 'id' | 'name' | 'description' | 'deletedAt' | 'selectableNotes'>
    & { CreatedBy?: Maybe<(
      { __typename?: 'TeamMember' }
      & Pick<TeamMember, 'firstName' | 'lastName'>
    )>, Parent?: Maybe<(
      { __typename?: 'FeedbackFramework' }
      & Pick<FeedbackFramework, 'name' | 'id' | 'description'>
      & { Parent?: Maybe<(
        { __typename?: 'FeedbackFramework' }
        & Pick<FeedbackFramework, 'name' | 'id' | 'description'>
      )> }
    )>, Children: Array<(
      { __typename?: 'FeedbackFramework' }
      & Pick<FeedbackFramework, 'id' | 'name' | 'description'>
      & { CreatedBy?: Maybe<(
        { __typename?: 'TeamMember' }
        & Pick<TeamMember, 'firstName' | 'lastName'>
      )>, Children: Array<(
        { __typename?: 'FeedbackFramework' }
        & Pick<FeedbackFramework, 'id' | 'name' | 'description'>
      )> }
    )> }
  )> }
);

export type GetFeedbackFrameworksQueryVariables = Exact<{
  manager?: Maybe<Scalars['uuid']>;
  teamMemberId?: Maybe<Scalars['uuid']>;
}>;


export type GetFeedbackFrameworksQuery = (
  { __typename?: 'query_root' }
  & { FeedbackFramework: Array<(
    { __typename?: 'FeedbackFramework' }
    & Pick<FeedbackFramework, 'id' | 'name'>
    & { CreatedBy?: Maybe<(
      { __typename?: 'TeamMember' }
      & Pick<TeamMember, 'firstName' | 'lastName'>
    )>, Parent?: Maybe<(
      { __typename?: 'FeedbackFramework' }
      & Pick<FeedbackFramework, 'name' | 'id'>
    )>, Children: Array<(
      { __typename?: 'FeedbackFramework' }
      & Pick<FeedbackFramework, 'id' | 'name'>
      & { CreatedBy?: Maybe<(
        { __typename?: 'TeamMember' }
        & Pick<TeamMember, 'firstName' | 'lastName'>
      )> }
    )> }
  )> }
);

export type GetFeedbackFrameworkTreeQueryVariables = Exact<{
  managerId?: Maybe<Scalars['uuid']>;
}>;


export type GetFeedbackFrameworkTreeQuery = (
  { __typename?: 'query_root' }
  & { FeedbackFramework: Array<(
    { __typename?: 'FeedbackFramework' }
    & Pick<FeedbackFramework, 'id' | 'name'>
    & { Children: Array<(
      { __typename?: 'FeedbackFramework' }
      & Pick<FeedbackFramework, 'id' | 'name'>
      & { Children: Array<(
        { __typename?: 'FeedbackFramework' }
        & Pick<FeedbackFramework, 'id' | 'name'>
        & { Children: Array<(
          { __typename?: 'FeedbackFramework' }
          & Pick<FeedbackFramework, 'id' | 'name'>
          & { Children: Array<(
            { __typename?: 'FeedbackFramework' }
            & Pick<FeedbackFramework, 'id' | 'name'>
            & { Children: Array<(
              { __typename?: 'FeedbackFramework' }
              & Pick<FeedbackFramework, 'id' | 'name'>
              & { Children: Array<(
                { __typename?: 'FeedbackFramework' }
                & Pick<FeedbackFramework, 'id' | 'name'>
                & { Children: Array<(
                  { __typename?: 'FeedbackFramework' }
                  & Pick<FeedbackFramework, 'id' | 'name'>
                )> }
              )> }
            )> }
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type GetFeedbackItemCountQueryVariables = Exact<{
  startDate: Scalars['timestamptz'];
  endDate: Scalars['timestamptz'];
  managerId?: Maybe<Scalars['uuid']>;
}>;


export type GetFeedbackItemCountQuery = (
  { __typename?: 'query_root' }
  & { positive: (
    { __typename?: 'Feedback_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'Feedback_aggregate_fields' }
      & Pick<Feedback_Aggregate_Fields, 'count'>
    )> }
  ), constructive: (
    { __typename?: 'Feedback_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'Feedback_aggregate_fields' }
      & Pick<Feedback_Aggregate_Fields, 'count'>
    )> }
  ), infraction: (
    { __typename?: 'Infraction_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'Infraction_aggregate_fields' }
      & Pick<Infraction_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type GetFeedbackItemCountByTeamIdQueryVariables = Exact<{
  id: Scalars['uuid'];
  startDate: Scalars['timestamptz'];
  endDate: Scalars['timestamptz'];
}>;


export type GetFeedbackItemCountByTeamIdQuery = (
  { __typename?: 'query_root' }
  & { positive: (
    { __typename?: 'Feedback_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'Feedback_aggregate_fields' }
      & Pick<Feedback_Aggregate_Fields, 'count'>
    )> }
  ), constructive: (
    { __typename?: 'Feedback_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'Feedback_aggregate_fields' }
      & Pick<Feedback_Aggregate_Fields, 'count'>
    )> }
  ), infraction: (
    { __typename?: 'Infraction_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'Infraction_aggregate_fields' }
      & Pick<Infraction_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type GetFeedbackSessionByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetFeedbackSessionByIdQuery = (
  { __typename?: 'query_root' }
  & { FeedbackSession: Array<(
    { __typename?: 'FeedbackSession' }
    & Pick<FeedbackSession, 'id' | 'createdAt' | 'companyId' | 'managerId' | 'teamMemberId' | 'deletedAt' | 'summarySentAt'>
    & { TeamMember: (
      { __typename?: 'TeamMember' }
      & Pick<TeamMember, 'lastName' | 'firstName' | 'id' | 'managerId' | 'title' | 'email'>
    ), Manager: (
      { __typename?: 'TeamMember' }
      & Pick<TeamMember, 'firstName' | 'lastName' | 'id'>
    ), FeedbackItem: Array<(
      { __typename?: 'Feedback' }
      & Pick<Feedback, 'type' | 'id' | 'teamMemberId' | 'sessionId' | 'notes' | 'managerId' | 'isAgreed' | 'impact' | 'deletedAt' | 'createdAt' | 'companyId' | 'category'>
    )> }
  )> }
);

export type GetFeedbackSessionHistoryQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetFeedbackSessionHistoryQuery = (
  { __typename?: 'query_root' }
  & { FeedbackSession: Array<(
    { __typename?: 'FeedbackSession' }
    & Pick<FeedbackSession, 'id' | 'createdAt' | 'companyId' | 'managerId' | 'teamMemberId' | 'deletedAt' | 'summarySentAt'>
    & { TeamMember: (
      { __typename?: 'TeamMember' }
      & Pick<TeamMember, 'lastName' | 'firstName' | 'id' | 'managerId' | 'title' | 'email'>
    ), Manager: (
      { __typename?: 'TeamMember' }
      & Pick<TeamMember, 'firstName' | 'lastName' | 'id'>
    ), FeedbackItem: Array<(
      { __typename?: 'Feedback' }
      & Pick<Feedback, 'type' | 'id' | 'teamMemberId' | 'sessionId' | 'notes' | 'managerId' | 'isAgreed' | 'impact' | 'deletedAt' | 'createdAt' | 'companyId' | 'category'>
      & { FeedbackEditHistory: Array<(
        { __typename?: 'FeedbackEditHistory' }
        & Pick<FeedbackEditHistory, 'id' | 'impact' | 'feedbackId' | 'createdAt' | 'isAgreed' | 'notes' | 'sessionId'>
      )> }
    )>, FeedbackEditHistory: Array<(
      { __typename?: 'FeedbackEditHistory' }
      & Pick<FeedbackEditHistory, 'id' | 'impact' | 'feedbackId' | 'createdAt' | 'isAgreed' | 'notes' | 'sessionId'>
      & { Manager: (
        { __typename?: 'TeamMember' }
        & Pick<TeamMember, 'id' | 'firstName' | 'lastName'>
      ) }
    )> }
  )> }
);

export type GetInfractionByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetInfractionByIdQuery = (
  { __typename?: 'query_root' }
  & { Infraction: Array<(
    { __typename?: 'Infraction' }
    & Pick<Infraction, 'id' | 'createdAt' | 'companyId' | 'managerId' | 'teamMemberId' | 'type' | 'notes' | 'isAgreed'>
    & { TeamMember: (
      { __typename?: 'TeamMember' }
      & Pick<TeamMember, 'lastName' | 'firstName' | 'id' | 'managerId' | 'title' | 'email'>
    ), Manager: (
      { __typename?: 'TeamMember' }
      & Pick<TeamMember, 'firstName' | 'lastName' | 'id'>
    ) }
  )> }
);

export type GetManagersQueryVariables = Exact<{
  excludeId?: Maybe<Scalars['uuid']>;
}>;


export type GetManagersQuery = (
  { __typename?: 'query_root' }
  & { TeamMember: Array<(
    { __typename?: 'TeamMember' }
    & Pick<TeamMember, 'id' | 'lastName' | 'firstName' | 'managerId'>
  )> }
);

export type GetRecentActivityQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRecentActivityQuery = (
  { __typename?: 'query_root' }
  & { FeedbackSession: Array<(
    { __typename?: 'FeedbackSession' }
    & Pick<FeedbackSession, 'id' | 'createdAt' | 'companyId' | 'managerId' | 'teamMemberId'>
    & { FeedbackItem: Array<(
      { __typename?: 'Feedback' }
      & Pick<Feedback, 'type' | 'id'>
    )>, TeamMember: (
      { __typename?: 'TeamMember' }
      & Pick<TeamMember, 'lastName' | 'firstName' | 'id'>
    ) }
  )>, Infraction: Array<(
    { __typename?: 'Infraction' }
    & Pick<Infraction, 'id' | 'createdAt' | 'companyId' | 'managerId' | 'teamMemberId'>
    & { TeamMember: (
      { __typename?: 'TeamMember' }
      & Pick<TeamMember, 'lastName' | 'firstName' | 'id'>
    ) }
  )>, TitleHistory: Array<(
    { __typename?: 'TitleHistory' }
    & Pick<TitleHistory, 'id' | 'createdAt' | 'companyId' | 'managerId' | 'teamMemberId' | 'title' | 'oldTitle'>
    & { TeamMember: (
      { __typename?: 'TeamMember' }
      & Pick<TeamMember, 'lastName' | 'firstName' | 'id'>
    ) }
  )> }
);

export type GetRecentActivityByTeamIdQueryVariables = Exact<{
  id: Scalars['uuid'];
  startDate: Scalars['timestamptz'];
  endDate: Scalars['timestamptz'];
  sort?: Maybe<Array<Feedback_Order_By>>;
  infractionSort?: Maybe<Array<Infraction_Order_By>>;
  titleSort?: Maybe<Array<TitleHistory_Order_By>>;
}>;


export type GetRecentActivityByTeamIdQuery = (
  { __typename?: 'query_root' }
  & { Feedback: Array<(
    { __typename?: 'Feedback' }
    & Pick<Feedback, 'id' | 'createdAt' | 'companyId' | 'managerId' | 'teamMemberId' | 'type' | 'sessionId'>
    & { TeamMember: (
      { __typename?: 'TeamMember' }
      & Pick<TeamMember, 'lastName' | 'firstName' | 'id'>
    ), Manager: (
      { __typename?: 'TeamMember' }
      & Pick<TeamMember, 'firstName' | 'lastName' | 'id'>
    ) }
  )>, Feedback_aggregate: (
    { __typename?: 'Feedback_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'Feedback_aggregate_fields' }
      & Pick<Feedback_Aggregate_Fields, 'count'>
    )> }
  ), Infraction: Array<(
    { __typename?: 'Infraction' }
    & Pick<Infraction, 'id' | 'createdAt' | 'companyId' | 'managerId' | 'teamMemberId'>
    & { TeamMember: (
      { __typename?: 'TeamMember' }
      & Pick<TeamMember, 'lastName' | 'firstName' | 'id'>
    ), Manager: (
      { __typename?: 'TeamMember' }
      & Pick<TeamMember, 'firstName' | 'lastName' | 'id'>
    ) }
  )>, Infraction_aggregate: (
    { __typename?: 'Infraction_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'Infraction_aggregate_fields' }
      & Pick<Infraction_Aggregate_Fields, 'count'>
    )> }
  ), TitleHistory: Array<(
    { __typename?: 'TitleHistory' }
    & Pick<TitleHistory, 'id' | 'createdAt' | 'companyId' | 'managerId' | 'teamMemberId' | 'title' | 'oldTitle'>
    & { TeamMember: (
      { __typename?: 'TeamMember' }
      & Pick<TeamMember, 'lastName' | 'firstName' | 'id'>
    ), Manager: (
      { __typename?: 'TeamMember' }
      & Pick<TeamMember, 'firstName' | 'lastName' | 'id'>
    ) }
  )>, TitleHistory_aggregate: (
    { __typename?: 'TitleHistory_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'TitleHistory_aggregate_fields' }
      & Pick<TitleHistory_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type GetRecentlyViewedTeamMembersQueryVariables = Exact<{
  teamMemberId: Scalars['uuid'];
}>;


export type GetRecentlyViewedTeamMembersQuery = (
  { __typename?: 'query_root' }
  & { TeamMember: Array<(
    { __typename?: 'TeamMember' }
    & Pick<TeamMember, 'id' | 'lastName' | 'firstName' | 'recentlyViewedTeam'>
  )> }
);

export type GetTeamMemberByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetTeamMemberByIdQuery = (
  { __typename?: 'query_root' }
  & { TeamMember: Array<(
    { __typename?: 'TeamMember' }
    & Pick<TeamMember, 'id' | 'lastName' | 'isActive' | 'firstName' | 'email' | 'department' | 'dateStarted' | 'phone' | 'title' | 'managerId' | 'role' | 'authenticationId'>
    & { Manager?: Maybe<(
      { __typename?: 'TeamMember' }
      & Pick<TeamMember, 'id' | 'firstName' | 'lastName'>
    )> }
  )> }
);

export type GetTeamMemberCountQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTeamMemberCountQuery = (
  { __typename?: 'query_root' }
  & { TeamMember_aggregate: (
    { __typename?: 'TeamMember_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'TeamMember_aggregate_fields' }
      & Pick<TeamMember_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type GetTeamMemberFrameworksQueryVariables = Exact<{
  teamMemberId?: Maybe<Scalars['uuid']>;
}>;


export type GetTeamMemberFrameworksQuery = (
  { __typename?: 'query_root' }
  & { TeamMemberFramework: Array<(
    { __typename?: 'TeamMemberFramework' }
    & Pick<TeamMemberFramework, 'id' | 'companyId' | 'teamMemberId' | 'frameworkId'>
    & { FeedbackFramework: (
      { __typename?: 'FeedbackFramework' }
      & Pick<FeedbackFramework, 'name'>
    ) }
  )> }
);

export type GetTeamMembersQueryVariables = Exact<{
  currentTeamMemberId: Scalars['uuid'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  searchQuery: Scalars['String'];
}>;


export type GetTeamMembersQuery = (
  { __typename?: 'query_root' }
  & { TeamMember: Array<(
    { __typename?: 'TeamMember' }
    & Pick<TeamMember, 'id' | 'lastName' | 'isActive' | 'firstName' | 'email' | 'department' | 'dateStarted' | 'phone' | 'title' | 'managerId' | 'role'>
    & { Manager?: Maybe<(
      { __typename?: 'TeamMember' }
      & Pick<TeamMember, 'firstName' | 'lastName' | 'id'>
    )> }
  )>, TeamMember_aggregate: (
    { __typename?: 'TeamMember_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'TeamMember_aggregate_fields' }
      & Pick<TeamMember_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type GetTitleChangeByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetTitleChangeByIdQuery = (
  { __typename?: 'query_root' }
  & { TitleHistory: Array<(
    { __typename?: 'TitleHistory' }
    & Pick<TitleHistory, 'id' | 'createdAt' | 'title' | 'oldTitle'>
    & { TeamMember: (
      { __typename?: 'TeamMember' }
      & Pick<TeamMember, 'id' | 'firstName' | 'lastName'>
    ), Manager: (
      { __typename?: 'TeamMember' }
      & Pick<TeamMember, 'id' | 'firstName' | 'lastName'>
    ) }
  )> }
);

export type InsertFeedbackFrameworkMutationVariables = Exact<{
  parentId?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  description: Scalars['String'];
  selectableNotes: Scalars['String'];
  companyId: Scalars['uuid'];
  createdBy?: Maybe<Scalars['uuid']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
}>;


export type InsertFeedbackFrameworkMutation = (
  { __typename?: 'mutation_root' }
  & { insert_FeedbackFramework_one?: Maybe<(
    { __typename?: 'FeedbackFramework' }
    & Pick<FeedbackFramework, 'id'>
  )> }
);

export type InsertFeedbackItemMutationVariables = Exact<{
  category: Scalars['String'];
  companyId: Scalars['uuid'];
  impact: Scalars['numeric'];
  managerId: Scalars['uuid'];
  sessionId: Scalars['Int'];
  teamMemberId: Scalars['uuid'];
  notes?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  categoryDescription?: Maybe<Scalars['String']>;
}>;


export type InsertFeedbackItemMutation = (
  { __typename?: 'mutation_root' }
  & { insert_Feedback_one?: Maybe<(
    { __typename?: 'Feedback' }
    & Pick<Feedback, 'id'>
  )> }
);

export type InsertFeedbackSessionMutationVariables = Exact<{
  companyId: Scalars['uuid'];
  managerId: Scalars['uuid'];
  teamMemberId: Scalars['uuid'];
}>;


export type InsertFeedbackSessionMutation = (
  { __typename?: 'mutation_root' }
  & { insert_FeedbackSession_one?: Maybe<(
    { __typename?: 'FeedbackSession' }
    & Pick<FeedbackSession, 'id' | 'companyId' | 'managerId' | 'teamMemberId'>
  )> }
);

export type InsertInfractionMutationVariables = Exact<{
  companyId: Scalars['uuid'];
  managerId: Scalars['uuid'];
  notes: Scalars['String'];
  teamMemberId: Scalars['uuid'];
  type: Scalars['String'];
}>;


export type InsertInfractionMutation = (
  { __typename?: 'mutation_root' }
  & { insert_Infraction_one?: Maybe<(
    { __typename?: 'Infraction' }
    & Pick<Infraction, 'id'>
  )> }
);

export type InsertTeamMemberMutationVariables = Exact<{
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  title: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  companyId: Scalars['uuid'];
  managerId?: Maybe<Scalars['uuid']>;
  dateStarted: Scalars['date'];
  email: Scalars['String'];
  role: Scalars['String'];
}>;


export type InsertTeamMemberMutation = (
  { __typename?: 'mutation_root' }
  & { insert_TeamMember_one?: Maybe<(
    { __typename?: 'TeamMember' }
    & Pick<TeamMember, 'id'>
  )> }
);

export type InsertTeamMemberFrameworkMutationVariables = Exact<{
  frameworkId: Scalars['Int'];
  companyId: Scalars['uuid'];
  teamMemberId: Scalars['uuid'];
}>;


export type InsertTeamMemberFrameworkMutation = (
  { __typename?: 'mutation_root' }
  & { insert_TeamMemberFramework_one?: Maybe<(
    { __typename?: 'TeamMemberFramework' }
    & Pick<TeamMemberFramework, 'id'>
  )> }
);

export type ReactivateSubscriptionMutationVariables = Exact<{ [key: string]: never; }>;


export type ReactivateSubscriptionMutation = (
  { __typename?: 'mutation_root' }
  & { update_CompanyReactivateSubscription?: Maybe<(
    { __typename?: 'CompanyReactivateSubscriptionOutput' }
    & Pick<CompanyReactivateSubscriptionOutput, 'isSuccess'>
  )> }
);

export type UpdateAdminTeamMemberMutationVariables = Exact<{
  id: Scalars['uuid'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  companyId: Scalars['uuid'];
  managerId?: Maybe<Scalars['uuid']>;
  authenticationId?: Maybe<Scalars['uuid']>;
  dateStarted?: Maybe<Scalars['date']>;
  email: Scalars['String'];
  role: Scalars['String'];
}>;


export type UpdateAdminTeamMemberMutation = (
  { __typename?: 'mutation_root' }
  & { update_TeamMember?: Maybe<(
    { __typename?: 'TeamMember_mutation_response' }
    & { returning: Array<(
      { __typename?: 'TeamMember' }
      & Pick<TeamMember, 'id'>
    )> }
  )> }
);

export type UpdateCompanyMutationVariables = Exact<{
  id: Scalars['uuid'];
  name: Scalars['String'];
  size?: Maybe<Scalars['String']>;
  businessType?: Maybe<Scalars['String']>;
}>;


export type UpdateCompanyMutation = (
  { __typename?: 'mutation_root' }
  & { update_Company?: Maybe<(
    { __typename?: 'Company_mutation_response' }
    & { returning: Array<(
      { __typename?: 'Company' }
      & Pick<Company, 'id'>
    )> }
  )> }
);

export type UpdateCompanyBillingMutationVariables = Exact<{
  billingEmail: Scalars['String'];
  billingToken: Scalars['String'];
  addressLine1: Scalars['String'];
  addressLine2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  companyName: Scalars['String'];
  postalCode: Scalars['String'];
  state: Scalars['String'];
}>;


export type UpdateCompanyBillingMutation = (
  { __typename?: 'mutation_root' }
  & { update_CompanyBilling?: Maybe<(
    { __typename?: 'CompanyBillingOutput' }
    & Pick<CompanyBillingOutput, 'isSuccess'>
  )> }
);

export type UpdateFeedbackFrameworkMutationVariables = Exact<{
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  description: Scalars['String'];
  selectableNotes: Scalars['String'];
}>;


export type UpdateFeedbackFrameworkMutation = (
  { __typename?: 'mutation_root' }
  & { update_FeedbackFramework?: Maybe<(
    { __typename?: 'FeedbackFramework_mutation_response' }
    & { returning: Array<(
      { __typename?: 'FeedbackFramework' }
      & Pick<FeedbackFramework, 'id'>
    )> }
  )> }
);

export type UpdateFeedbackItemMutationVariables = Exact<{
  impact: Scalars['numeric'];
  notes: Scalars['String'];
  id: Scalars['Int'];
}>;


export type UpdateFeedbackItemMutation = (
  { __typename?: 'mutation_root' }
  & { update_Feedback?: Maybe<(
    { __typename?: 'Feedback_mutation_response' }
    & { returning: Array<(
      { __typename?: 'Feedback' }
      & Pick<Feedback, 'id'>
    )> }
  )> }
);

export type UpdateFeedbackItemAgreedMutationVariables = Exact<{
  isAgreed: Scalars['Boolean'];
  id: Scalars['Int'];
}>;


export type UpdateFeedbackItemAgreedMutation = (
  { __typename?: 'mutation_root' }
  & { update_Feedback?: Maybe<(
    { __typename?: 'Feedback_mutation_response' }
    & { returning: Array<(
      { __typename?: 'Feedback' }
      & Pick<Feedback, 'id'>
    )> }
  )> }
);

export type UpdateInfractionAgreedMutationVariables = Exact<{
  isAgreed: Scalars['Boolean'];
  id: Scalars['Int'];
}>;


export type UpdateInfractionAgreedMutation = (
  { __typename?: 'mutation_root' }
  & { update_Infraction?: Maybe<(
    { __typename?: 'Infraction_mutation_response' }
    & { returning: Array<(
      { __typename?: 'Infraction' }
      & Pick<Infraction, 'id'>
    )> }
  )> }
);

export type UpdateMoveFeedbackFrameworkMutationVariables = Exact<{
  ids?: Maybe<Array<Scalars['Int']>>;
  parentId: Scalars['Int'];
}>;


export type UpdateMoveFeedbackFrameworkMutation = (
  { __typename?: 'mutation_root' }
  & { update_FeedbackFramework?: Maybe<(
    { __typename?: 'FeedbackFramework_mutation_response' }
    & { returning: Array<(
      { __typename?: 'FeedbackFramework' }
      & Pick<FeedbackFramework, 'id'>
    )> }
  )> }
);

export type UpdateMyAccountMutationVariables = Exact<{
  id: Scalars['uuid'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
}>;


export type UpdateMyAccountMutation = (
  { __typename?: 'mutation_root' }
  & { update_TeamMember?: Maybe<(
    { __typename?: 'TeamMember_mutation_response' }
    & { returning: Array<(
      { __typename?: 'TeamMember' }
      & Pick<TeamMember, 'id'>
    )> }
  )> }
);

export type UpdateUserPasswordMutationVariables = Exact<{
  email: Scalars['String'];
  newPassword: Scalars['String'];
  currentPassword: Scalars['String'];
}>;


export type UpdateUserPasswordMutation = (
  { __typename?: 'mutation_root' }
  & { change_UserPassword?: Maybe<(
    { __typename?: 'ChangePasswordOutput' }
    & Pick<ChangePasswordOutput, 'isSuccess'>
  )> }
);

export type UpdateRecentlyViewedTeamMutationVariables = Exact<{
  id: Scalars['uuid'];
  recentlyViewedTeam: Scalars['String'];
}>;


export type UpdateRecentlyViewedTeamMutation = (
  { __typename?: 'mutation_root' }
  & { update_TeamMember?: Maybe<(
    { __typename?: 'TeamMember_mutation_response' }
    & { returning: Array<(
      { __typename?: 'TeamMember' }
      & Pick<TeamMember, 'id'>
    )> }
  )> }
);

export type UpdateSettingsMutationVariables = Exact<{
  id: Scalars['uuid'];
  requiredInteractions: Scalars['numeric'];
  requiredInteractionTimePeriod: Scalars['String'];
}>;


export type UpdateSettingsMutation = (
  { __typename?: 'mutation_root' }
  & { update_Company?: Maybe<(
    { __typename?: 'Company_mutation_response' }
    & { returning: Array<(
      { __typename?: 'Company' }
      & Pick<Company, 'id'>
    )> }
  )> }
);

export type UpdateTeamMemberMutationVariables = Exact<{
  id: Scalars['uuid'];
  title: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  dateStarted: Scalars['date'];
  role?: Maybe<Scalars['String']>;
}>;


export type UpdateTeamMemberMutation = (
  { __typename?: 'mutation_root' }
  & { update_TeamMember?: Maybe<(
    { __typename?: 'TeamMember_mutation_response' }
    & { returning: Array<(
      { __typename?: 'TeamMember' }
      & Pick<TeamMember, 'id'>
    )> }
  )> }
);

export type UpdateTeamMemberArchivedMutationVariables = Exact<{
  id: Scalars['uuid'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
}>;


export type UpdateTeamMemberArchivedMutation = (
  { __typename?: 'mutation_root' }
  & { update_TeamMember?: Maybe<(
    { __typename?: 'TeamMember_mutation_response' }
    & { returning: Array<(
      { __typename?: 'TeamMember' }
      & Pick<TeamMember, 'id'>
    )> }
  )> }
);

export type UpdateTeamMemberIsFirstLoginMutationVariables = Exact<{
  id: Scalars['uuid'];
  isFirstLogin: Scalars['Boolean'];
}>;


export type UpdateTeamMemberIsFirstLoginMutation = (
  { __typename?: 'mutation_root' }
  & { update_TeamMember?: Maybe<(
    { __typename?: 'TeamMember_mutation_response' }
    & { returning: Array<(
      { __typename?: 'TeamMember' }
      & Pick<TeamMember, 'id'>
    )> }
  )> }
);

export type UpdateTeamMemberSimplifiedMutationVariables = Exact<{
  id: Scalars['uuid'];
  title: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  dateStarted: Scalars['date'];
}>;


export type UpdateTeamMemberSimplifiedMutation = (
  { __typename?: 'mutation_root' }
  & { update_TeamMember?: Maybe<(
    { __typename?: 'TeamMember_mutation_response' }
    & { returning: Array<(
      { __typename?: 'TeamMember' }
      & Pick<TeamMember, 'id'>
    )> }
  )> }
);

export type UpgradeSubscriptionMutationVariables = Exact<{
  billingEmail: Scalars['String'];
  billingToken: Scalars['String'];
  priceId: Scalars['String'];
  addressLine1: Scalars['String'];
  addressLine2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  companyName: Scalars['String'];
  postalCode: Scalars['String'];
  state: Scalars['String'];
}>;


export type UpgradeSubscriptionMutation = (
  { __typename?: 'mutation_root' }
  & { update_CompanyUpgradeSubscription?: Maybe<(
    { __typename?: 'CompanyUpgradeSubscriptionOutput' }
    & Pick<CompanyUpgradeSubscriptionOutput, 'isSuccess'>
  )> }
);


export const ArchiveFeedbackFrameworkDocument = gql`
    mutation ArchiveFeedbackFramework($id: Int!, $deletedAt: timestamptz) {
  update_FeedbackFramework(_set: {deletedAt: $deletedAt}, where: {id: {_eq: $id}}) {
    returning {
      id
    }
  }
}
    `;
export type ArchiveFeedbackFrameworkMutationFn = Apollo.MutationFunction<ArchiveFeedbackFrameworkMutation, ArchiveFeedbackFrameworkMutationVariables>;

/**
 * __useArchiveFeedbackFrameworkMutation__
 *
 * To run a mutation, you first call `useArchiveFeedbackFrameworkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveFeedbackFrameworkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveFeedbackFrameworkMutation, { data, loading, error }] = useArchiveFeedbackFrameworkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      deletedAt: // value for 'deletedAt'
 *   },
 * });
 */
export function useArchiveFeedbackFrameworkMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveFeedbackFrameworkMutation, ArchiveFeedbackFrameworkMutationVariables>) {
        return Apollo.useMutation<ArchiveFeedbackFrameworkMutation, ArchiveFeedbackFrameworkMutationVariables>(ArchiveFeedbackFrameworkDocument, baseOptions);
      }
export type ArchiveFeedbackFrameworkMutationHookResult = ReturnType<typeof useArchiveFeedbackFrameworkMutation>;
export type ArchiveFeedbackFrameworkMutationResult = Apollo.MutationResult<ArchiveFeedbackFrameworkMutation>;
export type ArchiveFeedbackFrameworkMutationOptions = Apollo.BaseMutationOptions<ArchiveFeedbackFrameworkMutation, ArchiveFeedbackFrameworkMutationVariables>;
export const ArchiveInfractionDocument = gql`
    mutation ArchiveInfraction($id: Int!, $deletedAt: timestamptz) {
  update_Infraction(_set: {deletedAt: $deletedAt}, where: {id: {_eq: $id}}) {
    returning {
      id
    }
  }
}
    `;
export type ArchiveInfractionMutationFn = Apollo.MutationFunction<ArchiveInfractionMutation, ArchiveInfractionMutationVariables>;

/**
 * __useArchiveInfractionMutation__
 *
 * To run a mutation, you first call `useArchiveInfractionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveInfractionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveInfractionMutation, { data, loading, error }] = useArchiveInfractionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      deletedAt: // value for 'deletedAt'
 *   },
 * });
 */
export function useArchiveInfractionMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveInfractionMutation, ArchiveInfractionMutationVariables>) {
        return Apollo.useMutation<ArchiveInfractionMutation, ArchiveInfractionMutationVariables>(ArchiveInfractionDocument, baseOptions);
      }
export type ArchiveInfractionMutationHookResult = ReturnType<typeof useArchiveInfractionMutation>;
export type ArchiveInfractionMutationResult = Apollo.MutationResult<ArchiveInfractionMutation>;
export type ArchiveInfractionMutationOptions = Apollo.BaseMutationOptions<ArchiveInfractionMutation, ArchiveInfractionMutationVariables>;
export const CloseAccountDocument = gql`
    mutation CloseAccount {
  delete_CompanyCancelSubscription {
    isSuccess
  }
}
    `;
export type CloseAccountMutationFn = Apollo.MutationFunction<CloseAccountMutation, CloseAccountMutationVariables>;

/**
 * __useCloseAccountMutation__
 *
 * To run a mutation, you first call `useCloseAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeAccountMutation, { data, loading, error }] = useCloseAccountMutation({
 *   variables: {
 *   },
 * });
 */
export function useCloseAccountMutation(baseOptions?: Apollo.MutationHookOptions<CloseAccountMutation, CloseAccountMutationVariables>) {
        return Apollo.useMutation<CloseAccountMutation, CloseAccountMutationVariables>(CloseAccountDocument, baseOptions);
      }
export type CloseAccountMutationHookResult = ReturnType<typeof useCloseAccountMutation>;
export type CloseAccountMutationResult = Apollo.MutationResult<CloseAccountMutation>;
export type CloseAccountMutationOptions = Apollo.BaseMutationOptions<CloseAccountMutation, CloseAccountMutationVariables>;
export const CreateCompanyDocument = gql`
    mutation CreateCompany($email: String!, $firstName: String!, $lastName: String!) {
  create_CompanyInitialSignUp(email: $email, firstName: $firstName, lastName: $lastName) {
    companyId
    teamMemberId
  }
}
    `;
export type CreateCompanyMutationFn = Apollo.MutationFunction<CreateCompanyMutation, CreateCompanyMutationVariables>;

/**
 * __useCreateCompanyMutation__
 *
 * To run a mutation, you first call `useCreateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyMutation, { data, loading, error }] = useCreateCompanyMutation({
 *   variables: {
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *   },
 * });
 */
export function useCreateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<CreateCompanyMutation, CreateCompanyMutationVariables>) {
        return Apollo.useMutation<CreateCompanyMutation, CreateCompanyMutationVariables>(CreateCompanyDocument, baseOptions);
      }
export type CreateCompanyMutationHookResult = ReturnType<typeof useCreateCompanyMutation>;
export type CreateCompanyMutationResult = Apollo.MutationResult<CreateCompanyMutation>;
export type CreateCompanyMutationOptions = Apollo.BaseMutationOptions<CreateCompanyMutation, CreateCompanyMutationVariables>;
export const CreateCompanyInitialSubscriptionDocument = gql`
    mutation CreateCompanyInitialSubscription($billingEmail: String!, $billingToken: String!, $priceId: String!, $billingInterval: String!, $addressLine1: String!, $addressLine2: String, $city: String!, $companyName: String!, $postalCode: String!, $state: String!) {
  create_CompanyInitialSubscription(billingEmail: $billingEmail, billingToken: $billingToken, priceId: $priceId, billingInterval: $billingInterval, addressLine1: $addressLine1, addressLine2: $addressLine2, city: $city, companyName: $companyName, postalCode: $postalCode, state: $state) {
    isSuccess
  }
}
    `;
export type CreateCompanyInitialSubscriptionMutationFn = Apollo.MutationFunction<CreateCompanyInitialSubscriptionMutation, CreateCompanyInitialSubscriptionMutationVariables>;

/**
 * __useCreateCompanyInitialSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateCompanyInitialSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyInitialSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyInitialSubscriptionMutation, { data, loading, error }] = useCreateCompanyInitialSubscriptionMutation({
 *   variables: {
 *      billingEmail: // value for 'billingEmail'
 *      billingToken: // value for 'billingToken'
 *      priceId: // value for 'priceId'
 *      billingInterval: // value for 'billingInterval'
 *      addressLine1: // value for 'addressLine1'
 *      addressLine2: // value for 'addressLine2'
 *      city: // value for 'city'
 *      companyName: // value for 'companyName'
 *      postalCode: // value for 'postalCode'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useCreateCompanyInitialSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CreateCompanyInitialSubscriptionMutation, CreateCompanyInitialSubscriptionMutationVariables>) {
        return Apollo.useMutation<CreateCompanyInitialSubscriptionMutation, CreateCompanyInitialSubscriptionMutationVariables>(CreateCompanyInitialSubscriptionDocument, baseOptions);
      }
export type CreateCompanyInitialSubscriptionMutationHookResult = ReturnType<typeof useCreateCompanyInitialSubscriptionMutation>;
export type CreateCompanyInitialSubscriptionMutationResult = Apollo.MutationResult<CreateCompanyInitialSubscriptionMutation>;
export type CreateCompanyInitialSubscriptionMutationOptions = Apollo.BaseMutationOptions<CreateCompanyInitialSubscriptionMutation, CreateCompanyInitialSubscriptionMutationVariables>;
export const DeleteTeamMemberFrameworksDocument = gql`
    mutation DeleteTeamMemberFrameworks($teamMemberId: uuid!) {
  delete_TeamMemberFramework(where: {teamMemberId: {_eq: $teamMemberId}}) {
    affected_rows
  }
}
    `;
export type DeleteTeamMemberFrameworksMutationFn = Apollo.MutationFunction<DeleteTeamMemberFrameworksMutation, DeleteTeamMemberFrameworksMutationVariables>;

/**
 * __useDeleteTeamMemberFrameworksMutation__
 *
 * To run a mutation, you first call `useDeleteTeamMemberFrameworksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTeamMemberFrameworksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTeamMemberFrameworksMutation, { data, loading, error }] = useDeleteTeamMemberFrameworksMutation({
 *   variables: {
 *      teamMemberId: // value for 'teamMemberId'
 *   },
 * });
 */
export function useDeleteTeamMemberFrameworksMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTeamMemberFrameworksMutation, DeleteTeamMemberFrameworksMutationVariables>) {
        return Apollo.useMutation<DeleteTeamMemberFrameworksMutation, DeleteTeamMemberFrameworksMutationVariables>(DeleteTeamMemberFrameworksDocument, baseOptions);
      }
export type DeleteTeamMemberFrameworksMutationHookResult = ReturnType<typeof useDeleteTeamMemberFrameworksMutation>;
export type DeleteTeamMemberFrameworksMutationResult = Apollo.MutationResult<DeleteTeamMemberFrameworksMutation>;
export type DeleteTeamMemberFrameworksMutationOptions = Apollo.BaseMutationOptions<DeleteTeamMemberFrameworksMutation, DeleteTeamMemberFrameworksMutationVariables>;
export const GetActionItemsDocument = gql`
    query GetActionItems($currentTeamMemberId: uuid!, $startDate: timestamptz!, $endDate: timestamptz!, $searchQuery: String!) {
  TeamMember(where: {_and: [{id: {_neq: $currentTeamMemberId}, managerId: {_eq: $currentTeamMemberId}, deletedAt: {_is_null: true}, _or: [{firstName: {_ilike: $searchQuery}}, {lastName: {_ilike: $searchQuery}}]}]}) {
    firstName
    id
    lastName
    Feedback_aggregate(where: {deletedAt: {_is_null: true}, managerId: {_eq: $currentTeamMemberId}, createdAt: {_gte: $startDate, _lte: $endDate}, isAgreed: {_is_null: false}}) {
      aggregate {
        count
      }
    }
  }
}
    `;

/**
 * __useGetActionItemsQuery__
 *
 * To run a query within a React component, call `useGetActionItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActionItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActionItemsQuery({
 *   variables: {
 *      currentTeamMemberId: // value for 'currentTeamMemberId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      searchQuery: // value for 'searchQuery'
 *   },
 * });
 */
export function useGetActionItemsQuery(baseOptions?: Apollo.QueryHookOptions<GetActionItemsQuery, GetActionItemsQueryVariables>) {
        return Apollo.useQuery<GetActionItemsQuery, GetActionItemsQueryVariables>(GetActionItemsDocument, baseOptions);
      }
export function useGetActionItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActionItemsQuery, GetActionItemsQueryVariables>) {
          return Apollo.useLazyQuery<GetActionItemsQuery, GetActionItemsQueryVariables>(GetActionItemsDocument, baseOptions);
        }
export type GetActionItemsQueryHookResult = ReturnType<typeof useGetActionItemsQuery>;
export type GetActionItemsLazyQueryHookResult = ReturnType<typeof useGetActionItemsLazyQuery>;
export type GetActionItemsQueryResult = Apollo.QueryResult<GetActionItemsQuery, GetActionItemsQueryVariables>;
export const GetActionItemsByTeamMemberDocument = gql`
    query GetActionItemsByTeamMember($startDate: timestamptz!, $endDate: timestamptz!, $teamMemberId: uuid!) {
  TeamMember(where: {id: {_eq: $teamMemberId}}) {
    firstName
    id
    lastName
    Feedback_aggregate(where: {createdAt: {_gte: $startDate, _lte: $endDate}, isAgreed: {_is_null: false}}) {
      aggregate {
        count
      }
    }
  }
}
    `;

/**
 * __useGetActionItemsByTeamMemberQuery__
 *
 * To run a query within a React component, call `useGetActionItemsByTeamMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActionItemsByTeamMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActionItemsByTeamMemberQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      teamMemberId: // value for 'teamMemberId'
 *   },
 * });
 */
export function useGetActionItemsByTeamMemberQuery(baseOptions?: Apollo.QueryHookOptions<GetActionItemsByTeamMemberQuery, GetActionItemsByTeamMemberQueryVariables>) {
        return Apollo.useQuery<GetActionItemsByTeamMemberQuery, GetActionItemsByTeamMemberQueryVariables>(GetActionItemsByTeamMemberDocument, baseOptions);
      }
export function useGetActionItemsByTeamMemberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActionItemsByTeamMemberQuery, GetActionItemsByTeamMemberQueryVariables>) {
          return Apollo.useLazyQuery<GetActionItemsByTeamMemberQuery, GetActionItemsByTeamMemberQueryVariables>(GetActionItemsByTeamMemberDocument, baseOptions);
        }
export type GetActionItemsByTeamMemberQueryHookResult = ReturnType<typeof useGetActionItemsByTeamMemberQuery>;
export type GetActionItemsByTeamMemberLazyQueryHookResult = ReturnType<typeof useGetActionItemsByTeamMemberLazyQuery>;
export type GetActionItemsByTeamMemberQueryResult = Apollo.QueryResult<GetActionItemsByTeamMemberQuery, GetActionItemsByTeamMemberQueryVariables>;
export const GetAdminArchivedTeamMembersDocument = gql`
    query GetAdminArchivedTeamMembers($currentTeamMemberId: uuid!, $limit: Int!, $offset: Int!, $searchQuery: String!, $managerId: uuid, $role: String, $sort: [TeamMember_order_by!]) {
  TeamMember(limit: $limit, offset: $offset, order_by: $sort, where: {_and: [{deletedAt: {_is_null: false}, managerId: {_eq: $managerId}, role: {_eq: $role}, _or: [{firstName: {_ilike: $searchQuery}}, {lastName: {_ilike: $searchQuery}}]}]}) {
    id
    lastName
    isActive
    firstName
    email
    department
    dateStarted
    phone
    title
    managerId
    role
    authenticationId
    Manager {
      firstName
      lastName
      id
    }
  }
  TeamMember_aggregate(where: {_and: [{deletedAt: {_is_null: false}, _or: [{firstName: {_ilike: $searchQuery}}, {lastName: {_ilike: $searchQuery}}]}]}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetAdminArchivedTeamMembersQuery__
 *
 * To run a query within a React component, call `useGetAdminArchivedTeamMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminArchivedTeamMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminArchivedTeamMembersQuery({
 *   variables: {
 *      currentTeamMemberId: // value for 'currentTeamMemberId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      searchQuery: // value for 'searchQuery'
 *      managerId: // value for 'managerId'
 *      role: // value for 'role'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetAdminArchivedTeamMembersQuery(baseOptions?: Apollo.QueryHookOptions<GetAdminArchivedTeamMembersQuery, GetAdminArchivedTeamMembersQueryVariables>) {
        return Apollo.useQuery<GetAdminArchivedTeamMembersQuery, GetAdminArchivedTeamMembersQueryVariables>(GetAdminArchivedTeamMembersDocument, baseOptions);
      }
export function useGetAdminArchivedTeamMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdminArchivedTeamMembersQuery, GetAdminArchivedTeamMembersQueryVariables>) {
          return Apollo.useLazyQuery<GetAdminArchivedTeamMembersQuery, GetAdminArchivedTeamMembersQueryVariables>(GetAdminArchivedTeamMembersDocument, baseOptions);
        }
export type GetAdminArchivedTeamMembersQueryHookResult = ReturnType<typeof useGetAdminArchivedTeamMembersQuery>;
export type GetAdminArchivedTeamMembersLazyQueryHookResult = ReturnType<typeof useGetAdminArchivedTeamMembersLazyQuery>;
export type GetAdminArchivedTeamMembersQueryResult = Apollo.QueryResult<GetAdminArchivedTeamMembersQuery, GetAdminArchivedTeamMembersQueryVariables>;
export const GetAdminFeedbackFrameworksDocument = gql`
    query GetAdminFeedbackFrameworks($manager: uuid) {
  FeedbackFramework(where: {createdBy: {_eq: $manager}, parentId: {_is_null: true}, deletedAt: {_is_null: true}}) {
    id
    name
    CreatedBy {
      firstName
      lastName
    }
    Parent {
      name
      id
    }
    Children(where: {deletedAt: {_is_null: true}}) {
      id
      name
      CreatedBy {
        firstName
        lastName
      }
    }
  }
}
    `;

/**
 * __useGetAdminFeedbackFrameworksQuery__
 *
 * To run a query within a React component, call `useGetAdminFeedbackFrameworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminFeedbackFrameworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminFeedbackFrameworksQuery({
 *   variables: {
 *      manager: // value for 'manager'
 *   },
 * });
 */
export function useGetAdminFeedbackFrameworksQuery(baseOptions?: Apollo.QueryHookOptions<GetAdminFeedbackFrameworksQuery, GetAdminFeedbackFrameworksQueryVariables>) {
        return Apollo.useQuery<GetAdminFeedbackFrameworksQuery, GetAdminFeedbackFrameworksQueryVariables>(GetAdminFeedbackFrameworksDocument, baseOptions);
      }
export function useGetAdminFeedbackFrameworksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdminFeedbackFrameworksQuery, GetAdminFeedbackFrameworksQueryVariables>) {
          return Apollo.useLazyQuery<GetAdminFeedbackFrameworksQuery, GetAdminFeedbackFrameworksQueryVariables>(GetAdminFeedbackFrameworksDocument, baseOptions);
        }
export type GetAdminFeedbackFrameworksQueryHookResult = ReturnType<typeof useGetAdminFeedbackFrameworksQuery>;
export type GetAdminFeedbackFrameworksLazyQueryHookResult = ReturnType<typeof useGetAdminFeedbackFrameworksLazyQuery>;
export type GetAdminFeedbackFrameworksQueryResult = Apollo.QueryResult<GetAdminFeedbackFrameworksQuery, GetAdminFeedbackFrameworksQueryVariables>;
export const GetAdminTeamMembersDocument = gql`
    query GetAdminTeamMembers($limit: Int!, $offset: Int!, $searchQuery: String!, $managerId: uuid, $role: String, $sort: [TeamMember_order_by!]) {
  TeamMember(limit: $limit, offset: $offset, order_by: $sort, where: {_and: [{deletedAt: {_is_null: true}, managerId: {_eq: $managerId}, role: {_eq: $role}, _or: [{firstName: {_ilike: $searchQuery}}, {lastName: {_ilike: $searchQuery}}]}]}) {
    id
    lastName
    isActive
    firstName
    email
    department
    dateStarted
    phone
    title
    managerId
    role
    authenticationId
    Manager {
      firstName
      lastName
      id
    }
  }
  TeamMember_aggregate(where: {_and: [{deletedAt: {_is_null: true}, _or: [{firstName: {_ilike: $searchQuery}}, {lastName: {_ilike: $searchQuery}}]}]}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetAdminTeamMembersQuery__
 *
 * To run a query within a React component, call `useGetAdminTeamMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminTeamMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminTeamMembersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      searchQuery: // value for 'searchQuery'
 *      managerId: // value for 'managerId'
 *      role: // value for 'role'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetAdminTeamMembersQuery(baseOptions?: Apollo.QueryHookOptions<GetAdminTeamMembersQuery, GetAdminTeamMembersQueryVariables>) {
        return Apollo.useQuery<GetAdminTeamMembersQuery, GetAdminTeamMembersQueryVariables>(GetAdminTeamMembersDocument, baseOptions);
      }
export function useGetAdminTeamMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdminTeamMembersQuery, GetAdminTeamMembersQueryVariables>) {
          return Apollo.useLazyQuery<GetAdminTeamMembersQuery, GetAdminTeamMembersQueryVariables>(GetAdminTeamMembersDocument, baseOptions);
        }
export type GetAdminTeamMembersQueryHookResult = ReturnType<typeof useGetAdminTeamMembersQuery>;
export type GetAdminTeamMembersLazyQueryHookResult = ReturnType<typeof useGetAdminTeamMembersLazyQuery>;
export type GetAdminTeamMembersQueryResult = Apollo.QueryResult<GetAdminTeamMembersQuery, GetAdminTeamMembersQueryVariables>;
export const GetArchivedFeedbackCategoryChildrenDocument = gql`
    query GetArchivedFeedbackCategoryChildren($id: Int!) {
  FeedbackFramework(where: {parentId: {_eq: $id}, deletedAt: {_is_null: false}}) {
    id
    name
    description
  }
}
    `;

/**
 * __useGetArchivedFeedbackCategoryChildrenQuery__
 *
 * To run a query within a React component, call `useGetArchivedFeedbackCategoryChildrenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArchivedFeedbackCategoryChildrenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArchivedFeedbackCategoryChildrenQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetArchivedFeedbackCategoryChildrenQuery(baseOptions?: Apollo.QueryHookOptions<GetArchivedFeedbackCategoryChildrenQuery, GetArchivedFeedbackCategoryChildrenQueryVariables>) {
        return Apollo.useQuery<GetArchivedFeedbackCategoryChildrenQuery, GetArchivedFeedbackCategoryChildrenQueryVariables>(GetArchivedFeedbackCategoryChildrenDocument, baseOptions);
      }
export function useGetArchivedFeedbackCategoryChildrenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetArchivedFeedbackCategoryChildrenQuery, GetArchivedFeedbackCategoryChildrenQueryVariables>) {
          return Apollo.useLazyQuery<GetArchivedFeedbackCategoryChildrenQuery, GetArchivedFeedbackCategoryChildrenQueryVariables>(GetArchivedFeedbackCategoryChildrenDocument, baseOptions);
        }
export type GetArchivedFeedbackCategoryChildrenQueryHookResult = ReturnType<typeof useGetArchivedFeedbackCategoryChildrenQuery>;
export type GetArchivedFeedbackCategoryChildrenLazyQueryHookResult = ReturnType<typeof useGetArchivedFeedbackCategoryChildrenLazyQuery>;
export type GetArchivedFeedbackCategoryChildrenQueryResult = Apollo.QueryResult<GetArchivedFeedbackCategoryChildrenQuery, GetArchivedFeedbackCategoryChildrenQueryVariables>;
export const GetArchivedFeedbackFrameworksDocument = gql`
    query GetArchivedFeedbackFrameworks($manager: uuid) {
  FeedbackFramework(where: {createdBy: {_eq: $manager}, parentId: {_is_null: true}, deletedAt: {_is_null: false}}) {
    id
    name
    description
    CreatedBy {
      firstName
      lastName
    }
  }
}
    `;

/**
 * __useGetArchivedFeedbackFrameworksQuery__
 *
 * To run a query within a React component, call `useGetArchivedFeedbackFrameworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArchivedFeedbackFrameworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArchivedFeedbackFrameworksQuery({
 *   variables: {
 *      manager: // value for 'manager'
 *   },
 * });
 */
export function useGetArchivedFeedbackFrameworksQuery(baseOptions?: Apollo.QueryHookOptions<GetArchivedFeedbackFrameworksQuery, GetArchivedFeedbackFrameworksQueryVariables>) {
        return Apollo.useQuery<GetArchivedFeedbackFrameworksQuery, GetArchivedFeedbackFrameworksQueryVariables>(GetArchivedFeedbackFrameworksDocument, baseOptions);
      }
export function useGetArchivedFeedbackFrameworksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetArchivedFeedbackFrameworksQuery, GetArchivedFeedbackFrameworksQueryVariables>) {
          return Apollo.useLazyQuery<GetArchivedFeedbackFrameworksQuery, GetArchivedFeedbackFrameworksQueryVariables>(GetArchivedFeedbackFrameworksDocument, baseOptions);
        }
export type GetArchivedFeedbackFrameworksQueryHookResult = ReturnType<typeof useGetArchivedFeedbackFrameworksQuery>;
export type GetArchivedFeedbackFrameworksLazyQueryHookResult = ReturnType<typeof useGetArchivedFeedbackFrameworksLazyQuery>;
export type GetArchivedFeedbackFrameworksQueryResult = Apollo.QueryResult<GetArchivedFeedbackFrameworksQuery, GetArchivedFeedbackFrameworksQueryVariables>;
export const GetCompanyDocument = gql`
    query GetCompany($id: uuid!) {
  Company(where: {id: {_eq: $id}}) {
    id
    name
    requiredInteractions
    requiredInteractionTimePeriod
    isActive
  }
}
    `;

/**
 * __useGetCompanyQuery__
 *
 * To run a query within a React component, call `useGetCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCompanyQuery(baseOptions?: Apollo.QueryHookOptions<GetCompanyQuery, GetCompanyQueryVariables>) {
        return Apollo.useQuery<GetCompanyQuery, GetCompanyQueryVariables>(GetCompanyDocument, baseOptions);
      }
export function useGetCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyQuery, GetCompanyQueryVariables>) {
          return Apollo.useLazyQuery<GetCompanyQuery, GetCompanyQueryVariables>(GetCompanyDocument, baseOptions);
        }
export type GetCompanyQueryHookResult = ReturnType<typeof useGetCompanyQuery>;
export type GetCompanyLazyQueryHookResult = ReturnType<typeof useGetCompanyLazyQuery>;
export type GetCompanyQueryResult = Apollo.QueryResult<GetCompanyQuery, GetCompanyQueryVariables>;
export const GetCompanyInvoiceDocument = gql`
    query GetCompanyInvoice {
  CompanyInvoice {
    datePaid
    invoicePdf
    totalUsers
  }
}
    `;

/**
 * __useGetCompanyInvoiceQuery__
 *
 * To run a query within a React component, call `useGetCompanyInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyInvoiceQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyInvoiceQuery(baseOptions?: Apollo.QueryHookOptions<GetCompanyInvoiceQuery, GetCompanyInvoiceQueryVariables>) {
        return Apollo.useQuery<GetCompanyInvoiceQuery, GetCompanyInvoiceQueryVariables>(GetCompanyInvoiceDocument, baseOptions);
      }
export function useGetCompanyInvoiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyInvoiceQuery, GetCompanyInvoiceQueryVariables>) {
          return Apollo.useLazyQuery<GetCompanyInvoiceQuery, GetCompanyInvoiceQueryVariables>(GetCompanyInvoiceDocument, baseOptions);
        }
export type GetCompanyInvoiceQueryHookResult = ReturnType<typeof useGetCompanyInvoiceQuery>;
export type GetCompanyInvoiceLazyQueryHookResult = ReturnType<typeof useGetCompanyInvoiceLazyQuery>;
export type GetCompanyInvoiceQueryResult = Apollo.QueryResult<GetCompanyInvoiceQuery, GetCompanyInvoiceQueryVariables>;
export const GetCompanyPaymentMethodDocument = gql`
    query GetCompanyPaymentMethod {
  CompanyPaymentMethod {
    name
    billingEmail
    addressLine1
    addressLine2
    city
    state
    postalCode
    brand
    expMonth
    expYear
    last4
  }
}
    `;

/**
 * __useGetCompanyPaymentMethodQuery__
 *
 * To run a query within a React component, call `useGetCompanyPaymentMethodQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyPaymentMethodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyPaymentMethodQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyPaymentMethodQuery(baseOptions?: Apollo.QueryHookOptions<GetCompanyPaymentMethodQuery, GetCompanyPaymentMethodQueryVariables>) {
        return Apollo.useQuery<GetCompanyPaymentMethodQuery, GetCompanyPaymentMethodQueryVariables>(GetCompanyPaymentMethodDocument, baseOptions);
      }
export function useGetCompanyPaymentMethodLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyPaymentMethodQuery, GetCompanyPaymentMethodQueryVariables>) {
          return Apollo.useLazyQuery<GetCompanyPaymentMethodQuery, GetCompanyPaymentMethodQueryVariables>(GetCompanyPaymentMethodDocument, baseOptions);
        }
export type GetCompanyPaymentMethodQueryHookResult = ReturnType<typeof useGetCompanyPaymentMethodQuery>;
export type GetCompanyPaymentMethodLazyQueryHookResult = ReturnType<typeof useGetCompanyPaymentMethodLazyQuery>;
export type GetCompanyPaymentMethodQueryResult = Apollo.QueryResult<GetCompanyPaymentMethodQuery, GetCompanyPaymentMethodQueryVariables>;
export const GetCompanySubscriptionDocument = gql`
    query GetCompanySubscription {
  CompanySubscription {
    cost
    interval
    name
    renewalDate
    units
    isCancelled
  }
}
    `;

/**
 * __useGetCompanySubscriptionQuery__
 *
 * To run a query within a React component, call `useGetCompanySubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanySubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanySubscriptionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanySubscriptionQuery(baseOptions?: Apollo.QueryHookOptions<GetCompanySubscriptionQuery, GetCompanySubscriptionQueryVariables>) {
        return Apollo.useQuery<GetCompanySubscriptionQuery, GetCompanySubscriptionQueryVariables>(GetCompanySubscriptionDocument, baseOptions);
      }
export function useGetCompanySubscriptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanySubscriptionQuery, GetCompanySubscriptionQueryVariables>) {
          return Apollo.useLazyQuery<GetCompanySubscriptionQuery, GetCompanySubscriptionQueryVariables>(GetCompanySubscriptionDocument, baseOptions);
        }
export type GetCompanySubscriptionQueryHookResult = ReturnType<typeof useGetCompanySubscriptionQuery>;
export type GetCompanySubscriptionLazyQueryHookResult = ReturnType<typeof useGetCompanySubscriptionLazyQuery>;
export type GetCompanySubscriptionQueryResult = Apollo.QueryResult<GetCompanySubscriptionQuery, GetCompanySubscriptionQueryVariables>;
export const GetCompanySubscriptionPriceDocument = gql`
    query GetCompanySubscriptionPrice($monthlyPriceId: String!, $yearlyPriceId: String!) {
  CompanySubscriptionCheckoutPrice(monthlyPriceId: $monthlyPriceId, yearlyPriceId: $yearlyPriceId) {
    monthlyFullCost
    monthlyProratedCost
    yearlyFullCost
    yearlyProratedCost
  }
}
    `;

/**
 * __useGetCompanySubscriptionPriceQuery__
 *
 * To run a query within a React component, call `useGetCompanySubscriptionPriceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanySubscriptionPriceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanySubscriptionPriceQuery({
 *   variables: {
 *      monthlyPriceId: // value for 'monthlyPriceId'
 *      yearlyPriceId: // value for 'yearlyPriceId'
 *   },
 * });
 */
export function useGetCompanySubscriptionPriceQuery(baseOptions?: Apollo.QueryHookOptions<GetCompanySubscriptionPriceQuery, GetCompanySubscriptionPriceQueryVariables>) {
        return Apollo.useQuery<GetCompanySubscriptionPriceQuery, GetCompanySubscriptionPriceQueryVariables>(GetCompanySubscriptionPriceDocument, baseOptions);
      }
export function useGetCompanySubscriptionPriceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanySubscriptionPriceQuery, GetCompanySubscriptionPriceQueryVariables>) {
          return Apollo.useLazyQuery<GetCompanySubscriptionPriceQuery, GetCompanySubscriptionPriceQueryVariables>(GetCompanySubscriptionPriceDocument, baseOptions);
        }
export type GetCompanySubscriptionPriceQueryHookResult = ReturnType<typeof useGetCompanySubscriptionPriceQuery>;
export type GetCompanySubscriptionPriceLazyQueryHookResult = ReturnType<typeof useGetCompanySubscriptionPriceLazyQuery>;
export type GetCompanySubscriptionPriceQueryResult = Apollo.QueryResult<GetCompanySubscriptionPriceQuery, GetCompanySubscriptionPriceQueryVariables>;
export const GetCurrentAuthenticatedTeamMemberDocument = gql`
    query GetCurrentAuthenticatedTeamMember($id: uuid!) {
  TeamMember(where: {authenticationId: {_eq: $id}}) {
    companyId
    id
    firstName
    lastName
    email
    title
    recentlyViewedTeam
    role
    phone
    isFirstLogin
    authenticationId
    DirectReports(where: {deletedAt: {_is_null: true}}) {
      id
    }
    Company {
      id
      requiredInteractions
      requiredInteractionTimePeriod
      name
      size
      businessType
      trialEndDate
      isActive
      billingId
      isFreeAccount
      billingEmail
    }
  }
}
    `;

/**
 * __useGetCurrentAuthenticatedTeamMemberQuery__
 *
 * To run a query within a React component, call `useGetCurrentAuthenticatedTeamMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentAuthenticatedTeamMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentAuthenticatedTeamMemberQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCurrentAuthenticatedTeamMemberQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentAuthenticatedTeamMemberQuery, GetCurrentAuthenticatedTeamMemberQueryVariables>) {
        return Apollo.useQuery<GetCurrentAuthenticatedTeamMemberQuery, GetCurrentAuthenticatedTeamMemberQueryVariables>(GetCurrentAuthenticatedTeamMemberDocument, baseOptions);
      }
export function useGetCurrentAuthenticatedTeamMemberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentAuthenticatedTeamMemberQuery, GetCurrentAuthenticatedTeamMemberQueryVariables>) {
          return Apollo.useLazyQuery<GetCurrentAuthenticatedTeamMemberQuery, GetCurrentAuthenticatedTeamMemberQueryVariables>(GetCurrentAuthenticatedTeamMemberDocument, baseOptions);
        }
export type GetCurrentAuthenticatedTeamMemberQueryHookResult = ReturnType<typeof useGetCurrentAuthenticatedTeamMemberQuery>;
export type GetCurrentAuthenticatedTeamMemberLazyQueryHookResult = ReturnType<typeof useGetCurrentAuthenticatedTeamMemberLazyQuery>;
export type GetCurrentAuthenticatedTeamMemberQueryResult = Apollo.QueryResult<GetCurrentAuthenticatedTeamMemberQuery, GetCurrentAuthenticatedTeamMemberQueryVariables>;
export const GetFeedbackByDateRangeDocument = gql`
    query GetFeedbackByDateRange($teamMemberId: uuid!, $startDate: timestamptz!, $endDate: timestamptz!, $startDate30Days: timestamptz, $endDate30Days: timestamptz!, $startDatePrevious30Days: timestamptz!, $endDatePrevious30Days: timestamptz!) {
  Feedback(order_by: {createdAt: asc}, where: {teamMemberId: {_eq: $teamMemberId}, createdAt: {_gte: $startDate, _lte: $endDate}, isAgreed: {_is_null: false}}) {
    id
    createdAt
    type
    teamMemberId
    sessionId
    impact
  }
  feedback30Days: Feedback(where: {teamMemberId: {_eq: $teamMemberId}, createdAt: {_gte: $startDate30Days, _lte: $endDate30Days}, isAgreed: {_is_null: false}}) {
    id
    createdAt
    type
    teamMemberId
    sessionId
    impact
  }
  feedbackPrevious30Days: Feedback(where: {teamMemberId: {_eq: $teamMemberId}, createdAt: {_gte: $startDatePrevious30Days, _lte: $endDatePrevious30Days}, isAgreed: {_is_null: false}}) {
    id
    createdAt
    type
    teamMemberId
    sessionId
    impact
  }
}
    `;

/**
 * __useGetFeedbackByDateRangeQuery__
 *
 * To run a query within a React component, call `useGetFeedbackByDateRangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeedbackByDateRangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeedbackByDateRangeQuery({
 *   variables: {
 *      teamMemberId: // value for 'teamMemberId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      startDate30Days: // value for 'startDate30Days'
 *      endDate30Days: // value for 'endDate30Days'
 *      startDatePrevious30Days: // value for 'startDatePrevious30Days'
 *      endDatePrevious30Days: // value for 'endDatePrevious30Days'
 *   },
 * });
 */
export function useGetFeedbackByDateRangeQuery(baseOptions?: Apollo.QueryHookOptions<GetFeedbackByDateRangeQuery, GetFeedbackByDateRangeQueryVariables>) {
        return Apollo.useQuery<GetFeedbackByDateRangeQuery, GetFeedbackByDateRangeQueryVariables>(GetFeedbackByDateRangeDocument, baseOptions);
      }
export function useGetFeedbackByDateRangeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFeedbackByDateRangeQuery, GetFeedbackByDateRangeQueryVariables>) {
          return Apollo.useLazyQuery<GetFeedbackByDateRangeQuery, GetFeedbackByDateRangeQueryVariables>(GetFeedbackByDateRangeDocument, baseOptions);
        }
export type GetFeedbackByDateRangeQueryHookResult = ReturnType<typeof useGetFeedbackByDateRangeQuery>;
export type GetFeedbackByDateRangeLazyQueryHookResult = ReturnType<typeof useGetFeedbackByDateRangeLazyQuery>;
export type GetFeedbackByDateRangeQueryResult = Apollo.QueryResult<GetFeedbackByDateRangeQuery, GetFeedbackByDateRangeQueryVariables>;
export const GetFeedbackByIdDocument = gql`
    query GetFeedbackById($id: Int!) {
  Feedback(where: {id: {_eq: $id}}) {
    type
    id
    teamMemberId
    sessionId
    notes
    managerId
    isAgreed
    impact
    deletedAt
    createdAt
    companyId
    category
    categoryDescription
    TeamMember {
      lastName
      firstName
      id
      managerId
      title
      email
    }
    Manager {
      firstName
      lastName
      id
    }
  }
}
    `;

/**
 * __useGetFeedbackByIdQuery__
 *
 * To run a query within a React component, call `useGetFeedbackByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeedbackByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeedbackByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFeedbackByIdQuery(baseOptions?: Apollo.QueryHookOptions<GetFeedbackByIdQuery, GetFeedbackByIdQueryVariables>) {
        return Apollo.useQuery<GetFeedbackByIdQuery, GetFeedbackByIdQueryVariables>(GetFeedbackByIdDocument, baseOptions);
      }
export function useGetFeedbackByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFeedbackByIdQuery, GetFeedbackByIdQueryVariables>) {
          return Apollo.useLazyQuery<GetFeedbackByIdQuery, GetFeedbackByIdQueryVariables>(GetFeedbackByIdDocument, baseOptions);
        }
export type GetFeedbackByIdQueryHookResult = ReturnType<typeof useGetFeedbackByIdQuery>;
export type GetFeedbackByIdLazyQueryHookResult = ReturnType<typeof useGetFeedbackByIdLazyQuery>;
export type GetFeedbackByIdQueryResult = Apollo.QueryResult<GetFeedbackByIdQuery, GetFeedbackByIdQueryVariables>;
export const GetFeedbackBySessionIdDocument = gql`
    query GetFeedbackBySessionId($id: Int!) {
  Feedback(where: {sessionId: {_eq: $id}}) {
    category
    companyId
    id
    impact
    isAgreed
    managerId
    notes
    sessionId
    teamMemberId
    type
    createdAt
  }
}
    `;

/**
 * __useGetFeedbackBySessionIdQuery__
 *
 * To run a query within a React component, call `useGetFeedbackBySessionIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeedbackBySessionIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeedbackBySessionIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFeedbackBySessionIdQuery(baseOptions?: Apollo.QueryHookOptions<GetFeedbackBySessionIdQuery, GetFeedbackBySessionIdQueryVariables>) {
        return Apollo.useQuery<GetFeedbackBySessionIdQuery, GetFeedbackBySessionIdQueryVariables>(GetFeedbackBySessionIdDocument, baseOptions);
      }
export function useGetFeedbackBySessionIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFeedbackBySessionIdQuery, GetFeedbackBySessionIdQueryVariables>) {
          return Apollo.useLazyQuery<GetFeedbackBySessionIdQuery, GetFeedbackBySessionIdQueryVariables>(GetFeedbackBySessionIdDocument, baseOptions);
        }
export type GetFeedbackBySessionIdQueryHookResult = ReturnType<typeof useGetFeedbackBySessionIdQuery>;
export type GetFeedbackBySessionIdLazyQueryHookResult = ReturnType<typeof useGetFeedbackBySessionIdLazyQuery>;
export type GetFeedbackBySessionIdQueryResult = Apollo.QueryResult<GetFeedbackBySessionIdQuery, GetFeedbackBySessionIdQueryVariables>;
export const GetFeedbackCategoriesDocument = gql`
    query GetFeedbackCategories($id: Int) {
  FeedbackFramework(where: {id: {_eq: $id}, deletedAt: {_is_null: true}}) {
    id
    name
    description
    deletedAt
    selectableNotes
    CreatedBy {
      firstName
      lastName
    }
    Parent {
      name
      id
      description
      Parent {
        name
        id
        description
      }
    }
    Children(where: {deletedAt: {_is_null: true}}) {
      id
      name
      description
      CreatedBy {
        firstName
        lastName
      }
      Children(where: {deletedAt: {_is_null: true}}) {
        id
        name
        description
      }
    }
  }
}
    `;

/**
 * __useGetFeedbackCategoriesQuery__
 *
 * To run a query within a React component, call `useGetFeedbackCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeedbackCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeedbackCategoriesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFeedbackCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetFeedbackCategoriesQuery, GetFeedbackCategoriesQueryVariables>) {
        return Apollo.useQuery<GetFeedbackCategoriesQuery, GetFeedbackCategoriesQueryVariables>(GetFeedbackCategoriesDocument, baseOptions);
      }
export function useGetFeedbackCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFeedbackCategoriesQuery, GetFeedbackCategoriesQueryVariables>) {
          return Apollo.useLazyQuery<GetFeedbackCategoriesQuery, GetFeedbackCategoriesQueryVariables>(GetFeedbackCategoriesDocument, baseOptions);
        }
export type GetFeedbackCategoriesQueryHookResult = ReturnType<typeof useGetFeedbackCategoriesQuery>;
export type GetFeedbackCategoriesLazyQueryHookResult = ReturnType<typeof useGetFeedbackCategoriesLazyQuery>;
export type GetFeedbackCategoriesQueryResult = Apollo.QueryResult<GetFeedbackCategoriesQuery, GetFeedbackCategoriesQueryVariables>;
export const GetFeedbackFrameworksDocument = gql`
    query GetFeedbackFrameworks($manager: uuid, $teamMemberId: uuid) {
  FeedbackFramework(where: {parentId: {_is_null: true}, deletedAt: {_is_null: true}, _or: [{createdBy: {_eq: $manager}, TeamMemberFrameworks: {teamMemberId: {_eq: $teamMemberId}}}, {createdBy: {_is_null: true}}, {isAdmin: {_eq: true}}]}) {
    id
    name
    CreatedBy {
      firstName
      lastName
    }
    Parent {
      name
      id
    }
    Children(where: {deletedAt: {_is_null: true}}) {
      id
      name
      CreatedBy {
        firstName
        lastName
      }
    }
  }
}
    `;

/**
 * __useGetFeedbackFrameworksQuery__
 *
 * To run a query within a React component, call `useGetFeedbackFrameworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeedbackFrameworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeedbackFrameworksQuery({
 *   variables: {
 *      manager: // value for 'manager'
 *      teamMemberId: // value for 'teamMemberId'
 *   },
 * });
 */
export function useGetFeedbackFrameworksQuery(baseOptions?: Apollo.QueryHookOptions<GetFeedbackFrameworksQuery, GetFeedbackFrameworksQueryVariables>) {
        return Apollo.useQuery<GetFeedbackFrameworksQuery, GetFeedbackFrameworksQueryVariables>(GetFeedbackFrameworksDocument, baseOptions);
      }
export function useGetFeedbackFrameworksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFeedbackFrameworksQuery, GetFeedbackFrameworksQueryVariables>) {
          return Apollo.useLazyQuery<GetFeedbackFrameworksQuery, GetFeedbackFrameworksQueryVariables>(GetFeedbackFrameworksDocument, baseOptions);
        }
export type GetFeedbackFrameworksQueryHookResult = ReturnType<typeof useGetFeedbackFrameworksQuery>;
export type GetFeedbackFrameworksLazyQueryHookResult = ReturnType<typeof useGetFeedbackFrameworksLazyQuery>;
export type GetFeedbackFrameworksQueryResult = Apollo.QueryResult<GetFeedbackFrameworksQuery, GetFeedbackFrameworksQueryVariables>;
export const GetFeedbackFrameworkTreeDocument = gql`
    query GetFeedbackFrameworkTree($managerId: uuid) {
  FeedbackFramework(where: {createdBy: {_eq: $managerId}, parentId: {_is_null: true}, deletedAt: {_is_null: true}}) {
    id
    name
    Children(where: {deletedAt: {_is_null: true}}) {
      id
      name
      Children(where: {deletedAt: {_is_null: true}}) {
        id
        name
        Children(where: {deletedAt: {_is_null: true}}) {
          id
          name
          Children(where: {deletedAt: {_is_null: true}}) {
            id
            name
            Children(where: {deletedAt: {_is_null: true}}) {
              id
              name
              Children(where: {deletedAt: {_is_null: true}}) {
                id
                name
                Children(where: {deletedAt: {_is_null: true}}) {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetFeedbackFrameworkTreeQuery__
 *
 * To run a query within a React component, call `useGetFeedbackFrameworkTreeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeedbackFrameworkTreeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeedbackFrameworkTreeQuery({
 *   variables: {
 *      managerId: // value for 'managerId'
 *   },
 * });
 */
export function useGetFeedbackFrameworkTreeQuery(baseOptions?: Apollo.QueryHookOptions<GetFeedbackFrameworkTreeQuery, GetFeedbackFrameworkTreeQueryVariables>) {
        return Apollo.useQuery<GetFeedbackFrameworkTreeQuery, GetFeedbackFrameworkTreeQueryVariables>(GetFeedbackFrameworkTreeDocument, baseOptions);
      }
export function useGetFeedbackFrameworkTreeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFeedbackFrameworkTreeQuery, GetFeedbackFrameworkTreeQueryVariables>) {
          return Apollo.useLazyQuery<GetFeedbackFrameworkTreeQuery, GetFeedbackFrameworkTreeQueryVariables>(GetFeedbackFrameworkTreeDocument, baseOptions);
        }
export type GetFeedbackFrameworkTreeQueryHookResult = ReturnType<typeof useGetFeedbackFrameworkTreeQuery>;
export type GetFeedbackFrameworkTreeLazyQueryHookResult = ReturnType<typeof useGetFeedbackFrameworkTreeLazyQuery>;
export type GetFeedbackFrameworkTreeQueryResult = Apollo.QueryResult<GetFeedbackFrameworkTreeQuery, GetFeedbackFrameworkTreeQueryVariables>;
export const GetFeedbackItemCountDocument = gql`
    query GetFeedbackItemCount($startDate: timestamptz!, $endDate: timestamptz!, $managerId: uuid) {
  positive: Feedback_aggregate(where: {type: {_eq: "Positive"}, createdAt: {_gte: $startDate, _lte: $endDate}, managerId: {_eq: $managerId}, isAgreed: {_is_null: false}}) {
    aggregate {
      count
    }
  }
  constructive: Feedback_aggregate(where: {type: {_eq: "Constructive"}, createdAt: {_gte: $startDate, _lte: $endDate}, managerId: {_eq: $managerId}, isAgreed: {_is_null: false}}) {
    aggregate {
      count
    }
  }
  infraction: Infraction_aggregate(where: {createdAt: {_gte: $startDate, _lte: $endDate}, managerId: {_eq: $managerId}, isAgreed: {_is_null: false}}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetFeedbackItemCountQuery__
 *
 * To run a query within a React component, call `useGetFeedbackItemCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeedbackItemCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeedbackItemCountQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      managerId: // value for 'managerId'
 *   },
 * });
 */
export function useGetFeedbackItemCountQuery(baseOptions?: Apollo.QueryHookOptions<GetFeedbackItemCountQuery, GetFeedbackItemCountQueryVariables>) {
        return Apollo.useQuery<GetFeedbackItemCountQuery, GetFeedbackItemCountQueryVariables>(GetFeedbackItemCountDocument, baseOptions);
      }
export function useGetFeedbackItemCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFeedbackItemCountQuery, GetFeedbackItemCountQueryVariables>) {
          return Apollo.useLazyQuery<GetFeedbackItemCountQuery, GetFeedbackItemCountQueryVariables>(GetFeedbackItemCountDocument, baseOptions);
        }
export type GetFeedbackItemCountQueryHookResult = ReturnType<typeof useGetFeedbackItemCountQuery>;
export type GetFeedbackItemCountLazyQueryHookResult = ReturnType<typeof useGetFeedbackItemCountLazyQuery>;
export type GetFeedbackItemCountQueryResult = Apollo.QueryResult<GetFeedbackItemCountQuery, GetFeedbackItemCountQueryVariables>;
export const GetFeedbackItemCountByTeamIdDocument = gql`
    query GetFeedbackItemCountByTeamId($id: uuid!, $startDate: timestamptz!, $endDate: timestamptz!) {
  positive: Feedback_aggregate(where: {type: {_eq: "Positive"}, teamMemberId: {_eq: $id}, createdAt: {_gte: $startDate, _lte: $endDate}, isAgreed: {_is_null: false}}) {
    aggregate {
      count
    }
  }
  constructive: Feedback_aggregate(where: {type: {_eq: "Constructive"}, teamMemberId: {_eq: $id}, createdAt: {_gte: $startDate, _lte: $endDate}, isAgreed: {_is_null: false}}) {
    aggregate {
      count
    }
  }
  infraction: Infraction_aggregate(where: {teamMemberId: {_eq: $id}, createdAt: {_gte: $startDate, _lte: $endDate}, isAgreed: {_is_null: false}}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetFeedbackItemCountByTeamIdQuery__
 *
 * To run a query within a React component, call `useGetFeedbackItemCountByTeamIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeedbackItemCountByTeamIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeedbackItemCountByTeamIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetFeedbackItemCountByTeamIdQuery(baseOptions?: Apollo.QueryHookOptions<GetFeedbackItemCountByTeamIdQuery, GetFeedbackItemCountByTeamIdQueryVariables>) {
        return Apollo.useQuery<GetFeedbackItemCountByTeamIdQuery, GetFeedbackItemCountByTeamIdQueryVariables>(GetFeedbackItemCountByTeamIdDocument, baseOptions);
      }
export function useGetFeedbackItemCountByTeamIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFeedbackItemCountByTeamIdQuery, GetFeedbackItemCountByTeamIdQueryVariables>) {
          return Apollo.useLazyQuery<GetFeedbackItemCountByTeamIdQuery, GetFeedbackItemCountByTeamIdQueryVariables>(GetFeedbackItemCountByTeamIdDocument, baseOptions);
        }
export type GetFeedbackItemCountByTeamIdQueryHookResult = ReturnType<typeof useGetFeedbackItemCountByTeamIdQuery>;
export type GetFeedbackItemCountByTeamIdLazyQueryHookResult = ReturnType<typeof useGetFeedbackItemCountByTeamIdLazyQuery>;
export type GetFeedbackItemCountByTeamIdQueryResult = Apollo.QueryResult<GetFeedbackItemCountByTeamIdQuery, GetFeedbackItemCountByTeamIdQueryVariables>;
export const GetFeedbackSessionByIdDocument = gql`
    query GetFeedbackSessionById($id: Int!) {
  FeedbackSession(where: {id: {_eq: $id}}) {
    id
    createdAt
    companyId
    managerId
    teamMemberId
    TeamMember {
      lastName
      firstName
      id
      managerId
      title
      email
    }
    Manager {
      firstName
      lastName
      id
    }
    FeedbackItem {
      type
      id
      teamMemberId
      sessionId
      notes
      managerId
      isAgreed
      impact
      deletedAt
      createdAt
      companyId
      category
    }
    deletedAt
    summarySentAt
  }
}
    `;

/**
 * __useGetFeedbackSessionByIdQuery__
 *
 * To run a query within a React component, call `useGetFeedbackSessionByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeedbackSessionByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeedbackSessionByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFeedbackSessionByIdQuery(baseOptions?: Apollo.QueryHookOptions<GetFeedbackSessionByIdQuery, GetFeedbackSessionByIdQueryVariables>) {
        return Apollo.useQuery<GetFeedbackSessionByIdQuery, GetFeedbackSessionByIdQueryVariables>(GetFeedbackSessionByIdDocument, baseOptions);
      }
export function useGetFeedbackSessionByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFeedbackSessionByIdQuery, GetFeedbackSessionByIdQueryVariables>) {
          return Apollo.useLazyQuery<GetFeedbackSessionByIdQuery, GetFeedbackSessionByIdQueryVariables>(GetFeedbackSessionByIdDocument, baseOptions);
        }
export type GetFeedbackSessionByIdQueryHookResult = ReturnType<typeof useGetFeedbackSessionByIdQuery>;
export type GetFeedbackSessionByIdLazyQueryHookResult = ReturnType<typeof useGetFeedbackSessionByIdLazyQuery>;
export type GetFeedbackSessionByIdQueryResult = Apollo.QueryResult<GetFeedbackSessionByIdQuery, GetFeedbackSessionByIdQueryVariables>;
export const GetFeedbackSessionHistoryDocument = gql`
    query GetFeedbackSessionHistory($id: Int!) {
  FeedbackSession(where: {id: {_eq: $id}}) {
    id
    createdAt
    companyId
    managerId
    teamMemberId
    TeamMember {
      lastName
      firstName
      id
      managerId
      title
      email
    }
    Manager {
      firstName
      lastName
      id
    }
    FeedbackItem {
      type
      id
      teamMemberId
      sessionId
      notes
      managerId
      isAgreed
      impact
      deletedAt
      createdAt
      companyId
      category
      FeedbackEditHistory {
        id
        impact
        feedbackId
        createdAt
        isAgreed
        notes
        sessionId
      }
    }
    FeedbackEditHistory {
      id
      impact
      feedbackId
      createdAt
      isAgreed
      notes
      sessionId
      Manager {
        id
        firstName
        lastName
      }
    }
    deletedAt
    summarySentAt
  }
}
    `;

/**
 * __useGetFeedbackSessionHistoryQuery__
 *
 * To run a query within a React component, call `useGetFeedbackSessionHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeedbackSessionHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeedbackSessionHistoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFeedbackSessionHistoryQuery(baseOptions?: Apollo.QueryHookOptions<GetFeedbackSessionHistoryQuery, GetFeedbackSessionHistoryQueryVariables>) {
        return Apollo.useQuery<GetFeedbackSessionHistoryQuery, GetFeedbackSessionHistoryQueryVariables>(GetFeedbackSessionHistoryDocument, baseOptions);
      }
export function useGetFeedbackSessionHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFeedbackSessionHistoryQuery, GetFeedbackSessionHistoryQueryVariables>) {
          return Apollo.useLazyQuery<GetFeedbackSessionHistoryQuery, GetFeedbackSessionHistoryQueryVariables>(GetFeedbackSessionHistoryDocument, baseOptions);
        }
export type GetFeedbackSessionHistoryQueryHookResult = ReturnType<typeof useGetFeedbackSessionHistoryQuery>;
export type GetFeedbackSessionHistoryLazyQueryHookResult = ReturnType<typeof useGetFeedbackSessionHistoryLazyQuery>;
export type GetFeedbackSessionHistoryQueryResult = Apollo.QueryResult<GetFeedbackSessionHistoryQuery, GetFeedbackSessionHistoryQueryVariables>;
export const GetInfractionByIdDocument = gql`
    query GetInfractionById($id: Int!) {
  Infraction(where: {id: {_eq: $id}}) {
    id
    createdAt
    companyId
    managerId
    teamMemberId
    type
    notes
    isAgreed
    TeamMember {
      lastName
      firstName
      id
      managerId
      title
      email
    }
    Manager {
      firstName
      lastName
      id
    }
  }
}
    `;

/**
 * __useGetInfractionByIdQuery__
 *
 * To run a query within a React component, call `useGetInfractionByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInfractionByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInfractionByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetInfractionByIdQuery(baseOptions?: Apollo.QueryHookOptions<GetInfractionByIdQuery, GetInfractionByIdQueryVariables>) {
        return Apollo.useQuery<GetInfractionByIdQuery, GetInfractionByIdQueryVariables>(GetInfractionByIdDocument, baseOptions);
      }
export function useGetInfractionByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInfractionByIdQuery, GetInfractionByIdQueryVariables>) {
          return Apollo.useLazyQuery<GetInfractionByIdQuery, GetInfractionByIdQueryVariables>(GetInfractionByIdDocument, baseOptions);
        }
export type GetInfractionByIdQueryHookResult = ReturnType<typeof useGetInfractionByIdQuery>;
export type GetInfractionByIdLazyQueryHookResult = ReturnType<typeof useGetInfractionByIdLazyQuery>;
export type GetInfractionByIdQueryResult = Apollo.QueryResult<GetInfractionByIdQuery, GetInfractionByIdQueryVariables>;
export const GetManagersDocument = gql`
    query GetManagers($excludeId: uuid) {
  TeamMember(where: {deletedAt: {_is_null: true}, id: {_neq: $excludeId}, _or: [{role: {_eq: "CompanyManager"}}, {role: {_eq: "CompanyAdmin"}}]}) {
    id
    lastName
    firstName
    managerId
  }
}
    `;

/**
 * __useGetManagersQuery__
 *
 * To run a query within a React component, call `useGetManagersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManagersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManagersQuery({
 *   variables: {
 *      excludeId: // value for 'excludeId'
 *   },
 * });
 */
export function useGetManagersQuery(baseOptions?: Apollo.QueryHookOptions<GetManagersQuery, GetManagersQueryVariables>) {
        return Apollo.useQuery<GetManagersQuery, GetManagersQueryVariables>(GetManagersDocument, baseOptions);
      }
export function useGetManagersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetManagersQuery, GetManagersQueryVariables>) {
          return Apollo.useLazyQuery<GetManagersQuery, GetManagersQueryVariables>(GetManagersDocument, baseOptions);
        }
export type GetManagersQueryHookResult = ReturnType<typeof useGetManagersQuery>;
export type GetManagersLazyQueryHookResult = ReturnType<typeof useGetManagersLazyQuery>;
export type GetManagersQueryResult = Apollo.QueryResult<GetManagersQuery, GetManagersQueryVariables>;
export const GetRecentActivityDocument = gql`
    query GetRecentActivity {
  FeedbackSession(limit: 6, order_by: {createdAt: desc}, where: {deletedAt: {_is_null: true}, FeedbackItem: {isAgreed: {_is_null: false}}}) {
    FeedbackItem {
      type
      id
    }
    id
    createdAt
    companyId
    managerId
    teamMemberId
    TeamMember {
      lastName
      firstName
      id
    }
  }
  Infraction(limit: 6, order_by: {createdAt: desc}, where: {deletedAt: {_is_null: true}, isAgreed: {_is_null: false}}) {
    id
    createdAt
    companyId
    managerId
    teamMemberId
    TeamMember {
      lastName
      firstName
      id
    }
  }
  TitleHistory(limit: 6, order_by: {createdAt: desc}, where: {deletedAt: {_is_null: true}}) {
    id
    createdAt
    companyId
    managerId
    teamMemberId
    title
    oldTitle
    TeamMember {
      lastName
      firstName
      id
    }
  }
}
    `;

/**
 * __useGetRecentActivityQuery__
 *
 * To run a query within a React component, call `useGetRecentActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecentActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecentActivityQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRecentActivityQuery(baseOptions?: Apollo.QueryHookOptions<GetRecentActivityQuery, GetRecentActivityQueryVariables>) {
        return Apollo.useQuery<GetRecentActivityQuery, GetRecentActivityQueryVariables>(GetRecentActivityDocument, baseOptions);
      }
export function useGetRecentActivityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRecentActivityQuery, GetRecentActivityQueryVariables>) {
          return Apollo.useLazyQuery<GetRecentActivityQuery, GetRecentActivityQueryVariables>(GetRecentActivityDocument, baseOptions);
        }
export type GetRecentActivityQueryHookResult = ReturnType<typeof useGetRecentActivityQuery>;
export type GetRecentActivityLazyQueryHookResult = ReturnType<typeof useGetRecentActivityLazyQuery>;
export type GetRecentActivityQueryResult = Apollo.QueryResult<GetRecentActivityQuery, GetRecentActivityQueryVariables>;
export const GetRecentActivityByTeamIdDocument = gql`
    query GetRecentActivityByTeamId($id: uuid!, $startDate: timestamptz!, $endDate: timestamptz!, $sort: [Feedback_order_by!], $infractionSort: [Infraction_order_by!], $titleSort: [TitleHistory_order_by!]) {
  Feedback(limit: 200, order_by: $sort, where: {deletedAt: {_is_null: true}, teamMemberId: {_eq: $id}, createdAt: {_gte: $startDate, _lte: $endDate}, isAgreed: {_is_null: false}}) {
    id
    createdAt
    companyId
    managerId
    teamMemberId
    type
    sessionId
    TeamMember {
      lastName
      firstName
      id
    }
    Manager {
      firstName
      lastName
      id
    }
  }
  Feedback_aggregate(where: {deletedAt: {_is_null: true}, teamMemberId: {_eq: $id}, createdAt: {_gte: $startDate, _lte: $endDate}, isAgreed: {_is_null: false}}) {
    aggregate {
      count
    }
  }
  Infraction(limit: 200, order_by: $infractionSort, where: {deletedAt: {_is_null: true}, teamMemberId: {_eq: $id}, createdAt: {_gte: $startDate, _lte: $endDate}, isAgreed: {_is_null: false}}) {
    id
    createdAt
    companyId
    managerId
    teamMemberId
    TeamMember {
      lastName
      firstName
      id
    }
    Manager {
      firstName
      lastName
      id
    }
  }
  Infraction_aggregate(where: {deletedAt: {_is_null: true}, teamMemberId: {_eq: $id}, createdAt: {_gte: $startDate, _lte: $endDate}, isAgreed: {_is_null: false}}) {
    aggregate {
      count
    }
  }
  TitleHistory(limit: 200, order_by: $titleSort, where: {deletedAt: {_is_null: true}, teamMemberId: {_eq: $id}, createdAt: {_gte: $startDate, _lte: $endDate}}) {
    id
    createdAt
    companyId
    managerId
    teamMemberId
    title
    oldTitle
    TeamMember {
      lastName
      firstName
      id
    }
    Manager {
      firstName
      lastName
      id
    }
  }
  TitleHistory_aggregate(where: {deletedAt: {_is_null: true}, teamMemberId: {_eq: $id}, createdAt: {_gte: $startDate, _lte: $endDate}}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetRecentActivityByTeamIdQuery__
 *
 * To run a query within a React component, call `useGetRecentActivityByTeamIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecentActivityByTeamIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecentActivityByTeamIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      sort: // value for 'sort'
 *      infractionSort: // value for 'infractionSort'
 *      titleSort: // value for 'titleSort'
 *   },
 * });
 */
export function useGetRecentActivityByTeamIdQuery(baseOptions?: Apollo.QueryHookOptions<GetRecentActivityByTeamIdQuery, GetRecentActivityByTeamIdQueryVariables>) {
        return Apollo.useQuery<GetRecentActivityByTeamIdQuery, GetRecentActivityByTeamIdQueryVariables>(GetRecentActivityByTeamIdDocument, baseOptions);
      }
export function useGetRecentActivityByTeamIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRecentActivityByTeamIdQuery, GetRecentActivityByTeamIdQueryVariables>) {
          return Apollo.useLazyQuery<GetRecentActivityByTeamIdQuery, GetRecentActivityByTeamIdQueryVariables>(GetRecentActivityByTeamIdDocument, baseOptions);
        }
export type GetRecentActivityByTeamIdQueryHookResult = ReturnType<typeof useGetRecentActivityByTeamIdQuery>;
export type GetRecentActivityByTeamIdLazyQueryHookResult = ReturnType<typeof useGetRecentActivityByTeamIdLazyQuery>;
export type GetRecentActivityByTeamIdQueryResult = Apollo.QueryResult<GetRecentActivityByTeamIdQuery, GetRecentActivityByTeamIdQueryVariables>;
export const GetRecentlyViewedTeamMembersDocument = gql`
    query GetRecentlyViewedTeamMembers($teamMemberId: uuid!) {
  TeamMember(where: {id: {_eq: $teamMemberId}}) {
    id
    lastName
    firstName
    recentlyViewedTeam
  }
}
    `;

/**
 * __useGetRecentlyViewedTeamMembersQuery__
 *
 * To run a query within a React component, call `useGetRecentlyViewedTeamMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecentlyViewedTeamMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecentlyViewedTeamMembersQuery({
 *   variables: {
 *      teamMemberId: // value for 'teamMemberId'
 *   },
 * });
 */
export function useGetRecentlyViewedTeamMembersQuery(baseOptions?: Apollo.QueryHookOptions<GetRecentlyViewedTeamMembersQuery, GetRecentlyViewedTeamMembersQueryVariables>) {
        return Apollo.useQuery<GetRecentlyViewedTeamMembersQuery, GetRecentlyViewedTeamMembersQueryVariables>(GetRecentlyViewedTeamMembersDocument, baseOptions);
      }
export function useGetRecentlyViewedTeamMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRecentlyViewedTeamMembersQuery, GetRecentlyViewedTeamMembersQueryVariables>) {
          return Apollo.useLazyQuery<GetRecentlyViewedTeamMembersQuery, GetRecentlyViewedTeamMembersQueryVariables>(GetRecentlyViewedTeamMembersDocument, baseOptions);
        }
export type GetRecentlyViewedTeamMembersQueryHookResult = ReturnType<typeof useGetRecentlyViewedTeamMembersQuery>;
export type GetRecentlyViewedTeamMembersLazyQueryHookResult = ReturnType<typeof useGetRecentlyViewedTeamMembersLazyQuery>;
export type GetRecentlyViewedTeamMembersQueryResult = Apollo.QueryResult<GetRecentlyViewedTeamMembersQuery, GetRecentlyViewedTeamMembersQueryVariables>;
export const GetTeamMemberByIdDocument = gql`
    query GetTeamMemberById($id: uuid!) {
  TeamMember(where: {id: {_eq: $id}}) {
    id
    lastName
    isActive
    firstName
    email
    department
    dateStarted
    phone
    title
    managerId
    role
    authenticationId
    Manager {
      id
      firstName
      lastName
    }
  }
}
    `;

/**
 * __useGetTeamMemberByIdQuery__
 *
 * To run a query within a React component, call `useGetTeamMemberByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamMemberByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamMemberByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTeamMemberByIdQuery(baseOptions?: Apollo.QueryHookOptions<GetTeamMemberByIdQuery, GetTeamMemberByIdQueryVariables>) {
        return Apollo.useQuery<GetTeamMemberByIdQuery, GetTeamMemberByIdQueryVariables>(GetTeamMemberByIdDocument, baseOptions);
      }
export function useGetTeamMemberByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeamMemberByIdQuery, GetTeamMemberByIdQueryVariables>) {
          return Apollo.useLazyQuery<GetTeamMemberByIdQuery, GetTeamMemberByIdQueryVariables>(GetTeamMemberByIdDocument, baseOptions);
        }
export type GetTeamMemberByIdQueryHookResult = ReturnType<typeof useGetTeamMemberByIdQuery>;
export type GetTeamMemberByIdLazyQueryHookResult = ReturnType<typeof useGetTeamMemberByIdLazyQuery>;
export type GetTeamMemberByIdQueryResult = Apollo.QueryResult<GetTeamMemberByIdQuery, GetTeamMemberByIdQueryVariables>;
export const GetTeamMemberCountDocument = gql`
    query GetTeamMemberCount {
  TeamMember_aggregate(where: {deletedAt: {_is_null: true}}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetTeamMemberCountQuery__
 *
 * To run a query within a React component, call `useGetTeamMemberCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamMemberCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamMemberCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTeamMemberCountQuery(baseOptions?: Apollo.QueryHookOptions<GetTeamMemberCountQuery, GetTeamMemberCountQueryVariables>) {
        return Apollo.useQuery<GetTeamMemberCountQuery, GetTeamMemberCountQueryVariables>(GetTeamMemberCountDocument, baseOptions);
      }
export function useGetTeamMemberCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeamMemberCountQuery, GetTeamMemberCountQueryVariables>) {
          return Apollo.useLazyQuery<GetTeamMemberCountQuery, GetTeamMemberCountQueryVariables>(GetTeamMemberCountDocument, baseOptions);
        }
export type GetTeamMemberCountQueryHookResult = ReturnType<typeof useGetTeamMemberCountQuery>;
export type GetTeamMemberCountLazyQueryHookResult = ReturnType<typeof useGetTeamMemberCountLazyQuery>;
export type GetTeamMemberCountQueryResult = Apollo.QueryResult<GetTeamMemberCountQuery, GetTeamMemberCountQueryVariables>;
export const GetTeamMemberFrameworksDocument = gql`
    query GetTeamMemberFrameworks($teamMemberId: uuid) {
  TeamMemberFramework(where: {teamMemberId: {_eq: $teamMemberId}}) {
    id
    companyId
    teamMemberId
    frameworkId
    FeedbackFramework {
      name
    }
  }
}
    `;

/**
 * __useGetTeamMemberFrameworksQuery__
 *
 * To run a query within a React component, call `useGetTeamMemberFrameworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamMemberFrameworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamMemberFrameworksQuery({
 *   variables: {
 *      teamMemberId: // value for 'teamMemberId'
 *   },
 * });
 */
export function useGetTeamMemberFrameworksQuery(baseOptions?: Apollo.QueryHookOptions<GetTeamMemberFrameworksQuery, GetTeamMemberFrameworksQueryVariables>) {
        return Apollo.useQuery<GetTeamMemberFrameworksQuery, GetTeamMemberFrameworksQueryVariables>(GetTeamMemberFrameworksDocument, baseOptions);
      }
export function useGetTeamMemberFrameworksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeamMemberFrameworksQuery, GetTeamMemberFrameworksQueryVariables>) {
          return Apollo.useLazyQuery<GetTeamMemberFrameworksQuery, GetTeamMemberFrameworksQueryVariables>(GetTeamMemberFrameworksDocument, baseOptions);
        }
export type GetTeamMemberFrameworksQueryHookResult = ReturnType<typeof useGetTeamMemberFrameworksQuery>;
export type GetTeamMemberFrameworksLazyQueryHookResult = ReturnType<typeof useGetTeamMemberFrameworksLazyQuery>;
export type GetTeamMemberFrameworksQueryResult = Apollo.QueryResult<GetTeamMemberFrameworksQuery, GetTeamMemberFrameworksQueryVariables>;
export const GetTeamMembersDocument = gql`
    query GetTeamMembers($currentTeamMemberId: uuid!, $limit: Int!, $offset: Int!, $searchQuery: String!) {
  TeamMember(limit: $limit, offset: $offset, where: {_and: [{id: {_neq: $currentTeamMemberId}, deletedAt: {_is_null: true}, managerId: {_eq: $currentTeamMemberId}, _or: [{firstName: {_ilike: $searchQuery}}, {lastName: {_ilike: $searchQuery}}]}]}) {
    id
    lastName
    isActive
    firstName
    email
    department
    dateStarted
    phone
    title
    managerId
    role
    Manager {
      firstName
      lastName
      id
    }
  }
  TeamMember_aggregate(where: {_and: [{id: {_neq: $currentTeamMemberId}, deletedAt: {_is_null: true}, managerId: {_eq: $currentTeamMemberId}, _or: [{firstName: {_ilike: $searchQuery}}, {lastName: {_ilike: $searchQuery}}]}]}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetTeamMembersQuery__
 *
 * To run a query within a React component, call `useGetTeamMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamMembersQuery({
 *   variables: {
 *      currentTeamMemberId: // value for 'currentTeamMemberId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      searchQuery: // value for 'searchQuery'
 *   },
 * });
 */
export function useGetTeamMembersQuery(baseOptions?: Apollo.QueryHookOptions<GetTeamMembersQuery, GetTeamMembersQueryVariables>) {
        return Apollo.useQuery<GetTeamMembersQuery, GetTeamMembersQueryVariables>(GetTeamMembersDocument, baseOptions);
      }
export function useGetTeamMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeamMembersQuery, GetTeamMembersQueryVariables>) {
          return Apollo.useLazyQuery<GetTeamMembersQuery, GetTeamMembersQueryVariables>(GetTeamMembersDocument, baseOptions);
        }
export type GetTeamMembersQueryHookResult = ReturnType<typeof useGetTeamMembersQuery>;
export type GetTeamMembersLazyQueryHookResult = ReturnType<typeof useGetTeamMembersLazyQuery>;
export type GetTeamMembersQueryResult = Apollo.QueryResult<GetTeamMembersQuery, GetTeamMembersQueryVariables>;
export const GetTitleChangeByIdDocument = gql`
    query GetTitleChangeById($id: Int!) {
  TitleHistory(where: {id: {_eq: $id}}) {
    id
    createdAt
    title
    oldTitle
    TeamMember {
      id
      firstName
      lastName
    }
    Manager {
      id
      firstName
      lastName
    }
  }
}
    `;

/**
 * __useGetTitleChangeByIdQuery__
 *
 * To run a query within a React component, call `useGetTitleChangeByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTitleChangeByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTitleChangeByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTitleChangeByIdQuery(baseOptions?: Apollo.QueryHookOptions<GetTitleChangeByIdQuery, GetTitleChangeByIdQueryVariables>) {
        return Apollo.useQuery<GetTitleChangeByIdQuery, GetTitleChangeByIdQueryVariables>(GetTitleChangeByIdDocument, baseOptions);
      }
export function useGetTitleChangeByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTitleChangeByIdQuery, GetTitleChangeByIdQueryVariables>) {
          return Apollo.useLazyQuery<GetTitleChangeByIdQuery, GetTitleChangeByIdQueryVariables>(GetTitleChangeByIdDocument, baseOptions);
        }
export type GetTitleChangeByIdQueryHookResult = ReturnType<typeof useGetTitleChangeByIdQuery>;
export type GetTitleChangeByIdLazyQueryHookResult = ReturnType<typeof useGetTitleChangeByIdLazyQuery>;
export type GetTitleChangeByIdQueryResult = Apollo.QueryResult<GetTitleChangeByIdQuery, GetTitleChangeByIdQueryVariables>;
export const InsertFeedbackFrameworkDocument = gql`
    mutation InsertFeedbackFramework($parentId: Int, $name: String!, $description: String!, $selectableNotes: String!, $companyId: uuid!, $createdBy: uuid, $isAdmin: Boolean) {
  insert_FeedbackFramework_one(object: {parentId: $parentId, name: $name, description: $description, selectableNotes: $selectableNotes, companyId: $companyId, createdBy: $createdBy, isAdmin: $isAdmin}) {
    id
  }
}
    `;
export type InsertFeedbackFrameworkMutationFn = Apollo.MutationFunction<InsertFeedbackFrameworkMutation, InsertFeedbackFrameworkMutationVariables>;

/**
 * __useInsertFeedbackFrameworkMutation__
 *
 * To run a mutation, you first call `useInsertFeedbackFrameworkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertFeedbackFrameworkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertFeedbackFrameworkMutation, { data, loading, error }] = useInsertFeedbackFrameworkMutation({
 *   variables: {
 *      parentId: // value for 'parentId'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      selectableNotes: // value for 'selectableNotes'
 *      companyId: // value for 'companyId'
 *      createdBy: // value for 'createdBy'
 *      isAdmin: // value for 'isAdmin'
 *   },
 * });
 */
export function useInsertFeedbackFrameworkMutation(baseOptions?: Apollo.MutationHookOptions<InsertFeedbackFrameworkMutation, InsertFeedbackFrameworkMutationVariables>) {
        return Apollo.useMutation<InsertFeedbackFrameworkMutation, InsertFeedbackFrameworkMutationVariables>(InsertFeedbackFrameworkDocument, baseOptions);
      }
export type InsertFeedbackFrameworkMutationHookResult = ReturnType<typeof useInsertFeedbackFrameworkMutation>;
export type InsertFeedbackFrameworkMutationResult = Apollo.MutationResult<InsertFeedbackFrameworkMutation>;
export type InsertFeedbackFrameworkMutationOptions = Apollo.BaseMutationOptions<InsertFeedbackFrameworkMutation, InsertFeedbackFrameworkMutationVariables>;
export const InsertFeedbackItemDocument = gql`
    mutation InsertFeedbackItem($category: String!, $companyId: uuid!, $impact: numeric!, $managerId: uuid!, $sessionId: Int!, $teamMemberId: uuid!, $notes: String, $type: String!, $categoryDescription: String) {
  insert_Feedback_one(object: {category: $category, companyId: $companyId, impact: $impact, managerId: $managerId, sessionId: $sessionId, teamMemberId: $teamMemberId, notes: $notes, type: $type, categoryDescription: $categoryDescription}) {
    id
  }
}
    `;
export type InsertFeedbackItemMutationFn = Apollo.MutationFunction<InsertFeedbackItemMutation, InsertFeedbackItemMutationVariables>;

/**
 * __useInsertFeedbackItemMutation__
 *
 * To run a mutation, you first call `useInsertFeedbackItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertFeedbackItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertFeedbackItemMutation, { data, loading, error }] = useInsertFeedbackItemMutation({
 *   variables: {
 *      category: // value for 'category'
 *      companyId: // value for 'companyId'
 *      impact: // value for 'impact'
 *      managerId: // value for 'managerId'
 *      sessionId: // value for 'sessionId'
 *      teamMemberId: // value for 'teamMemberId'
 *      notes: // value for 'notes'
 *      type: // value for 'type'
 *      categoryDescription: // value for 'categoryDescription'
 *   },
 * });
 */
export function useInsertFeedbackItemMutation(baseOptions?: Apollo.MutationHookOptions<InsertFeedbackItemMutation, InsertFeedbackItemMutationVariables>) {
        return Apollo.useMutation<InsertFeedbackItemMutation, InsertFeedbackItemMutationVariables>(InsertFeedbackItemDocument, baseOptions);
      }
export type InsertFeedbackItemMutationHookResult = ReturnType<typeof useInsertFeedbackItemMutation>;
export type InsertFeedbackItemMutationResult = Apollo.MutationResult<InsertFeedbackItemMutation>;
export type InsertFeedbackItemMutationOptions = Apollo.BaseMutationOptions<InsertFeedbackItemMutation, InsertFeedbackItemMutationVariables>;
export const InsertFeedbackSessionDocument = gql`
    mutation InsertFeedbackSession($companyId: uuid!, $managerId: uuid!, $teamMemberId: uuid!) {
  insert_FeedbackSession_one(object: {companyId: $companyId, managerId: $managerId, teamMemberId: $teamMemberId}) {
    id
    companyId
    managerId
    teamMemberId
  }
}
    `;
export type InsertFeedbackSessionMutationFn = Apollo.MutationFunction<InsertFeedbackSessionMutation, InsertFeedbackSessionMutationVariables>;

/**
 * __useInsertFeedbackSessionMutation__
 *
 * To run a mutation, you first call `useInsertFeedbackSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertFeedbackSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertFeedbackSessionMutation, { data, loading, error }] = useInsertFeedbackSessionMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      managerId: // value for 'managerId'
 *      teamMemberId: // value for 'teamMemberId'
 *   },
 * });
 */
export function useInsertFeedbackSessionMutation(baseOptions?: Apollo.MutationHookOptions<InsertFeedbackSessionMutation, InsertFeedbackSessionMutationVariables>) {
        return Apollo.useMutation<InsertFeedbackSessionMutation, InsertFeedbackSessionMutationVariables>(InsertFeedbackSessionDocument, baseOptions);
      }
export type InsertFeedbackSessionMutationHookResult = ReturnType<typeof useInsertFeedbackSessionMutation>;
export type InsertFeedbackSessionMutationResult = Apollo.MutationResult<InsertFeedbackSessionMutation>;
export type InsertFeedbackSessionMutationOptions = Apollo.BaseMutationOptions<InsertFeedbackSessionMutation, InsertFeedbackSessionMutationVariables>;
export const InsertInfractionDocument = gql`
    mutation InsertInfraction($companyId: uuid!, $managerId: uuid!, $notes: String!, $teamMemberId: uuid!, $type: String!) {
  insert_Infraction_one(object: {companyId: $companyId, managerId: $managerId, notes: $notes, teamMemberId: $teamMemberId, type: $type}) {
    id
  }
}
    `;
export type InsertInfractionMutationFn = Apollo.MutationFunction<InsertInfractionMutation, InsertInfractionMutationVariables>;

/**
 * __useInsertInfractionMutation__
 *
 * To run a mutation, you first call `useInsertInfractionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertInfractionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertInfractionMutation, { data, loading, error }] = useInsertInfractionMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      managerId: // value for 'managerId'
 *      notes: // value for 'notes'
 *      teamMemberId: // value for 'teamMemberId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useInsertInfractionMutation(baseOptions?: Apollo.MutationHookOptions<InsertInfractionMutation, InsertInfractionMutationVariables>) {
        return Apollo.useMutation<InsertInfractionMutation, InsertInfractionMutationVariables>(InsertInfractionDocument, baseOptions);
      }
export type InsertInfractionMutationHookResult = ReturnType<typeof useInsertInfractionMutation>;
export type InsertInfractionMutationResult = Apollo.MutationResult<InsertInfractionMutation>;
export type InsertInfractionMutationOptions = Apollo.BaseMutationOptions<InsertInfractionMutation, InsertInfractionMutationVariables>;
export const InsertTeamMemberDocument = gql`
    mutation InsertTeamMember($firstName: String!, $lastName: String!, $title: String!, $phone: String, $companyId: uuid!, $managerId: uuid, $dateStarted: date!, $email: String!, $role: String!) {
  insert_TeamMember_one(object: {firstName: $firstName, lastName: $lastName, title: $title, phone: $phone, companyId: $companyId, managerId: $managerId, dateStarted: $dateStarted, email: $email, role: $role}) {
    id
  }
}
    `;
export type InsertTeamMemberMutationFn = Apollo.MutationFunction<InsertTeamMemberMutation, InsertTeamMemberMutationVariables>;

/**
 * __useInsertTeamMemberMutation__
 *
 * To run a mutation, you first call `useInsertTeamMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertTeamMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertTeamMemberMutation, { data, loading, error }] = useInsertTeamMemberMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      title: // value for 'title'
 *      phone: // value for 'phone'
 *      companyId: // value for 'companyId'
 *      managerId: // value for 'managerId'
 *      dateStarted: // value for 'dateStarted'
 *      email: // value for 'email'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useInsertTeamMemberMutation(baseOptions?: Apollo.MutationHookOptions<InsertTeamMemberMutation, InsertTeamMemberMutationVariables>) {
        return Apollo.useMutation<InsertTeamMemberMutation, InsertTeamMemberMutationVariables>(InsertTeamMemberDocument, baseOptions);
      }
export type InsertTeamMemberMutationHookResult = ReturnType<typeof useInsertTeamMemberMutation>;
export type InsertTeamMemberMutationResult = Apollo.MutationResult<InsertTeamMemberMutation>;
export type InsertTeamMemberMutationOptions = Apollo.BaseMutationOptions<InsertTeamMemberMutation, InsertTeamMemberMutationVariables>;
export const InsertTeamMemberFrameworkDocument = gql`
    mutation InsertTeamMemberFramework($frameworkId: Int!, $companyId: uuid!, $teamMemberId: uuid!) {
  insert_TeamMemberFramework_one(object: {frameworkId: $frameworkId, companyId: $companyId, teamMemberId: $teamMemberId}) {
    id
  }
}
    `;
export type InsertTeamMemberFrameworkMutationFn = Apollo.MutationFunction<InsertTeamMemberFrameworkMutation, InsertTeamMemberFrameworkMutationVariables>;

/**
 * __useInsertTeamMemberFrameworkMutation__
 *
 * To run a mutation, you first call `useInsertTeamMemberFrameworkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertTeamMemberFrameworkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertTeamMemberFrameworkMutation, { data, loading, error }] = useInsertTeamMemberFrameworkMutation({
 *   variables: {
 *      frameworkId: // value for 'frameworkId'
 *      companyId: // value for 'companyId'
 *      teamMemberId: // value for 'teamMemberId'
 *   },
 * });
 */
export function useInsertTeamMemberFrameworkMutation(baseOptions?: Apollo.MutationHookOptions<InsertTeamMemberFrameworkMutation, InsertTeamMemberFrameworkMutationVariables>) {
        return Apollo.useMutation<InsertTeamMemberFrameworkMutation, InsertTeamMemberFrameworkMutationVariables>(InsertTeamMemberFrameworkDocument, baseOptions);
      }
export type InsertTeamMemberFrameworkMutationHookResult = ReturnType<typeof useInsertTeamMemberFrameworkMutation>;
export type InsertTeamMemberFrameworkMutationResult = Apollo.MutationResult<InsertTeamMemberFrameworkMutation>;
export type InsertTeamMemberFrameworkMutationOptions = Apollo.BaseMutationOptions<InsertTeamMemberFrameworkMutation, InsertTeamMemberFrameworkMutationVariables>;
export const ReactivateSubscriptionDocument = gql`
    mutation ReactivateSubscription {
  update_CompanyReactivateSubscription {
    isSuccess
  }
}
    `;
export type ReactivateSubscriptionMutationFn = Apollo.MutationFunction<ReactivateSubscriptionMutation, ReactivateSubscriptionMutationVariables>;

/**
 * __useReactivateSubscriptionMutation__
 *
 * To run a mutation, you first call `useReactivateSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReactivateSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reactivateSubscriptionMutation, { data, loading, error }] = useReactivateSubscriptionMutation({
 *   variables: {
 *   },
 * });
 */
export function useReactivateSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<ReactivateSubscriptionMutation, ReactivateSubscriptionMutationVariables>) {
        return Apollo.useMutation<ReactivateSubscriptionMutation, ReactivateSubscriptionMutationVariables>(ReactivateSubscriptionDocument, baseOptions);
      }
export type ReactivateSubscriptionMutationHookResult = ReturnType<typeof useReactivateSubscriptionMutation>;
export type ReactivateSubscriptionMutationResult = Apollo.MutationResult<ReactivateSubscriptionMutation>;
export type ReactivateSubscriptionMutationOptions = Apollo.BaseMutationOptions<ReactivateSubscriptionMutation, ReactivateSubscriptionMutationVariables>;
export const UpdateAdminTeamMemberDocument = gql`
    mutation UpdateAdminTeamMember($id: uuid!, $firstName: String!, $lastName: String!, $title: String, $phone: String, $companyId: uuid!, $managerId: uuid, $authenticationId: uuid, $dateStarted: date, $email: String!, $role: String!) {
  update_TeamMember(_set: {firstName: $firstName, lastName: $lastName, title: $title, phone: $phone, companyId: $companyId, managerId: $managerId, authenticationId: $authenticationId, dateStarted: $dateStarted, email: $email, role: $role}, where: {id: {_eq: $id}}) {
    returning {
      id
    }
  }
}
    `;
export type UpdateAdminTeamMemberMutationFn = Apollo.MutationFunction<UpdateAdminTeamMemberMutation, UpdateAdminTeamMemberMutationVariables>;

/**
 * __useUpdateAdminTeamMemberMutation__
 *
 * To run a mutation, you first call `useUpdateAdminTeamMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdminTeamMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdminTeamMemberMutation, { data, loading, error }] = useUpdateAdminTeamMemberMutation({
 *   variables: {
 *      id: // value for 'id'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      title: // value for 'title'
 *      phone: // value for 'phone'
 *      companyId: // value for 'companyId'
 *      managerId: // value for 'managerId'
 *      authenticationId: // value for 'authenticationId'
 *      dateStarted: // value for 'dateStarted'
 *      email: // value for 'email'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useUpdateAdminTeamMemberMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAdminTeamMemberMutation, UpdateAdminTeamMemberMutationVariables>) {
        return Apollo.useMutation<UpdateAdminTeamMemberMutation, UpdateAdminTeamMemberMutationVariables>(UpdateAdminTeamMemberDocument, baseOptions);
      }
export type UpdateAdminTeamMemberMutationHookResult = ReturnType<typeof useUpdateAdminTeamMemberMutation>;
export type UpdateAdminTeamMemberMutationResult = Apollo.MutationResult<UpdateAdminTeamMemberMutation>;
export type UpdateAdminTeamMemberMutationOptions = Apollo.BaseMutationOptions<UpdateAdminTeamMemberMutation, UpdateAdminTeamMemberMutationVariables>;
export const UpdateCompanyDocument = gql`
    mutation UpdateCompany($id: uuid!, $name: String!, $size: String, $businessType: String) {
  update_Company(_set: {name: $name, size: $size, businessType: $businessType}, where: {id: {_eq: $id}}) {
    returning {
      id
    }
  }
}
    `;
export type UpdateCompanyMutationFn = Apollo.MutationFunction<UpdateCompanyMutation, UpdateCompanyMutationVariables>;

/**
 * __useUpdateCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyMutation, { data, loading, error }] = useUpdateCompanyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      size: // value for 'size'
 *      businessType: // value for 'businessType'
 *   },
 * });
 */
export function useUpdateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>) {
        return Apollo.useMutation<UpdateCompanyMutation, UpdateCompanyMutationVariables>(UpdateCompanyDocument, baseOptions);
      }
export type UpdateCompanyMutationHookResult = ReturnType<typeof useUpdateCompanyMutation>;
export type UpdateCompanyMutationResult = Apollo.MutationResult<UpdateCompanyMutation>;
export type UpdateCompanyMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>;
export const UpdateCompanyBillingDocument = gql`
    mutation UpdateCompanyBilling($billingEmail: String!, $billingToken: String!, $addressLine1: String!, $addressLine2: String, $city: String!, $companyName: String!, $postalCode: String!, $state: String!) {
  update_CompanyBilling(billingEmail: $billingEmail, billingToken: $billingToken, addressLine1: $addressLine1, addressLine2: $addressLine2, city: $city, companyName: $companyName, postalCode: $postalCode, state: $state) {
    isSuccess
  }
}
    `;
export type UpdateCompanyBillingMutationFn = Apollo.MutationFunction<UpdateCompanyBillingMutation, UpdateCompanyBillingMutationVariables>;

/**
 * __useUpdateCompanyBillingMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyBillingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyBillingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyBillingMutation, { data, loading, error }] = useUpdateCompanyBillingMutation({
 *   variables: {
 *      billingEmail: // value for 'billingEmail'
 *      billingToken: // value for 'billingToken'
 *      addressLine1: // value for 'addressLine1'
 *      addressLine2: // value for 'addressLine2'
 *      city: // value for 'city'
 *      companyName: // value for 'companyName'
 *      postalCode: // value for 'postalCode'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useUpdateCompanyBillingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyBillingMutation, UpdateCompanyBillingMutationVariables>) {
        return Apollo.useMutation<UpdateCompanyBillingMutation, UpdateCompanyBillingMutationVariables>(UpdateCompanyBillingDocument, baseOptions);
      }
export type UpdateCompanyBillingMutationHookResult = ReturnType<typeof useUpdateCompanyBillingMutation>;
export type UpdateCompanyBillingMutationResult = Apollo.MutationResult<UpdateCompanyBillingMutation>;
export type UpdateCompanyBillingMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyBillingMutation, UpdateCompanyBillingMutationVariables>;
export const UpdateFeedbackFrameworkDocument = gql`
    mutation UpdateFeedbackFramework($id: Int, $name: String!, $description: String!, $selectableNotes: String!) {
  update_FeedbackFramework(_set: {name: $name, description: $description, selectableNotes: $selectableNotes}, where: {id: {_eq: $id}}) {
    returning {
      id
    }
  }
}
    `;
export type UpdateFeedbackFrameworkMutationFn = Apollo.MutationFunction<UpdateFeedbackFrameworkMutation, UpdateFeedbackFrameworkMutationVariables>;

/**
 * __useUpdateFeedbackFrameworkMutation__
 *
 * To run a mutation, you first call `useUpdateFeedbackFrameworkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFeedbackFrameworkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFeedbackFrameworkMutation, { data, loading, error }] = useUpdateFeedbackFrameworkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      selectableNotes: // value for 'selectableNotes'
 *   },
 * });
 */
export function useUpdateFeedbackFrameworkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFeedbackFrameworkMutation, UpdateFeedbackFrameworkMutationVariables>) {
        return Apollo.useMutation<UpdateFeedbackFrameworkMutation, UpdateFeedbackFrameworkMutationVariables>(UpdateFeedbackFrameworkDocument, baseOptions);
      }
export type UpdateFeedbackFrameworkMutationHookResult = ReturnType<typeof useUpdateFeedbackFrameworkMutation>;
export type UpdateFeedbackFrameworkMutationResult = Apollo.MutationResult<UpdateFeedbackFrameworkMutation>;
export type UpdateFeedbackFrameworkMutationOptions = Apollo.BaseMutationOptions<UpdateFeedbackFrameworkMutation, UpdateFeedbackFrameworkMutationVariables>;
export const UpdateFeedbackItemDocument = gql`
    mutation UpdateFeedbackItem($impact: numeric!, $notes: String!, $id: Int!) {
  update_Feedback(_set: {impact: $impact, notes: $notes, isAgreed: null}, where: {id: {_eq: $id}}) {
    returning {
      id
    }
  }
}
    `;
export type UpdateFeedbackItemMutationFn = Apollo.MutationFunction<UpdateFeedbackItemMutation, UpdateFeedbackItemMutationVariables>;

/**
 * __useUpdateFeedbackItemMutation__
 *
 * To run a mutation, you first call `useUpdateFeedbackItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFeedbackItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFeedbackItemMutation, { data, loading, error }] = useUpdateFeedbackItemMutation({
 *   variables: {
 *      impact: // value for 'impact'
 *      notes: // value for 'notes'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateFeedbackItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFeedbackItemMutation, UpdateFeedbackItemMutationVariables>) {
        return Apollo.useMutation<UpdateFeedbackItemMutation, UpdateFeedbackItemMutationVariables>(UpdateFeedbackItemDocument, baseOptions);
      }
export type UpdateFeedbackItemMutationHookResult = ReturnType<typeof useUpdateFeedbackItemMutation>;
export type UpdateFeedbackItemMutationResult = Apollo.MutationResult<UpdateFeedbackItemMutation>;
export type UpdateFeedbackItemMutationOptions = Apollo.BaseMutationOptions<UpdateFeedbackItemMutation, UpdateFeedbackItemMutationVariables>;
export const UpdateFeedbackItemAgreedDocument = gql`
    mutation UpdateFeedbackItemAgreed($isAgreed: Boolean!, $id: Int!) {
  update_Feedback(_set: {isAgreed: $isAgreed}, where: {id: {_eq: $id}}) {
    returning {
      id
    }
  }
}
    `;
export type UpdateFeedbackItemAgreedMutationFn = Apollo.MutationFunction<UpdateFeedbackItemAgreedMutation, UpdateFeedbackItemAgreedMutationVariables>;

/**
 * __useUpdateFeedbackItemAgreedMutation__
 *
 * To run a mutation, you first call `useUpdateFeedbackItemAgreedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFeedbackItemAgreedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFeedbackItemAgreedMutation, { data, loading, error }] = useUpdateFeedbackItemAgreedMutation({
 *   variables: {
 *      isAgreed: // value for 'isAgreed'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateFeedbackItemAgreedMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFeedbackItemAgreedMutation, UpdateFeedbackItemAgreedMutationVariables>) {
        return Apollo.useMutation<UpdateFeedbackItemAgreedMutation, UpdateFeedbackItemAgreedMutationVariables>(UpdateFeedbackItemAgreedDocument, baseOptions);
      }
export type UpdateFeedbackItemAgreedMutationHookResult = ReturnType<typeof useUpdateFeedbackItemAgreedMutation>;
export type UpdateFeedbackItemAgreedMutationResult = Apollo.MutationResult<UpdateFeedbackItemAgreedMutation>;
export type UpdateFeedbackItemAgreedMutationOptions = Apollo.BaseMutationOptions<UpdateFeedbackItemAgreedMutation, UpdateFeedbackItemAgreedMutationVariables>;
export const UpdateInfractionAgreedDocument = gql`
    mutation UpdateInfractionAgreed($isAgreed: Boolean!, $id: Int!) {
  update_Infraction(_set: {isAgreed: $isAgreed}, where: {id: {_eq: $id}}) {
    returning {
      id
    }
  }
}
    `;
export type UpdateInfractionAgreedMutationFn = Apollo.MutationFunction<UpdateInfractionAgreedMutation, UpdateInfractionAgreedMutationVariables>;

/**
 * __useUpdateInfractionAgreedMutation__
 *
 * To run a mutation, you first call `useUpdateInfractionAgreedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInfractionAgreedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInfractionAgreedMutation, { data, loading, error }] = useUpdateInfractionAgreedMutation({
 *   variables: {
 *      isAgreed: // value for 'isAgreed'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateInfractionAgreedMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInfractionAgreedMutation, UpdateInfractionAgreedMutationVariables>) {
        return Apollo.useMutation<UpdateInfractionAgreedMutation, UpdateInfractionAgreedMutationVariables>(UpdateInfractionAgreedDocument, baseOptions);
      }
export type UpdateInfractionAgreedMutationHookResult = ReturnType<typeof useUpdateInfractionAgreedMutation>;
export type UpdateInfractionAgreedMutationResult = Apollo.MutationResult<UpdateInfractionAgreedMutation>;
export type UpdateInfractionAgreedMutationOptions = Apollo.BaseMutationOptions<UpdateInfractionAgreedMutation, UpdateInfractionAgreedMutationVariables>;
export const UpdateMoveFeedbackFrameworkDocument = gql`
    mutation UpdateMoveFeedbackFramework($ids: [Int!], $parentId: Int!) {
  update_FeedbackFramework(_set: {parentId: $parentId}, where: {id: {_in: $ids}}) {
    returning {
      id
    }
  }
}
    `;
export type UpdateMoveFeedbackFrameworkMutationFn = Apollo.MutationFunction<UpdateMoveFeedbackFrameworkMutation, UpdateMoveFeedbackFrameworkMutationVariables>;

/**
 * __useUpdateMoveFeedbackFrameworkMutation__
 *
 * To run a mutation, you first call `useUpdateMoveFeedbackFrameworkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMoveFeedbackFrameworkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMoveFeedbackFrameworkMutation, { data, loading, error }] = useUpdateMoveFeedbackFrameworkMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *      parentId: // value for 'parentId'
 *   },
 * });
 */
export function useUpdateMoveFeedbackFrameworkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMoveFeedbackFrameworkMutation, UpdateMoveFeedbackFrameworkMutationVariables>) {
        return Apollo.useMutation<UpdateMoveFeedbackFrameworkMutation, UpdateMoveFeedbackFrameworkMutationVariables>(UpdateMoveFeedbackFrameworkDocument, baseOptions);
      }
export type UpdateMoveFeedbackFrameworkMutationHookResult = ReturnType<typeof useUpdateMoveFeedbackFrameworkMutation>;
export type UpdateMoveFeedbackFrameworkMutationResult = Apollo.MutationResult<UpdateMoveFeedbackFrameworkMutation>;
export type UpdateMoveFeedbackFrameworkMutationOptions = Apollo.BaseMutationOptions<UpdateMoveFeedbackFrameworkMutation, UpdateMoveFeedbackFrameworkMutationVariables>;
export const UpdateMyAccountDocument = gql`
    mutation UpdateMyAccount($id: uuid!, $firstName: String!, $lastName: String!, $phone: String) {
  update_TeamMember(_set: {firstName: $firstName, lastName: $lastName, phone: $phone}, where: {id: {_eq: $id}}) {
    returning {
      id
    }
  }
}
    `;
export type UpdateMyAccountMutationFn = Apollo.MutationFunction<UpdateMyAccountMutation, UpdateMyAccountMutationVariables>;

/**
 * __useUpdateMyAccountMutation__
 *
 * To run a mutation, you first call `useUpdateMyAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMyAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMyAccountMutation, { data, loading, error }] = useUpdateMyAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useUpdateMyAccountMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMyAccountMutation, UpdateMyAccountMutationVariables>) {
        return Apollo.useMutation<UpdateMyAccountMutation, UpdateMyAccountMutationVariables>(UpdateMyAccountDocument, baseOptions);
      }
export type UpdateMyAccountMutationHookResult = ReturnType<typeof useUpdateMyAccountMutation>;
export type UpdateMyAccountMutationResult = Apollo.MutationResult<UpdateMyAccountMutation>;
export type UpdateMyAccountMutationOptions = Apollo.BaseMutationOptions<UpdateMyAccountMutation, UpdateMyAccountMutationVariables>;
export const UpdateUserPasswordDocument = gql`
    mutation UpdateUserPassword($email: String!, $newPassword: String!, $currentPassword: String!) {
  change_UserPassword(email: $email, newPassword: $newPassword, currentPassword: $currentPassword) {
    isSuccess
  }
}
    `;
export type UpdateUserPasswordMutationFn = Apollo.MutationFunction<UpdateUserPasswordMutation, UpdateUserPasswordMutationVariables>;

/**
 * __useUpdateUserPasswordMutation__
 *
 * To run a mutation, you first call `useUpdateUserPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserPasswordMutation, { data, loading, error }] = useUpdateUserPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *      newPassword: // value for 'newPassword'
 *      currentPassword: // value for 'currentPassword'
 *   },
 * });
 */
export function useUpdateUserPasswordMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserPasswordMutation, UpdateUserPasswordMutationVariables>) {
        return Apollo.useMutation<UpdateUserPasswordMutation, UpdateUserPasswordMutationVariables>(UpdateUserPasswordDocument, baseOptions);
      }
export type UpdateUserPasswordMutationHookResult = ReturnType<typeof useUpdateUserPasswordMutation>;
export type UpdateUserPasswordMutationResult = Apollo.MutationResult<UpdateUserPasswordMutation>;
export type UpdateUserPasswordMutationOptions = Apollo.BaseMutationOptions<UpdateUserPasswordMutation, UpdateUserPasswordMutationVariables>;
export const UpdateRecentlyViewedTeamDocument = gql`
    mutation UpdateRecentlyViewedTeam($id: uuid!, $recentlyViewedTeam: String!) {
  update_TeamMember(_set: {recentlyViewedTeam: $recentlyViewedTeam}, where: {id: {_eq: $id}}) {
    returning {
      id
    }
  }
}
    `;
export type UpdateRecentlyViewedTeamMutationFn = Apollo.MutationFunction<UpdateRecentlyViewedTeamMutation, UpdateRecentlyViewedTeamMutationVariables>;

/**
 * __useUpdateRecentlyViewedTeamMutation__
 *
 * To run a mutation, you first call `useUpdateRecentlyViewedTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRecentlyViewedTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRecentlyViewedTeamMutation, { data, loading, error }] = useUpdateRecentlyViewedTeamMutation({
 *   variables: {
 *      id: // value for 'id'
 *      recentlyViewedTeam: // value for 'recentlyViewedTeam'
 *   },
 * });
 */
export function useUpdateRecentlyViewedTeamMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRecentlyViewedTeamMutation, UpdateRecentlyViewedTeamMutationVariables>) {
        return Apollo.useMutation<UpdateRecentlyViewedTeamMutation, UpdateRecentlyViewedTeamMutationVariables>(UpdateRecentlyViewedTeamDocument, baseOptions);
      }
export type UpdateRecentlyViewedTeamMutationHookResult = ReturnType<typeof useUpdateRecentlyViewedTeamMutation>;
export type UpdateRecentlyViewedTeamMutationResult = Apollo.MutationResult<UpdateRecentlyViewedTeamMutation>;
export type UpdateRecentlyViewedTeamMutationOptions = Apollo.BaseMutationOptions<UpdateRecentlyViewedTeamMutation, UpdateRecentlyViewedTeamMutationVariables>;
export const UpdateSettingsDocument = gql`
    mutation UpdateSettings($id: uuid!, $requiredInteractions: numeric!, $requiredInteractionTimePeriod: String!) {
  update_Company(_set: {requiredInteractions: $requiredInteractions, requiredInteractionTimePeriod: $requiredInteractionTimePeriod}, where: {id: {_eq: $id}}) {
    returning {
      id
    }
  }
}
    `;
export type UpdateSettingsMutationFn = Apollo.MutationFunction<UpdateSettingsMutation, UpdateSettingsMutationVariables>;

/**
 * __useUpdateSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSettingsMutation, { data, loading, error }] = useUpdateSettingsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      requiredInteractions: // value for 'requiredInteractions'
 *      requiredInteractionTimePeriod: // value for 'requiredInteractionTimePeriod'
 *   },
 * });
 */
export function useUpdateSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSettingsMutation, UpdateSettingsMutationVariables>) {
        return Apollo.useMutation<UpdateSettingsMutation, UpdateSettingsMutationVariables>(UpdateSettingsDocument, baseOptions);
      }
export type UpdateSettingsMutationHookResult = ReturnType<typeof useUpdateSettingsMutation>;
export type UpdateSettingsMutationResult = Apollo.MutationResult<UpdateSettingsMutation>;
export type UpdateSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateSettingsMutation, UpdateSettingsMutationVariables>;
export const UpdateTeamMemberDocument = gql`
    mutation UpdateTeamMember($id: uuid!, $title: String!, $phone: String, $dateStarted: date!, $role: String) {
  update_TeamMember(_set: {title: $title, phone: $phone, dateStarted: $dateStarted, role: $role}, where: {id: {_eq: $id}}) {
    returning {
      id
    }
  }
}
    `;
export type UpdateTeamMemberMutationFn = Apollo.MutationFunction<UpdateTeamMemberMutation, UpdateTeamMemberMutationVariables>;

/**
 * __useUpdateTeamMemberMutation__
 *
 * To run a mutation, you first call `useUpdateTeamMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamMemberMutation, { data, loading, error }] = useUpdateTeamMemberMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      phone: // value for 'phone'
 *      dateStarted: // value for 'dateStarted'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useUpdateTeamMemberMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTeamMemberMutation, UpdateTeamMemberMutationVariables>) {
        return Apollo.useMutation<UpdateTeamMemberMutation, UpdateTeamMemberMutationVariables>(UpdateTeamMemberDocument, baseOptions);
      }
export type UpdateTeamMemberMutationHookResult = ReturnType<typeof useUpdateTeamMemberMutation>;
export type UpdateTeamMemberMutationResult = Apollo.MutationResult<UpdateTeamMemberMutation>;
export type UpdateTeamMemberMutationOptions = Apollo.BaseMutationOptions<UpdateTeamMemberMutation, UpdateTeamMemberMutationVariables>;
export const UpdateTeamMemberArchivedDocument = gql`
    mutation UpdateTeamMemberArchived($id: uuid!, $deletedAt: timestamptz) {
  update_TeamMember(_set: {deletedAt: $deletedAt}, where: {id: {_eq: $id}}) {
    returning {
      id
    }
  }
}
    `;
export type UpdateTeamMemberArchivedMutationFn = Apollo.MutationFunction<UpdateTeamMemberArchivedMutation, UpdateTeamMemberArchivedMutationVariables>;

/**
 * __useUpdateTeamMemberArchivedMutation__
 *
 * To run a mutation, you first call `useUpdateTeamMemberArchivedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamMemberArchivedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamMemberArchivedMutation, { data, loading, error }] = useUpdateTeamMemberArchivedMutation({
 *   variables: {
 *      id: // value for 'id'
 *      deletedAt: // value for 'deletedAt'
 *   },
 * });
 */
export function useUpdateTeamMemberArchivedMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTeamMemberArchivedMutation, UpdateTeamMemberArchivedMutationVariables>) {
        return Apollo.useMutation<UpdateTeamMemberArchivedMutation, UpdateTeamMemberArchivedMutationVariables>(UpdateTeamMemberArchivedDocument, baseOptions);
      }
export type UpdateTeamMemberArchivedMutationHookResult = ReturnType<typeof useUpdateTeamMemberArchivedMutation>;
export type UpdateTeamMemberArchivedMutationResult = Apollo.MutationResult<UpdateTeamMemberArchivedMutation>;
export type UpdateTeamMemberArchivedMutationOptions = Apollo.BaseMutationOptions<UpdateTeamMemberArchivedMutation, UpdateTeamMemberArchivedMutationVariables>;
export const UpdateTeamMemberIsFirstLoginDocument = gql`
    mutation UpdateTeamMemberIsFirstLogin($id: uuid!, $isFirstLogin: Boolean!) {
  update_TeamMember(_set: {isFirstLogin: $isFirstLogin}, where: {id: {_eq: $id}}) {
    returning {
      id
    }
  }
}
    `;
export type UpdateTeamMemberIsFirstLoginMutationFn = Apollo.MutationFunction<UpdateTeamMemberIsFirstLoginMutation, UpdateTeamMemberIsFirstLoginMutationVariables>;

/**
 * __useUpdateTeamMemberIsFirstLoginMutation__
 *
 * To run a mutation, you first call `useUpdateTeamMemberIsFirstLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamMemberIsFirstLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamMemberIsFirstLoginMutation, { data, loading, error }] = useUpdateTeamMemberIsFirstLoginMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isFirstLogin: // value for 'isFirstLogin'
 *   },
 * });
 */
export function useUpdateTeamMemberIsFirstLoginMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTeamMemberIsFirstLoginMutation, UpdateTeamMemberIsFirstLoginMutationVariables>) {
        return Apollo.useMutation<UpdateTeamMemberIsFirstLoginMutation, UpdateTeamMemberIsFirstLoginMutationVariables>(UpdateTeamMemberIsFirstLoginDocument, baseOptions);
      }
export type UpdateTeamMemberIsFirstLoginMutationHookResult = ReturnType<typeof useUpdateTeamMemberIsFirstLoginMutation>;
export type UpdateTeamMemberIsFirstLoginMutationResult = Apollo.MutationResult<UpdateTeamMemberIsFirstLoginMutation>;
export type UpdateTeamMemberIsFirstLoginMutationOptions = Apollo.BaseMutationOptions<UpdateTeamMemberIsFirstLoginMutation, UpdateTeamMemberIsFirstLoginMutationVariables>;
export const UpdateTeamMemberSimplifiedDocument = gql`
    mutation UpdateTeamMemberSimplified($id: uuid!, $title: String!, $phone: String, $dateStarted: date!) {
  update_TeamMember(_set: {title: $title, phone: $phone, dateStarted: $dateStarted}, where: {id: {_eq: $id}}) {
    returning {
      id
    }
  }
}
    `;
export type UpdateTeamMemberSimplifiedMutationFn = Apollo.MutationFunction<UpdateTeamMemberSimplifiedMutation, UpdateTeamMemberSimplifiedMutationVariables>;

/**
 * __useUpdateTeamMemberSimplifiedMutation__
 *
 * To run a mutation, you first call `useUpdateTeamMemberSimplifiedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamMemberSimplifiedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamMemberSimplifiedMutation, { data, loading, error }] = useUpdateTeamMemberSimplifiedMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      phone: // value for 'phone'
 *      dateStarted: // value for 'dateStarted'
 *   },
 * });
 */
export function useUpdateTeamMemberSimplifiedMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTeamMemberSimplifiedMutation, UpdateTeamMemberSimplifiedMutationVariables>) {
        return Apollo.useMutation<UpdateTeamMemberSimplifiedMutation, UpdateTeamMemberSimplifiedMutationVariables>(UpdateTeamMemberSimplifiedDocument, baseOptions);
      }
export type UpdateTeamMemberSimplifiedMutationHookResult = ReturnType<typeof useUpdateTeamMemberSimplifiedMutation>;
export type UpdateTeamMemberSimplifiedMutationResult = Apollo.MutationResult<UpdateTeamMemberSimplifiedMutation>;
export type UpdateTeamMemberSimplifiedMutationOptions = Apollo.BaseMutationOptions<UpdateTeamMemberSimplifiedMutation, UpdateTeamMemberSimplifiedMutationVariables>;
export const UpgradeSubscriptionDocument = gql`
    mutation UpgradeSubscription($billingEmail: String!, $billingToken: String!, $priceId: String!, $addressLine1: String!, $addressLine2: String, $city: String!, $companyName: String!, $postalCode: String!, $state: String!) {
  update_CompanyUpgradeSubscription(billingEmail: $billingEmail, billingToken: $billingToken, priceId: $priceId, addressLine1: $addressLine1, addressLine2: $addressLine2, city: $city, companyName: $companyName, postalCode: $postalCode, state: $state) {
    isSuccess
  }
}
    `;
export type UpgradeSubscriptionMutationFn = Apollo.MutationFunction<UpgradeSubscriptionMutation, UpgradeSubscriptionMutationVariables>;

/**
 * __useUpgradeSubscriptionMutation__
 *
 * To run a mutation, you first call `useUpgradeSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpgradeSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upgradeSubscriptionMutation, { data, loading, error }] = useUpgradeSubscriptionMutation({
 *   variables: {
 *      billingEmail: // value for 'billingEmail'
 *      billingToken: // value for 'billingToken'
 *      priceId: // value for 'priceId'
 *      addressLine1: // value for 'addressLine1'
 *      addressLine2: // value for 'addressLine2'
 *      city: // value for 'city'
 *      companyName: // value for 'companyName'
 *      postalCode: // value for 'postalCode'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useUpgradeSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<UpgradeSubscriptionMutation, UpgradeSubscriptionMutationVariables>) {
        return Apollo.useMutation<UpgradeSubscriptionMutation, UpgradeSubscriptionMutationVariables>(UpgradeSubscriptionDocument, baseOptions);
      }
export type UpgradeSubscriptionMutationHookResult = ReturnType<typeof useUpgradeSubscriptionMutation>;
export type UpgradeSubscriptionMutationResult = Apollo.MutationResult<UpgradeSubscriptionMutation>;
export type UpgradeSubscriptionMutationOptions = Apollo.BaseMutationOptions<UpgradeSubscriptionMutation, UpgradeSubscriptionMutationVariables>;