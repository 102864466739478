import React from 'react';
import Helmet from 'react-helmet';
import classes from 'classnames';
import css from './AuthPageFrame.module.scss';
import { Panel } from 'components/Panel/Panel';
import SvgCulch from 'assets/svg-components/Culch';

interface IAuthPageFrame {
	title: string;
	description?: string | React.FC;
	metaDescription: string;
	HTMLTitle: string;
	ContentTop?: React.FC;
	ContentBottom?: React.FC;
	className?: string;
}

export const AuthPageFrame: React.FC<IAuthPageFrame> = props => {
	const {
		className,
		children,
		title,
		description,
		metaDescription,
		HTMLTitle,
		ContentBottom,
		ContentTop
	} = props;

	const showErrorMessage = false;
	return (
		<>
			<Helmet>
				<title>{HTMLTitle} | Culch</title>
				<meta name='description' content={metaDescription} />
			</Helmet>
			<Panel className={classes(className, css.authPanel)}>
				<div className={css.logo}>
					<SvgCulch></SvgCulch>
				</div>
				<h1 className={classes('h4', css.title)}>{title}</h1>
				{showErrorMessage && <p className={css.errorMessage}>Invalid sign in, please try again.</p>}

				{ContentTop && <ContentTop />}
				{description &&
					(typeof description === 'string' ? (
						<p className={css.description}>{description}</p>
					) : (
						<div className={css.description}>{(description as React.FC)({})}</div>
					))}
				{children}
				{ContentBottom && (
					<div className={css.contentBottom}>
						<ContentBottom />
					</div>
				)}
			</Panel>
		</>
	);
};
