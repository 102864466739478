import * as React from 'react';

function SvgWhiteLabelLogo(props) {
	return (
		<svg
			id='Layer_1'
			x='0px'
			y='0px'
			viewBox='0 0 1728 237'
			style={{
				enableBackground: 'new 0 0 1728 237',
			}}
			xmlSpace='preserve'
			{...props}
		>
			<style type='text/css'>{'\n\t.st0{clip-path:url(#SVGID_2_);fill:#003764;}\n'}</style>
			<g>
				<defs>
					<rect id='SVGID_1_' x={0.4} y={0} width={1727.6} height={236.5} />
				</defs>
				<clipPath id='SVGID_2_'>
					<use
						xlinkHref='#SVGID_1_'
						style={{
							overflow: 'visible',
						}}
					/>
				</clipPath>
				<path
					className='st0'
					d='M227,8.5H5.8v41.4H73l0.7,184.3H124l-0.7-184.3h56C191,32.2,207.3,18,227,8.5'
				/>
				<path
					className='st0'
					d='M477.4,3.5c31.4,0,59.3,8.6,82.7,30.1l-30.5,36.1c-14.9-13.6-32.4-21.2-53.3-21.2c-40.7,0-64.5,30.8-64.5,69.2 c0,43,27.5,74.1,70.8,74.1c11.3,0,20.5-2.3,30.5-7.6v-39.1h-40.7l3.6-34.8h86.4v98c-26.5,18.5-54.3,25.8-86.7,25.8 c-69.8,0-118.5-45-118.5-114.9C357.2,50.5,408.6,3.5,477.4,3.5'
				/>
				<path
					className='st0'
					d='M349,119.4c0-3,0.1-6,0.3-8.9h-63l-3.6,34.8h40.7v39.1c-9.9,5.3-19.2,7.6-30.4,7.6 c-43.4,0-70.8-31.1-70.8-74.1c0-38.4,23.8-69.2,64.5-69.2c20.9,0,38.4,7.6,53.3,21.2l30.5-36.1C346.8,12.1,319,3.5,287.6,3.5 c-68.8,0-120.1,47-120.1,115.8c0,69.8,48.7,114.8,118.5,114.8c32.4,0,60.2-7.3,86.7-25.8v-13C357.5,175.2,349,149.3,349,119.4'
				/>
				<path
					className='st0'
					d='M680.2,234.2c-31.8,0-52.5-17.3-59-21.9l4.9-18.8c4.6,4.3,25.3,24.7,53.8,24.7c25.9,0,44.8-14.8,44.8-42 c0-54.1-91.7-37.7-91.7-101.9c0-33.1,26.9-54.1,58.4-54.1c25.7,0,43.6,13.9,48.2,17.6l-7.1,15.8c-4-3.1-18.5-17-42.3-17 C668.8,36.5,653,51,653,73.3c0,50,92.4,35.2,92.4,101.6C745.4,212.6,716,234.2,680.2,234.2'
				/>
				<path
					className='st0'
					d='M839.9,234.2c-42.3,0-69.8-31.5-69.8-73.2c0-40.8,27.2-73.2,69.5-73.2c43.6,0,68.6,32.4,68.6,73.5 C908.2,201.2,881.3,234.2,839.9,234.2 M838.4,102.3c-31.8,0-49.7,26.3-49.7,56.2c0,31.8,18.8,61.2,52.5,61.2 c31.2,0,48.5-27.5,48.5-58.1C889.6,130.4,872.6,102.3,838.4,102.3'
				/>
				<rect x={943.4} y={11.8} className='st0' width={17.9} height={218.4} />
				<path
					className='st0'
					d='M1132.8,232.7c-1.6,0.3-6.5,1.2-11.4,1.2c-13.3,0-21.3-6.5-22.9-19.8c-13,11.4-25.3,20.1-45.1,20.1 c-31.5,0-48.2-17-48.2-49.4V91.5h18.2v89.9c0,22.9,9.9,37.4,34,37.4c17.6,0,27.2-7.4,39.8-18.5V91.5h17.9v107.8 c0,13,0.6,21.9,12.7,21.9c4,0,7.4-0.9,7.4-0.9L1132.8,232.7z'
				/>
				<path
					className='st0'
					d='M1206,233.6c-25.6,0-34.9-13-34.9-39.5v-88.7h-29.7V94l11.7-0.6c17.6-0.9,22.3-4.3,24.4-20.1l1.6-22.2h9.9 v40.5h38.9v13.9H1189v81.9c0,18.8,0.9,31.5,22.2,31.5c9.3,0,19.2-3.1,25-5.6l2.5,13.3C1227.6,231.1,1218.3,233.6,1206,233.6'
				/>
				<path
					className='st0'
					d='M1272.1,54.4c-7.7,0-12.7-4.9-12.7-12.4s5.2-12.7,12.7-12.7c7.4,0,12.4,5.6,12.4,12.7 C1284.4,49.5,1279.5,54.4,1272.1,54.4 M1262.5,91.5h17.9v138.7h-17.9V91.5z'
				/>
				<path
					className='st0'
					d='M1385.5,234.2c-42.3,0-69.8-31.5-69.8-73.2c0-40.8,27.2-73.2,69.5-73.2c43.6,0,68.6,32.4,68.6,73.5 C1453.7,201.2,1426.9,234.2,1385.5,234.2 M1383.9,102.3c-31.8,0-49.7,26.3-49.7,56.2c0,31.8,18.8,61.2,52.5,61.2 c31.2,0,48.5-27.5,48.5-58.1C1435.2,130.4,1418.2,102.3,1383.9,102.3'
				/>
				<path
					className='st0'
					d='M1585.6,230.2v-85.9c0-24.4-9-41.1-35.2-41.1c-17.9,0-29.6,9-41.4,21.6v105.3h-17.9v-91.4 c0-15.1,0.6-32.4-9.3-44.8l15.4-6.5c7.1,5.9,9.9,15.1,10.5,23.8c13.3-14.5,27.5-23.5,47.6-23.5c18.2,0,32.1,7.4,40.2,19.5 c5.3,8,8,18.5,8,30v93H1585.6z'
				/>
				<path
					className='st0'
					d='M1676.2,234.2c-16.7,0-32.7-6.2-44.8-17.9l7.7-13c9.6,9.9,23.2,16.4,37.1,16.4c17,0,28.4-9,28.4-25.6 c0-34.3-63.7-23.5-63.7-68.3c0-23.2,18.5-38,42.7-38c13.6,0,25.6,4.9,36.1,12l-5.9,13.9c-9.6-6.8-18.5-11.7-30.3-11.7 c-15.8,0-24.7,9.3-24.7,21.9c0,33.4,64.6,23.2,64.6,68.9C1723.4,219.4,1700.6,234.2,1676.2,234.2'
				/>
			</g>
		</svg>
	);
}

export default SvgWhiteLabelLogo;
