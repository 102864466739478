import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { PrivateRoute } from './PrivateRoute';
import * as Views from 'views';
import { LoadingDelay } from 'components/LoadingDelay/LoadingDelay';
import { Spinner } from 'components/Spinner';
import { AppFrame } from 'components/AppFrame';
import { AuthFrame } from 'components/AuthFrame';
import { AuthProvider } from 'components/Auth';
import { PublicRoute } from './PublicRoute';
import { SubscribedRoute } from './SubscribedRoute';
import { AdminRoute } from './AdminRoute';

// Wrapper component for component lazy loading
const RouteSuspense: React.FC = props => (
	<Suspense
		fallback={
			<LoadingDelay>
				<Spinner type='darker' size='large' isCenteredHorizontally isCenteredVertically></Spinner>
			</LoadingDelay>
		}
	>
		{props.children}
	</Suspense>
);

// Definition for all the routes for the whole app
export const Routes = (
	<AuthProvider>
		<Switch>
			<PublicRoute
				exact
				path={[
					'/signin',
					'/forgotpassword',
					'/resetpassword/:changePasswordId',
					'/accountsetup/:changePasswordId',
					'/confirm',
					'/signup'
				]}
			>
				<AuthFrame>
					<Switch>
						<Route exact path='/signin'>
							<Views.LoginView />
						</Route>
						<Route exact path='/forgotpassword'>
							<RouteSuspense>
								<Views.ForgotPasswordView />
							</RouteSuspense>
						</Route>
						<Route exact path='/resetpassword/:changePasswordId'>
							<RouteSuspense>
								<Views.ResetPasswordView />
							</RouteSuspense>
						</Route>
						<Route exact path='/accountsetup/:changePasswordId'>
							<RouteSuspense>
								<Views.AccountSetupView />
							</RouteSuspense>
						</Route>
						<Route exact path='/confirm'>
							<RouteSuspense>
								<Views.ConfirmEmailView />
							</RouteSuspense>
						</Route>
						<Route exact path='/signup'>
							<RouteSuspense>
								<Views.SignUpView />
							</RouteSuspense>
						</Route>
					</Switch>
				</AuthFrame>
			</PublicRoute>
			<PrivateRoute path='/'>
				<RouteSuspense>
					<Switch>
						<Route exact path='/trialEnded'>
							<Views.TrialEndedView />
						</Route>
						<Route exact path='/completeAccount'>
							<AuthFrame>
								<Views.CompleteYourAccountView />
							</AuthFrame>
						</Route>
						<Route path='/'>
							<AppFrame>
								<Switch>
									<Route exact path='/admin/companyDetails'>
										<Views.CompanyDetailsView />
									</Route>
									<Route exact path='/gettingStarted'>
										<Views.GettingStartedView />
									</Route>
									<Route exact path='/admin/plan/purchase'>
										<Views.PlanPurchaseView />
									</Route>
									<Route exact path='/admin/plan/history'>
										<Views.PlanHistoryView />
									</Route>
									<Route exact path='/admin/plan'>
										<Views.PlanOverviewView />
									</Route>
									<Route exact path='/admin/plan/billing'>
										<Views.PlanBillingView />
									</Route>
									<SubscribedRoute path='/'>
										<Switch>
											<Route exact path='/'>
												<Views.HomeView />
											</Route>
											<Route exact path='/dashboard'>
												<Views.HomeView />
											</Route>
											<Route exact path='/team/:teamMemberId'>
												<Views.TeamMemberView />
											</Route>
											<Route exact path='/team'>
												<Views.TeamView />
											</Route>
											<Route exact path='/feedback'>
												<Views.FeedbackView />
											</Route>
											<Route exact path='/feedbackComplete/:feedbackSessionId'>
												<Views.FeedbackCompleteView />
											</Route>
											<Route exact path='/feedbackHistory/:feedbackSessionId'>
												<Views.FeedbackSessionHistory />
											</Route>
											<Route exact path='/infraction'>
												<Views.InfractionView />
											</Route>
											<Route exact path='/infractionReview/:infractionId'>
												<Views.InfractionReviewView />
											</Route>
											<Route exact path='/infractionComplete/:infractionId'>
												<Views.InfractionCompleteView />
											</Route>
											<Route exact path='/infractionHistory/:infractionId'>
												<Views.InfractionHistoryView />
											</Route>
											<Route exact path='/review/:feedbackSessionId'>
												<Views.ReviewView />
											</Route>
											<Route exact path='/feedbackEdit/:feedbackItemId'>
												<Views.FeedbackEditView />
											</Route>
											<Route exact path='/accountChangeDetails/:titleChangeId'>
												<Views.AccountChangeDetails />
											</Route>
											<Route exact path='/admin/frameworks/:frameworkId'>
												<Views.AdminCategoriesView />
											</Route>
											<Route exact path='/admin/frameworks'>
												<Views.AdminFrameworksView />
											</Route>
											<Route exact path='/admin/addCategory/:parentId'>
												<Views.AdminAddCategoryView />
											</Route>
											<Route exact path='/admin/addCategory'>
												<Views.AdminAddCategoryView />
											</Route>
											<Route exact path='/admin/editCategory/:categoryId'>
												<Views.AdminEditCategoryView />
											</Route>
											<Route exact path='/myAccount'>
												<Views.MyAccountView />
											</Route>
											<AdminRoute path='/'>
												<Switch>
													<Route exact path='/admin/addUser'>
														<Views.AdminAddUserView />
													</Route>
													<Route exact path='/admin/editUser/:teamMemberId'>
														<Views.AdminEditUserView />
													</Route>
													<Route exact path='/admin/users'>
														<Views.AdminUsersView />
													</Route>
													<Route exact path='/admin/settings'>
														<Views.AdminGeneralSettingsView />
													</Route>
													<Route exact path='/admin/archivedUsers'>
														<Views.AdminArchivedUsersView />
													</Route>
												</Switch>
											</AdminRoute>
										</Switch>
									</SubscribedRoute>
								</Switch>
							</AppFrame>
						</Route>
					</Switch>
				</RouteSuspense>
			</PrivateRoute>
		</Switch>
	</AuthProvider>
);
