import * as React from 'react';

function SvgChartBar(props) {
	return (
		<svg enableBackground='new 0 0 24 24' viewBox='0 0 24 24' {...props}>
			<g
				style={{
					fill: 'none',
					stroke: '#000',
					strokeWidth: 1,
					strokeLinecap: 'round',
					strokeLinejoin: 'round',
					strokeMiterlimit: 10,
				}}
			>
				<path d='m4 23c-1.105 0-2-.895-2-2v-8c0-1.105.895-2 2-2 1.105 0 2 .895 2 2v8c0 1.105-.895 2-2 2z' />
				<path d='m20 23c-1.105 0-2-.895-2-2v-11c0-1.105.895-2 2-2 1.105 0 2 .895 2 2v11c0 1.105-.895 2-2 2z' />
				<path d='m12 23c-1.105 0-2-.895-2-2v-18c0-1.105.895-2 2-2 1.105 0 2 .895 2 2v18c0 1.105-.895 2-2 2z' />
			</g>
		</svg>
	);
}

export default SvgChartBar;
