import React from 'react';
import css from './GridItem.module.scss';
import classes from 'classnames';

interface IGridItemProps {
	action?: (() => void) | string;
	hasBorder?: boolean;
	className?: string;
	style?: React.CSSProperties;
}

export const GridItem: React.FC<IGridItemProps> = props => {
	const { action, hasBorder, className, children, style } = props;

	const gridItemClasses = classes(css.gridItem, { [css.border]: hasBorder }, className);

	switch (typeof action) {
		case 'function':
			return (
				<button className={gridItemClasses} onClick={action} style={style}>
					{children}
				</button>
			);
		case 'string':
			return (
				<a className={gridItemClasses} href={action} style={style}>
					{children}
				</a>
			);
		default:
			return (
				<div className={gridItemClasses} style={style}>
					{children}
				</div>
			);
	}
};
