export interface BodyScrollOptions {
	reserveScrollBarGap?: boolean;
	allowTouchMove?: (el: any) => boolean;
}

interface Lock {
	targetElement: any;
	options: BodyScrollOptions;
}

// Older browsers don't support event options, feature detect it.
let hasPassiveEvents = false;
if (typeof window !== 'undefined') {
	const passiveTestOptions = {
		get passive(): any {
			hasPassiveEvents = true;
			return undefined;
		}
	};
	window.addEventListener('testPassive', null, passiveTestOptions);
	window.removeEventListener('testPassive', null, passiveTestOptions as EventListenerOptions);
}

const isIosDevice =
	typeof window !== 'undefined' &&
	window.navigator &&
	window.navigator.platform &&
	/iP(ad|hone|od)/.test(window.navigator.platform);
type HandleScrollEvent = TouchEvent;

let locks: Array<Lock> = [];
let documentListenerAdded: boolean = false;
let initialClientY: number = -1;
let previousBodyOverflowSetting: any;
let previousBodyPaddingRight: any;

// returns true if `el` should be allowed to receive touchmove events.
const allowTouchMove = (el: EventTarget): boolean =>
	locks.some(lock => {
		if (lock.options.allowTouchMove && lock.options.allowTouchMove(el)) {
			return true;
		}

		return false;
	});

const preventDefault = (rawEvent: HandleScrollEvent): boolean => {
	const e = rawEvent || window.event;

	// For the case whereby consumers adds a touchmove event listener to document.
	// Recall that we do document.addEventListener('touchmove', preventDefault, { passive: false })
	// in disableBodyScroll - so if we provide this opportunity to allowTouchMove, then
	// the touchmove event on document will break.
	if (allowTouchMove(e.target)) {
		return true;
	}

	// Do not prevent if the event has more than one touch (usually meaning this is a multi touch gesture like pinch to zoom).
	if ((e as any).touches.length > 1) return true;

	if (e.preventDefault) e.preventDefault();

	return false;
};

const setOverflowHidden = (options?: BodyScrollOptions) => {
	// Setting overflow on body/documentElement synchronously in Desktop Safari slows down
	// the responsiveness for some reason. Setting within a setTimeout fixes this.
	setTimeout(() => {
		// If previousBodyPaddingRight is already set, don't set it again.
		if (previousBodyPaddingRight === undefined) {
			const reserveScrollBarGap = !!options && options.reserveScrollBarGap === true;
			const scrollBarGap = window.innerWidth - document.documentElement.clientWidth;

			if (reserveScrollBarGap && scrollBarGap > 0) {
				previousBodyPaddingRight = document.body.style.paddingRight;
				document.body.style.paddingRight = `${scrollBarGap}px`;
			}
		}

		// If previousBodyOverflowSetting is already set, don't set it again.
		if (previousBodyOverflowSetting === undefined) {
			previousBodyOverflowSetting = document.body.style.overflow;
			document.body.style.overflow = 'hidden';
		}
	});
};

const restoreOverflowSetting = () => {
	// Setting overflow on body/documentElement synchronously in Desktop Safari slows down
	// the responsiveness for some reason. Setting within a setTimeout fixes this.
	setTimeout(() => {
		if (previousBodyPaddingRight !== undefined) {
			document.body.style.paddingRight = previousBodyPaddingRight;

			// Restore previousBodyPaddingRight to undefined so setOverflowHidden knows it
			// can be set again.
			previousBodyPaddingRight = undefined;
		}

		if (previousBodyOverflowSetting !== undefined) {
			document.body.style.overflow = previousBodyOverflowSetting;

			// Restore previousBodyOverflowSetting to undefined
			// so setOverflowHidden knows it can be set again.
			previousBodyOverflowSetting = undefined;
		}
	});
};

// https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight#Problems_and_solutions
const isTargetElementTotallyScrolled = (targetElement: any): boolean =>
	targetElement
		? targetElement.scrollHeight - targetElement.scrollTop <= targetElement.clientHeight
		: false;

const handleScroll = (event: HandleScrollEvent, targetElement: any): boolean => {
	const clientY = event.targetTouches[0].clientY - initialClientY;

	if (allowTouchMove(event.target)) {
		return false;
	}

	if (targetElement && targetElement.scrollTop === 0 && clientY > 0) {
		// element is at the top of its scroll.
		return preventDefault(event);
	}

	if (isTargetElementTotallyScrolled(targetElement) && clientY < 0) {
		// element is at the top of its scroll.
		return preventDefault(event);
	}

	event.stopPropagation();
	return true;
};

export const disableBodyScroll = (targetElement: any, options?: BodyScrollOptions): void => {
	if (isIosDevice) {
		// targetElement must be provided, and disableBodyScroll must not have been
		// called on this targetElement before.
		if (!targetElement) {
			// eslint-disable-next-line no-console
			console.error(
				'disableBodyScroll unsuccessful - targetElement must be provided when calling disableBodyScroll on IOS devices.'
			);
			return;
		}

		if (targetElement && !locks.some(lock => lock.targetElement === targetElement)) {
			const lock = {
				targetElement,
				options: options || {}
			};

			locks = [...locks, lock];

			targetElement.ontouchstart = (event: HandleScrollEvent) => {
				if (event.targetTouches.length === 1) {
					// detect single touch.
					initialClientY = event.targetTouches[0].clientY;
				}
			};
			targetElement.ontouchmove = (event: HandleScrollEvent) => {
				if (event.targetTouches.length === 1) {
					// detect single touch.
					handleScroll(event, targetElement);
				}
			};

			if (!documentListenerAdded) {
				document.addEventListener(
					'touchmove',
					preventDefault,
					hasPassiveEvents ? { passive: false } : undefined
				);
				documentListenerAdded = true;
			}
		}
	} else {
		setOverflowHidden(options);
		const lock = {
			targetElement,
			options: options || {}
		};

		locks = [...locks, lock];
	}
};

export const clearAllBodyScrollLocks = (): void => {
	if (isIosDevice) {
		// Clear all locks ontouchstart/ontouchmove handlers, and the references.
		locks.forEach((lock: Lock) => {
			lock.targetElement.ontouchstart = null;
			lock.targetElement.ontouchmove = null;
		});

		if (documentListenerAdded) {
			document.removeEventListener(
				'touchmove',
				preventDefault as any,
				hasPassiveEvents ? ({ passive: false } as any) : undefined
			);
			documentListenerAdded = false;
		}

		locks = [];

		// Reset initial clientY.
		initialClientY = -1;
	} else {
		restoreOverflowSetting();
		locks = [];
	}
};

export const enableBodyScroll = (targetElement: any): void => {
	if (isIosDevice) {
		if (!targetElement) {
			// eslint-disable-next-line no-console
			console.error(
				'enableBodyScroll unsuccessful - targetElement must be provided when calling enableBodyScroll on IOS devices.'
			);
			return;
		}

		targetElement.ontouchstart = null;
		targetElement.ontouchmove = null;

		locks = locks.filter(lock => lock.targetElement !== targetElement);

		if (documentListenerAdded && locks.length === 0) {
			document.removeEventListener(
				'touchmove',
				preventDefault as any,
				hasPassiveEvents ? ({ passive: false } as any) : undefined
			);

			documentListenerAdded = false;
		}
	} else {
		locks = locks.filter(lock => lock.targetElement !== targetElement);
		if (!locks.length) {
			restoreOverflowSetting();
		}
	}
};
