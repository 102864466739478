import * as React from 'react';

function SvgUsers(props) {
	return (
		<svg enableBackground='new 0 0 24 24' viewBox='0 0 24 24' {...props}>
			<g
				style={{
					fill: 'none',
					stroke: '#000',
					strokeWidth: 1,
					strokeLinecap: 'round',
					strokeLinejoin: 'round',
					strokeMiterlimit: 10,
				}}
			>
				<path d='m23 22v-1.625c0-2.687-2.583-4.913-5.942-5.307' />
				<path d='m15 22v-1.625c0-2.964-3.14-5.375-7-5.375s-7 2.411-7 5.375v1.625' />
				<path d='m15.245 10.923c.246.042.496.077.755.077 2.481 0 4.5-2.019 4.5-4.5s-2.019-4.5-4.5-4.5c-.259 0-.509.034-.756.077' />
				<circle cx={8} cy={6.5} r={4.5} />
			</g>
		</svg>
	);
}

export default SvgUsers;
