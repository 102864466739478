import { Epic, ofType } from 'redux-observable';
import * as exampleStoreActions from './exampleStoreActions';
import { ActionType } from 'typesafe-actions';
import { from, of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { getPost } from './exampleStoreApi';
import { fromFetch } from 'rxjs/fetch';
import { ApplicationState } from 'stores/stores';

type actionInterfaces = ActionType<typeof exampleStoreActions>;

// Epic: Function that takes action$ ( stream of actions ($: stream) ) & returns a stream of
export const fetchPost: Epic<actionInterfaces, actionInterfaces, ApplicationState> = (action$, store$) =>
	action$.pipe(
		ofType('GET_POST_REQUEST'),
		// swtichMap: unsubscribes from the previous observable and subscribes to the new observable
		// switchMap: Provides concurrent getRequests, always the latest data.
		switchMap(() =>
			// Pipe: Composes pipeable operators
			fromFetch(getPost(store$.value.exampleStore.integer)).pipe(
				switchMap(response => {
					if (response.ok) {
						// from: will accept an arg that is a subscribable object, a Promise,
						// an Observable - like, an Array, an iterable or an array - like object to be converted
						return from(response.json());
					} else {
						throw new Error('response not ok');
					}
				}),
				// map: transforms data by applying a function and returns the transformed data
				// map(json => exampleStoreActions.getPostSuccess(json))
				// map() below is using currying. map() above is equivalent
				map(exampleStoreActions.getPostSuccess),
				// of: Always accepts only values and performs no conversion
				catchError(error => of(exampleStoreActions.getPostError(error)))
			)
		)
	);
