import * as React from 'react';

function SvgHeadset(props) {
	return (
		<svg width={71} height={77} viewBox='0 0 71 77' fill='none' {...props}>
			<path
				d='M38.3018 76.4583H31.669C28.4152 76.4583 25.7246 73.8349 25.7246 70.6625C25.7246 67.4901 28.4152 64.8667 31.669 64.8667H38.3018C41.5556 64.8667 44.2462 67.4901 44.2462 70.6625C44.2462 73.8959 41.5556 76.4583 38.3018 76.4583Z'
				fill='#C63414'
			/>
			<path
				d='M6.17383 31.1458C7.21153 11.2188 25.1577 -2.91685 42.8597 2.99896C54.2744 6.79753 62.9423 17.8819 63.7969 30.8344'
				stroke='#E94D2A'
				strokeWidth={3}
				strokeMiterlimit={10}
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M44.2461 70.1354C44.2461 70.1354 64.154 68.6478 64.8258 51.1675'
				stroke='#E94D2A'
				strokeWidth={3}
				strokeMiterlimit={10}
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M12.3477 33.3195V50.1729C12.3477 50.1729 1.44526 54.9168 0.155714 43.6812C0.155714 43.6812 -2.30614 24.8928 12.3477 33.3195Z'
				fill='#C63414'
			/>
			<path
				d='M57.624 33.3195V50.1729C57.624 50.1729 69.4352 54.9168 70.8322 43.6812C70.8322 43.6812 73.4992 24.8928 57.624 33.3195Z'
				fill='#C63414'
			/>
		</svg>
	);
}

export default SvgHeadset;
