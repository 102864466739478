import React, { useState, useContext, useEffect, useRef } from 'react';
import css from './AppFrame.module.scss';
import classes from 'classnames';
import { Header } from 'components/AppFrame/Header';
import { Sidebar } from './Sidebar/Sidebar';
import { TrayNavigation } from 'components/TrayNavigation/TrayNavigation';
import { disableBodyScroll, enableBodyScroll } from 'common/utils/bodyScrollLock';
import { useCurrentUser } from 'common/hooks/useCurrentUser';

interface IAppFrameProps {
	className?: string;
}
const AppFrameContext = React.createContext(null);

export const AppFrame: React.FC<IAppFrameProps> = props => {
	const { children, className } = props;
	const [isSidebarShown, setIsSidebarShown] = useState(false);
	const [backLink, setBackLink] = useState(null);
	const headerRef = useRef();

	useEffect(() => {
		if (isSidebarShown) {
			disableBodyScroll(headerRef.current);
		} else {
			enableBodyScroll(headerRef.current);
		}
	}, [isSidebarShown, headerRef]);

	const { currentUser } = useCurrentUser();
	return (
		<>
			<AppFrameContext.Provider value={{ setBackLink: setBackLink, backLink: backLink }}>
				<div className={classes(css.appframe, className)}>
					<a href='#content' id='skipNavLink' className={css.skipNavigation}>
						Skip Navigation
					</a>
					<Sidebar isSidebarShown={isSidebarShown} setIsSidebarShown={setIsSidebarShown}></Sidebar>

					<div className={css.main}>
						<Header
							ref={headerRef}
							isSidebarShown={isSidebarShown}
							setIsSidebarShown={setIsSidebarShown}
						></Header>
						<div id='content'>{children}</div>
						{(currentUser.role !== 'CompanyAdmin' || currentUser.DirectReports.length > 0) && (
							<TrayNavigation></TrayNavigation>
						)}
					</div>
				</div>
			</AppFrameContext.Provider>
		</>
	);
};

const useAppFrameContext = () => {
	const appFrameContextProps: {
		setBackLink: any;
		backLink: any;
	} = useContext(AppFrameContext);

	return appFrameContextProps;
};
export { AppFrameContext, useAppFrameContext };
