import React from 'react';
import css from './InputFrame.module.scss';
import classes from 'classnames';
import { InputMessage } from '../InputMessage/InputMessage';
import { FormikProps } from 'formik';
import { getProcessedFormikProps } from 'common/utils/getProcessedFromikProps';

export interface IInputFrame {
	inputId: string;
	isLabelVisible?: boolean;
	label: string;
	messageId: string;
	inputMessage?: string;
	formikProps?: FormikProps<any>;
	name: string;
	errorMessage?: string;
	className?: string;
	labelClassName?: string;
	isCheckboxGroup?: boolean;
	isRolePresentation?: boolean;
}

export const InputFrame: React.FC<IInputFrame> = props => {
	const {
		children,
		inputId,
		isLabelVisible = true,
		label,
		messageId,
		inputMessage,
		formikProps,
		name,
		errorMessage,
		className,
		labelClassName,
		isCheckboxGroup,
		isRolePresentation = true
	} = props;

	const { isError, displayedMessage } = getProcessedFormikProps(
		formikProps,
		name,
		errorMessage,
		inputMessage
	);

	const labelClasses = classes(
		css.inputLabel,
		labelClassName,
		{ [css.labelError]: isError },
		{ screenReaderText: !isLabelVisible }
	);

	const fieldsetProps = isRolePresentation ? { role: 'presentation' } : {};

	return (
		<div className={classes(css.fieldset, className)} {...fieldsetProps}>
			{label && (
				<>
					{isCheckboxGroup ? (
						<legend className={labelClasses}>{label}</legend>
					) : (
						<label htmlFor={inputId} className={labelClasses}>
							{label}
						</label>
					)}
				</>
			)}

			{children}
			{displayedMessage && (
				<InputMessage isError={isError} id={messageId}>
					{displayedMessage}
				</InputMessage>
			)}
		</div>
	);
};
