import * as React from 'react';

function SvgTime(props) {
	return (
		<svg enableBackground='new 0 0 24 24' viewBox='0 0 24 24' {...props}>
			<g
				style={{
					fill: 'none',
					stroke: '#000',
					strokeWidth: 1,
					strokeLinecap: 'round',
					strokeLinejoin: 'round',
					strokeMiterlimit: 10,
				}}
			>
				<circle cx={12} cy={12} r={11} />
				<path d='m8 13h4v-6' />
			</g>
		</svg>
	);
}

export default SvgTime;
