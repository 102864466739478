import React, { useState, useEffect } from 'react';
import css from './PanelTable.module.scss';
import classes from 'classnames';

import { Link, ILinkProps } from 'components/Link';
import { Icon } from 'components/Icon/Icon';
import { getUniqueId } from 'common/utils/getUniqueId';
import { debounce } from 'common/utils/debounce';
import { TSortDirections } from 'common/types/TSortDirections';
import { RemoveProp } from 'common/types/TypeHelpers';
import { TPanelTableData } from 'common/types/TPanelTableData';
import { TableMetaContent } from './TableMetaContent/TableMetaContent';

export type TTableLink = RemoveProp<RemoveProp<ILinkProps, 'type'>, 'size'> & { label: string };

interface IPanelTableProps {
	className?: string;
	tableHeadClassname?: string;
	tableMetaTitleClass?: string;
	tableCellClassname?: string;
	headers: TPanelTableHeader[];
	showHeaders?: boolean;
	data: TPanelTableData;
	contentTop?: React.ReactNode;
	title?: string;
	numberOfActions?: number;
	link?: TTableLink;
	contentBottom?: React.ReactNode;
	onClick?: TOnClick;
	activeSort?: TActiveSort;
	contentLeftClassname?: string;
	description?: string;
	noPadding?: boolean;
}

export type TActiveSort = {
	columnIndex: number;
	sortDirection: TSortDirections;
};

export type TOnClick = (data: { rowIndex: number; columnIndex: number; value: string }) => void;

export type TPanelTableHeader = {
	HeaderRenderFunction?: React.ComponentType;
	label: string;
	property: string;
	isSortable?: boolean;
	RenderFunction?: React.ComponentType;
};

const setToEqualHeight = (firstId: string, secondId: string) => {
	const firstElement = document.getElementById(firstId);
	const secondElement = document.getElementById(secondId);

	firstElement.style.height = '';
	secondElement.style.height = '';

	const columnOneHeight = firstElement.offsetHeight;
	const columnTwoHeight = secondElement.offsetHeight;

	const largestHeight = Math.max(columnOneHeight, columnTwoHeight);
	firstElement.style.height = largestHeight.toString() + 'px';
	secondElement.style.height = largestHeight.toString() + 'px';
};

export const PanelTable: React.FC<IPanelTableProps> = props => {
	const {
		className,
		tableHeadClassname,
		tableMetaTitleClass,
		tableCellClassname,
		data,
		headers,
		contentTop,
		title,
		numberOfActions,
		link,
		contentBottom,
		onClick,
		activeSort,
		showHeaders = true,
		contentLeftClassname,
		description,
		noPadding = false
	} = props;
	const [uniqueId] = useState(getUniqueId());

	useEffect(() => {
		const handleResize = () => {
			const totalTableRows = showHeaders ? data.length + 1 : data.length; //Add one for the table header
			for (let rowIndex = showHeaders ? 0 : 1; rowIndex < totalTableRows; rowIndex++) {
				const columnOneTDId = uniqueId + rowIndex + 0;
				const columnTwoTDId = uniqueId + rowIndex + 1;
				setToEqualHeight(columnOneTDId, columnTwoTDId);
			}
		};

		handleResize();

		const debouncedHandleResize = debounce(handleResize, 200, false);

		window.addEventListener('resize', debouncedHandleResize);
		return () => {
			window.removeEventListener('resize', debouncedHandleResize);
		};
	}, [data, uniqueId, showHeaders]);

	return (
		<div className={classes(css.tableWrapper, className, noPadding && css.noPadding)}>
			{(title || contentTop) && (
				<TableMetaContent
					contentLeftClassname={contentLeftClassname}
					contentLeft={
						title && (
							<h3 className={classes(css.title, tableMetaTitleClass)}>
								{title}{' '}
								{numberOfActions != null && (
									<span className={css.numberOfActions}>({numberOfActions})</span>
								)}{' '}
							</h3>
						)
					}
					//, tableMetaContentClass)
					contentRight={contentTop}
					className={css.header}
				></TableMetaContent>
			)}
			{description && <p className={css.description}>{description}</p>}
			<div className={css.table}>
				{showHeaders && (
					<div className={css.tableHead}>
						<div className={css.row}>
							{headers.map((header, columnIndex) => (
								<div
									className={classes(css.headerCell, css[header.property])}
									key={'0' + columnIndex}
									id={uniqueId + 0 + columnIndex}
								>
									{header.isSortable ? (
										<button
											className={css.headerItemInner}
											onClick={() => onClick({ rowIndex: 0, columnIndex, value: header.label })}
										>
											<div
												className={classes(css.headerLabel, css[header.property], tableHeadClassname)}
											>
												{header.label}
											</div>
											{activeSort?.columnIndex === columnIndex ? (
												<Icon
													size='smallest'
													className={css.sortIcon}
													type={
														activeSort.sortDirection === 'desc' ? 'CaretDownFilled' : 'CaretUpFilled'
													}
													color='blue'
												></Icon>
											) : (
												<Icon
													size='smallest'
													className={css.sortIcon}
													type='CaretDown'
													color='blue'
												></Icon>
											)}
										</button>
									) : (
										<div className={css.headerItemInner}>
											<div
												className={classes(css.headerLabel, css[header.property], tableHeadClassname)}
											>
												{header.HeaderRenderFunction ? (
													<header.HeaderRenderFunction {...(header as any)} />
												) : (
													header.label
												)}
											</div>
										</div>
									)}
								</div>
							))}
						</div>
					</div>
				)}
				<div className={css.tableBody}>
					{data.map((row, rowIndex) => (
						<React.Fragment key={row.id}>
							<div className={classes(css.row, css['border-' + row.borderColor])}>
								{headers.map((header, columnIndex) => {
									const { RenderFunction } = header;
									return (
										<div
											className={classes(css.cell, css[header.property], tableCellClassname)}
											key={`${columnIndex}${row.id}`}
											id={uniqueId + (rowIndex + 1) + columnIndex}
										>
											{RenderFunction ? <RenderFunction {...(row as any)} /> : row[header.property]}
										</div>
									);
								})}
							</div>
							<div className={css.rowSpacer}></div>
						</React.Fragment>
					))}
				</div>
			</div>
			{contentBottom && (
				<TableMetaContent contentRight={contentBottom} className={css.footer}>
					{contentBottom}
				</TableMetaContent>
			)}
			{link && (
				<Link {...link} className={css.link} type='primary'>
					{link.label}
				</Link>
			)}
		</div>
	);
};
