import React from 'react';
import classes from 'classnames';
import css from './Spinner.module.scss';
import { Icon, TIconSize } from 'components/Icon/Icon';

interface ISpinnerProps {
	size: TIconSize;
	ariaHidden?: boolean;
	className?: string;
	type: 'darker' | 'dark' | 'light' | 'lighter';
	isCenteredHorizontally?: boolean;
	isCenteredVertically?: boolean;
}

export const Spinner: React.FC<ISpinnerProps> = props => {
	const { size, ariaHidden, className, type, isCenteredHorizontally, isCenteredVertically } = props;
	return (
		<Icon
			type='SpinnerThin'
			size={size}
			className={classes(
				css.spinner,
				className,
				css[type],
				{ [css.isCenteredHorizontally]: isCenteredHorizontally },
				{ [css.isCenteredVertically]: isCenteredVertically }
			)}
			aria-hidden={ariaHidden}
		></Icon>
	);
};
