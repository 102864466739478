import React, { useEffect, useState } from 'react';
import css from './DashboardTabs.module.scss';
import classes from 'classnames';
import { Section } from 'components/Section/Section';
import { Container } from 'components/Container/Container';

interface ITabItem {
	className?: string;
	content: any;
	title: string;
	isSidebarItem?: boolean;
	itemClassName?: string;
}
interface IDashboardTabsProps {
	className?: string;
	sidebarClass?: string;
	items: ITabItem[];
	activeTab?: number;
}

export const DashboardTabs: React.FC<IDashboardTabsProps> = props => {
	const { className, items, sidebarClass, activeTab = null } = props;

	const [currentTab, setCurrentTab] = useState(0);

	useEffect(() => {
		if (activeTab !== null) {
			setCurrentTab(activeTab);
		}
		//eslint-disable-next-line
	}, [activeTab]);

	return (
		<div className={classes(css.dashboardTabs, className)}>
			<div className={css.tabNavigation}>
				{items.map((item, index) => (
					<button
						className={classes(
							css.tabNavigationItem,
							currentTab === index && css.active,
							item.itemClassName
						)}
						key={index}
						onClick={() => setCurrentTab(index)}
					>
						<div className={css.inner}>{item.title}</div>
					</button>
				))}
			</div>
			<div className={css.main}>
				<Container className={css.container}>
					{items.map((item, index) => (
						<React.Fragment key={index}>
							{item.isSidebarItem !== true && (
								<Section
									className={classes(css.tabItem, currentTab === index && css.active)}
									key={index}
								>
									{item.content}
								</Section>
							)}
						</React.Fragment>
					))}
				</Container>
			</div>
			<div className={classes(css.sidebar, sidebarClass)}>
				<Container className={css.container}>
					{items.map((item, index) => (
						<React.Fragment key={index}>
							{item.isSidebarItem === true && (
								<Section
									className={classes(css.tabItem, currentTab === index && css.active)}
									key={index}
								>
									{item.content}
								</Section>
							)}
						</React.Fragment>
					))}
				</Container>
			</div>
		</div>
	);
};
