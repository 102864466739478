import React from 'react';
import css from './Container.module.scss';
import classes from 'classnames';

interface IContainerProps {
	className?: string;
}

export const Container: React.FC<IContainerProps> = props => {
	const { children, className } = props;

	return <div className={classes(css.container, className)}>{children}</div>;
};
