import React from 'react';
import css from './ArrowLink.module.scss';
import classes from 'classnames';
import { Icon } from 'components/Icon/Icon';
import { Link } from 'react-router-dom';

interface IArrowLinkProps {
	className?: string;
	location?: any;
	color?: 'green' | 'yellow' | 'red' | 'blue';
	onClick?: () => any;
}

export const ArrowLink: React.FC<IArrowLinkProps> = props => {
	const { children, className, location, color = 'green', onClick } = props;
	if (typeof location === 'function') {
		return (
			<button onClick={onClick && location} className={classes(css.ArrowLink, className, css[color])}>
				<div className={css.text}>{children}</div>
				<div className={css.iconContainer}>
					<Icon className={css.icon} color='gray-1' type='ArrowRight'></Icon>
				</div>
			</button>
		);
	} else {
		return (
			<Link onClick={onClick} to={location} className={classes(css.ArrowLink, className, css[color])}>
				<div className={css.text}>{children}</div>
				<div className={css.iconContainer}>
					<Icon className={css.icon} color='gray-1' type='ArrowRight'></Icon>
				</div>
			</Link>
		);
	}
};
