import * as React from 'react';

function SvgFolderUser(props) {
	return (
		<svg enableBackground='new 0 0 24 24' viewBox='0 0 24 24' {...props}>
			<g
				style={{
					fill: 'none',
					stroke: '#000',
					strokeWidth: 1,
					strokeLinecap: 'round',
					strokeLinejoin: 'round',
					strokeMiterlimit: 10,
				}}
			>
				<circle cx={12} cy={12.5} r={2.5} />
				<path d='m17 22v-1.083c0-1.605-2.25-2.917-5-2.917s-5 1.312-5 2.917v1.083' />
				<path d='m21 22c1.105 0 2-.895 2-2v-12c0-1.105-.895-2-2-2h-9l-3-3h-6c-1.105 0-2 .895-2 2v15c0 1.105.895 2 2 2' />
			</g>
		</svg>
	);
}

export default SvgFolderUser;
