import React, { useState, useContext, useCallback } from 'react';
import MessageContainer from '../Message/MessageContainer';
import { IMessageProps } from 'components/Message';

const MessageContext = React.createContext(null);

export const MessageProvider: React.FC = props => {
	const { children } = props;
	const [messages, setMessages] = useState([]);

	const createMessage = useCallback(
		(props: IMessageProps) => {
			setMessages(messages => [
				...messages,
				{
					id: `${Math.random()}${Date.now()}`,
					props: props
				}
			]);
		},
		[setMessages]
	);

	const removeMessage = useCallback(
		id => {
			setMessages(messages => messages.filter(t => t.id !== id));
		},
		[setMessages]
	);

	return (
		<MessageContext.Provider
			value={{
				createMessage,
				removeMessage
			}}
		>
			<MessageContainer messages={messages} />
			{children}
		</MessageContext.Provider>
	);
};

const useMessage = () => {
	const messageHelpers: {
		createMessage: (props: IMessageProps) => void;
		removeMessage: (id: number) => {};
	} = useContext(MessageContext);

	return messageHelpers;
};

export { MessageContext, useMessage };
