import React from 'react';
import css from './Header.module.scss';
import classes from 'classnames';
import WhiteLabelLogo from 'assets/svg-components/WhiteLabelLogo';
import { DropdownAccount } from 'components/AppFrame/DropdownAccount';
import { DropdownQuickActions } from '../DropdownQuickActions/DropdownQuickActions';
import { DropdownList } from 'components/DropdownList/DropdownList';
import { TDropdownItem } from 'common/types/TDropdownItem';
import { Link } from 'components/Link/Link';
import { useAppFrameContext } from '../AppFrame';
import { DropdownTeam } from '../DropdownTeam/DropdownTeam';
import { useCurrentUser } from 'common/hooks/useCurrentUser';
import { CulchLogo } from 'assets/svg-components';
import { useLocation } from 'react-router';

interface IHeaderProps {
	className?: string;
	setIsSidebarShown?: React.Dispatch<React.SetStateAction<boolean>>;
	isSidebarShown?: boolean;
	ref?: any;
}

export const Header: React.FC<IHeaderProps> = props => {
	const { className, isSidebarShown = false, setIsSidebarShown, ref } = props;
	const navAdminItems: TDropdownItem[] = [
		{ title: 'Feedback Frameworks', action: '/admin/frameworks' },
		{ title: 'User Management', action: '/admin/users' },
		{ title: 'General Settings', action: '/admin/settings' }
	];

	const { backLink } = useAppFrameContext();

	const { pathname } = useLocation();

	const { currentUser } = useCurrentUser();

	const trialEndDate = new Date(currentUser.Company.trialEndDate);
	const today = new Date();
	var dayValue = 1000 * 60 * 60 * 24;
	const daysLeftInTrial = Math.max(0, Math.ceil((trialEndDate.getTime() - today.getTime()) / dayValue));
	const showTrialBanner =
		!currentUser.Company.isFreeAccount &&
		!currentUser.Company.billingId &&
		daysLeftInTrial > 0 &&
		currentUser.role === 'CompanyAdmin';

	return (
		<>
			{showTrialBanner && (
				<div className={css.mobileTrialPeriod}>
					<strong>{daysLeftInTrial} days left</strong> in your free trial{' '}
					<Link type='link' to='/admin/plan'>
						Purchase
					</Link>
				</div>
			)}
			<div
				ref={ref}
				className={classes(
					css.header,
					className,
					isSidebarShown && css.sidebarShown,
					showTrialBanner && css.hasTrialBanner
				)}
				id='header'
			>
				<Link to='/' className={css.logo}>
					{currentUser.Company.name === 'TGG' ? (
						<WhiteLabelLogo></WhiteLabelLogo>
					) : (
						<CulchLogo></CulchLogo>
					)}
					<span className='screenReaderText'>Home</span>
				</Link>

				<span className={css.backLink}>{backLink}</span>

				<ul className={css.mainNav}>
					<li>
						<Link
							className={classes(pathname === '/' && css.active, css.mainNavItem)}
							to='/'
							type='control'
						>
							Dashboard
						</Link>
					</li>
					{(currentUser.role !== 'CompanyAdmin' || currentUser.DirectReports.length > 0) && (
						<li>
							<DropdownTeam
								className={classes(css.mainNavItem, pathname.indexOf('team') >= 0 && css.active)}
							>
								Team
							</DropdownTeam>
						</li>
					)}
					<li>
						{currentUser.role === 'CompanyAdmin' ? (
							<DropdownList
								enableHover={true}
								className={classes(css.mainNavItem, pathname.indexOf('admin') >= 0 && css.active)}
								type='control'
								items={navAdminItems}
							>
								Admin
							</DropdownList>
						) : (
							<Link className={css.mainNavItem} type='control' to='/admin/frameworks'>
								My Feedback Frameworks
							</Link>
						)}
					</li>
				</ul>

				<div className={css.headerDropdowns}>
					{showTrialBanner && (
						<div className={css.trialPeriod}>
							<span>
								<strong>{daysLeftInTrial} days left</strong>
								<br />
								in your free trial
							</span>
							<Link type='primary' to='/admin/plan'>
								Purchase
							</Link>
						</div>
					)}
					{(currentUser.role !== 'CompanyAdmin' || currentUser.DirectReports.length > 0) && (
						<DropdownQuickActions />
					)}
					{/* <DropdownNotifications /> */}
					<DropdownAccount />
				</div>
				<button
					onClick={() => {
						setIsSidebarShown(!isSidebarShown);
					}}
					className={css.menuToggle}
					aria-controls='primary-menu'
					aria-expanded='false'
				>
					<div className={css.target}>
						<span></span>
						<span></span>
						<span></span>
					</div>
				</button>
			</div>
		</>
	);
};
