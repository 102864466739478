import { FormikProps } from 'formik';

interface IGetProcessedFormikProps {
	displayedErrorMessage: string;
	isError: boolean;
	displayedMessage: string;
}

export const getProcessedFormikProps = (
	formikProps: FormikProps<any>,
	fieldName: string,
	errorMessage: string,
	inputMessage: string
): IGetProcessedFormikProps => {
	const getDisplayedErrorMessage = (): string => {
		if (formikProps?.errors[fieldName] && formikProps?.touched[fieldName]) {
			return formikProps.errors[fieldName] as string;
		} else if (errorMessage) {
			return errorMessage;
		} else {
			return null;
		}
	};

	const displayedErrorMessage = getDisplayedErrorMessage();

	const isError = displayedErrorMessage ? true : false;

	const displayedMessage = displayedErrorMessage ? displayedErrorMessage : inputMessage;

	return { displayedErrorMessage, isError, displayedMessage };
};
