import React, { useState, useRef, useEffect } from 'react';
import css from './input.module.scss';
import classes from 'classnames';
import { removePropertiesFromObjects } from 'common/utils/removePropertiesFromObjects';
import { getUniqueId } from 'common/utils/getUniqueId';
import { TIconTypes } from 'common/types/TIconTypes';
import { Icon } from 'components/Icon';
import { InputFrame, IInputFrame } from 'components/Form/InputFrame';
import { getProcessedFormikProps } from 'common/utils/getProcessedFromikProps';
import { RemoveProp } from 'common/types/TypeHelpers';
import { TInput } from 'common/types/TInput';
import { replacePropsWithFormikProps } from 'common/utils/replacePropsWithFormikProps';
import { equals } from 'ramda';

export type IInputProps = {
	className?: string;
	labelClassName?: string;
	inputFrameClassName?: string;
	inputContainerClassName?: string;
	icon?: TIconTypes;
	setRef?: (ref: React.MutableRefObject<any>) => void;
} & TInput &
	RemoveProp<RemoveProp<IInputFrame, 'inputId'>, 'messageId'>;

export const Input: React.FC<IInputProps> = props => {
	const {
		label,
		inputMessage,
		errorMessage,
		className,
		labelClassName,
		inputFrameClassName,
		inputContainerClassName,
		id,
		formikProps,
		name,
		icon,
		isLabelVisible,
		setRef
	} = props;
	const [inputId] = useState(id ? id : getUniqueId());
	const [messageId] = useState(getUniqueId());
	const { isError } = getProcessedFormikProps(formikProps, name, errorMessage, inputMessage);
	const ref = useRef(null);

	const prevErrorMessageRef: React.MutableRefObject<any> = useRef();
	useEffect(() => {
		if (formikProps) {
			prevErrorMessageRef.current = formikProps.errors;
		}
	});
	const prevErrorMessage = prevErrorMessageRef.current;

	// Focus on first error field
	useEffect(() => {
		if (formikProps) {
			const didErrorMessageChange = !equals(prevErrorMessage, formikProps.errors);
			if (didErrorMessageChange) {
				const firstKey = Object.keys(formikProps.errors)[0];
				if (name === firstKey) {
					const firstErrorElement = document.querySelector('.error');
					// @ts-ignore
					firstErrorElement?.focus();
				}
			}
		}
	});

	const inputProps: any = removePropertiesFromObjects(
		[
			'label',
			'inputMessage',
			'errorMessage',
			'errorMessageId',
			'formikProps',
			'isLabelVisible',
			'inputContainerClassName',
			'inputFrameClassName',
			'setRef',
			'labelClassName'
		],
		props
	);

	const inputPropsWithFormik = replacePropsWithFormikProps<IInputProps>(inputProps, formikProps, name);

	useEffect(() => {
		if (setRef) {
			setRef(ref);
		}
	}, [setRef]);

	return (
		<InputFrame
			inputId={inputId}
			isLabelVisible={isLabelVisible}
			label={label}
			messageId={messageId}
			inputMessage={inputMessage}
			formikProps={formikProps}
			name={name}
			errorMessage={errorMessage}
			labelClassName={labelClassName}
			className={inputFrameClassName}
		>
			<div className={classes(css.inputContainer, inputContainerClassName)}>
				<input
					{...inputPropsWithFormik}
					id={inputId}
					aria-describedby={messageId}
					className={classes(
						className,
						css.input,
						{ [css.error]: isError },
						{ error: isError },
						{ [css.hasIcon]: icon }
					)}
					ref={ref}
				/>
				{icon && <Icon type={icon} className={css.icon} color='blue' />}
			</div>
		</InputFrame>
	);
};
