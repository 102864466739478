import React, { useState, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { IAuth } from 'common/interfaces/IAuth';
import { isAccessTokenExpired } from 'common/utils/isAccessTokenExpired';
import { authApiService, IAuthLoginResponse } from './authApiService';

// Apollo Client
// import { initApolloClient } from 'config/ApolloClientConfig';

import { client } from 'config/ApolloClientConfig';
import { ApolloProvider } from '@apollo/client';
import { authConfig } from 'config/authConfig';

const AuthContext = React.createContext(null);

interface ILocation {
	firstLogin?: string;
}
const getRedirectLocation = (redirectLocationAfterLogin: string): string => {
	if (!redirectLocationAfterLogin || redirectLocationAfterLogin === '/signin') {
		return '/';
	}
	return redirectLocationAfterLogin;
};

export const AuthProvider: React.FC = props => {
	const { children } = props;
	const [authData, setAuthData] = useState<IAuth>({});
	const history = useHistory();
	const [loginErrorMessage, setLoginErrorMessage] = useState('');
	const location = useLocation<ILocation>();

	// Login a user
	// This will redirect back to main landing page by default but will redirect back based on optional redirect param
	const userLogin = async (email: string, password: string, redirectLocationAfterLogin?: string) => {
		try {
			const authenticatedUser: IAuthLoginResponse = await authApiService.userLogin(email, password);
			setAuthData({
				user: authenticatedUser.user
			});
			authConfig.accessToken = authenticatedUser.accessToken;
			// If redirectLocationAfterLogin equals /logout return '/' as redirectLocationAfterLogin or just use redirectLocationAfterLogin. Fallback to '/'
			// Checks to make sure a user doesn't redirect to to the logout page immediately after logging in
			const redirectLocation: string = getRedirectLocation(redirectLocationAfterLogin);
			if (
				location &&
				location.state &&
				location.state.firstLogin &&
				authenticatedUser.user.registrations[0].roles[0] === 'CompanyAdmin'
			) {
				history.push({ pathname: '/completeAccount', state: { email: authenticatedUser.user.email } });
			} else {
				history.push(redirectLocation);
			}
			setLoginErrorMessage('');
		} catch (error) {
			setLoginErrorMessage(error.message);
		}
	};

	// Removes all data from the auth context and sets to null and kills auth session
	const userLogout = async () => {
		await authApiService.userLogout();
		authConfig.accessToken = '';
		history.push('/signin');
	};

	// Detects if there is a token set in the context for the current logged in user
	const getIsAuthenticated = async (): Promise<boolean> => {
		if (authConfig.accessToken) {
			if (isAccessTokenExpired(authConfig.accessToken)) {
				try {
					const accessToken = await authApiService.refreshAccessToken();
					authConfig.accessToken = accessToken;
					return true;
				} catch (error) {
					return false;
				}
			} else {
				return true;
			}
		} else {
			// Attempt to get a token
			try {
				const accessToken = await authApiService.refreshAccessToken();
				const user = await authApiService.getUser(accessToken);
				setAuthData({
					...authData,
					user
				});
				authConfig.accessToken = accessToken;
				return true;
			} catch (error) {
				return false;
			}
		}
	};

	return (
		<AuthContext.Provider
			value={{
				userLogin,
				userLogout,
				getIsAuthenticated,
				currentUserId: authData?.user?.id,
				loginErrorMessage
			}}
		>
			<ApolloProvider client={client}>{children}</ApolloProvider>
		</AuthContext.Provider>
	);
};

// A custom auth hook that taps into the auth context helper methods
const useAuth = () => {
	const authHelpers: {
		userLogin: (email: string, password: string, redirectLocationAfterLogin?: string) => void;
		userLogout: () => void;
		getIsAuthenticated: () => Promise<boolean>;
		currentUserId: string;
		loginErrorMessage: string;
	} = useContext(AuthContext);

	return authHelpers;
};

export { AuthContext, useAuth };
