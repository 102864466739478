import * as React from 'react';

function SvgCaretDownFilled(props) {
	return (
		<svg width={24} height={24} viewBox='0 0 24 24' fill='none' {...props}>
			<path
				d='M12.836 15.6952C12.6454 15.8681 12.3546 15.8681 12.164 15.6952L4.366 8.62031C4.02734 8.31306 4.2447 7.75 4.70196 7.75L20.298 7.75C20.7553 7.75 20.9727 8.31306 20.634 8.62031L12.836 15.6952Z'
				fill='#27363E'
			/>
		</svg>
	);
}

export default SvgCaretDownFilled;
