import { FormikProps } from 'formik';

export const replacePropsWithFormikProps = <T>(
	propsToReplace: T,
	formikProps: FormikProps<any>,
	name: string
): T => {
	if (formikProps) {
		return {
			...propsToReplace,
			value: formikProps.values[name],
			onChange: formikProps.handleChange,
			onBlur: formikProps.handleBlur
		};
	} else {
		return propsToReplace;
	}
};
