import React from 'react';
import css from './TeamRecentlyViewed.module.scss';
import classes from 'classnames';
import { Section } from 'components/Section/Section';
import { Link } from 'components/Link/Link';
import { GridContainer } from 'components/Grid/GridContainer/GridContainer';
import { GridItem } from 'components/Grid/GridItem/GridItem';
import { useCurrentUser } from 'common/hooks/useCurrentUser';

export interface ITeamRecentlyViewedProps {
	className?: string;
	isGrid?: boolean;
	heading?: any;
}

export interface IRecentlyViewedTeam {
	id: string;
	name: string;
}

export const TeamRecentlyViewed: React.FC<ITeamRecentlyViewedProps> = props => {
	const { className, isGrid, heading = <h4>Recently Viewed</h4> } = props;

	const { currentUser, loading, error } = useCurrentUser();

	if (!loading && !error && currentUser && currentUser.recentlyViewedTeam) {
		const recentlyViewedTeam = JSON.parse(currentUser.recentlyViewedTeam);
		return (
			<Section className={classes(css.teamRecentlyViewed, className)}>
				{heading}
				{isGrid === true ? (
					<GridContainer columnAmount='2' className={css.teamList}>
						{recentlyViewedTeam &&
							recentlyViewedTeam.map((teamMember: IRecentlyViewedTeam) => (
								<GridItem>
									<Link to={'/team/' + teamMember.id} type='link'>
										{teamMember.name}
									</Link>
								</GridItem>
							))}
					</GridContainer>
				) : (
					<ul className={css.teamList}>
						{recentlyViewedTeam &&
							recentlyViewedTeam.map((teamMember: IRecentlyViewedTeam) => (
								<li>
									<Link to={'/team/' + teamMember.id} type='link'>
										{teamMember.name}
									</Link>
								</li>
							))}
					</ul>
				)}
			</Section>
		);
	} else {
		return <Section className={classes(css.teamRecentlyViewed, className)}></Section>;
	}
};
