import React, { useRef, useEffect, useState } from 'react';
import css from './Dropdown.module.scss';
import classes from 'classnames';
import { Button } from 'components/Button/Button';
import { IButtonBaseProps } from 'common/interfaces/IButtonBaseProps';
import { removePropertiesFromObjects } from 'common/utils/removePropertiesFromObjects';
import { useToggle } from 'common/hooks/useToggle';
import { useLocation } from 'react-router';

export type IDropdownProps = IButtonBaseProps & {
	className?: string;
	dropdownContentClassName?: string;
	buttonClassName?: string;
	DropdownContent: React.ComponentType<any>;
	dropdownPosition?: 'left' | 'right';
	isOpen?: boolean;
	enableHover?: boolean;
};

export const Dropdown: React.FC<IDropdownProps> = props => {
	const {
		children,
		className,
		buttonClassName,
		DropdownContent,
		dropdownContentClassName,
		dropdownPosition = 'left',
		isOpen = false,
		enableHover = false
	} = props;

	const location = useLocation();
	const toggle = useToggle(null);

	const dropdownRef = useRef(null);

	useEffect(() => {
		toggle.setRef(dropdownRef);
		if (isOpen !== null) {
			toggle.setIsShown(isOpen);
		}
		// eslint-disable-next-line
	}, [dropdownRef, isOpen]);

	useEffect(() => {
		toggle.setIsShown(isOpen);
		// eslint-disable-next-line
	}, [location]);

	// Remove these props from the inherited button base props
	const dropdownPropsToRemove = [
		'className',
		'onClick',
		'DropdownContent',
		'isOpen',
		'dropdownPosition'
	];
	// Remove the above props and create a variable to add to the button component
	const buttonProps = removePropertiesFromObjects(dropdownPropsToRemove, props);

	const [delayHandler, setDelayHandler] = useState(null);

	const handleMouseEnter = (event: any) => {
		if (enableHover) {
			if (delayHandler) clearTimeout(delayHandler);
			setDelayHandler(
				setTimeout(() => {
					toggle.setIsShown(true);
				}, 350)
			);
		}
	};

	const handleMouseLeave = () => {
		if (enableHover) {
			clearTimeout(delayHandler);
			// setDelayHandler(
			// 	setTimeout(() => {
			toggle.setIsShown(false);
			// }, 350)
		}
	};
	return (
		<div
			className={classes(
				css.dropdownWrapper,
				className,
				{
					[css.positionRight]: dropdownPosition === 'right'
				},
				toggle.isShown && css.isOpen
			)}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			<Button
				onClick={event => toggle.toggle(event)}
				className={classes(css.dropdownButton, buttonClassName)}
				{...buttonProps}
			>
				{children}
			</Button>

			{toggle.isShown && (
				<div className={classes(dropdownContentClassName, css.dropdownContentArea)} ref={dropdownRef}>
					<DropdownContent toggle={toggle}></DropdownContent>
				</div>
			)}
		</div>
	);
};
