import React from 'react';
import css from './BackLink.module.scss';
import classes from 'classnames';
import { Icon } from '../Icon/Icon';
import { Link } from 'components/Link/Link';

interface IBackLinkProps {
	title?: string;
	subTitle?: string;
	action?: string | (() => void);
	className?: string;
}

export const BackLink: React.FC<IBackLinkProps> = props => {
	const { title, subTitle, action, className } = props;

	const content = (
		<>
			{action && <Icon type='ArrowLeft' color='black' className={css.arrowIcon}></Icon>}
			<div className={css.text}>
				<span className={css.title}>
					{title} {title && subTitle && <span className={css.colon}>:</span>}
				</span>
				{subTitle && <span>{subTitle}</span>}
			</div>
		</>
	);
	if (typeof action === 'string') {
		return (
			<Link className={classes({ className }, css.backLink)} to={action}>
				{content}
			</Link>
		);
	} else {
		return (
			<button className={classes({ className }, css.backLink)} onClick={action}>
				{content}
			</button>
		);
	}
};
