import * as React from 'react';

function SvgTriangleRed(props) {
	return (
		<svg width={22} height={14} viewBox='0 0 22 14' fill='#E94D2A' {...props}>
			<path d='M1.62359 13L11 0.81968L20.3764 13H1.62359Z' stroke='black' />
		</svg>
	);
}

export default SvgTriangleRed;
