import * as React from 'react';

function SvgCaretUp(props) {
	return (
		<svg width={24} height={24} viewBox='0 0 24 24' fill='none' {...props}>
			<path
				d='M3 16L12 8L21 16'
				stroke='#27363E'
				strokeWidth={1.5}
				strokeMiterlimit={10}
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
}

export default SvgCaretUp;
