import * as React from 'react';

function SvgLaptop(props) {
	return (
		<svg enableBackground='new 0 0 24 24' viewBox='0 0 24 24' {...props}>
			<g
				style={{
					fill: 'none',
					stroke: '#000',
					strokeWidth: 1,
					strokeLinecap: 'round',
					strokeLinejoin: 'round',
					strokeMiterlimit: 10,
				}}
			>
				<path d='m3 15v-9c0-1.105.895-2 2-2h14c1.105 0 2 .895 2 2v9' />
				<path d='m1 16h22v2c0 1.105-.895 2-2 2h-18c-1.105 0-2-.895-2-2z' />
			</g>
		</svg>
	);
}

export default SvgLaptop;
