import * as React from 'react';

function SvgCaretDown(props) {
	return (
		<svg width={24} height={24} viewBox='0 0 24 24' fill='none' {...props}>
			<path
				d='M3 8L12 16L21 8'
				stroke='#27363E'
				strokeWidth={1.5}
				strokeMiterlimit={10}
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
}

export default SvgCaretDown;
