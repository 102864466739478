import React from 'react';
import css from './TableMetaContent.module.scss';
import classes from 'classnames';

interface ITableMetaContent {
	contentLeft?: React.ReactNode;
	contentRight?: React.ReactNode;
	className?: string;
	contentLeftClassname?: string;
}

export const TableMetaContent: React.FC<ITableMetaContent> = props => {
	const { contentLeft, contentRight, className, contentLeftClassname } = props;

	return (
		<div className={classes(css.tableMetaContent, className)}>
			<div className={classes(css.contentArea, contentLeftClassname)}>{contentLeft}</div>
			<div className={css.contentArea}>{contentRight}</div>
		</div>
	);
};
