import React from 'react';
import css from './Sidebar.module.scss';
import classes from 'classnames';
import { NavigationGroup } from 'components/AppFrame/NavigationGroup';
import { INavigationGroupProps } from 'common/interfaces/INavigationGroupProps';
import { useCurrentUser } from 'common/hooks/useCurrentUser';
import { useAuth } from 'components/Auth/AuthProvider';

interface ISidebarProps {
	className?: string;
	setIsSidebarShown?: React.Dispatch<React.SetStateAction<boolean>>;
	isSidebarShown?: boolean;
}

export const Sidebar: React.FC<ISidebarProps> = props => {
	const { className, isSidebarShown = false, setIsSidebarShown } = props;
	const { currentUser } = useCurrentUser();
	const { userLogout } = useAuth();

	const trialEndDate = new Date(currentUser.Company.trialEndDate);
	const today = new Date();
	var dayValue = 1000 * 60 * 60 * 24;
	const daysLeftInTrial = Math.max(0, Math.ceil((trialEndDate.getTime() - today.getTime()) / dayValue));
	const showTrialBanner =
		!currentUser.Company.isFreeAccount &&
		!currentUser.Company.billingId &&
		daysLeftInTrial > 0 &&
		currentUser.role === 'CompanyAdmin';
	const navigationGroups: INavigationGroupProps[] = [
		{
			items: [
				{ title: 'Dashboard', iconType: 'Home', location: '/', exact: true },
				(currentUser.role !== 'CompanyAdmin' || currentUser.DirectReports.length > 0) && {
					title: 'Team',
					iconType: 'Users',
					location: '/team',
					exact: true
				},
				// { title: 'Reports', iconType: 'PageCheck', location: '/users', exact: true },
				{
					title: 'Admin',
					iconType: 'Settings',
					location: '/admin/frameworks',
					exact: true,
					subItems: [
						{
							title: 'Feedback Frameworks',
							location: '/admin/frameworks',
							type: 'secondary',
							exact: true
						},
						{
							title: 'User Management',
							location: '/admin/users',
							type: 'secondary',
							exact: true
						},
						{
							title: 'General Settings',
							location: '/admin/settings',
							type: 'secondary',
							exact: true
						}
					]
				},

				// {
				// 	title: 'Drafts (8)',
				// 	type: 'secondary',
				// 	iconType: 'InboxFull',
				// 	location: '/drafts',
				// 	exact: true
				// },
				// {
				// 	title: 'Notifications (24)',
				// 	type: 'secondary',
				// 	iconType: 'Bell',
				// 	location: '/notifications',
				// 	isBadge: true,
				// 	exact: true
				// },
				{
					title: 'Account',
					type: 'primary',
					iconType: 'UserCircle',
					location: '/myAccount',
					subItems: [
						{
							title: 'Getting Started',
							location: '/gettingStarted',
							type: 'secondary',

							exact: true
						},
						{
							action: userLogout,
							title: 'Log Out'
						}
					]
				}
			]
		}
	];

	if (currentUser.role === 'CompanyManager') {
		navigationGroups[0].items[2] = {
			title: 'My Feedback Frameworks',
			type: 'primary',
			iconType: 'Settings',
			location: 'admin/frameworks',
			exact: true
		};
	}

	return (
		<nav
			className={classes(
				css.sidebar,
				className,
				isSidebarShown && css.shown,
				showTrialBanner && css.hasTrialBanner
			)}
			id='sidebar'
		>
			<div className={css.sidebarNavigation}>
				{navigationGroups.map((navigationGroup, index) => (
					<NavigationGroup
						key={navigationGroup.title + index}
						isMobileOnly={navigationGroup.isMobileOnly}
						title={navigationGroup.title}
						iconType={navigationGroup.iconType}
						items={navigationGroup.items}
						setIsSidebarShown={setIsSidebarShown}
					></NavigationGroup>
				))}
			</div>
			{/* <div className={css.sidebarFooter}>
				<div className={classes(css.heading, 'h6')}>Powered By</div>
				<Logotype className={css.footerLogo}></Logotype>
			</div> */}
		</nav>
	);
};
