import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import 'wicg-inert';
import { THTMLElementEvent } from 'common/types/THTMLElementEvent';
import { TSourceRef } from 'common/types/TSourceRef';

export type TToggle = {
	isShown: boolean;
	toggle: (event?: React.MouseEvent<HTMLElement, Event>) => void;
	setIsShown: Dispatch<SetStateAction<boolean>>;
	ref: React.MutableRefObject<HTMLElement>;
	setRef: Dispatch<any>;
};

export function useToggle(defaultValue: boolean = false) {
	const [isShown, setIsShown] = useState(defaultValue);
	const [ref, setRef] = useState(null);
	const [isToggleCancellable, setIsToggleCancellable] = useState(null);
	const [sourceEventTarget, setSourceEventTarget]: [HTMLElement, Dispatch<HTMLElement>] = useState(null);

	const toggle = (event?: React.MouseEvent<HTMLElement, Event>) => {
		if (event?.target) {
			setSourceEventTarget(event.target as HTMLElement);
		}
		setIsShown(!isShown);
	};

	const setSourceEvent = (event?: THTMLElementEvent<TSourceRef>) => {
		if (event?.target) {
			setSourceEventTarget(event.target);
		}
	};

	useEffect(() => {
		const dismissOnEscape = (event: KeyboardEvent) => {
			if (event.keyCode === 27) {
				setIsShown(false);

				if (sourceEventTarget) {
					sourceEventTarget.focus();
				}
			}
		};

		const isEventCancellable = (event: Event) => {
			if (
				!(ref?.current).contains(event.target as Element) &&
				!sourceEventTarget?.contains(event.target as Element)
			) {
				return true;
			} else {
				return false;
			}
		};

		const handleMouseDown = (event: Event) => {
			setIsToggleCancellable(isEventCancellable(event));
		};

		const handleMouseUp = (event: Event) => {
			if (isToggleCancellable && isEventCancellable(event)) {
				setIsShown(false);
			}
		};

		// if (enableInert) {
		// 	const bodyRef = document.querySelector('body');
		// 	const rootElement = document.querySelector('#root');
		// 	if (isShown) {
		// 		// @ts-ignore
		// 		rootElement.inert = true;
		// 		disableBodyScroll(bodyRef);
		// 	} else {
		// 		// @ts-ignore
		// 		rootElement.inert = false;
		// 		enableBodyScroll(bodyRef);
		// 	}
		// }

		if (isShown) {
			document.addEventListener('mousedown', handleMouseDown);
			document.addEventListener('mouseup', handleMouseUp);
			document.addEventListener('keyup', dismissOnEscape, true);
		} else {
			document.removeEventListener('mousedown', handleMouseDown);
			document.removeEventListener('mouseup', handleMouseUp);
			document.removeEventListener('keyup', dismissOnEscape, true);
		}

		if (!isShown && sourceEventTarget) {
			sourceEventTarget.focus();
			sourceEventTarget.setAttribute('focus', 'focus');
		}

		return () => {
			document.removeEventListener('mousedown', handleMouseDown);
			document.removeEventListener('mouseup', handleMouseUp);
			document.removeEventListener('keyup', dismissOnEscape, true);
		};
	}, [isShown, setIsToggleCancellable, isToggleCancellable, ref, sourceEventTarget]);
	return { isShown, toggle, setIsShown, ref, setRef, setSourceEvent };
}
