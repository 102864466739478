import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import css from './PageFrame.module.scss';
import { useAppFrameContext } from 'components/AppFrame/AppFrame';
import { BackLink } from 'components/BackLink/BackLink';
import { Container } from 'components/Container/Container';

interface IPageFrame {
	title: string;
	subTitle?: string;
	backAction?: string | (() => void);
}

export const PageFrame: React.FC<IPageFrame> = props => {
	const { title, subTitle, children, backAction } = props;

	const { setBackLink, backLink } = useAppFrameContext();

	useEffect(() => {
		setBackLink(<BackLink title={title} subTitle={subTitle} action={backAction}></BackLink>);
	}, [title, setBackLink, subTitle, backAction]);

	return (
		<>
			<Helmet>
				<title>{title} | Culch</title>
			</Helmet>

			<div className={css.content}>
				{backAction && <Container className={css.backLink}>{backLink}</Container>}
				{children}
			</div>
		</>
	);
};
