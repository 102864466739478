import * as React from 'react';

function SvgMoneyCircle(props) {
	return (
		<svg enableBackground='new 0 0 24 24' viewBox='0 0 24 24' {...props}>
			<g
				style={{
					fill: 'none',
					stroke: '#000',
					strokeWidth: 1,
					strokeLinecap: 'round',
					strokeLinejoin: 'round',
					strokeMiterlimit: 10,
				}}
			>
				<path d='m14 8h-3c-1.105 0-2 .895-2 2 0 1.105.895 2 2 2h2c1.105 0 2 .895 2 2 0 1.105-.895 2-2 2h-3' />
				<path d='m12 8v-2' />
				<path d='m12 18v-2' />
				<circle cx={12} cy={12} r={11} />
			</g>
		</svg>
	);
}

export default SvgMoneyCircle;
