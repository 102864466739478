import React from 'react';
import css from './Section.module.scss';
import classes from 'classnames';

export interface ISectionProps {
	title?: string;
	className?: string;
	titleClassName?: string;
}

export const Section: React.FC<ISectionProps> = props => {
	const { title, children, className, titleClassName } = props;
	return (
		<div className={classes(css.section, className)}>
			{title && <h2 className={classes(css.title, titleClassName, 'h5')}>{title}</h2>}
			{children}
		</div>
	);
};
