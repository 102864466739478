import React from 'react';
import css from './DropdownQuickActions.module.scss';
import { Dropdown } from 'components/Dropdown/Dropdown';
import { ArrowLink } from 'components/ArrowLink/ArrowLink';
// import classes from 'classnames';
// import { IUser } from 'common/interfaces/IUser';

interface IDropdownQuickActionsProps {
	isOpen?: boolean;
}

export const DropdownQuickActions: React.FC<IDropdownQuickActionsProps> = props => {
	const { isOpen } = props;

	const DropdownContent = () => (
		<div className={css.dropdownContent}>
			<h4>Quick Actions</h4>
			<ul>
				<li>
					<ArrowLink location={{ pathname: '/feedback', state: { feedbackType: 'Positive' } }}>
						Provide Positive Feedback
					</ArrowLink>
				</li>
				<li>
					<ArrowLink
						color='yellow'
						location={{ pathname: '/feedback', state: { feedbackType: 'Constructive' } }}
					>
						Provide Constructive Feedback
					</ArrowLink>
				</li>
				<li>
					<ArrowLink color='red' location='/infraction'>
						Record an Infraction
					</ArrowLink>
				</li>
				{/* <li>
					<ArrowLink color='blue' location='/'>
						View Drafts
					</ArrowLink>
				</li> */}
			</ul>
		</div>
	);

	return (
		<Dropdown
			type='primary'
			iconRight={{ type: 'Add', color: 'white' }}
			DropdownContent={DropdownContent}
			dropdownContentClassName={css.dropdownContentArea}
			isOpen={isOpen}
			className={css.dropdownQuickActions}
			enableHover={true}
		></Dropdown>
	);
};
