// This file sets up the redux store

import { createStore, compose, applyMiddleware, Action, combineReducers } from 'redux';
import { ExampleStoreReducer } from './exampleStore/exampleStoreReducers';
import { ActionType } from 'typesafe-actions';
import * as actions from 'stores/actions';
import { createEpicMiddleware } from 'redux-observable';
import { IExampleStoreState } from 'stores/exampleStore/exampleStoreStateInterface';
import { combineEpics } from 'redux-observable';
import { fetchPost } from 'stores/exampleStore/exampleStoreEpics';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { connectRouter, routerMiddleware, RouterState } from 'connected-react-router';
import { History, createBrowserHistory } from 'history';

// This is where the application state is defined
export interface ApplicationState {
	router: RouterState;
	exampleStore: IExampleStoreState;
}

const composeEnhancer: typeof compose = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

type actionInterfaces = ActionType<typeof actions>;

const epicMiddleware = createEpicMiddleware<actionInterfaces, actionInterfaces, ApplicationState>();

export const history = createBrowserHistory();

const createRootReducer = (history: History) =>
	combineReducers({
		router: connectRouter(history),
		exampleStore: ExampleStoreReducer
	});

export const rootEpic = combineEpics(fetchPost);

export const configureStore = (initialState?: any) => {
	// configure middlewares
	const middlewares = [epicMiddleware, thunk, logger, routerMiddleware(history)];
	// compose enhancers
	const enhancer = composeEnhancer(applyMiddleware(...middlewares));
	// create store
	return createStore(createRootReducer(history), initialState, enhancer);
};

export const store = configureStore();

epicMiddleware.run(rootEpic);

export interface AppThunkAction<TAction extends Action<string>, TReturn = void> {
	(dispatch: (action: TAction) => TReturn, getState: () => IExampleStoreState, {}: any): TReturn; // eslint-disable-line
}
