import * as React from 'react';

function SvgTriangleGreen(props) {
	return (
		<svg width={22} height={14} viewBox='0 0 22 14' fill='none' {...props}>
			<path d='M11 0L21.3923 13.5H0.607696L11 0Z' fill='#23C881' />
		</svg>
	);
}

export default SvgTriangleGreen;
