import * as React from 'react';

function SvgEdit(props) {
	return (
		<svg enableBackground='new 0 0 24 24' viewBox='0 0 24 24' {...props}>
			<g
				style={{
					fill: 'none',
					stroke: '#000',
					strokeWidth: 2,
					strokeLinecap: 'round',
					strokeLinejoin: 'round',
					strokeMiterlimit: 10,
				}}
			>
				<path d='m16.302 3.977 3.721 3.721' />
				<path d='m18.508 1.771-10.046 10.046-1.462 5.183 5.183-1.462 10.046-10.046c1.028-1.028 1.028-2.693 0-3.721-1.027-1.028-2.693-1.028-3.721 0z' />
				<path d='m8 6h-3c-2.209 0-4 1.791-4 4v9c0 2.209 1.791 4 4 4h9c2.209 0 4-1.791 4-4v-3' />
			</g>
		</svg>
	);
}

export default SvgEdit;
