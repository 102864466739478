import * as React from 'react';

function SvgBell(props) {
	return (
		<svg enableBackground='new 0 0 24 24' viewBox='0 0 24 24' {...props}>
			<g
				style={{
					fill: 'none',
					stroke: '#000',
					strokeWidth: 1,
					strokeLinecap: 'round',
					strokeLinejoin: 'round',
					strokeMiterlimit: 10,
				}}
			>
				<path d='m14.905 19.25c.062.24.095.491.095.75 0 1.657-1.343 3-3 3s-3-1.343-3-3c0-.259.033-.51.095-.75' />
				<path d='m22 19h-20l4-7v-5c0-3.314 2.686-6 6-6 3.314 0 6 2.686 6 6v5z' />
			</g>
		</svg>
	);
}

export default SvgBell;
