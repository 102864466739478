import * as React from 'react';

function SvgUserList(props) {
	return (
		<svg enableBackground='new 0 0 24 24' viewBox='0 0 24 24' {...props}>
			<g
				style={{
					fill: 'none',
					stroke: '#000',
					strokeWidth: 2,
					strokeLinecap: 'round',
					strokeLinejoin: 'round',
					strokeMiterlimit: 10,
				}}
			>
				<path d='m15 22v-1.625c0-2.964-3.14-5.375-7-5.375s-7 2.411-7 5.375v1.625' />
				<circle cx={8} cy={6.5} r={4.5} />
				<path d='m17 13h6' />
				<path d='m17 3h6' />
				<path d='m17 8h6' />
			</g>
		</svg>
	);
}

export default SvgUserList;
