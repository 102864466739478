import React, { LegacyRef } from 'react';
import { Link as RouterLink, LinkProps } from 'react-router-dom';
import { TButtonTypes } from 'common/types/TButtonTypes';
import classes from 'classnames';
import css from './Link.module.scss';
import { TButtonSizes } from 'common/types/TButtonSizes';

export type ILinkProps = { type?: TButtonTypes; size?: TButtonSizes; external?: boolean } & LinkProps;

export const Link = React.forwardRef((props: ILinkProps, ref?: LegacyRef<any>) => {
	const { children, className, type = 'link', size = 'medium', external = false } = props;
	if (external) {
		return (
			<a
				href={props.to as string}
				className={classes(className, css[type], css.linkLocal, css[size])}
				ref={ref}
				rel='noopener noreferrer'
				target='_blank'
			>
				<span className={css.buttonContent}>{children}</span>
			</a>
		);
	}
	return (
		<RouterLink {...props} className={classes(className, css[type], css.linkLocal, css[size])} ref={ref}>
			<span className={css.buttonContent}>{children}</span>
		</RouterLink>
	);
});
