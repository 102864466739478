import React from 'react';
import { createPortal } from 'react-dom';
import css from './Message.module.scss';
import Message, { IMessageProps } from '../Message';

interface IMessageContainerProps {
	messages: { id: number; props: IMessageProps }[];
}

const MessageContainer: React.FC<IMessageContainerProps> = props => {
	const { messages } = props;
	return createPortal(
		<div className={css.messageContainer}>
			{messages.map((message: any) => {
				return <Message key={message.id} id={message.id} {...message.props}></Message>;
			})}
		</div>,
		document.body
	);
};

export default MessageContainer;
