import React from 'react';
import css from './NavigationGroup.module.scss';
import classes from 'classnames';
import { Icon } from 'components/Icon/Icon';
import { NavLink } from 'react-router-dom';
import { INavigationGroupProps } from 'common/interfaces/INavigationGroupProps';
import { BadgeIcon } from 'components/BadgeIcon/BadgeIcon';
import { Button } from 'components/Button/Button';

export const NavigationGroup: React.FC<INavigationGroupProps> = props => {
	const { className, items, isMobileOnly = false, setIsSidebarShown } = props;

	return (
		<div className={classes(css.navigationGroup, className, { [css.isMobileOnly]: isMobileOnly })}>
			<ul className={classes(css.items, css.hiddenMobile)}>
				{items.map((item, index) => (
					<>
						{item && (
							<li key={index}>
								<NavLink
									exact={item.exact}
									to={item.location}
									activeClassName={css.current}
									onClick={() => setIsSidebarShown(false)}
									className={item.type && css[item.type]}
								>
									<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' className={css.topCurve}>
										<path d='M0,100h100V0C100,55.2,55.2,100,0,100z' />
									</svg>

									<div className={css.navigationItemContent}>
										{item.iconType && item.isBadge ? (
											<BadgeIcon
												badge={{ color: 'orange' }}
												icon={{ type: item.iconType, color: 'white' }}
												className={css.navigationItemIcon}
											></BadgeIcon>
										) : (
											<Icon className={css.navigationItemIcon} type={item.iconType} color='white'></Icon>
										)}
										<span className={css.navigationItemTitle}>{item.title}</span>
									</div>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										viewBox='0 0 100 100'
										className={css.bottomCurve}
									>
										<path d='M0,100h100V0C100,55.2,55.2,100,0,100z' />
									</svg>
								</NavLink>
								{item.subItems && (
									<ul className={css.subMenuItems}>
										{item.subItems.map((subItem, index) => (
											<li key={index}>
												{subItem.action ? (
													<Button onClick={subItem.action} type='link' className={css.subMenuButton}>
														{subItem.title}
													</Button>
												) : (
													<NavLink
														exact={subItem.exact}
														to={subItem.location}
														activeClassName={css.current}
														onClick={() => setIsSidebarShown(false)}
														className={subItem.type && css[subItem.type]}
													>
														<div className={css.navigationItemContent}>
															<span className={css.navigationItemTitle}>{subItem.title}</span>
														</div>
													</NavLink>
												)}
											</li>
										))}
									</ul>
								)}
							</li>
						)}
					</>
				))}
			</ul>
		</div>
	);
};
