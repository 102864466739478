import React from 'react';
import css from './DataPanels.module.scss';
// import classes from 'classnames';
import { Panel } from '../Panel/Panel';

interface IDataPanelsProps {
	panel: IPanel[];
	totalInteractions: number;
}

interface IPanel {
	description: string;
	interactions: number;
}

export const DataPanels: React.FC<IDataPanelsProps> = props => {
	const { panel = [], totalInteractions } = props;

	return (
		<div className={css.dataPanel}>
			<div className={css.totalInteractionsContainer}>
				<div className={css.totalInteractionText}>
					<span className={css.totalInteractions}>{totalInteractions}</span>
					<span className={css.interactionsLabel}>total</span>
					<span className={css.interactionsLabel}>interactions</span>
				</div>
			</div>
			<div className={css.panelsContainer}>
				{panel.map((panel, key) => (
					<Panel key={key} className={css.panel}>
						<span className={css.panelDescription}>{panel.description}</span>
						<span className={css.panelInteractionContainer}>{panel.interactions}</span>
					</Panel>
				))}
			</div>
		</div>
	);
};
