import { createAction } from 'typesafe-actions';
import { IPost } from 'common/interfaces/IPost';
import { AppThunkAction } from 'stores';
import { ActionType } from 'typesafe-actions';

// Define Actions for Redux-Observables and Redux-Thunk
// The createAction function is going to take the type and returns an action/function that
// executes a reducer.
export const incrementInteger = createAction('INCREMENT_INTEGER')();
export const decrementInteger = createAction('DECREMENT_INTEGER')();
export const incrementIntegerByAmount = createAction('INCREMENT_BY_AMOUNT')<number>();

export const getPostRequest = createAction('GET_POST_REQUEST')();
export const getPostSuccess = createAction('GET_POST_SUCCESS')<IPost>();
export const getPostError = createAction('GET_POST_ERROR')<any>();

//Redux Thunk Example Below
type exampleStoreActionTypes = ActionType<
	| typeof incrementInteger
	| typeof decrementInteger
	| typeof incrementIntegerByAmount
	| typeof getPostRequest
	| typeof getPostSuccess
	| typeof getPostError
>;

//
// Redux Thunk is a middleware that lets you call action creators that
// return a function instead of an action object.
// Thunk takes in Dispatch and State
// Dispatch allows us to call multiple reducers.
export const getPost = (): AppThunkAction<exampleStoreActionTypes> => {
	return async (dispatch, getState) => {
		dispatch({ type: 'GET_POST_REQUEST' });
	};
};
