import * as React from 'react';

function SvgFrown(props) {
	return (
		<svg enableBackground='new 0 0 24 24' viewBox='0 0 24 24' {...props}>
			<g
				style={{
					fill: 'none',
					stroke: '#000',
					strokeWidth: 2,
					strokeLinecap: 'round',
					strokeLinejoin: 'round',
					strokeMiterlimit: 10,
				}}
			>
				<circle cx={12} cy={12} r={11} />
				<path d='m8.002 17c.912-1.214 2.365-2 4-2 1.634 0 3.085.784 3.998 1.996' />
				<path d='m16 10v.01' />
				<path d='m8 10v.01' />
			</g>
		</svg>
	);
}

export default SvgFrown;
