import React, { useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useAuth } from 'components/Auth';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import { Input } from 'components/Form/Input';
import { Button } from 'components/Button';
import css from './LoginView.module.scss';
import { FormFooter } from 'components/Form/FormFooter';
import { Alert } from 'components/Alert';
import { AuthPageFrame } from 'components/AuthPageFrame';

export const LoginView = () => {
	const { userLogin, loginErrorMessage } = useAuth();
	const location = useLocation();

	const [successMessage, setSuccessMessage] = useState(location?.state?.successMessage);

	return (
		<AuthPageFrame title='Sign in' HTMLTitle='Sign In' metaDescription='Sign in to your Culch account.'>
			<>
				{successMessage && <Alert type='success-light'>{successMessage}</Alert>}
				<Formik
					initialValues={{ email: '', password: '' }}
					validateOnChange={false}
					validateOnBlur={false}
					onSubmit={async (data, { setSubmitting }) => {
						setSubmitting(true);
						setSuccessMessage('');
						try {
							await userLogin(
								data.email,
								data.password,
								location.state?.from?.pathname + location.state?.from?.search
							);
						} catch {
							setSubmitting(false);
						}
					}}
					validationSchema={yup.object().shape({
						email: yup
							.string()
							.email('Email is not a valid email')
							.required('Email is not valid'),
						password: yup.string().required('Password is required')
					})}
				>
					{props => {
						return (
							<>
								{loginErrorMessage && !props.isSubmitting && (
									<Alert type='danger-light'>
										{loginErrorMessage && 'Invalid sign in, please try again.'}
									</Alert>
								)}

								<Form className={css.loginForm}>
									<div className={css.input}>
										<Input
											label={'Email:'}
											name={'email'}
											placeholder={'Enter your email'}
											autoFocus={true}
											autoComplete={'email'}
											formikProps={props}
											type='email'
											aria-invalid={props.errors.email && props.touched.email ? 'true' : null}
											labelClassName={css.inputLabel}
										></Input>
									</div>
									<Input
										label={'Password:'}
										name={'password'}
										type={'password'}
										placeholder={'Enter your password'}
										autoComplete={'current-password'}
										formikProps={props}
										aria-invalid={props.errors.password && props.touched.password ? 'true' : null}
										labelClassName={css.inputLabel}
									></Input>
									<FormFooter className={css.formFooter}>
										<Button
											type='primary'
											htmlType='submit'
											isLoading={props.isSubmitting}
											className={css.submitButton}
										>
											Sign In
										</Button>
										<Link to='/signup' className={css.signUpLink}>
											Sign Up
										</Link>
										<Link to='/forgotpassword' className={css.forgotPasswordLink}>
											Forgot password?
										</Link>
									</FormFooter>
								</Form>
							</>
						);
					}}
				</Formik>
			</>
		</AuthPageFrame>
	);
};

export default LoginView;
