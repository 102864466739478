import React from 'react';
import css from './Panel.module.scss';
import classes from 'classnames';

interface IPanelProps {
	title?: string;
	description?: string;
	hasPadding?: boolean;
	className?: string;
	doesPanelWrapTable?: boolean;
}

export const Panel: React.FC<IPanelProps> = props => {
	const { children, title, hasPadding = true, className, description, doesPanelWrapTable } = props;
	return (
		<div
			className={classes(
				css.panel,
				className,
				{ [css.hasPadding]: hasPadding },
				{ [css.doesPanelWrapTable]: doesPanelWrapTable }
			)}
		>
			{title && <h3 className={css.title}>{title}</h3>}
			{description && <p className={css.description}>{description}</p>}
			{children}
		</div>
	);
};
